import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import { Field, FieldArray, FieldProps, FormikProps } from 'formik'
import { get } from 'lodash'

import { isValidJson } from '../../utils/helper-functions'

import useMetadataFormStyles from './metadata-form.styles'

export interface MetadataType {
  key: string
  value: any
}

export interface IMetadataItems {
  metadataItems: MetadataType[]
}

type MetadataFormProps = IMetadataItems & {
  theme?: any
}

export const validateMetadataItems = (values: IMetadataItems) => {
  const { metadataItems } = values
  const errors: any = {
    metadataItems: [],
  }

  metadataItems.forEach(item => {
    if (!isValidJson(item.value)) {
      // If it doesnt exist, initialize it
      if (!errors.metadataItems) {
        errors.metadataItems = {}
      }
    } else {
      // Push a null item here so that value appear in the appropriate order (kind of hacky)
      errors.metadataItems.push(null)
    }
  })
  // If no items have errored, return empty object
  const filter = errors.metadataItems.filter((item: any) => item != null)
  if (filter.length <= 0) {
    return {}
  }
  return errors
}

export const fieldError = ({ touched, errors }: Pick<FormikProps<IMetadataItems>, 'touched' | 'errors'>, index: number, key: keyof MetadataType) => {
  if (get(touched, `metadataItems.${index}`) && get(errors, `metadataItems.${index}`)) {
    /* Check if field item has been touched before we deem it to have an error */
    const touchedItem = get(touched, `metadataItems.${index}`)
    /* Check if field item has errors associated with it */
    const errorItem = get(errors, `metadataItems.${index}`)
    /* Check if the key is touched */
    const wasTouched = touchedItem && touchedItem[key]
    /* Check if there is an error associated with our particular key */
    const error = errorItem && errorItem[key]
    return Boolean(wasTouched && error)
  } else {
    return false
  }
}

export const hasTrailingOrLeadingWhitespace = (val?: string): boolean => {
  if (typeof val === 'string') {
    if (val == null) {
      return false
    } else {
      return val?.trim() !== val
    }
  }
  return false
}

/** we are going to highlight metadata fields in orange, if one of the following conditions are met:
 * 1) metadata field has a trailing or leading whitespace
 * OR
 * 2) metadata field was touched, but was left empty
 * this is not enforced by validation, we just want to visually emphasize such fields and highlight them in orange
 */
export const showMetadataFieldWarning = (field: any, form: any, index: number, metadataFieldName: 'key' | 'value'): boolean => {
  const fieldTouchedButEmpty =
    field.value === '' &&
    form.touched &&
    form.touched.metadataItems &&
    form.touched.metadataItems[index] &&
    form.touched.metadataItems[index][metadataFieldName]
  const showWarning = hasTrailingOrLeadingWhitespace(field.value) || fieldTouchedButEmpty

  return showWarning
}

const MetadataForm = ({ metadataItems }: MetadataFormProps & IMetadataItems) => {
  const classes = useMetadataFormStyles()
  return (
    <FieldArray name="metadataItems">
      {arrayHelpers => (
        <div data-testid="metadata-fields-container">
          {metadataItems && metadataItems.length > 0 ? (
            metadataItems.map((item, index: number) => (
              <Grid container={true} spacing={3} justifyContent="center" data-testid="metadata-field-container" key={index} className={classes.rowWrapper}>
                <Grid item={true} container={true} spacing={3} xs={8} sm={8} md={9} lg={9} xl={9}>
                  <Grid item={true} xs={6}>
                    <Field name={`metadataItems.${index}.key`}>
                      {({ field, form }: FieldProps<any>) => {
                        const { touched, errors } = form
                        const label = `Key #${index + 1}`
                        const fieldDidError = fieldError({ touched, errors }, index, 'key')

                        return (
                          <div>
                            <TextField
                              {...field}
                              value={field.value}
                              error={fieldDidError}
                              fullWidth={true}
                              InputLabelProps={{
                                classes: {
                                  root: classNames({
                                    [classes.cssLabel]: showMetadataFieldWarning(field, form, index, 'key'),
                                  }),
                                },
                              }}
                              inputProps={{
                                'aria-label': label,
                                'aria-labelledby': `${field.name}-key-label`,
                              }}
                              InputProps={{
                                classes: {
                                  root: classNames({
                                    [classes.warningOutlinedInput]: showMetadataFieldWarning(field, form, index, 'key'),
                                  }),
                                  notchedOutline: classNames({
                                    [classes.notchedOutline]: showMetadataFieldWarning(field, form, index, 'key'),
                                  }),
                                },
                              }}
                              variant="outlined"
                              data-cy="metaKey"
                              label={label}
                            />

                            {fieldDidError === true && (
                              <FormHelperText data-testid="component-error-text" data-cy="requiredText">
                                {get(errors, `metadataItems.${index}.key`, '4 characters or more')}
                              </FormHelperText>
                            )}

                            {!fieldDidError && hasTrailingOrLeadingWhitespace(field.value) && (
                              <FormHelperText className={classes.warningNotification}>Contains trailing or leading white space</FormHelperText>
                            )}
                          </div>
                        )
                      }}
                    </Field>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <Field name={`metadataItems.${index}.value`}>
                      {({ field, form }: FieldProps<any>) => {
                        const { touched, errors } = form
                        const label = `Value #${index + 1}`
                        const fieldDidError = fieldError({ touched, errors }, index, 'value')

                        return (
                          <div>
                            <TextField
                              {...field}
                              error={fieldDidError}
                              variant="outlined"
                              data-cy="metaValue"
                              fullWidth={true}
                              InputLabelProps={{
                                classes: {
                                  root: classNames({
                                    [classes.cssLabel]: showMetadataFieldWarning(field, form, index, 'value'),
                                  }),
                                },
                              }}
                              InputProps={{
                                'aria-label': label,
                                'aria-labelledby': `${field.name}-value-label`,
                                classes: {
                                  root: classNames({
                                    [classes.warningOutlinedInput]: showMetadataFieldWarning(field, form, index, 'value'),
                                  }),
                                  notchedOutline: classNames({
                                    [classes.notchedOutline]: showMetadataFieldWarning(field, form, index, 'value'),
                                  }),
                                },
                              }}
                              // this is very confusing, but overriding classNames has to happen
                              // inside InputProps, while data-testid has to happen
                              // inside inputProps. If you combine those too, then either conditional styling above won't work
                              // or RTL will say that this textfiled doens't have value setters and you won't be able to add tests
                              inputProps={{
                                'data-testid': `${field.name}-metadata-val`,
                              }}
                              label={label}
                            />
                            {fieldDidError === true && (
                              <FormHelperText id="component-error-text">{get(errors, `metadataItems.${index}.value`, '4 characters or more')}</FormHelperText>
                            )}

                            {!fieldDidError && hasTrailingOrLeadingWhitespace(field.value) && (
                              <FormHelperText className={classes.warningNotification}>Contains trailing or leading white space</FormHelperText>
                            )}
                          </div>
                        )
                      }}
                    </Field>
                  </Grid>
                </Grid>

                <Grid item={true} container={true} alignItems="center" spacing={4} xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Grid item={true} xs={4}>
                    <Fab
                      onClick={() => arrayHelpers.remove(index)}
                      size="small"
                      data-cy="removeKeyValue"
                      data-testid="metadata-remove-field"
                      aria-label="Remove"
                    >
                      <RemoveIcon />
                    </Fab>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Fab
                      onClick={() => arrayHelpers.insert(index + 1, { key: '', value: '' })}
                      size="small"
                      data-cy="addKeyValue"
                      data-testid="metadata-add-field"
                      aria-label="Add"
                    >
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item={true} xs={4}>
                    {/* empty grid item to match themeForm */}
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid container={true} item={true} xs={6}>
              <Button
                color="secondary"
                variant="outlined"
                data-cy="addMetaDataBtn"
                data-testid="metadata-add-item-action"
                onClick={() => arrayHelpers.push({ key: '', value: '' })}
              >
                Add Metadata Item
              </Button>
            </Grid>
          )}
        </div>
      )}
    </FieldArray>
  )
}

export default MetadataForm
