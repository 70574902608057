import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import ListItem from '@mui/material/ListItem'
import Tooltip from '@mui/material/Tooltip'

import useListItemStyles from './list-item-link.styles'

type ListItemLinkProps = {
  to: string | { pathname: string; search: string }
  linkName: string
}

type DisabledItemLink = {
  itemName: string
}

export const ListItemLink = (props: ListItemLinkProps) => {
  const classes = useListItemStyles()
  const renderLink = forwardRef((itemProps: any, ref) => <NavLink to={props.to} {...itemProps} activeClassName={classes.activeMenuItem} ref={ref} />)
  const { linkName } = props

  return (
    <li>
      <ListItem button={true} component={renderLink} className={classes.listItem}>
        <div className={classes.menuItem}>{linkName}</div>
      </ListItem>
    </li>
  )
}

export const DisabledListItem = (props: DisabledItemLink) => {
  const classes = useListItemStyles()

  return (
    <Tooltip title={`You do not have permissions to read ${props.itemName}`} placement="bottom-end">
      <ListItem className={classes.listItem}>
        <div className={classes.disabledSidenavItem}>{props.itemName}</div>
      </ListItem>
    </Tooltip>
  )
}
