import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

export const DriversListView = () => {
  const classes = useTableStyles()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  const [drivers, setDrivers] = useState<IDriverType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const searchResultsHook = useSearchData()

  useDocumentTitle('Drivers')

  const columns = [
    { name: 'Name', options: { filter: true, sort: true } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchDriversData = async () => {
    const retrievedDrivers = await Api.get('/api/drivers')

    if (retrievedDrivers.error) {
      addAlert({ alertType: 'error', message: retrievedDrivers?.message || 'Could not load drivers' })
      return Promise.reject(retrievedDrivers)
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = retrievedDrivers.map((driver: IDriverType) => {
      return [driver.name, convertTimestampToDate(driver.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setDrivers(retrievedDrivers) })
  }

  const NewDriverLink = forwardRef((linkProps: any, ref: any) => <Link to="/drivers/new" ref={ref} {...linkProps} />)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchDriversData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  return (
    <Grid className={classes.tableContainer} data-cy="driversList" container={true} justifyContent="flex-end">
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewDriverLink}
              entityName="Drivers"
              buttonName="Add Driver"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Drivers"
          idList={drivers != null ? drivers.map(d => ({ id: d.id })) : []}
          entityDeleteEndpoint="/api/drivers"
          refresh={() => setRefreshCounter(c => c + 1)}
          preserveSearchResults={{ ...searchResultsHook }}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, drivers, 'drivers', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="drivers"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!drivers?.find(driver => driver.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DriversListView
