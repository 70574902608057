import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import SnackbarContent from '@mui/material/SnackbarContent'
import { SvgIconProps } from '@mui/material/SvgIcon'

import useAlertStyles from './alert.styles'

export type AlertTypes = 'success' | 'warning' | 'error'

const variantIcon: { [key in AlertTypes]: React.ComponentType<SvgIconProps> } = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
}

export const titles: { [key in AlertTypes]: string } = {
  error: 'Error',
  success: 'Success',
  warning: 'Warning',
}

export interface AlertProps {
  alertType: AlertTypes /* Indicates if this is a warning, success or error alert */
  children?: React.ReactNode[] | React.ReactNode
  message?: string /* Message to be shared in alert */
}

const Alert = (props: AlertProps) => {
  const Icon = variantIcon[props.alertType]
  const classes = useAlertStyles()

  const messageContent = (
    <span className={classes.alertDisplayMessage}>
      <Icon className={classes.notificationIcon} />
      <span data-testid="alertText">
        {props.message}
        {props.children}
      </span>
    </span>
  )

  return (
    <div>
      <SnackbarContent elevation={0} className={classes[props.alertType]} aria-describedby="notification" message={messageContent} />
    </div>
  )
}

export default Alert
