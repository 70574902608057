export const JSONHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const get = async (endpoint: string, options?: object, headersNeeded?: boolean) => {
  /**
   * @param endpoint - string: API endpoint without preceding forward slash
   *
   * @returns Promise<Array> - Promise that is resolved or rejected containing API responce
   */
  let response: any = await await fetch(`${endpoint}`, {
    method: 'GET',
    headers: JSONHeaders,
    credentials: 'same-origin',
    // remove this cache fix once https://meshify.atlassian.net/browse/BET-2255 is fixed
    cache: endpoint === '/api/folders/tree' ? 'no-store' : undefined,
    ...options,
  })
  const textResponse = response.clone()
  const responseHeaders = response.headers

  try {
    response = await response.json()
    if (response.status >= 400) {
      response = { error: true, message: response.detail, statusCode: textResponse.status }
    }
    // v2 endpoints
    if (response.code) {
      response = { error: true, message: response.message, statusCode: textResponse.status }
    }
  } catch (e) {
    if (textResponse.status >= 400) {
      const errorMessage = await textResponse.text()
      return {
        error: true,
        message: errorMessage,
        statusCode: textResponse.status,
      }
    } else {
      response = await textResponse.text()
    }
  }
  if (headersNeeded) {
    return [response, responseHeaders]
  }
  return response
}

const put = async (endpoint: string, data: object, options?: object) => {
  /**
   * @param endpoint - string: API endpoint without preceding forward slash
   * @param data - Object containing data to be sent to API
   * @returns Promise<Array> - Promise that is resolved or rejected containing API responce
   */
  let response: any = await await fetch(`${endpoint}`, {
    method: 'PUT',
    headers: JSONHeaders,
    credentials: 'same-origin',
    body: JSON.stringify(data),
    ...options,
  })
  const textResponse = response.clone()
  try {
    response = await response.json()
    if (response.status >= 400) {
      response = { error: true, message: response.detail }
    }
    // v2 endpoints
    if (response.code) {
      response = { error: true, message: response.message }
    }
  } catch (e) {
    if (textResponse.status >= 400) {
      const errorMessage = await textResponse.text()
      return {
        error: true,
        message: `Error in put for ${endpoint}: ${errorMessage}`,
      }
    } else {
      response = await textResponse.text()
    }
  }

  return response
}

const post = async (endpoint: string, data: object, options?: object) => {
  /**
   * @param endpoint - string: API endpoint without preceding forward slash
   * @param data - Object containing data to be sent to API
   * @returns Promise<Array> - Promise that is resolved or rejected containing API responce
   */

  let response: any = await await fetch(`${endpoint}`, {
    method: 'POST',
    headers: JSONHeaders,
    credentials: 'same-origin',
    body: JSON.stringify(data),
    ...options,
  })
  const textResponse = response.clone()
  try {
    response = await response.json()
    if (response.status >= 400) {
      response = { error: true, message: response.detail }
    }
    // v2 endpoints
    if (response.code) {
      response = { error: true, message: response.message }
    }
  } catch (e) {
    if (textResponse.status >= 400) {
      const errorMessage = await textResponse.text()
      return {
        error: true,
        message: `Error in post for ${endpoint}: ${errorMessage}`,
      }
    } else {
      response = await textResponse.text()
    }
  }
  return response
}

const patch = async (endpoint: string, data: object, options?: object) => {
  /**
   * @param endpoint - string: API endpoint without preceding forward slash
   * @param data - Object containing data to be sent to API
   * @returns Promise<Array> - Promise that is resolved or rejected containing API responce
   */

  let response: any = await await fetch(`${endpoint}`, {
    method: 'PATCH',
    headers: JSONHeaders,
    credentials: 'same-origin',
    body: JSON.stringify(data),
    ...options,
  })
  const textResponse = response.clone()
  try {
    response = await response.json()
    if (response.status >= 400) {
      response = { error: true, message: response.detail }
    }
    // v2 endpoints
    if (response.code) {
      response = { error: true, message: response.message }
    }
  } catch (e) {
    if (textResponse.status >= 400) {
      const errorMessage = await textResponse.text()
      return {
        error: true,
        message: `Error in patch for ${endpoint}: ${errorMessage}`,
      }
    } else {
      response = await textResponse.text()
    }
  }
  return response
}

const del = async (endpoint: string, item?: any, options?: object) => {
  /**
   * @param endpoint - string: API endpoint without preceding forward slash
   * @param item - String indicating particular data that is to be deleted.
   * @returns Promise<Array> - Promise that is resolved or rejected containing API responce
   */

  let response: any = null
  if (!item) {
    response = await fetch(`${endpoint}`, {
      method: 'DELETE',
      headers: JSONHeaders,
      credentials: 'same-origin',
      ...options,
    })
  } else {
    const URL = endpoint[endpoint.length - 1] === '/' ? `${endpoint}${item}` : `${endpoint}/${item}`
    response = await fetch(URL, {
      method: 'DELETE',
      headers: JSONHeaders,
      credentials: 'same-origin',
      ...options,
    })
  }

  const textResponse = response.clone()
  try {
    response = await response.json()
    if (response.status >= 400) {
      response = { error: true, message: response.detail }
    }
    // v2 endpoints
    if (response.code) {
      response = { error: true, message: response.message }
    }
  } catch (e) {
    if (textResponse.status >= 400) {
      const errorMessage = await textResponse.text()
      return {
        error: true,
        message: `Error in delete for ${endpoint}: ${errorMessage}`,
      }
    } else {
      response = await textResponse.text()
    }
  }
  return response
}

const getPhoneInfo = async (phoneNumber: string, options?: object) => {
  /**
   * @param phoneNumber - string: phone number to look up
   *
   * @returns Promise<Array> - Promise that is resolved or rejected containing API responce
   */
  let response: any = await await fetch(`/api/users/phoneinfo?number=${phoneNumber}`, {
    method: 'GET',
    headers: JSONHeaders,
    credentials: 'same-origin',
    ...options,
  })
  const textResponse = response.clone()

  try {
    response = await response.json()
    if (response.status >= 400) {
      response = { error: true, message: response.detail }
    }
  } catch (e) {
    if (textResponse.status >= 400) {
      const errorMessage = await textResponse.text()
      const message = textResponse.status === 404 ? 'Not valid mobile number' : `Error in get for /api/: ${errorMessage}`
      return {
        error: true,
        message,
      }
    } else {
      response = await textResponse.text()
    }
  }
  return response
}

/**
 * This function will be used for file upload only
 * We should see if we can refactor this and make put and putFile be one function
 *
 */
const putFile = async (endpoint: string, data: any, options?: any) => {
  const headers = JSONHeaders
  if (options && options.headers && options.headers.Accept) {
    // headers["Accept"] = "text/html"
    headers.Accept = options.headers.Accept
  }

  let response: any = await await fetch(`${endpoint}`, {
    method: 'PUT',
    headers,
    credentials: 'same-origin',
    body: data,
  })
  const textResponse = response.clone()
  try {
    response = await response.json()
    if (response.status >= 400) {
      response = { error: true, message: response.detail }
    }
  } catch (e) {
    if (textResponse.status >= 400) {
      const errorMessage = await textResponse.text()

      if (options && options.headers && options.headers.Accept === 'text/html') {
        return errorMessage
      } else {
        return {
          error: true,
          message: `Error in put for ${endpoint}: ${errorMessage}`,
        }
      }
    } else {
      response = await textResponse.text()
    }
  }
  return response
}

const getFile = async (endpoint: string, fileType: string, options?: object) => {
  /**
   * @param endpoint - string: API endpoint without preceding forward slash
   * @param fileType - string: 'image', or 'text', to get text files or images
   * @returns Promise<Array> - Promise that is resolved or rejected containing API responce
   */

  let response: any = await await fetch(`${endpoint}`, {
    method: 'GET',
    headers: JSONHeaders,
    credentials: 'same-origin',
    ...options,
  })
  const textResponse = response.clone()
  try {
    response = (await (fileType === 'image')) ? response.blob() : response
  } catch (e) {
    return {
      error: true,
      message: `Error in get for ${endpoint}: ${textResponse.status >= 400 ? await textResponse.text() : (e as Error).message}`,
    }
  }
  return response
}

export default { get, put, post, patch, del, getPhoneInfo, putFile, getFile }
