import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { NumberParam, useQueryParam } from 'use-query-params'

import useReactionFormStyles from './create-edit.styles'
import ReactionForm from './reaction-form'
import SingleReactionHistory from './reaction-log-tab'

export const ReactionsForm = () => {
  const classes = useReactionFormStyles()
  const params = useParams<{ reactionId: string }>()
  const isEditMode = params.reactionId !== 'new'

  const defaultTabIndex = 0

  const [selectedTabIndex, setSelectedTabIndex] = useQueryParam('tab', NumberParam)

  useEffect(() => {
    if (!selectedTabIndex) {
      setSelectedTabIndex(0)
    }
  }, [selectedTabIndex, setSelectedTabIndex])

  return (
    <>
      {!isEditMode && (
        <Card data-cy="reactionTitle">
          <CardHeader title="Create Reaction" data-cy="reactionTitle" />
          <ReactionForm />
        </Card>
      )}

      {isEditMode && (
        <>
          <Card>
            <CardHeader title={isEditMode ? 'Edit Reaction' : 'Create Reaction'} data-cy="reactionTitle" />

            <Tabs
              value={selectedTabIndex ? selectedTabIndex : defaultTabIndex}
              onChange={(event: React.ChangeEvent<{}>, value: number) => setSelectedTabIndex(value, 'push')}
              textColor="secondary"
              indicatorColor="secondary"
              className={classes.tabs}
            >
              <Tab label="Settings" />
              <Tab label="Reaction Log" data-testid="reactionLogTab" />
            </Tabs>

            {selectedTabIndex === 0 && <ReactionForm />}
          </Card>

          {selectedTabIndex === 1 && <SingleReactionHistory reactionId={params.reactionId} />}
        </>
      )}
    </>
  )
}

export default ReactionsForm
