import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import useIconFormStyles from './create-edit.styles'
import IconPreview from './icon-preview'

interface IIconValues {
  color: string
  description: string
  level: string
  name: string
  shape: string
}

interface IIcon {
  color: string
  description: string
  level: number
  name: string
  shape: string
}

const IconForm = () => {
  const classes = useIconFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ iconId: string }>()
  const iconId = params.iconId
  const isEditMode = iconId !== 'new'

  const [currentIcon, setCurrentIcon] = useState<IIconType | null>(null)
  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  useDocumentTitle('Icons', isEditMode ? currentIcon?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/icons/${iconId}`).then(retrievedIcon => {
        redirectToListViewOnError(retrievedIcon, history, addAlert, '/icons')

        if (isSubscribed) {
          setCurrentIcon(retrievedIcon)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, iconId, history, addAlert])

  const shapes = ['circle', 'square', 'fire', 'cross', 'warning', 'gps', 'star', 'blocked', 'triangle']

  const saveIcon = async (values: IIconValues, actions: FormikHelpers<IIconValues>) => {
    const createFolderPayload: IIcon = {
      color: values.color,
      description: values.description,
      level: +values.level,
      name: values.name,
      shape: values.shape,
    }

    try {
      if (isEditMode) {
        handleFormSubmit(await Api.put(`/api/icons/${iconId}`, createFolderPayload), addAlert, history, null, values, actions)
      } else {
        let createdIcon
        handleFormSubmit((createdIcon = await Api.post('/api/icons', createFolderPayload)), addAlert, history, `/icons/${createdIcon.id}`, values, actions)
      }
    } catch (e) {
      addAlert({ alertType: 'error', message: 'Could not submit the icon' })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Icon name is required'),
    level: Yup.number().required('Level is required'),
    color: Yup.string().required('Color is required'),
    shape: Yup.string().required('Shape is required'),
  })

  const deleteIcon = async () => {
    try {
      handleDelete(await Api.del('/api/icons', iconId), addAlert, history, '/icons')
    } catch (e) {
      addAlert({ alertType: 'error', message: 'Could not delete the icon' })
    }
  }

  const initialValues =
    isEditMode && currentIcon
      ? {
          name: currentIcon.name,
          level: String(currentIcon.level),
          color: currentIcon.color,
          shape: currentIcon.shape,
          description: currentIcon.description,
        }
      : {
          name: '',
          level: '',
          color: '',
          shape: '',
          description: '',
        }

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={schema} onSubmit={saveIcon}>
        {({ isValid, values, isSubmitting, dirty }: FormikProps<IIconValues>) => (
          <Form>
            <Card>
              <CardHeader title={isEditMode ? 'Edit Icon' : 'Create Icon'} data-cy="iconsTitle" />

              <CardContent>
                <Grid spacing={3} container={true}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormikTextField initialized={isEditMode} id="nameInput" data-cy="name" label="Name" name="name" required={true} />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Alert
                      alertType="warning"
                      message="Icons are prioritized by level. If a node triggers multiple rules, each with different icons, the node will take the icon with the highest level."
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormikTextField initialized={isEditMode} id="levelInput" data-cy="level" label="Level" name="level" required={true} type="number" />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <Typography className={classes.denseFormSubheader} variant="subtitle1">
                      Icon
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormikTextField initialized={isEditMode} id="colorInput" data-cy="color" label="Color" name="color" required={true} />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormikTextField
                      initialized={isEditMode}
                      data-cy="shape"
                      select={true}
                      label="Shape"
                      name="shape"
                      required={true}
                      helperText="Please select a shape"
                      hasEmptyOption={true}
                    >
                      {shapes
                        .sort((a, b) => {
                          return a.toLowerCase() < b.toLowerCase() ? -1 : a > b ? 1 : 0
                        })
                        .map((shape: string) => {
                          return (
                            <option key={shape} value={shape}>
                              {shape}
                            </option>
                          )
                        })}
                    </FormikTextField>
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Alert
                      alertType="warning"
                      message="When a node triggers a Rule, that node's icon changes in the Dashboard application's list and map views."
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <IconPreview isListView={false} color={values.color} shape={values.shape} />
                  </Grid>

                  <Box width="100%" />

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormikTextField
                      initialized={isEditMode}
                      id="descriptionInput"
                      data-cy="description"
                      label="Description"
                      name="description"
                      multiline={true}
                      variant="outlined"
                      className={classes.description}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                  Save
                </Button>

                <Button type="button" onClick={() => history.push('/icons')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                  Cancel
                </Button>

                {isEditMode && (
                  <Button type="button" data-cy="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal} data-testid="deleteBtn">
                    Delete
                  </Button>
                )}
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteIcon}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this icon?"
      />
    </>
  )
}

export default IconForm
