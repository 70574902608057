import { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { get } from 'lodash'
import { MUIDataTableColumn } from 'mui-datatables'

import { ServerSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import TableLink from '../../components/table-link'
import { UserPermissionsContext } from '../../providers'
import useTableStyles from '../../shared-styles/table.styles'
import api from '../../utils/api'
import { useDocumentTitle, usePagination, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

export const getFolders = (folderIds: number[], folderIdMap: { [key: number]: string }) => {
  return folderIds
    .map(folderId => {
      if (folderIdMap[folderId] == null) {
        return null
      }
      return { name: folderIdMap[folderId], id: folderId }
    })
    .filter(item => item != null)
}

const SubscriptionListView = () => {
  const classes = useTableStyles()
  const history = useHistory()

  const pageHook = usePagination()

  const [users, setUsers] = useState<IUserType[] | null>(null)
  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)

  useDocumentTitle('Subscriptions')

  const columns: MUIDataTableColumn[] = [
    { name: 'Email', options: { filter: true, sort: true } },
    { name: 'First Name', options: { filter: true, sort: true } },
    { name: 'Last Name', options: { filter: true, sort: true } },
    { name: 'Role', options: { filter: true, sort: false } },
    { name: 'Folders', options: { filter: true, sort: false } },
  ]

  const RoleName = ({ user }: { user: IUserType }) => {
    return (
      <TableLink
        className={classes.applicationLink}
        to={`/roles/${user.roleId}`}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {user.roleName}
      </TableLink>
    )
  }

  const formatData = (fetchedUsers: IUserType[] | null): React.ReactNode[][] => {
    return fetchedUsers && fetchedUsers.length
      ? fetchedUsers.map((user: IUserType, index: number) => {
          const userFolders = user.foldersDetail ? getFolders(user.folders, user.foldersDetail) : []
          const firstFolder = userFolders.length > 0 && userFolders[0]
          const folderNameList =
            userFolders.length > 0 ? (
              <Grid container={true}>
                <TableLink
                  to={firstFolder ? `/folders/${firstFolder.id}` : ''}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  {firstFolder && firstFolder.name}
                </TableLink>
                {userFolders.length > 1 ? (
                  <span
                    data-testid="moreFolders"
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    , ...
                  </span>
                ) : null}
              </Grid>
            ) : (
              '...'
            )

          return [user.email, get(user, 'information.first', 'NA'), get(user, 'information.last', 'NA'), <RoleName key={index} user={user} />, folderNameList]
        })
      : []
  }

  const sanitizeColName = (colName: string) => {
    switch (colName) {
      case 'Email':
        return 'email'
      case 'First Name':
        return 'firstName'
      case 'Last Name':
        return 'lastName'
      case 'Modified':
        return 'updatedAt'
      default:
        return ''
    }
  }

  const fetchUserData = async () => {
    let fetchedUsers: IUserType[] = []
    const { offset, limit, searchText, currentSort, sortDir } = pageHook

    const queryBody: IQueryBody = {
      type: 'or',
      first: limit,
      orderBy: currentSort ? sanitizeColName(currentSort) : undefined,
      orderDirection: sortDir !== 'none' ? sortDir : undefined,

      filters: [
        { fieldName: 'email', operator: 'multilike', value: searchText },
        { fieldName: 'firstName', operator: 'multilike', value: searchText },
        { fieldName: 'lastName', operator: 'multilike', value: searchText },
      ],
      after: offset ? btoa(String(offset)) : undefined,
    }
    const res = await api.post('/api/users/query?detail=true', queryBody)
    const { totalCount, results } = res
    fetchedUsers = results
    const totalEntityCount = Number(totalCount)

    if (res.error) {
      return Promise.reject(res)
    }

    if (!fetchedUsers.length) {
      return Promise.resolve({
        data: [],
        setEntities: () => setUsers(fetchedUsers),
        setTotalRows: () => pageHook.setTotalRows(Number(totalEntityCount)),
      })
    }

    const paginatedData: React.ReactNode[][] = formatData(fetchedUsers)
    return Promise.resolve({
      data: paginatedData,
      setEntities: () => setUsers(fetchedUsers),
      setTotalRows: () => pageHook.setTotalRows(Number(totalEntityCount)),
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchUserData, [
    pageHook.searchText,
    pageHook.pageNumber,
    pageHook.rowsPerPage,
    pageHook.currentSort,
    pageHook.sortDir,
    pageHook.rowsDeletedCounter,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoFormatData = useCallback(formatData, [userPermissions])

  const { loading, data, error } = useTableState(memoizedFetch, memoFormatData, users)

  return (
    <Grid justifyContent="center" className={classes.tableContainer} container={true} data-cy="subscriptionsList">
      <Grid item={true} xs={12} data-cy="subscriptionsGrid">
        <ServerSidePaginatedTable
          title="Subscriptions"
          pagination={{ ...pageHook }}
          columns={columns}
          loading={loading}
          error={error}
          data={data}
          idList={users != null && users.length ? users.map(u => ({ id: u.id })) : []}
          entityDeleteEndpoint="/api/users"
          refresh={pageHook.refreshTable}
          options={{
            selectableRows: 'none',
            sortOrder: pageHook.sortDir !== 'none' ? { name: pageHook.currentSort, direction: pageHook.sortDir } : undefined,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, users, 'subscriptions', cellMeta, pageHook.abort),
            textLabels: {
              body: {
                noMatch: 'Sorry, no records founds',
                toolTip: '',
              },
            },
            customSearchRender: (searchText, handleSearch) => {
              return <CustomSearchBox searchText={searchText} handleSearch={handleSearch} entityPath="users" abortToken={pageHook.abort} />
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SubscriptionListView
