import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    defaultScheduleSubheader: {
      margin: '40px 0 30px 0',
      color: theme.palette.grey[600],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    topMargin: {
      marginTop: theme.spacing(3),
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
