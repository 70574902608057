import { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Checkbox, Divider, Fab, FormControlLabel, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import classNames from 'classnames'
import { FastField, FieldProps } from 'formik'
import moment from 'moment'

import { useToggle } from '../../../utils/hooks'
import ConfirmDeletionModal from '../../modal'
import MultiSelect from '../multiselect/non-formik-multiselect'

import useDateScheduleStyles from './date-schedule.styles'

const allDaysOfWeek = [
  { displayName: 'Sunday', id: 0 },
  { displayName: 'Monday', id: 1 },
  { displayName: 'Tuesday', id: 2 },
  { displayName: 'Wednesday', id: 3 },
  { displayName: 'Thursday', id: 4 },
  { displayName: 'Friday', id: 5 },
  { displayName: 'Saturday', id: 6 },
]

interface IDateScheduleItems {
  name: string
  schema?: INotificationSchemaType // schemas passed in to find the default value if needed
}

type DateScheduleProps = IDateScheduleItems & {
  theme?: any
  helperText?: string
  required: boolean
  showHelperText: boolean
  showScheduleLabel?: boolean
  hasBusinessClosed: boolean // to show 'business is closed' checkbox
  hasOpenAllDay: boolean // to show 'open 24 hours' checkbox
}

interface IDaysOfWeekType {
  displayName: string
  id: number
}

interface IScheduleDisplayType {
  days: IDaysOfWeekType[]
  startTime: moment.Moment | null
  endTime: moment.Moment | null
}

interface IBusinessScheduleOptions {
  [scheduleNum: string]: boolean
}

// parses the string into an array of objects for easy display
export const parseStringToArray = (scheduleString: string): IScheduleDisplayType[] => {
  let schedules = []
  if (scheduleString.includes(';')) {
    schedules = scheduleString.split(';')
  } else {
    schedules.push(scheduleString)
  }

  return schedules.map(schedule => {
    const sections = schedule.split('|')
    const days = sections[0]
      .trim()
      .split('')
      .map(id => {
        return allDaysOfWeek[parseInt(id, 10)]
      })

    const times = sections[1].split('-')
    const startTime = times[0] ? moment(times[0].trim(), 'HH:mm') : null
    const endTime = times[1] ? moment(times[1].trim(), 'HH:mm') : null
    return { days, startTime, endTime }
  })
}

// parses the display array to a string, to set the formik field value.
export const parseArrayToString = (schemas: IScheduleDisplayType[]): string => {
  return schemas
    .reduce<string[]>((accum, cv) => {
      const daysString = cv.days.map((day: IDaysOfWeekType) => day.id).join('')
      const startTimeString = cv.startTime ? moment(cv.startTime).format('HH:mm') : ''
      const endTimeString = cv.endTime ? moment(cv.endTime).format('HH:mm') : ''
      const singleSchedule = `${daysString}|${startTimeString}-${endTimeString}`
      accum.push(singleSchedule)
      return accum
    }, [])
    .join(';')
    .trim()
}

const InnerForm = (props: FieldProps<any> & DateScheduleProps) => {
  const { field, form, schema, required, name, showHelperText, showScheduleLabel, hasBusinessClosed, hasOpenAllDay } = props
  let { helperText } = props

  const [businessClosed, setBusinessClosed] = useState<IBusinessScheduleOptions>({})
  const [openAllDay, setOpenAllDay] = useState<IBusinessScheduleOptions>({})

  const classes = useDateScheduleStyles()

  const [schedules, setSchedules] = useState<IScheduleDisplayType[]>([])
  const [showConfirmDeletionModal, toggleConfirmDeletionModal] = useToggle(false)
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null)

  const { errors, touched } = form
  const { value } = field
  helperText = helperText != null ? helperText : ''

  useEffect(() => {
    let isSubscribed = true
    if (value) {
      if (isSubscribed) {
        setSchedules(parseStringToArray(value))
      }
    } else if (schema) {
      if (isSubscribed) {
        setSchedules(parseStringToArray(schema.defaultSchedule))
      }
    }

    return () => {
      isSubscribed = false
    }
  }, [value, schema])

  // if business is closed, set the business is closed state properly for each schedule
  // business is considered closed if the schedule is 00:00-00:00
  useEffect(() => {
    if (hasBusinessClosed && schedules && schedules.length) {
      schedules.forEach((schedule, index) => {
        const start = moment(schedule.startTime).format('HH:mm')
        const end = moment(schedule.endTime).format('HH:mm')

        if (start === '00:00' && end === '00:00') {
          setBusinessClosed(prevState => ({
            ...prevState,
            [`schedule-${index}`]: true,
          }))
        } else {
          setBusinessClosed(prevState => ({
            ...prevState,
            [`schedule-${index}`]: false,
          }))
        }
      })
    }
  }, [schedules, hasBusinessClosed])

  // if business is open 24h, set the business is open all day state properly for each schedule
  // business is considered open 24hours if the schedule is 00:00-23:59
  useEffect(() => {
    if (hasOpenAllDay && schedules && schedules.length) {
      schedules.forEach((schedule, index) => {
        const start = moment(schedule.startTime).format('HH:mm')
        const end = moment(schedule.endTime).format('HH:mm')

        if (start === '00:00' && end === '23:59') {
          setOpenAllDay(prevState => ({
            ...prevState,
            [`schedule-${index}`]: true,
          }))
        } else {
          setOpenAllDay(prevState => ({
            ...prevState,
            [`schedule-${index}`]: false,
          }))
        }
      })
    }
  }, [schedules, hasOpenAllDay])

  const handleChange = (event: any, fieldName: keyof IScheduleDisplayType, index: number, setFieldValue: (field: string, value: any) => void) => {
    if (moment.isMoment(event)) {
      const newTime = moment(event).format('HH:mm')
      schedules[index][fieldName as 'startTime' | 'endTime'] = moment(newTime, 'HH:mm')
    } else {
      schedules[index][fieldName as 'days'] = event
    }
    setFieldValue(name, parseArrayToString(schedules))
  }

  const handleDeleteBtnClick = (index: number) => {
    setIndexToDelete(index)
    toggleConfirmDeletionModal()
  }

  const deleteSchedule = () => {
    // 0 is a valid index, so we need to check against null specifically
    if (indexToDelete !== null) {
      schedules.splice(indexToDelete, 1)
      form.setFieldValue(name, parseArrayToString(schedules))
      form.setFieldTouched(name, true, false)
      toggleConfirmDeletionModal()
    }
  }

  // if 'open 24 hrs' checkbox should exist, set default schedule to 9am-17pm
  // so that new schedule isn't added with 'open 24h' checkbox selected by default
  const addSchedule = (setFieldValue: any) => {
    const defaultSchedule = hasOpenAllDay ? '0123456|09:00-17:00' : '0123456|00:00-23:59'
    schedules.push(parseStringToArray(defaultSchedule)[0])
    setFieldValue(name, parseArrayToString(schedules))
  }

  const handleBusinessClosedChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, setFieldValue: (fieldName: string, val: string) => void) => {
    setBusinessClosed(prevState => ({
      ...prevState,
      [`schedule-${index}`]: event.target.checked,
    }))

    schedules[index].startTime = moment('00:00', 'HH:mm')
    schedules[index].endTime = moment('00:00', 'HH:mm')
    setFieldValue('defaultSchedule', parseArrayToString(schedules))
  }

  const handleOpen24hChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, setFieldValue: (fieldName: string, val: string) => void) => {
    setOpenAllDay(prevState => ({
      ...prevState,
      [`schedule-${index}`]: event.target.checked,
    }))

    schedules[index].startTime = moment('00:00', 'HH:mm')
    schedules[index].endTime = moment('23:59', 'HH:mm')
    setFieldValue('defaultSchedule', parseArrayToString(schedules))
  }

  // disable start and end time if either one of the checkboxes is checked
  const isTimepickerDisabled = (index: number): boolean => {
    let isDisabled = false

    if (businessClosed?.[`schedule-${index}`] || openAllDay?.[`schedule-${index}`]) {
      isDisabled = true
    }

    return isDisabled
  }

  return (
    <>
      <FormControl fullWidth={true} required={required} error={Boolean(touched[name] && errors[name])}>
        <Grid>
          {schedules.length > 0 && showHelperText && (
            <Grid item={true} xs={12}>
              <p className={classNames([classes.fullDayMargin, classes.fullDayWarningCopy])}>
                To set 24 hour range, please set start time to 00:00 and end time to 23:59
              </p>
            </Grid>
          )}

          {schedules.length > 0 &&
            schedules.map((schedule, index) => (
              <Grid container={true} direction="row" justifyContent="space-between" alignItems="center" key={index} data-testid={`dateScheduler-${index}`}>
                <Grid item={true} container={true} alignItems="center" justifyContent="space-between" xs={12}>
                  {showScheduleLabel && (
                    <Grid item={true} xs={12}>
                      <p className={classes.label}>Days and Times</p>
                    </Grid>
                  )}

                  <Grid item={true} xs={9} data-cy="days">
                    <MultiSelect
                      listItemsToSelect={allDaysOfWeek}
                      setValues={event => handleChange(event, 'days', index, form.setFieldValue)}
                      checkedValues={schedule.days}
                      required={false}
                      label="Days"
                    />
                  </Grid>

                  <Grid item={true}>
                    <Fab
                      aria-label="Delete"
                      data-testid="removeScheduleBtn"
                      data-cy="deleteScheduleBtn"
                      onClick={() => handleDeleteBtnClick(index)}
                      className={classes.deleteScheduleButton}
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </Fab>
                  </Grid>
                </Grid>

                <Grid item={true} container={true} alignItems="center" justifyContent="space-between" className={classes.timeSelectionContainer} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      label="Start Time"
                      ampm={false}
                      value={schedule.startTime}
                      onChange={event => handleChange(event, 'startTime', index, form.setFieldValue)}
                      data-cy="startTime"
                      disabled={isTimepickerDisabled(index)}
                      renderInput={params => <TextField {...params} variant="standard" className={classes.timePicker} />}
                    />
                  </LocalizationProvider>

                  <Typography className={classes.formSubheader} variant="subtitle1">
                    To
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      label="End Time"
                      ampm={false}
                      value={schedule.endTime}
                      onChange={event => handleChange(event, 'endTime', index, form.setFieldValue)}
                      data-cy="endTime"
                      disabled={isTimepickerDisabled(index)}
                      renderInput={params => <TextField {...params} variant="standard" className={classes.timePicker} />}
                    />
                  </LocalizationProvider>
                </Grid>

                {hasBusinessClosed && hasOpenAllDay && (
                  <Grid item={true} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={businessClosed.hasOwnProperty(`schedule-${index}`) ? businessClosed[`schedule-${index}`] : false}
                          onChange={e => handleBusinessClosedChange(e, index, form.setFieldValue)}
                          name="business-closed"
                          color="secondary"
                          inputProps={{
                            // @ts-ignore
                            'data-testid': `business-closed-${index}`,
                          }}
                        />
                      }
                      label="business is closed"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={openAllDay.hasOwnProperty(`schedule-${index}`) ? openAllDay[`schedule-${index}`] : false}
                          onChange={e => handleOpen24hChange(e, index, form.setFieldValue)}
                          name="business-open"
                          color="secondary"
                          inputProps={{
                            // @ts-ignore
                            'data-testid': `business-open-${index}`,
                          }}
                        />
                      }
                      label="open 24 hrs"
                    />
                  </Grid>
                )}

                <Grid item={true} container={true} alignItems="center" justifyContent="center" xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
            ))}

          <Grid item={true} container={true} alignItems="center">
            <Grid item={true}>
              <Button
                className={classes.scheduleButton}
                color="secondary"
                variant="outlined"
                aria-label="Add"
                data-testid="addScheduleBtn"
                data-cy="addScheduleBtn"
                onClick={() => addSchedule(form.setFieldValue)}
              >
                Add Schedule
              </Button>
            </Grid>

            <Grid item={true}>
              <FormHelperText data-testid="dateScheduleErrorText">{touched[name] && errors[name] ? errors[name] : helperText}</FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <ConfirmDeletionModal
        show={showConfirmDeletionModal}
        confirmModalAction={deleteSchedule}
        closeModal={toggleConfirmDeletionModal}
        dialogTitle="Are you sure you want to delete this schedule? Changes will not be applied until you click Save button on the main screen"
      />
    </>
  )
}

const DateSchedule = (props: DateScheduleProps) => {
  return <FastField name={props.name}>{(fieldProps: FieldProps<any>) => <InnerForm {...fieldProps} {...props} />}</FastField>
}

export default DateSchedule
