import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CircularProgress, Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import classNames from 'classnames'

import PermissionsFormError, { combineErrorMessages } from '../../../../components/permissions-form-error/form-error-message'
import { ImpersonateContext, OwnUserContext, UserPermissionsContext } from '../../../../providers'
import { hasImpersonatePermission } from '../../../users/list'
import useWhatsInsideStyles from '../whats-inside.styles'

interface IProps {
  folderUsers: IUserType[]
  isLoadingUsers: boolean
  folderUsersError: string
}

// we will show user email for users with email,
// or first and last name for users without an email on file
export const getUserContact = (userObj: IUserType): string => {
  if (userObj.email) {
    return userObj.email
  } else {
    return userObj.information && userObj.information.first && userObj.information.last ? userObj.information.first + ' ' + userObj.information.last : 'User'
  }
}

const WhatsInsideUsers = (props: IProps) => {
  const { folderUsers, isLoadingUsers, folderUsersError } = props
  const classes = useWhatsInsideStyles()

  const currentUser = useContext<IUserType | null>(OwnUserContext)
  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)

  const theme = useTheme()
  const smallScreenMatches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      {!isLoadingUsers && (
        <>
          {folderUsers.length > 0 && !folderUsersError && (
            <Grid item={true} xs={12}>
              <div className={classes.usersContainer} data-cy="usersList">
                {folderUsers.length > 0 &&
                  folderUsers.map(user => {
                    return (
                      <Grid item={true} xs={12} sm={12} md={8} lg={8} xl={8} key={user.id}>
                        <div className={classes.whatsInsideItemWrapper} data-testid="userInfo">
                          <Link className={classes.link} to={`/users/${user.id}`} data-cy="user">
                            {smallScreenMatches ? (
                              <Tooltip title={getUserContact(user)}>
                                <span className={classes.truncatedEmail}>{getUserContact(user)}</span>
                              </Tooltip>
                            ) : (
                              getUserContact(user)
                            )}
                          </Link>

                          <ImpersonateContext.Consumer>
                            {(context: IImpersonateContext | null) => {
                              return (
                                context &&
                                currentUser &&
                                !currentUser.impersonatedBy &&
                                hasImpersonatePermission(userPermissions) &&
                                currentUser.id !== user.id && (
                                  <span
                                    data-cy="impersonate"
                                    data-testid="users-list-impersonate-btn"
                                    className={classNames({
                                      [classes.impersonateLink]: true,
                                      [classes.applicationLink]: true,
                                    })}
                                    onClick={e => {
                                      e.stopPropagation()
                                      context.setImpersonatedUserId(user.id)
                                    }}
                                  >
                                    Impersonate
                                  </span>
                                )
                              )
                            }}
                          </ImpersonateContext.Consumer>
                        </div>
                      </Grid>
                    )
                  })}
              </div>
            </Grid>
          )}

          {!folderUsers.length && !folderUsersError && (
            <Grid item={true} xs={12}>
              <div className={classes.noItems} data-testid="noUserMessage">
                No users in this folder
              </div>
            </Grid>
          )}

          {folderUsersError && (
            <Grid item={true} xs={12}>
              <PermissionsFormError errorMessages={combineErrorMessages(folderUsersError)} formName="Folder Users" hasGoBackButton={false} />
            </Grid>
          )}
        </>
      )}

      {isLoadingUsers && (
        <div className={classes.spinnerWrapper}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  )
}

export default WhatsInsideUsers
