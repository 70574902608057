import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    app: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      height: '100%',
    },
    layoutWrapper: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    mainItemsWrapper: {
      flex: '1 1 auto',
      display: 'flex',
      height: 'inherit',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    fallback: {
      marginTop: 300,
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
