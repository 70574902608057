import { useCallback, useContext, useEffect, useState } from 'react'
import * as React from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'lodash'

import { StreamDispatchContext } from '../../../providers/websocket-stream'
import Api from '../../../utils/api'
import { useTableState } from '../../../utils/hooks'
import useCurrentValuesStyles from '../create-edit.styles'

import EditChannel from './edit-channel-current-values'
import { LiveStreamBtn } from './live-stream-btn'
import { Table as OffSchemaChannelsTable } from './off-schema-current-values'
import { Table as OnSchemaValuesTable } from './on-schema-current-values'
import { ChannelHistory } from './on-schema-history'

export const parseChannelValue = (channelValue: any): string => {
  if (channelValue !== null) {
    if (typeof channelValue !== 'object') {
      return channelValue.toString()
    } else {
      return JSON.stringify(channelValue)
    }
  } else {
    return ''
  }
}

const CustomTableHeader = ({ hasValues }: { hasValues?: boolean }) => (
  <div>
    <Typography variant="h6">Channels</Typography>
    {hasValues && <Typography variant="caption">Please click on a channel to view its history</Typography>}
  </div>
)

const CurrentValues = () => {
  const [currentNodeValues, setCurrentNodeValues] = useState<ICurrentValueType>({})
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null)

  const classes = useCurrentValuesStyles()
  const { dispatch, nodeStreaming } = useContext(StreamDispatchContext)
  const params = useParams<{ nodeId: string; currentValueId: string }>()
  const history = useHistory()

  const activeRoute = params?.nodeId ?? params?.currentValueId ?? ''
  const nodeId = activeRoute ?? ''

  const { subscribed, selectedNodeId } = nodeStreaming
  const ourNodeStreaming = Boolean(subscribed && selectedNodeId != null)

  useEffect(() => {
    let isSubscribed = true

    const currentNode = nodeId
    if (isSubscribed && !ourNodeStreaming) {
      dispatch({ type: 'subscribe:data', payload: { nodeId: Number(currentNode) } })
    }

    const unregister = () => history.listen(() => dispatch({ type: 'unsubscribe:data', payload: { nodeId: Number(currentNode) } }))

    return () => {
      unregister()
      isSubscribed = false
    }
  }, [nodeId, history, dispatch, ourNodeStreaming])

  const fetchChannelData = async () => {
    if (nodeId === '') {
      return Promise.resolve({ data: [[]] })
    }

    const currentNodeValuesData: ICurrentValueType = await Api.get(`/api/data/current?nodeId=${nodeId}`)

    if (currentNodeValuesData.error) {
      return Promise.reject(currentNodeValuesData)
    }

    const currentValueObj = currentNodeValuesData[nodeId]
    const channels = Object.keys(currentValueObj ?? {})

    const allRowData: React.ReactNode[][] = channels.map((channel: string) => {
      return [channel, parseChannelValue(currentValueObj[channel].value), currentValueObj[channel].timestamp]
    })

    return Promise.resolve({
      data: allRowData,
      setEntities: () => setCurrentNodeValues(currentNodeValuesData),
    })
  }

  const memoizedFetch = useCallback(fetchChannelData, [nodeId])
  const state = useTableState(memoizedFetch)

  return (
    <Grid container={true} spacing={4}>
      <Grid item={true} xs={12} className={classes.container}>
        <LiveStreamBtn nodeId={+nodeId} loadingData={state.loading} isSubscribed={ourNodeStreaming} dispatch={dispatch} />
      </Grid>
      <Grid item={true} data-cy="currentValues" xs={12} className={classes.container}>
        <OnSchemaValuesTable
          title={CustomTableHeader({ hasValues: !isEmpty(currentNodeValues[nodeId]) })}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          {...state}
        />
      </Grid>

      {selectedChannel && currentNodeValues[nodeId] && (
        <Grid item={true} xs={12} className={classes.container}>
          <EditChannel
            initialValue={currentNodeValues[nodeId][selectedChannel]?.value}
            selectedChannel={selectedChannel}
            nodeId={Number(nodeId)}
            data-testid="editChannelComponent"
          />
        </Grid>
      )}

      {selectedChannel && (
        <Grid item={true} data-cy="onschema-history" xs={12} className={classes.container}>
          <ChannelHistory selectedChannel={selectedChannel} nodeId={Number(nodeId)} data-testid="channelHistoryComponent" />
        </Grid>
      )}

      <Grid item={true} data-cy="off-schema-channels" xs={12} className={classes.container}>
        <OffSchemaChannelsTable nodeId={nodeId} />
      </Grid>
    </Grid>
  )
}

export default CurrentValues
