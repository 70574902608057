import { MutableRefObject, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { ImpersonateContext } from '../providers'

import { constructFrontEndTableParams, constructTableParams, getLimit, getOffset, getParams } from './table/table-helpers'

interface ITableState {
  loading: boolean
  data: React.ReactNode[][] | null
  error: { message: string } | null
}

export const getExtraParams = (location: { pathname: string; search: string; state: any }) => {
  let extraParams: string | null = null
  const { search } = location
  if (search) {
    // Split on standard separator and filter out params we are going to change
    extraParams = search
      .split('&')
      .filter(
        (param: string) =>
          !(
            param.includes('pageNumber') ||
            param.includes('rowsPerPage') ||
            param.includes('searchText') ||
            param.includes('currentSort') ||
            param.includes('sortDir')
          )
      )
      .join('&')
  }
  return extraParams
}

export interface IPaginationState {
  offset: number
  limit: number
  searchText: string
  currentSort: string
  sortDir: 'asc' | 'desc' | 'none'
  setTotalRows: (num: number) => void
  setPageNumber: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, pageNumber: number) => void
  setRowsPerPage: (numberOfRows: number | string) => void
  setSearchText: (str: string) => void
  setCurrentSort: (columnName: string, sort: IPaginationState['sortDir']) => void
  rowsPerPage: number
  pageNumber: number
  totalRowCount: number
  refreshTable: () => void
  rowsDeletedCounter: number
  abort: MutableRefObject<boolean>
}

export const useToggle = (initialState: boolean): [boolean, () => void] => {
  const [state, setState] = useState(initialState)
  const toggle = () => setState(!state)

  return [state, toggle]
}

export const usePagination = (): IPaginationState => {
  const history = useHistory()
  const location = useLocation()
  const params = getParams(location)
  const pageNumber = Number(params.pageNumber)
  const rowsPerPage = Number(params.rowsPerPage)
  const searchText = params.searchText ? decodeURIComponent(String(params.searchText)) : ''
  const currentSort = params.currentSort ? decodeURIComponent(String(params.currentSort)) : ''
  const sortDir = (params.sortDir ? decodeURIComponent(String(params.sortDir)) : 'none') as IPaginationState['sortDir']
  const [totalRowCount, setTotalRows] = useState<number>(0)
  const [rowsDeletedCounter, setRowsDeletedCounter] = useState<number>(0)
  const abort = useRef<boolean>(false)

  const setCurrentSort = (colName: string, sort: IPaginationState['sortDir']) => {
    const extraParams = getExtraParams(location)
    // We need to check for pageNumber !== 0 here to ensure that we dont add an after cursor for page 0
    const pageAndRows = constructTableParams('', { ...params, pageNumber: 0, currentSort: colName, sortDir: sort })
    history.push({
      pathname: location.pathname,
      search: extraParams ? `${pageAndRows}&${extraParams}` : pageAndRows,
    })
  }

  const setPageNumber = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    const extraParams = getExtraParams(location)
    // We need to check for pageNumber !== 0 here to ensure that we dont add an after cursor for page 0
    const tableParams = constructTableParams('', { ...params, pageNumber: newPage ?? 0 })
    history.push({
      pathname: location.pathname,
      search: extraParams ? `${tableParams}&${extraParams}` : tableParams,
    })
  }

  const setSearchText = (text: string) => {
    // setSearchText is on a debounce. If we type something in but also click something, we should abort the history.push
    if (abort.current) {
      return
    }
    const extraParams = getExtraParams(location)
    const tableParams = constructTableParams('', { ...params, searchText: text, pageNumber: 0 })
    history.push({
      pathname: location.pathname,
      search: extraParams ? `${tableParams}&${extraParams}` : tableParams,
    })
  }

  const setRowsPerPage = (newRowsPerPage: number | string) => {
    // Attempt to the keep the offset constant between changes in rowsPerPage
    const oldOffset = getOffset({ pageNumber: pageNumber ?? 0, rowsPerPage: rowsPerPage ?? 10 })
    const newPage = Math.floor(oldOffset / +newRowsPerPage)
    const extraParams = getExtraParams(location)
    // We need to check for newPage !== 0 here to ensure that we dont add an after cursor for page 0
    const tableParams = constructTableParams('', { ...params, rowsPerPage: Number(newRowsPerPage ?? 10), pageNumber: Number(newPage ?? 0) })
    history.push({
      pathname: location.pathname,
      search: extraParams ? `${tableParams}&${extraParams}` : tableParams,
    })
  }

  const refreshTable = () => {
    // we are going to trigger table refresh every time data is deleted from table
    setRowsDeletedCounter(rowsDeletedCounter + 1)
  }

  return {
    offset: getOffset({ pageNumber: pageNumber ?? 0, rowsPerPage: rowsPerPage ?? 10 }),
    limit: getLimit({ pageNumber: pageNumber ?? 0, rowsPerPage: rowsPerPage ?? 10 }),
    searchText,
    setSearchText,
    rowsPerPage: rowsPerPage ?? 10,
    setRowsPerPage,
    setCurrentSort,
    currentSort,
    sortDir,
    pageNumber: pageNumber ?? 0,
    setPageNumber,
    totalRowCount,
    setTotalRows,
    refreshTable,
    rowsDeletedCounter,
    abort,
  }
}

type ActionType = { type: 'toggleLoading' } | { type: 'setData'; payload: React.ReactNode[][] } | { type: 'setError'; payload: { message: string } | null }
type FetchType = () => Promise<{
  data: React.ReactNode[][] | any[]
  setEntities?: () => void
  setTotalRows?: () => void
  errorMessage?: string | null
}>

export const useTableState = (
  fetch: FetchType,
  memoizedFormatEntities?: (entities: any[] | null) => React.ReactNode[][], // This has to be any because of poor overlap wtih backend types
  entities?: IBackendEntityType[] | null
) => {
  const impersonateContext = useContext(ImpersonateContext)
  const initialState: ITableState = { loading: false, data: null, error: null }

  const reducer = (state: ITableState, action: ActionType) => {
    switch (action.type) {
      case 'toggleLoading':
        return { ...state, loading: true }
      case 'setData':
        return { ...state, data: action.payload, loading: false }
      case 'setError':
        return { ...state, error: action.payload, loading: false }
      default:
        throw Error('Action type not supported')
    }
  }

  const [tableState, dispatch] = useReducer(reducer, initialState)
  const impersonatedUserId = impersonateContext?.impersonatedUserId

  useEffect(() => {
    let isSubscribed = true
    dispatch({ type: 'toggleLoading' })

    const updateTableView = () => {
      fetch()
        .then(res => {
          const { data, setTotalRows, setEntities, errorMessage } = res

          if (isSubscribed) {
            if (data) {
              dispatch({ type: 'setData', payload: data })
            }

            // we need to set error to null right away, otherwise if error was previously set it persists for some reason
            dispatch({ type: 'setError', payload: null })

            if (setTotalRows) {
              setTotalRows()
            }
            if (setEntities) {
              setEntities()
            }

            if (errorMessage && data.length === 0) {
              dispatch({ type: 'setError', payload: { message: errorMessage } })
            }
          }
        })
        .catch((error: ErrorEvent) => {
          if (isSubscribed) {
            dispatch({ type: 'setError', payload: { message: error?.message ?? 'Something went wrong' } })
          }
        })
    }

    updateTableView()

    return () => {
      isSubscribed = false
    }
  }, [fetch, impersonatedUserId])

  useEffect(() => {
    if (entities && memoizedFormatEntities) {
      // Reformat with the data available to the memoizedFormatEntities function
      dispatch({ type: 'setData', payload: memoizedFormatEntities(entities) })
    }
  }, [memoizedFormatEntities, entities])

  return useMemo(() => tableState, [tableState])
}

export type SearchState = {
  searchText: string
  setSearchText: (str: string) => void
}

/** this custom hook will be used in front end paginated tables to save search in url params */
export const useSearchData = () => {
  const history = useHistory()
  const location = useLocation()
  const params = getParams(location)
  const abort = useRef<boolean>(false)

  const searchText = params.searchText ? decodeURIComponent(String(params.searchText)) : ''

  const setSearchText = (text: string) => {
    // setSearchText is on a debounce. If we type something in but also click something, we should abort the history.push
    if (abort.current) {
      return
    }
    const extraParams = getExtraParams(location)
    // when search is cleared using X button in search field, `text` is being set to null
    // we don't want that since it would add 'null' into search field, no search text should always be an empty string
    const tableParams = constructFrontEndTableParams({
      ...params,
      searchText: text === null || text === 'null' || text === undefined ? '' : text,
      pageNumber: 0,
    })
    history.replace({
      pathname: location.pathname,
      search: extraParams ? `${tableParams}&${extraParams}` : tableParams,
    })
  }

  return {
    searchText,
    setSearchText,
    abort,
  }
}

export const useFileAPIExists = () => {
  const [fileAPIExists, setFileAPIExists] = useState<boolean>(true)
  useEffect(() => {
    try {
      // eslint-disable-next-line no-new
      new File([], '')
    } catch (e) {
      setFileAPIExists(false)
    }
  }, [setFileAPIExists])

  return fileAPIExists
}

export const useClipboardAPIExists = () => {
  const [clipboardAPIExists, setClipboardAPIExists] = useState<boolean>(true)
  const unsupportedMessage = `To copy this to your clipboard, please use recent version of Chrome, Firefox, Edge or Safari`
  useEffect(() => {
    setClipboardAPIExists(Boolean(navigator && navigator.clipboard && navigator.clipboard.writeText))
  }, [setClipboardAPIExists])

  return [clipboardAPIExists, unsupportedMessage]
}

// custom hook for setting document title on a page
export const useDocumentTitle = (title: string, suffix?: string | undefined) => {
  const originalTitle = document.title

  useEffect(() => {
    document.title = title
    if (suffix) {
      document.title = document.title + ': ' + suffix
    }

    return () => {
      document.title = originalTitle
    }
  }, [title, originalTitle, suffix])
}
