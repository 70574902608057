import { useContext } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import { AlertContext } from '../../providers'
import { ThemeContext } from '../../providers/theme'
import Api from '../../utils/api'
import { useToggle } from '../../utils/hooks'
import ConfirmDeleteFileModal from '../modal'

import useStyles from './file-upload.styles'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/gruvbox-dark.css'

type FilePreviewProps = {
  currentlySelectedFileUrl: string
  currentlySelectedFileName: string // name of a file that user clicked on
  fileType: 'image' | 'text' | 'other'
  decodedImage: any
  encodedImage: any
  currentlySelectedFileTextVal: string // a text response for a text file
  setCodemirrorValue: (val: string) => void // function to set a value from coremirror in a parent component,
  updatedFileName: string // new name of file user may enter in a file name textfield
  setNewFileName: (val: string) => void // function to set a new file name in a parent component,
  fileEndpoint: string
  resetStateAfterFileDeletion: (val: any) => void
  resetStateAfterFileUpdate: (val: any) => void
  fileAccessType: 'public' | 'private'
}

export const generateFileUrl = (fileAccessType: 'public' | 'private', fileName: string): string => {
  const urlOrigin = window.location.origin
  return `${urlOrigin}/api/files/${fileAccessType}${fileName}`
}

const FilePreview = ({
  currentlySelectedFileUrl,
  currentlySelectedFileName,
  fileType,
  decodedImage,
  encodedImage,
  currentlySelectedFileTextVal,
  setCodemirrorValue,
  updatedFileName,
  setNewFileName,
  fileEndpoint,
  resetStateAfterFileDeletion,
  resetStateAfterFileUpdate,
  fileAccessType,
}: FilePreviewProps) => {
  const { addAlert } = useContext(AlertContext)
  const { themeKey } = useContext(ThemeContext)

  const classes = useStyles()
  const [showConfirmDeleteFileModal, toggleConfirmDeleteFileModal] = useToggle(false)

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFileName(event.target.value)
  }

  const handleResponse = (data: any, alertMessage: string) => {
    addAlert(data.error ? { message: `Submission Errored: ${String(data.message)}`, alertType: 'error' } : { message: alertMessage, alertType: 'success' })
  }

  const deleteFile = async () => {
    try {
      handleResponse(await Api.del(fileEndpoint, currentlySelectedFileName), 'File deleted successfully')
      const allCoreFiles = await Api.get(fileEndpoint)
      resetStateAfterFileDeletion({
        files: allCoreFiles,
        currentlySelectedFileName: '',
        currentlySelectedFileUrl: '',
        updatedFileName: '',
      })
      toggleConfirmDeleteFileModal()
    } catch (error) {
      console.error(error)
    }
  }

  const updateFile = async () => {
    try {
      await Api.del(fileEndpoint, currentlySelectedFileName)
    } catch (err) {
      console.error(err)
    }

    const payloadToSend = fileType === 'image' ? encodedImage : currentlySelectedFileTextVal
    try {
      handleResponse(await Api.putFile(fileEndpoint + updatedFileName, payloadToSend), 'File Saved Successfully')

      const allCoreFiles = await Api.get(fileEndpoint)
      resetStateAfterFileUpdate(allCoreFiles)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {currentlySelectedFileUrl && fileType === 'image' ? (
        <img alt="The rendering of this particular file" className={classes.imageFile} src={decodedImage} />
      ) : null}

      {currentlySelectedFileUrl && fileType === 'text' ? (
        <CodeMirror
          value={currentlySelectedFileTextVal}
          options={{
            mode: 'xml',
            lineWrapping: true,
            lineNumbers: true,
            theme: themeKey === 'dark' ? 'gruvbox-dark' : 'default',
          }}
          autoCursor={false}
          className={classes.codemirror}
          onChange={(editor, data, value) => {
            setCodemirrorValue(value)
          }}
        />
      ) : null}

      <div className={classes.fileActionsWrapper}>
        <Grid container={true} justifyContent="flex-end" alignItems="center">
          <Grid item={true} xs={12} sm={12} md={8} lg={8} xl={8}>
            <TextField
              label="File Name"
              id="fileName"
              fullWidth={true}
              variant="standard"
              value={updatedFileName}
              onChange={handleFileNameChange}
              data-cy="fileName"
              helperText={generateFileUrl(fileAccessType, `/${updatedFileName}`)}
            />
          </Grid>

          <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className={classes.floatRight}>
              <Button className={classes.deleteBtn} type="button" onClick={toggleConfirmDeleteFileModal} data-cy="deleteBtn">
                Delete File
              </Button>

              <Button type="button" color="primary" onClick={updateFile} data-cy="saveBtn">
                Save File
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>

      <ConfirmDeleteFileModal
        show={showConfirmDeleteFileModal}
        confirmModalAction={deleteFile}
        closeModal={toggleConfirmDeleteFileModal}
        dialogTitle="Are you sure you want to delete this file?"
      />
    </>
  )
}

export default FilePreview
