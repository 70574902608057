import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { FieldArray, Form, Formik } from 'formik'
import * as Yup from 'yup'

import SingleSelect from '../../../../components/form-elements/filter-select/single-select'
import ConfirmDeletionModal from '../../../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../../../components/permissions-form-error/form-error-message'
import { AlertContext } from '../../../../providers'
import Api from '../../../../utils/api'
import { folderTreeToList } from '../../../../utils/helper-functions'
import useWhatsInsideStyles from '../whats-inside.styles'

import GenericNodeListItem from './generic-node-list-item'

interface IProps {
  folderNodes: INodeType[]
  nodesLoading: boolean
  nodesError: string
  setNodesMovedCounter: Dispatch<SetStateAction<number>>
}

export interface IFormValues {
  selectedNodes: number[]
  newFolderId: number | null
}

/** checkboxes disabled in 2 cases
 * 1) node is of one of the following node types: attributes, mweather, order, return
 * 2) 10 checkboxes are selected (not allowed to move more than 10 nodes at a time)
 */
export const isCheckboxDisabled = (node: INodeType, selectedNodes: number[]): boolean => {
  let isDisabled = false

  const prohibitedNodetypes = ['mweather', 'order', 'return', 'attributes']
  const isMaxAmountSelected = selectedNodes.filter(x => x).length >= 10

  if (isMaxAmountSelected) {
    isDisabled = !selectedNodes.includes(node.id)
  } else if (node && node.nodeTypeName && prohibitedNodetypes.includes(node.nodeTypeName)) {
    isDisabled = true
  }

  return isDisabled
}

export const getFolderNameById = (folders: IFolderTypeSimple[], folderId: number | null): string => {
  let folderName: string = ''

  if (folders?.length && folderId && folders?.find) {
    folderName = folders?.find(folder => folder.id === folderId)?.name ?? ''
  }

  return folderName
}

const InsideNodes = (props: IProps) => {
  const { folderNodes, nodesLoading, nodesError, setNodesMovedCounter } = props

  const classes = useWhatsInsideStyles()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ folderId: string }>()
  const folderId = params.folderId

  const [folders, setFolders] = useState<IFolderTypeSimple[]>([])
  const [foldersLoading, setFoldersLoading] = useState<boolean>(false)
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    setFoldersLoading(true)

    Api.get('/api/folders/tree')
      .then(folderData => {
        setFoldersLoading(false)
        if (isSubscribed && !folderData.error) {
          setFolders(folderTreeToList(folderData))
        }
      })
      .catch(() => {
        setFoldersLoading(false)
      })

    return () => {
      isSubscribed = false
    }
  }, [])

  const getDialogContentMessage = (values: IFormValues): string => {
    return `You are about to move ${values.selectedNodes.filter(x => x).length} node(s) to
      ${getFolderNameById(folders, values.newFolderId)} from ${getFolderNameById(folders, Number(folderId))}`
  }

  const moveNodes = async (
    values: IFormValues,
    setSubmitting: (state: boolean) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    setShowConfirmDeletionModal(false)
    setSubmitting(true)

    const results = await Promise.all(
      values.selectedNodes
        .filter(x => x)
        .map(nodeId => {
          return Api.put(`/api/nodes/${nodeId}`, { folderId: values.newFolderId })
        })
    )

    setNodesMovedCounter((c: number) => ++c)
    setSubmitting(false)

    let movedCounter = 0
    results.forEach(res => {
      if (res.error) {
        addAlert({ message: `Failed to move a node to new folder. ${res.message ?? ''}`, alertType: 'error' })
      } else {
        movedCounter++
      }
    })

    if (movedCounter === values.selectedNodes.filter(x => x).length) {
      addAlert({ message: 'All selected nodes were successfully moved to a new folder', alertType: 'success' })
    } else {
      addAlert({ message: 'Not all selected nodes were moved to a new folder', alertType: 'warning' })
    }
    setFieldValue('selectedNodes', [])
  }

  const initialValues: IFormValues = {
    selectedNodes: [],
    newFolderId: null,
  }

  const validationSchema = Yup.object().shape({
    selectedNodes: Yup.array(),
    newFolderId: Yup.number().nullable(true),
  })

  return (
    <>
      {!nodesLoading && (
        <div className={classes.nodesContainer} data-cy="nodesList">
          <Formik
            initialValues={initialValues}
            enableReinitialize={false}
            onSubmit={() => {}} // we show confirm modal before submitting, so actual form submit happens when user confirms in modal, hence leaving onSubmit blank
            validationSchema={validationSchema}
          >
            {({ values, isSubmitting, setSubmitting, setFieldValue, dirty }) => (
              <Form>
                <Grid container={true} spacing={2}>
                  {!nodesError && folderNodes && folderNodes.length > 0 && (
                    <Grid item={true} className={classes.nodesHelperText}>
                      <Typography variant="caption">
                        If you would like to move nodes to a different folder, start by selecting nodes you wish to move. Note that the nodes of following node
                        types are not allowed to be moved: attributes, mweather, order, or return. <strong>Only 10 or less nodes can be moved at a time</strong>
                      </Typography>
                    </Grid>
                  )}

                  <Grid item={true} xs={12} className={classes.nodesListWrapper}>
                    <Grid container={true} item={true} xs={12} spacing={1}>
                      {folderNodes && folderNodes.length > 0 && (
                        <FieldArray name="selectedNodes">
                          {() =>
                            folderNodes.map((node, i) => (
                              <Grid item={true} xs={12} key={node.id} data-testid="nodeInfo">
                                <GenericNodeListItem node={node} index={i} checkboxDisabled={isCheckboxDisabled(node, values.selectedNodes)} />
                              </Grid>
                            ))
                          }
                        </FieldArray>
                      )}
                    </Grid>
                  </Grid>

                  {values.selectedNodes && values.selectedNodes.filter(x => x).length > 0 && folders && (
                    <>
                      <Grid item={true} xs={12} sm={12} md={6} lg={4} xl={4}>
                        <SingleSelect<IFolderTypeSimple, IFormValues>
                          name="newFolderId"
                          label="Please select a new folder and move selected nodes there"
                          required={false}
                          items={folders}
                          loading={foldersLoading}
                          displayField="name"
                          initialValue={undefined}
                        />
                      </Grid>

                      <Grid item={true} xs={12} sm={6} md={2} lg={2} xl={2} container={true} justifyContent="flex-end" alignItems="flex-start">
                        <Button
                          color="secondary"
                          variant="outlined"
                          type="button"
                          disabled={!values.newFolderId || isSubmitting || !dirty}
                          data-testid="moveNodesBtn"
                          data-cy="moveNodesBtn"
                          onClick={() => setShowConfirmDeletionModal(true)}
                        >
                          Move Nodes
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>

                <ConfirmDeletionModal
                  show={showConfirmDeletionModal}
                  confirmModalAction={() => moveNodes(values, setSubmitting, setFieldValue)}
                  closeModal={() => setShowConfirmDeletionModal(false)}
                  dialogContent={getDialogContentMessage(values)}
                  dialogTitle="Are you sure you want to move selected node(s) to a new folder?"
                />
              </Form>
            )}
          </Formik>

          {(folderNodes === undefined || !folderNodes.length) && !nodesError && (
            <Grid item={true} xs={12}>
              <div className={classes.noItems} data-testid="noNodesMessage">
                No nodes in this folder
              </div>
            </Grid>
          )}

          {nodesError && <PermissionsFormError errorMessages={combineErrorMessages(nodesError)} formName="Folder Nodes" hasGoBackButton={false} />}
        </div>
      )}

      {nodesLoading && (
        <div className={classes.spinnerWrapper}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  )
}

export default InsideNodes
