import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { NumberParam, useQueryParam } from 'use-query-params'

import useSharedFormStyles from '../../shared-styles/form.styles'

import NotificationSchemaForm from './notification-schema-form'
import NotificationSchemaUsers from './notification-schema-users'

const CreateEditNotificationSchema = () => {
  const classes = useSharedFormStyles()
  const params = useParams<{ schemaId: string }>()

  const schemaId = params.schemaId
  const isEditMode = schemaId !== 'new'
  const defaultTabIndex = 0

  const [selectedTabIndex, setSelectedTabIndex] = useQueryParam('tab', NumberParam)

  useEffect(() => {
    if (!selectedTabIndex) {
      setSelectedTabIndex(0)
    }
  }, [selectedTabIndex, setSelectedTabIndex])

  return (
    <>
      {!isEditMode && (
        <Card>
          <CardHeader title="Create Notification Schema" data-cy="notificationSchemaTitle" />

          <CardContent>
            <NotificationSchemaForm />
          </CardContent>
        </Card>
      )}

      {isEditMode && (
        <Card>
          <CardHeader title="Edit Notification Schema" data-cy="notificationSchemaTitle" />

          <CardContent>
            <Tabs
              value={selectedTabIndex ? selectedTabIndex : defaultTabIndex}
              onChange={(event: React.ChangeEvent<{}>, value: number) => setSelectedTabIndex(value, 'push')}
              textColor="secondary"
              indicatorColor="secondary"
              className={classes.tabs}
            >
              <Tab label="Info" />
              <Tab label="Users" />
            </Tabs>
          </CardContent>

          {selectedTabIndex === 0 && <NotificationSchemaForm />}
        </Card>
      )}

      {selectedTabIndex === 1 && <NotificationSchemaUsers schemaId={Number(schemaId)} />}
    </>
  )
}

export default CreateEditNotificationSchema
