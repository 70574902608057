import * as React from 'react'

import { AlertContext, AppAlerts } from './alerts'
import { AuthenticationContext, IdentityProviders, ImpersonateContext, OwnUserContext, UserPermissionsContext } from './identity'
import { SidenavContext } from './sidenav'
import { MaterialThemeProvider } from './theme'
import { StreamProvider } from './websocket-stream'

const AllProviders = ({ children }: { children: React.ReactNode[] | React.ReactNode }) => {
  return (
    <MaterialThemeProvider>
      <AppAlerts>
        <IdentityProviders>
          <StreamProvider>{children}</StreamProvider>
        </IdentityProviders>
      </AppAlerts>
    </MaterialThemeProvider>
  )
}

// we're adding this here to keep the mocked exports signature the same as the non-mocked one.
// this function should never be used in tests or in the actual code, it is here purely to let
// typescript know that an `addAlert` function does exist in the mocked providers.
export const addAlert = () => {
  throw new Error('not implemented, this declaration is for the jest tests')
}

export { AlertContext, AllProviders, AuthenticationContext, ImpersonateContext, OwnUserContext, SidenavContext, UserPermissionsContext }
