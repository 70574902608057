import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { Chip, Link, Paper } from '@mui/material'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import FormikCheckBox from '../../components/form-elements/checkbox'
import SingleFilterSelect from '../../components/form-elements/filter-select/single-select'
import useFilterSelectStyles from '../../components/form-elements/filter-select/styles'
import FormikTextField from '../../components/form-elements/text-field'
import Metadata, { IMetadataItems, MetadataType, validateMetadataItems } from '../../components/metadata'
import ConfirmDeletionModal from '../../components/modal'
import OfficialDocsLink from '../../components/official-doc-link'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import TagCreator from '../../components/tag-creator'
import { AlertContext } from '../../providers'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit } from '../../utils/form/form-helpers'
import { folderTreeToList } from '../../utils/helper-functions'
import { useClipboardAPIExists, useToggle } from '../../utils/hooks'
import { getFolderLocation } from '../folders/primary-contact'

import useNodeFormStyles from './create-edit.styles'

type IFormValues = {
  lat: string
  lng: string
  tags: string[]
  folderId: number
  parentNodeId: string
  nodeTypeId: string
  isActive: boolean
  mute: boolean
  uniqueId: string
  vanity: string
  area: string
  building: string
  floor: string
  genClaim: boolean
} & IMetadataItems

type NodesProps = {
  nodeTypes: INodeTypeType[]
  currentNode: INodeType | null
  setCurrentNode: Dispatch<SetStateAction<INodeType | null>>
  isEditMode: boolean
  nodeTypesError: string
  nodeTypesLoading: boolean
}

interface INodePayload {
  location: { lat: number; lng: number } | null
  tags: string[]
  metadata: MetadataType[]
  folderId: number
  parentNodeId: number
  nodeTypeId: number
  isActive: boolean
  mute: boolean
  uniqueId: string
  vanity: string
  area: string
  building: string
  floor: string
}
interface INodesFilterSelectProps {
  retrievedNode: INodeType | null
}

const NodesFilterSelect = (props: INodesFilterSelectProps) => {
  const { retrievedNode } = props

  const filterClasses = useFilterSelectStyles()
  const classes = useNodeFormStyles()

  const [nodes, setNodes] = useState<INodeType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showNodeParentList, setShowNodeParentList] = useState<boolean>(false)

  useEffect(() => {
    // only fetch if we are showing the list
    if (!showNodeParentList) return
    // only fetch once
    if (nodes.length > 0) return

    let isSubscribed = true
    setLoading(true)

    Api.get('/api/nodes')
      .then(data => {
        if (isSubscribed) {
          setLoading(false)
          if (!data.error) {
            setNodes(data as INodeType[])
          }
        }
      })
      .catch(() => {
        setLoading(false)
      })

    return () => {
      isSubscribed = false
    }
  }, [showNodeParentList, nodes])

  if (!showNodeParentList)
    return (
      <div className={classes.nodeParentFakeContainer}>
        <div className={filterClasses.label}>Please select a parent node</div>
        <Paper elevation={4} className={classes.nodeParentFakePaper}>
          <Button onClick={() => setShowNodeParentList(true)} variant="contained" color="secondary" className={classes.nodeParentButton}>
            Change parent node
          </Button>
        </Paper>
      </div>
    )

  return (
    <Grid item={true} xs={12}>
      <SingleFilterSelect
        name="parentNodeId"
        label="Please select a parent node"
        required={Boolean(retrievedNode?.parentNodeId)}
        displayField="vanity"
        items={nodes}
        data-cy="parentNodeIdInput"
        loading={loading}
        initialValue={retrievedNode?.parentNodeId}
      />
    </Grid>
  )
}

const NodeForm = (props: NodesProps) => {
  const { currentNode, nodeTypes, isEditMode, nodeTypesError, setCurrentNode, nodeTypesLoading } = props

  const history = useHistory()
  const params = useParams<{ nodeId?: string }>()
  const nodeId = params.nodeId

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)
  const { addAlert } = useContext(AlertContext)
  const [clipboardAPIExists, clipboardUnsupportedMessage] = useClipboardAPIExists()
  const classes = useNodeFormStyles()

  const [folders, setFolders] = useState<IFolderTypeSimple[]>([])
  const [nodeFolder, setNodeFolder] = useState<IFolderType | null>(null)
  const [foldersError, setFoldersError] = useState<string>('')
  const [foldersLoading, setFoldersLoading] = useState<boolean>(false)

  const [photoError, setPhotoError] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    setFoldersLoading(true)

    Api.get('/api/folders/tree').then(data => {
      setFoldersLoading(false)

      if (isSubscribed) {
        if (!data.error) {
          setFolders(folderTreeToList(data as IFolderTreeType))
        } else {
          setFoldersError(data.message)
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (!currentNode?.folderId) return
    let isSubscribed = true

    Api.get(`/api/folders/${currentNode?.folderId}`).then(data => {
      if (isSubscribed) {
        if (!data.error) {
          setNodeFolder(data)
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [currentNode?.folderId])

  const saveNode = async (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
    actions.setSubmitting(true)

    const createNodePayload: INodePayload = {
      vanity: values.vanity,
      uniqueId: values.uniqueId,
      isActive: values.isActive,
      mute: values.mute,
      parentNodeId: Number(values.parentNodeId),
      nodeTypeId: Number(values.nodeTypeId),
      folderId: Number(values.folderId),
      location: values.lat || values.lat ? { lat: +values.lat, lng: +values.lng } : null,
      area: values.area,
      building: values.building,
      floor: values.floor,
      metadata:
        values.metadataItems.length > 0
          ? values.metadataItems.reduce((acc: any, curr: any) => {
              acc[curr.key] = curr.value
              return acc
            }, {})
          : {},
      tags: values.tags,
    }

    let response
    try {
      if (isEditMode) {
        response = await Api.put(`/api/nodes/${nodeId}`, createNodePayload)
        handleFormSubmit(response, addAlert, history, null, values, actions)
      } else {
        let submittedNode
        handleFormSubmit((submittedNode = await Api.post('/api/nodes', createNodePayload)), addAlert, history, `/nodes/${submittedNode.id}`, values, actions)
      }

      if (values.genClaim) {
        history.push({ pathname: `/claims/lora-tracknet/new`, search: `?uniqueId=${values.uniqueId}` })
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit node' })
    } finally {
      // Ensure that it hasn't errored
      if (response?.nodeTypeId) {
        setCurrentNode(response)
      }
      actions.setSubmitting(false)
    }
  }

  const schema = Yup.object().shape({
    vanity: Yup.string().required('Vanity name is required'),
    uniqueId: Yup.string().required('Unique Id is required'),
    isActive: Yup.boolean(),
    mute: Yup.boolean(),
    nodeTypeId: Yup.number()
      .required()
      .positive('Node Type is required'), // -1 is used to indicate that no value is selected in signle-select, so we need postiive() validation here
    folderId: Yup.number()
      .required()
      .positive('Folder is required'),
    lng: Yup.number()
      .max(180, 'Must be less than 180')
      .min(-180, 'Must be greater than -180'),
    lat: Yup.number()
      .max(90, 'Must be less than 90')
      .min(-90, 'Must be greater than -90'),
    metadataItems: Yup.array(Yup.object()),
    tags: Yup.array(Yup.string()),
  })

  const deleteNode = async () => {
    try {
      handleDelete(await Api.del('/api/nodes', nodeId), addAlert, history, '/nodes')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete node' })
    }
  }

  const initialValues: IFormValues =
    isEditMode && currentNode
      ? {
          vanity: currentNode.vanity,
          uniqueId: currentNode.uniqueId,
          isActive: Boolean(currentNode.isActive),
          mute: Boolean(currentNode.mute),
          nodeTypeId: currentNode.nodeTypeId,
          parentNodeId: currentNode.parentNodeId,
          folderId: currentNode.folderId,
          lat: currentNode.location && currentNode.location.lat ? currentNode.location.lat : '',
          lng: currentNode.location && currentNode.location.lng ? currentNode.location.lng : '',
          metadataItems: currentNode.metadata
            ? Object.entries(currentNode.metadata).map(item => ({
                key: item[0],
                value: String(item[1]),
                asJSON: false,
              }))
            : [],
          tags: currentNode.tags ? currentNode.tags : [],
          area: currentNode.area,
          building: currentNode.building,
          floor: currentNode.floor,
          genClaim: false,
        }
      : {
          vanity: '',
          uniqueId: '',
          isActive: false,
          mute: false,
          nodeTypeId: '',
          parentNodeId: '',
          folderId: -1,
          lat: '',
          lng: '',
          metadataItems: [],
          tags: [],
          area: '',
          building: '',
          floor: '',
          genClaim: false,
        }

  return (
    <>
      {!foldersError && !nodeTypesError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveNode} validationSchema={schema} validate={validateMetadataItems}>
          {({ isValid, values, isSubmitting, setFieldValue, dirty }: FormikProps<IFormValues>) => (
            <Form>
              <CardContent>
                <Grid spacing={2} container={true}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="vanityInput">
                    <FormikTextField id="vanityInput" label="Vanity Name" name="vanity" required={true} />
                  </Grid>

                  <Grid
                    container={true}
                    justifyContent="flex-start"
                    alignContent="flex-end"
                    alignItems="center"
                    item={true}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    data-cy="uniqueIdInput"
                  >
                    <Grid item={true} xs={isEditMode ? 10 : 12}>
                      <FormikTextField id="uniqueIdInput" label="Unique Id" name="uniqueId" required={true} disabled={isEditMode} data-testid="uniqueId" />
                    </Grid>

                    {isEditMode && (
                      <Grid
                        item={true}
                        xs={1}
                        className={classes.clipboard}
                        onClick={() => {
                          const copy = (uniqueId: string): void => {
                            if (uniqueId) {
                              if (clipboardAPIExists) {
                                navigator?.clipboard?.writeText(uniqueId).then(
                                  () => {
                                    addAlert({ alertType: 'success', message: `Copied ${uniqueId} to clipboard` })
                                  },
                                  err => {
                                    addAlert({ alertType: 'error', message: `Could not copy to clipboard. ${err}` })
                                  }
                                )
                              } else {
                                addAlert({ alertType: 'error', message: clipboardUnsupportedMessage as string })
                              }
                            }
                          }
                          copy(values.uniqueId)
                        }}
                      >
                        <Tooltip title="Copy ID to Clipboard" arrow={true}>
                          <Button>
                            <AssignmentIcon color="secondary" />
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item={true} container={true} xs={12}>
                    <Grid container={true} item={true} xs={12} sm={12} md={6}>
                      <Grid item={true} xs={12} className={classes.densePadding}>
                        <Typography className={classes.denseFormSubheader} variant="subtitle1">
                          Settings
                        </Typography>
                      </Grid>

                      <Grid container={true} item={true} xs={12} className={classes.densePadding} flexDirection="row">
                        {/* extra div is needed for the tooltip to work */}
                        <Tooltip title="Nodes will receive sensor data and be visible in UIs when active" arrow={true}>
                          <div data-cy="activeCheckBox">
                            <FormikCheckBox name="isActive" label="Active" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Nodes will not trigger alerts to users when muted" arrow={true}>
                          <div data-cy="muteCheckBox">
                            <FormikCheckBox name="mute" label="Muted" />
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Grid container={true} item={true} xs={12} sm={12} md={6}>
                      <Grid item={true} xs={12} className={classes.densePadding}>
                        <Typography className={classes.denseFormSubheader} variant="subtitle1">
                          Properties
                        </Typography>
                      </Grid>

                      <Grid item={true} xs={12} className={classes.densePadding}>
                        {currentNode?.isAmmonia && <Chip label="Ammonia" variant="outlined" />}
                        {currentNode?.isBvs && <Chip label="Shutoff Valve" variant="outlined" />}
                        {currentNode?.isGateway && <Chip label="Gateway" variant="outlined" />}
                        {currentNode?.isLora && <Chip label="Lora" variant="outlined" />}
                        {currentNode?.isPhysical && <Chip label="Physical" variant="outlined" />}
                        {currentNode?.isSensor && <Chip label="Sensor" variant="outlined" />}
                        {currentNode?.isTing && <Chip label="Ting" variant="outlined" />}
                        {currentNode?.isV3 && <Chip label="V3" variant="outlined" />}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item={true} xs={12} className={classes.noPadding} />

                  <Grid item={true} xs={12} sm={12} md={4} lg={3} xl={3} data-cy="nodeType">
                    <SingleFilterSelect<INodeTypeType, IFormValues>
                      name="nodeTypeId"
                      label="Please select a Node Type"
                      required={true}
                      items={nodeTypes}
                      displayField="name"
                      loading={nodeTypesLoading}
                      initialValue={currentNode?.nodeTypeId}
                    />
                  </Grid>

                  <Grid container={true} item={true} xs={12} sm={12} md={4} lg={4} xl={4} data-cy="parentNode">
                    <NodesFilterSelect retrievedNode={currentNode} />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={4} lg={3} xl={3} data-cy="folder">
                    <SingleFilterSelect<IFolderTypeSimple, IFormValues>
                      displayField="name"
                      name="folderId"
                      label="Please select a folder"
                      required={true}
                      items={folders}
                      data-cy="folderIdInput"
                      loading={foldersLoading}
                      initialValue={currentNode?.folderId}
                    />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <div className={classes.docsLinkWrapper}>
                      <OfficialDocsLink url="https://github.com/meshifyiot/public-carbon-docs/blob/master/concepts/nodes.md" />
                    </div>
                  </Grid>

                  {isEditMode && nodeFolder && (
                    <>
                      <Grid item={true} xs={12} data-cy="folder">
                        <Typography className={classes.denseFormSubheader} variant="subtitle1">
                          Node Folder
                        </Typography>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <span className={classes.nodeFolderAddress}>Folder Name - </span>
                        <Link to={`/folders/${nodeFolder.id}`} color="primary" component={RouterLink}>
                          {nodeFolder?.name}
                        </Link>
                        <p className={classes.nodeFolderAddress}>{nodeFolder?.information?.address?.address}</p>
                        <p className={classes.nodeFolderAddress}>{getFolderLocation(nodeFolder)}</p>
                        <p className={classes.nodeFolderAddress}>{nodeFolder?.information?.address?.code}</p>
                      </Grid>
                    </>
                  )}

                  <Grid item={true} xs={12}>
                    <Typography className={classes.denseFormSubheader} variant="subtitle1">
                      Node Location
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <FormikTextField label="Building" name="building" />
                  </Grid>

                  <Grid item={true} xs={4}>
                    <FormikTextField label="Floor" name="floor" />
                  </Grid>

                  <Grid item={true} xs={4}>
                    <FormikTextField label="Area" name="area" />
                  </Grid>

                  <Grid item={true} xs={6} data-cy="lat">
                    <FormikTextField label="Latitude" type="number" name="lat" helperText="Between -90 and 90" />
                  </Grid>

                  <Grid item={true} xs={6} data-cy="long">
                    <FormikTextField label="Longtitude" type="number" name="lng" helperText="Between -180 and 180" />
                  </Grid>

                  <Grid container={true} item={true} xs={12}>
                    <Grid item={true} xs={12} lg={6}>
                      <Typography className={classes.denseFormSubheader} variant="subtitle1">
                        Metadata
                      </Typography>
                      <Metadata metadataItems={values.metadataItems} />
                    </Grid>

                    {currentNode?.id && currentNode?.isPhysical && (
                      <Grid item={true} xs={12} lg={6}>
                        <Typography className={classes.denseFormSubheader} variant="subtitle1">
                          Location Photo (Protect)
                        </Typography>
                        <a href={`/api/v2/protect/devices/${currentNode.id}/photo`} target="_blank" rel="noreferrer">
                          <img
                            src={`/api/v2/protect/devices/${currentNode.id}/photo`}
                            alt=""
                            onError={() => setPhotoError(true)}
                            onLoad={() => setPhotoError(false)}
                            className={classes.protectPhoto}
                          />
                        </a>
                        {photoError && <Typography variant="subtitle1">Image does not exist</Typography>}
                      </Grid>
                    )}
                  </Grid>

                  <Grid item={true} xs={12}>
                    <Typography className={classes.denseFormSubheader} variant="subtitle1">
                      Tags
                    </Typography>

                    <TagCreator tags={values.tags} />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={classes.actionButtons}>
                <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-testid="saveBtn" data-cy="saveBtn">
                  Save
                </Button>

                <Button type="button" onClick={() => history.push('/nodes')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                  Cancel
                </Button>

                {isEditMode && (
                  <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                    Delete
                  </Button>
                )}

                {!isEditMode && (
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => setFieldValue('genClaim', true)}
                    disabled={!isValid || isSubmitting || !dirty}
                    data-testid="saveAndClaimBtn"
                    data-cy="saveAndClaimBtn"
                  >
                    Save and Create Claim
                  </Button>
                )}
              </CardActions>
            </Form>
          )}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteNode}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this node?"
      />

      {(foldersError || nodeTypesError) && (
        <div className={classes.permissionsAlertWrapper}>
          <PermissionsFormError
            errorMessages={combineErrorMessages(foldersError, nodeTypesError)}
            formName="Nodes Form"
            listViewRoute="/nodes"
            hasGoBackButton={true}
          />
        </div>
      )}
    </>
  )
}

export default NodeForm
