import { useEffect } from 'react'

import FormikTextField from '../../../components/form-elements/text-field'

interface IChannelsDDProps {
  selectedNodeTypeId?: number
  channels: string[]
  initialNodeTypeId?: number
  initialChannelName: string
  setFieldValue: (fieldName: string, val: string) => void
}

const ChannelsDropdown = (channelsProps: IChannelsDDProps) => {
  const { selectedNodeTypeId, channels, initialChannelName, initialNodeTypeId, setFieldValue } = channelsProps
  // When different nodetype is selected, new channels array is populated,
  // When that happens we want  to reset channelName value to make sure we don't save previously selected channel
  // In the case we change back to the initial nodetype, we reset to initial value.
  useEffect(() => {
    if (selectedNodeTypeId === initialNodeTypeId) {
      setFieldValue('channelName', initialChannelName)
    } else {
      setFieldValue('channelName', '')
    }
  }, [channels, initialChannelName, setFieldValue, selectedNodeTypeId, initialNodeTypeId])

  return (
    <FormikTextField
      formDependent={true}
      data-cy="channelSelect"
      select={true}
      label="Channel"
      name="channelName"
      required={true}
      hasEmptyOption={true}
      disabled={!selectedNodeTypeId}
    >
      {selectedNodeTypeId &&
        channels.length &&
        channels.map((key: string, index: number) => {
          return (
            <option key={index} value={key}>
              {key}
            </option>
          )
        })}
    </FormikTextField>
  )
}

export default ChannelsDropdown
