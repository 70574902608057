import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { uniqBy } from 'lodash'
import moment from 'moment'

import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import { AlertContext } from '../../providers'
import api from '../../utils/api'
import { redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle } from '../../utils/hooks'

import SubscriptionsScheduleList from './form-elements/subscription-schedule-list'
import { unpackSchedule } from './form-elements/subscriptions-helpers'
import useSubscriptionFormStyles from './create-edit.styles'

interface ISubscriptionPayloadType<T> {
  route: string
  payload: T
}

export type ICreatePayload = ISubscriptionPayloadType<{
  notificationSchemaId: number
  schedule: string
  userId: number
}>

export type IUpdatePayload = ISubscriptionPayloadType<{ schedule: string }>

export type IDeletePayload = ISubscriptionPayloadType<undefined>

export interface IUserSubscriptionDisplayType extends IUserSubscriptionType {
  schedules: IScheduleDisplayType[]
  name: string
}

export interface ISubscriptionFormValues {
  subscriptions: IUserSubscriptionDisplayType[]
  selectedSchemas: number[]
}

type SubscriptionFormProps = {
  initialUserSubscriptions: IUserSubscriptionDisplayType[]
  notificationSchemas: INotificationSchemaType[]
  user: IUserType
  setFormSubmittedCounter: any
}

/* If schedule is default, returns empty string, else return subscription schedule */
export const checkIfDefault = (subscription: IUserSubscriptionType, notificationSchemas: INotificationSchemaType[]) => {
  let result = ''

  const notificationSchema = notificationSchemas.find((schema: INotificationSchemaType) => schema.id === subscription.notificationSchemaId)

  if (notificationSchema?.defaultSchedule) {
    const subscriptionSched = subscription.schedule.split(' ').join('')
    const defaultSched = notificationSchema?.defaultSchedule.split(' ').join('')
    if (subscriptionSched !== defaultSched) {
      result = subscriptionSched
    }
  }

  return result
}

/* Packs up the schedules to be sent back to the backend */
const packSchedules = (schedules: IScheduleDisplayType[]) => {
  return schedules
    .reduce((accum: string[], cv: IScheduleDisplayType) => {
      const daysString = cv.days.map((day: IDaysOfWeekType) => day.id).join('')
      const startTimeString = moment(cv.startTime).format('HH:mm')
      const endTimeString = moment(cv.endTime).format('HH:mm')
      const singleSchedule = `${daysString}|${startTimeString}-${endTimeString}`
      accum.push(singleSchedule)
      return accum
    }, [])
    .join(';')
    .trim()
}

/* The backend subscription format is hard to deal with, this parses and unpacks schedule data to be manageable for the frontend */
const unpackSubscription = (notificationSchemas: INotificationSchemaType[], subscription: IUserSubscriptionType) => {
  let schedules: IScheduleDisplayType[] = []

  const schema = notificationSchemas.find((schemaItem: INotificationSchemaType) => schemaItem.id === subscription.notificationSchemaId)
  const backendScheduleString = subscription.schedule ? subscription.schedule : schema && schema.defaultSchedule
  // parse the schedule string to an array
  if (backendScheduleString) {
    schedules = unpackSchedule(backendScheduleString)
  }
  // add the subscriptions and the schedules
  const unpackedSubscription: IUserSubscriptionDisplayType = { ...subscription, schedules, name: schema?.name ?? 'Name not found' }

  return unpackedSubscription
}

/* Take a unpacked subscription and repack it for delivery to the backend */
const packSubscription = (subscription: IUserSubscriptionDisplayType) => {
  let scheduleStr = ''

  if (subscription.schedules) {
    scheduleStr = packSchedules(subscription.schedules)
  }

  // create a new subscription without the schedules and name fields
  const packedSubscription: IUserSubscriptionType = {
    createdAt: subscription.createdAt,
    notificationSchemaId: subscription.notificationSchemaId,
    schedule: scheduleStr,
    updatedAt: Date(),
    userId: subscription.userId,
  }

  return packedSubscription
}

const SubscriptionForm = (props: SubscriptionFormProps) => {
  const { user, initialUserSubscriptions, notificationSchemas, setFormSubmittedCounter } = props

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  const subscriptionExisted = (notificationSchemaId: number) => {
    return initialUserSubscriptions.some((sub: IUserSubscriptionType) => sub.notificationSchemaId === notificationSchemaId)
  }

  const handleSubmit = async (values: ISubscriptionFormValues, actions: FormikHelpers<ISubscriptionFormValues>) => {
    const { subscriptions } = values
    // convert the subscription.schedules to strings, and remove denormalized properties
    const packedSubscriptions = subscriptions.map(packSubscription)

    const genCreatePayload = () => {
      return packedSubscriptions.reduce((accum: ICreatePayload[], subscription: IUserSubscriptionType) => {
        if (!subscriptionExisted(subscription.notificationSchemaId)) {
          accum.push({
            route: '/api/usersubscriptions',
            payload: {
              notificationSchemaId: subscription.notificationSchemaId,
              schedule: subscription.schedule,
              userId: user.id,
            },
          })
        }
        return accum
      }, [])
    }

    const genUpdatePayload = () => {
      return packedSubscriptions.reduce((accum: IUpdatePayload[], subscription: IUserSubscriptionType) => {
        const newSchedule = checkIfDefault(subscription, notificationSchemas)
        if (subscriptionExisted(subscription.notificationSchemaId) && newSchedule) {
          accum.push({
            route: `/api/usersubscriptions?userId=${user.id}&notificationSchemaId=${subscription.notificationSchemaId}`,
            payload: { schedule: newSchedule },
          })
        }
        return accum
      }, [])
    }

    const genDeletePayload = () => {
      const deletions: IDeletePayload[] = []
      // Deletions should occur when initial subscriptions no longer exist in current subscriptions
      initialUserSubscriptions.forEach(initialSub => {
        if (packedSubscriptions.find(currentSub => currentSub.notificationSchemaId === initialSub.notificationSchemaId) == null) {
          deletions.push({
            route: `/api/usersubscriptions?userId=${user.id}&notificationSchemaId=${initialSub.notificationSchemaId}`,
            payload: undefined,
          })
        }
      })
      return deletions
    }

    const saveSubscriptions = async () => {
      const p: Array<Promise<unknown>> = []
      genCreatePayload().forEach(sub => {
        p.push(api.post(sub.route, sub.payload))
      })
      genUpdatePayload().forEach(sub => {
        p.push(api.put(sub.route, sub.payload))
      })
      genDeletePayload().forEach(sub => {
        p.push(api.del(sub.route))
      })
      return Promise.all(p)
    }

    try {
      const responses = await saveSubscriptions()
      const erroredItem = responses.find(res => (res as Error).hasOwnProperty('error'))

      if (erroredItem) {
        addAlert({
          alertType: 'error',
          message: (erroredItem as Error).message ?? 'Something went wrong',
        })
      } else {
        addAlert({ alertType: 'success', message: 'Successfully Submitted' })
      }

      actions.setSubmitting(false)
      setFormSubmittedCounter((c: number) => c + 1)
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit the subscription' })
      actions.setSubmitting(false)
    }
  }

  const initialValues = user
    ? {
        subscriptions: initialUserSubscriptions,
        selectedSchemas: initialUserSubscriptions ? initialUserSubscriptions.map((item: IUserSubscriptionType) => item.notificationSchemaId) : [],
      }
    : {
        subscriptions: [],
        selectedSchemas: [],
      }

  let displayName = `${user.information && user.information.first} ${user.information && user.information.last}`.trim()
  displayName = displayName ?? 'Guest User'

  return (
    <Formik data-testid="subscription-form" initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
      {({ isValid, isSubmitting, dirty }) => {
        return (
          <Form>
            <Card elevation={1}>
              <CardHeader
                data-testid="subscriptionsTitle"
                title="User Subscriptions"
                subheader={<Typography variant="subtitle1">{user?.email ?? displayName}</Typography>}
              />

              <SubscriptionsScheduleList
                initialSchemas={uniqBy(initialUserSubscriptions, sub => sub.notificationSchemaId).map(sub => sub.notificationSchemaId)}
                notificationSchemas={notificationSchemas}
              />

              <CardActions>
                <Button data-testid="subscriptions-save-btn" data-cy="saveBtn" color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty}>
                  Save
                </Button>

                <Button type="button" onClick={() => history.push('/subscriptions')} color="inherit" data-testid="subscriptions-cancel-btn" data-cy="cancelBtn">
                  Cancel
                </Button>
              </CardActions>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

const SubscriptionFormContainer = () => {
  const history = useHistory()
  const params = useParams<{ subscriptionId: string }>()
  const classes = useSubscriptionFormStyles()
  const userId = params.subscriptionId
  const { addAlert } = useContext(AlertContext)

  const [notificationSchemas, setNotificationSchemas] = useState<INotificationSchemaType[]>([])
  const [notificationSchemasError, setNotificationSchemasError] = useState<string>('')
  const [initialUserSubscriptions, setInitialUserSubscriptions] = useState<IUserSubscriptionDisplayType[]>([])
  const [initialSubscriptionsError, setInitialSubscriptionsError] = useState<string>('')
  const [user, setUser] = useState<IUserType | null>(null)
  const [userError, setUserError] = useState<string>('')
  const [formSubmittedCounter, setFormSubmittedCounter] = useState<number>(0)

  useDocumentTitle('Subscriptions', user?.email)

  useEffect(() => {
    let isSubscribed: boolean = true

    const getData = async () => {
      try {
        // Notification schemas, user subscriptions and user subscriing to those subscriptions
        const [schemas, subscriptions, subscriber] = await Promise.all([
          api.get('/api/notificationschemas'),
          api.get(`/api/usersubscriptions?userId=${userId}`),
          api.get(`/api/users/${userId}`),
        ])

        if (isSubscribed) {
          if (!schemas.error) {
            setNotificationSchemas(schemas as INotificationSchemaType[])
          } else {
            setNotificationSchemasError(schemas.message)
          }

          if (!subscriptions.error) {
            setInitialUserSubscriptions(subscriptions ? (subscriptions as IUserSubscriptionType[]).map(sub => unpackSubscription(schemas, sub)) : [])
          } else {
            setInitialSubscriptionsError(subscriptions.message)
          }

          if (!subscriber.error) {
            setUser(subscriber)
          } else {
            setUserError(subscriber.message)
          }

          // only redirect to list view if API did not provide a reason for erroring out
          if (subscriptions.error && !subscriptions.message) {
            redirectToListViewOnError(subscriptions, history, addAlert, '/subscriptions')
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    getData()

    return () => {
      isSubscribed = false
    }
    // we need to re-run this useEffext when form has been submitted
    // this is needed so that we can update initialUserSubscriptions after form has been submitted to properly track subscriptions that need to be created
    // otherwise, if user unclicked, save, and then clicked and re-saved the same subscription, it wasn't actually saved since initialSubscriptions weren't updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, formSubmittedCounter])

  if (user && notificationSchemas && initialUserSubscriptions && !notificationSchemasError && !initialSubscriptionsError && !userError) {
    return (
      <SubscriptionForm
        user={user}
        notificationSchemas={notificationSchemas}
        initialUserSubscriptions={initialUserSubscriptions}
        setFormSubmittedCounter={setFormSubmittedCounter}
      />
    )
  } else if (notificationSchemasError || userError || initialSubscriptionsError) {
    return (
      <PermissionsFormError
        errorMessages={combineErrorMessages(notificationSchemasError, initialSubscriptionsError, userError)}
        formName="Subscriptions Form"
        listViewRoute="/subscriptions"
        hasGoBackButton={true}
      />
    )
  } else {
    return <CircularProgress className={classes.subscriptionSpinner} color="secondary" />
  }
}

export default SubscriptionFormContainer
