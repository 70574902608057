import { useContext, useMemo } from 'react'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'

import Alert from '../../../../components/alert'
import FormikTextField from '../../../../components/form-elements/text-field'
import { AlertContext } from '../../../../providers'
import useStyles from '../../../../shared-styles/form.styles'
import api from '../../../../utils/api'

type EditAttributesProps = {
  selectedAttribute: string
  folderId: number
  initialValue: any
  updateValue: (attribute: string, value: any) => void
}

interface IEditAttributeValues {
  attributeValue: string
}

const EditAttributes = ({ selectedAttribute, initialValue, folderId, updateValue }: EditAttributesProps) => {
  const initialValueMemo = useMemo(() => ({ attributeValue: JSON.stringify(initialValue), attributeName: selectedAttribute }), [
    initialValue,
    selectedAttribute,
  ])
  const { addAlert } = useContext(AlertContext)

  const classes = useStyles()

  const saveValue = async (values: IEditAttributeValues, helpers: FormikHelpers<any>) => {
    const { setFieldError, setSubmitting } = helpers

    try {
      setSubmitting(true)
      let value = values.attributeValue
      try {
        value = JSON.parse(value)
      } catch {
        // do nothing, just use regular value
      }

      const res = await api.patch(`/api/v2/folders/${folderId}/attributes`, {
        [selectedAttribute]: value,
      })

      if (res.error || res.code) {
        setFieldError('attributeValue', res.message)
      } else {
        addAlert({ alertType: 'success', message: 'Channel value successfully updated' })
        updateValue(selectedAttribute, value)
      }
    } catch (e) {
      setFieldError('attributeValue', JSON.stringify(e))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik data-testid="edit-attribute-form" initialValues={initialValueMemo} enableReinitialize={true} onSubmit={saveValue}>
      {({ isSubmitting, resetForm, dirty }: FormikProps<IEditAttributeValues>) => {
        return (
          <Form>
            <Card>
              <CardHeader title={`Edit Attribute Value - ${selectedAttribute}`} />

              <CardContent>
                <Typography className={classes.denseFormSubheader} variant="subtitle1">
                  Update Value
                </Typography>
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={true}>
                    <FormikTextField initialized={true} name="attributeValue" label="New Value" required={true} multiline={true} />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Alert alertType="warning" message="it will parse the string as JSON if it's valid JSON" />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button type="submit" disabled={isSubmitting} color="primary">
                  save
                </Button>
                <Button color="secondary" onClick={() => resetForm()} disabled={!dirty}>
                  reset
                </Button>
              </CardActions>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditAttributes
