import { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Button, Grid, IconButton } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { Location } from 'history'
import * as Yup from 'yup'

import FormikTextField from '../../components/form-elements/text-field'
import { AlertContext, AuthenticationContext } from '../../providers'
import Api from '../../utils/api'
import { useDocumentTitle } from '../../utils/hooks'

import ForgotPassword from './forgot-password'
import useLoginStyles from './login.styles'
import LoginContent from './login-content'

interface ILoginValues {
  username: string
  password: string
}

const VALID_DOMAIN_LIST = ['@meshify.com', '@hsb.com', '@munichre.com', '@sercomm.com', '@praetorian.com', '@biico.com', '@calyxtechs.com', '@ubitech.hk']

const isValidEmailDomain = (username: string, domainList: string[]) => {
  for (const domain of domainList) {
    if (username.endsWith(domain)) {
      return true
    }
  }
  return false
}

const Login = () => {
  const classes = useLoginStyles()
  const location = useLocation() as Location<{ from: { pathname: string } }>
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const { setIsAuthenticated } = useContext(AuthenticationContext)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false)

  useDocumentTitle('Login')

  const schema = Yup.object().shape({
    username: Yup.string()
      .required(' ')
      .email('Must be a valid email'),
    password: Yup.string().required(' '),
  })

  const initialValues = {
    username: '',
    password: '',
  }

  const loginUser = async (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => {
    const { username, password } = values

    if (isValidEmailDomain(username, VALID_DOMAIN_LIST)) {
      try {
        const res = await Api.post('/api/login', {
          username,
          password,
        })

        if (res.error) {
          actions.resetForm()
          setIsAuthenticated(false)
          addAlert({
            alertType: 'error',
            message: `Login Errored: ${res.message ? res.message : 'Error Unknown'}`,
          })
          return
        }

        // Just setting a token with a value on successful login so we don't have to call /users/me
        // every time to validate that we are logged in. Any error with auth related to a 401 should
        // trigger a "logout" action that clears this token and redirects to login.
        setIsAuthenticated(true)

        const redirect = location?.state?.from
        history.push(redirect?.pathname.includes('login') === false ? redirect : '/users')
      } catch (e) {
        setIsAuthenticated(false)
        addAlert({ alertType: 'error', message: 'Something went wrong' })
      }
    } else {
      const domain = username.split('@').pop()
      addAlert({
        alertType: 'error',
        message: `@${domain} is not allowed to login to Admin UI. Please use email with approved domain to login. You can still log in to other apps you have access to`,
      })
    }
  }

  const endAdornment = () => (
    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} data-testid="show-password" size="large">
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={12} sm={12} md={5} lg={5} xl={4}>
          <Grid container={true} direction="row" justifyContent="center" alignItems="center">
            <Grid item={true} xs={8}>
              <div className={classes.meshifyTextWrapper}>
                <span className={classes.meshifySpan}>Meshify</span>
                <span className={classes.adminSpan}>Admin</span>
              </div>
            </Grid>

            <Grid item={true} xs={8}>
              <p className={classes.welcomeText}>Welcome</p>
            </Grid>

            <Grid item={true} xs={8}>
              <span className={classes.loginText}>Log in to your account</span>
            </Grid>

            <Grid item={true} xs={8}>
              <Formik initialValues={initialValues} onSubmit={loginUser} validationSchema={schema}>
                {(formikBag: FormikProps<ILoginValues>) => {
                  const { isValid, dirty } = formikBag

                  return (
                    <Form className={classes.smallTopMargin}>
                      <Grid container={true}>
                        <Grid item={true} xs={12} data-cy="username">
                          <FormikTextField name="username" label="Email" required={true} variant="outlined" />
                        </Grid>

                        <Grid item={true} xs={12} data-cy="password" className={classes.smallTopMargin}>
                          <FormikTextField
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            label="Password"
                            required={true}
                            variant="outlined"
                            formDependent={true}
                            endAdornment={endAdornment}
                          />
                        </Grid>

                        <Grid item={true} xs={12} className={classes.smallTopMargin}>
                          <Button
                            data-cy="signIn"
                            variant="contained"
                            data-testid="signinBtn"
                            color="primary"
                            type="submit"
                            disabled={!isValid || !dirty}
                            fullWidth={true}
                            size="large"
                          >
                            Log In
                          </Button>
                        </Grid>

                        <Grid item={true} xs={12}>
                          <Grid container={true} justifyContent="flex-end" className={classes.smallTopMargin}>
                            <Button data-testid="forgotPwBtn" data-cy="forgotPassBtn" onClick={() => setShowForgotPassword(true)} color="primary">
                              Forgot Password?
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>

        <LoginContent />
      </Grid>

      {showForgotPassword && <ForgotPassword open={showForgotPassword} setVisible={setShowForgotPassword} />}
    </>
  )
}

export default Login
