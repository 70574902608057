import { useContext } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { camelCase } from 'lodash'

import { WRITE_PERMISSIONS_LIST } from '../../constants/permissions'
import { OwnUserContext } from '../../providers'
import useTableStyles from '../../shared-styles/table.styles'

type BtnProps = {
  userPermissions: IPermissionType[]
  newComponentLink: any
  entityName: string
  buttonName: string
  dataCy: string
  dataTestId: string
}

export const isAddBtnDisabled = (menuItemName: string, userPermissions: IPermissionType[], currentUser: IUserType | null): boolean => {
  // let's convert sidenav item name to camelcase
  // so that we can grab it by name from READ_PERMISSIONS_LIST constant
  const camelCaseItemName = camelCase(menuItemName)
  if (currentUser?.isRoot) {
    return false
  } else {
    if (userPermissions.length === 0) {
      return true
    } else if (WRITE_PERMISSIONS_LIST[camelCaseItemName]) {
      return userPermissions.find(item => item.id === WRITE_PERMISSIONS_LIST[camelCaseItemName].id) === undefined
    }
  }

  return false
}

const AddBtn = (props: BtnProps) => {
  const currentUser = useContext<IUserType | null>(OwnUserContext)
  const classes = useTableStyles()
  const { userPermissions, newComponentLink, entityName, buttonName, dataCy, dataTestId } = props

  const buttonDisabled = isAddBtnDisabled(entityName, userPermissions, currentUser)
  return buttonDisabled ? (
    <Tooltip title={`You do not have permissions to write ${entityName}`} placement="left" arrow={true}>
      <span>
        <Button
          variant="outlined"
          color="primary"
          component={newComponentLink}
          data-cy={`disabled-${dataCy}`}
          data-testid={`disabled-${dataTestId}`}
          className={classes.addBtn}
          disabled={true}
        >
          {buttonName}
        </Button>
      </span>
    </Tooltip>
  ) : (
    <Button variant="outlined" color="primary" component={newComponentLink} data-cy={dataCy} data-testid={dataTestId} className={classes.addBtn}>
      {buttonName}
    </Button>
  )
}

export default AddBtn
