import CircularProgress from '@mui/material/CircularProgress'
import MUIDataTable from 'mui-datatables'

import useTableStyles from './table.styles'

interface IProps {
  title: string | React.ReactNode
  elevation: number | undefined
}

export const LoadingSpinnerRow = () => {
  const classes = useTableStyles()
  return (
    <div data-testid="table-loader" data-cy="tableLoader" className={classes.tableLoader}>
      <CircularProgress color="secondary" />
    </div>
  )
}

export const LoadingTable = ({ title, elevation }: IProps) => {
  const props = {
    title,
    columns: [
      {
        name: '',
        options: {
          customBodyRender: () => <LoadingSpinnerRow />,
        },
      },
    ],
    data: [['just a placeholder to ensure a row is rendered']],
    options: {
      elevation: elevation ?? 1,
      page: 0,
      filter: false,
      print: false,
      viewColumns: false,
      download: false,
      search: false,
      selectableRows: 'none' as const,
      customFooter: () => {
        return <tbody />
      },
    },
  }
  return <MUIDataTable {...props} />
}
export default LoadingTable
