import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { NumberParam, useQueryParam } from 'use-query-params'

import useSharedFormStyles from '../../shared-styles/form.styles'

import WhatsInside from './whats-inside/whats-inside'
import FolderForm from './folder-form'
import PrimaryContact from './primary-contact'

const CreateEditFolder = () => {
  const classes = useSharedFormStyles()

  const history = useHistory()
  const params = useParams<{ folderId: string }>()

  const folderId = params.folderId
  const isEditMode = folderId !== 'new'
  const defaultTabIndex = 0

  const [selectedTabIndex, setSelectedTabIndex] = useQueryParam('tab', NumberParam)

  const [dashboardUrl, setDashboardUrl] = useState<string>('')

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      const domain = window.location.href.substr(0, window.location.href.indexOf('/admin'))
      const folderDashboardUrl = domain + `/dashboard3#folders?folderId=${folderId}`
      if (isSubscribed) {
        setDashboardUrl(folderDashboardUrl)
      }
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, folderId])

  useEffect(() => {
    if (!selectedTabIndex) {
      setSelectedTabIndex(0)
    }
  }, [selectedTabIndex, setSelectedTabIndex])

  return (
    <>
      {!isEditMode && (
        <Card>
          <CardHeader title="Create Folder" data-cy="formTitle" />

          <CardContent>
            <FolderForm />
          </CardContent>
        </Card>
      )}

      {isEditMode && (
        <Card>
          <CardHeader
            title="Edit Folder"
            action={
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                target="_blank"
                href={dashboardUrl}
                data-testid="dashboardBtn"
                data-cy="viewInDashboard"
              >
                View in Dashboard
              </Button>
            }
            data-cy="formTitle"
          />

          <CardContent>
            <Tabs
              value={selectedTabIndex ? selectedTabIndex : defaultTabIndex}
              onChange={(event: React.ChangeEvent<{}>, value: number) => setSelectedTabIndex(value, 'push')}
              textColor="secondary"
              indicatorColor="secondary"
              className={classes.tabs}
            >
              <Tab label="Settings" data-cy="settings" />
              <Tab label="What's inside" data-testid="whatsInsideTab" data-cy="whatsInside" />
              <Tab label="Primary Contact" />
            </Tabs>

            {selectedTabIndex === 0 && <FolderForm />}
            {selectedTabIndex === 1 && <WhatsInside />}
            {selectedTabIndex === 2 && <PrimaryContact folderId={folderId} />}
          </CardContent>

          <CardActions>
            {selectedTabIndex === 1 && (
              <Button type="button" onClick={() => history.push('/folders')} color="inherit" data-testid="whatsInsideCancelBtn">
                Cancel
              </Button>
            )}
          </CardActions>
        </Card>
      )}
    </>
  )
}

export default CreateEditFolder
