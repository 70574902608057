import { forwardRef, Ref, useCallback, useContext, useRef, useState } from 'react'
import { Link, LinkProps, useHistory, useLocation } from 'react-router-dom'
import { Button, Icon, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'

import AddButton from '../../components/add-entity-button'
import ConfirmDeleteModal from '../../components/modal'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import { AlertContext, UserPermissionsContext } from '../../providers'
import api from '../../utils/api'
import { getExtraParams, useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { constructFrontEndTableParams, getParams } from '../../utils/table/table-helpers'

import CustomFooter from './custom-footer'
import useStyles from './list.styles'

type ErrorType = { error: boolean; message: string }

export const TableCopy = {
  CONFIRM_DELETE: 'Are you sure you want to delete this Nodetype Template?',
  DELETING_TEMPLATE: (n: INodeTypeTemplateType) => `You will be deleting ${n.name}.`,
  NO_ROWS_TO_DELETE: 'There are no Nodetype templates to delete',
  DEFAULT_ERROR_MESSAGE: 'Something went wrong. You might be logged out. Try logging out and logging back in.',
}

const StyledTableCell: React.FC<{ colSpan: number; index: number } & TableCellProps> = ({ children, onClick, colSpan }) => {
  const classes = makeStyles((theme: Theme) => {
    return {
      root: {
        paddingLeft: theme.spacing(3),
      },
    }
  })()
  return (
    <TableCell onClick={onClick} classes={classes} colSpan={colSpan}>
      {children}
    </TableCell>
  )
}

const StyledTableRow: React.FC<{ nttID: number }> = ({ children, nttID }) => {
  const history = useHistory()
  const classes = makeStyles(() => {
    return {
      root: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    }
  })()

  return (
    <TableRow
      data-cy="template"
      onClick={() => {
        history.push({ pathname: `/nodetype-templates/${nttID}` })
      }}
      hover={true}
      classes={classes}
    >
      {children}
    </TableRow>
  )
}

const NTTList = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const params = getParams(location)
  const { searchText, setSearchText } = useSearchData()

  const userPermissions = useContext(UserPermissionsContext)
  const { addAlert } = useContext(AlertContext)

  const nodeTypeMap = useRef(new Map<string, INodeTypeType>())
  const nodeTypeTemplateMap = useRef(new Map<number, INodeTypeTemplateType[]>())

  const [nodeTypes, setNodeTypes] = useState<INodeTypeType[]>([])
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const [nodeTypeTemplateToDelete, setNodeTypeTemplateToDelete] = useState<INodeTypeTemplateType | null>(null)

  const { DEFAULT_ERROR_MESSAGE, DELETING_TEMPLATE, CONFIRM_DELETE, NO_ROWS_TO_DELETE } = TableCopy

  useDocumentTitle('Nodetype Templates')

  const toggleConfirmModal = useCallback(
    (ntt: INodeTypeTemplateType | null, shouldRefresh: boolean) => {
      setConfirmDeleteModal(!showConfirmDeleteModal)
      setNodeTypeTemplateToDelete(ntt ? ntt : null)
      if (shouldRefresh) {
        setRefreshCounter(refreshCounter + 1)
      }
    },
    [setRefreshCounter, setConfirmDeleteModal, showConfirmDeleteModal, refreshCounter]
  )

  const deleteNodeTypeTemplate = async () => {
    const deleteEndpoint = `/api/nodetypetemplates/${nodeTypeTemplateToDelete?.id}`

    try {
      const response = await api.del(deleteEndpoint)

      if (response.error) {
        addAlert({
          alertType: 'error',
          message: DEFAULT_ERROR_MESSAGE,
        })
      } else {
        addAlert({
          alertType: 'success',
          message: `Node Type Template ${nodeTypeTemplateToDelete?.name} deleted.`,
        })
      }
    } catch (e) {
      addAlert({
        alertType: 'error',
        message: String(e) || 'Could not delete nodetype template(s)',
      })
    }
    toggleConfirmModal(null, true)
  }
  const columns = [{ name: 'Node Type Name' }, { name: ' ', options: { filter: false } }]

  const fetch = async () => {
    nodeTypeMap.current = new Map<string, INodeTypeType>()
    nodeTypeTemplateMap.current = new Map<number, INodeTypeTemplateType[]>()

    const retrievedNodeTypes: INodeTypeType[] | ErrorType | undefined = await api.get('/api/nodetypes')
    if (retrievedNodeTypes && (retrievedNodeTypes as ErrorType).error) {
      throw new Error((retrievedNodeTypes as ErrorType).message)
    }

    const retrievedNodeTypeTemplates: INodeTypeTemplateType[] | ErrorType | undefined = await api.get('/api/nodetypetemplates')
    if (retrievedNodeTypeTemplates && (retrievedNodeTypeTemplates as ErrorType).error) {
      throw new Error((retrievedNodeTypeTemplates as ErrorType).message)
    }
    if (retrievedNodeTypeTemplates == null) {
      return { data: [] }
    }

    ;(retrievedNodeTypeTemplates as INodeTypeTemplateType[]).forEach(ntt => {
      let templatesForID = nodeTypeTemplateMap.current.get(ntt.nodeTypeId)

      if (templatesForID) {
        templatesForID.push(ntt)
      } else {
        templatesForID = [ntt]
      }
      nodeTypeTemplateMap.current.set(ntt.nodeTypeId, templatesForID)
    })
    ;(retrievedNodeTypes as INodeTypeType[]).forEach(nt => {
      nodeTypeMap.current.set(nt.name, nt)
    })

    const data = retrievedNodeTypes
      ? (retrievedNodeTypes as INodeTypeType[]).map((nt, idx) => {
          const newTemplateLink = forwardRef((componentProps: any, ref: any) => <Link {...componentProps} to={`/nodetype-templates/new/${nt.id}`} ref={ref} />)

          return [
            nt.name,
            <Tooltip title={`Add ${nt.name} template`} key={idx}>
              <Button
                to={`/nodetype-templates/new/${nt.id}`}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
                component={newTemplateLink}
                data-testid={`${nt.id}-createFromTemplateBtn`}
                data-cy="createFromTemplateBtn"
                size="small"
                color="secondary"
                className={classes.createFromTemplateBtn}
              >
                <i className="material-icons">add_circle_outline</i>
              </Button>
            </Tooltip>,
          ]
        })
      : []

    setNodeTypes((retrievedNodeTypes as INodeTypeType[]) ?? [])
    return { data }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetch, [refreshCounter])

  const tableState = useTableState(memoizedFetch)

  const BlankNodeTypeTemplateLink = forwardRef((componentProps: LinkProps<HTMLAnchorElement>, ref: Ref<HTMLAnchorElement>) => (
    <Link {...componentProps} ref={ref} to="/nodetype-templates/new" />
  ))

  return (
    <>
      <Grid className={classes.tableContainer} data-cy="nodeTypeTemplatesList" container={true} justifyContent="flex-end">
        <Grid container={true} justifyContent="flex-end">
          <AddButton
            userPermissions={userPermissions}
            newComponentLink={BlankNodeTypeTemplateLink}
            entityName="Nodes"
            buttonName="Add Node Type Template"
            dataCy="addBtn"
            dataTestId="addBtn"
          />
        </Grid>

        <Grid item={true} xs={12}>
          <ClientSidePaginatedTable
            data-cy="nodeTypeTemplatesList"
            title="Node Type Templates"
            entityDeleteEndpoint=""
            refresh={() => setRefreshCounter(c => c + 1)}
            idList={nodeTypes.map((n: INodeTypeType) => ({ id: n.id }))}
            columns={columns}
            loading={tableState.loading}
            error={tableState.error}
            data={tableState.data}
            preserveSearchResults={{ searchText, setSearchText }}
            options={{
              selectableRows: 'none',
              expandableRows: true,
              customSearch: (text: string, row: React.ReactNode[]): boolean => {
                let isFound = false
                if (row) {
                  row.forEach(el => {
                    const nodeType = nodeTypeMap.current.get(el as string)
                    const nodeTypeTemplateList = nodeType ? nodeTypeTemplateMap.current.get(nodeType.id) : []

                    if (
                      nodeType?.name.toLowerCase().match(text.toLowerCase()) ||
                      nodeTypeTemplateList?.some((item: INodeTypeTemplateType) => item.name.toLowerCase().match(text.toLowerCase()))
                    ) {
                      isFound = true
                    }
                  })
                }
                return isFound
              },
              customFooter: (
                totalRowCount: number,
                currentPageNumber: number,
                currentRowsPerPage: number,
                changeRowsPerPage: (n: React.ReactText) => void,
                changePage: (n: number) => void
              ) => {
                // Hacky approach to change what we're iterating over based on if theres search text or not
                const currentView = tableState?.data
                  ? searchText !== ''
                    ? tableState.data.filter(el => {
                        const nodeType = nodeTypeMap.current.get(el[0] as string)
                        const nodeTypeTemplateList = nodeType ? nodeTypeTemplateMap.current.get(nodeType.id) : []
                        let isFound = false
                        if (
                          nodeType?.name.toLowerCase().match(searchText.toLowerCase()) ||
                          nodeTypeTemplateList?.some((item: INodeTypeTemplateType) => item.name.toLowerCase().match(searchText.toLowerCase()))
                        ) {
                          isFound = true
                        }
                        return isFound
                      })
                    : tableState.data.slice(currentPageNumber * currentRowsPerPage, currentPageNumber * currentRowsPerPage + currentRowsPerPage)
                  : []
                const nodeTypeTemplateCount = currentView.reduce((acc, curr) => {
                  const nodeType = nodeTypeMap.current.get(curr[0] as string)
                  if (nodeType) {
                    const nttList = nodeTypeTemplateMap.current.get(nodeType.id)
                    if (nttList) {
                      // eslint-disable-next-line operator-assignment
                      acc = acc + nttList.filter(item => item.name.toLowerCase().match(searchText.toLowerCase())).length
                    }
                  }
                  return acc
                }, 0)
                return (
                  <CustomFooter
                    nodeTypeTemplateCount={nodeTypeTemplateCount}
                    count={totalRowCount}
                    page={currentPageNumber}
                    rowsPerPage={currentRowsPerPage ?? 10}
                    changeRowsPerPage={changeRowsPerPage}
                    onChangePage={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, pageNum: number) => {
                      changePage(pageNum)
                      const extraParams = getExtraParams(location)
                      const tableParams = constructFrontEndTableParams({ ...params, pageNumber: pageNum ?? 0 })
                      history.push({
                        pathname: location.pathname,
                        search: extraParams ? `${tableParams}&${extraParams}` : tableParams,
                      })
                    }}
                  />
                )
              },
              isRowExpandable: () => {
                return true
              },
              rowsExpanded: tableState?.data
                ? tableState.data.map((i, index) => {
                    return index
                  })
                : [],
              filter: true,
              filterType: 'dropdown' as const,
              onCellClick: (_, cellMeta) => {
                if (nodeTypes.length > 0) {
                  history.push({
                    pathname: `/node-types/${nodeTypes[cellMeta.dataIndex].id}`,
                  })
                }
              },
              renderExpandableRow: rowData => {
                const [name] = rowData

                const nodeTypeData = nodeTypeMap.current.get(name)
                if (nodeTypeData) {
                  const allTemplates = nodeTypeTemplateMap.current.get(nodeTypeData.id)
                  const visibleTemplates = allTemplates ? allTemplates.filter(item => item.name.toLowerCase().match(searchText.toLowerCase())) : []

                  return visibleTemplates.length > 0 ? (
                    visibleTemplates.map((ntt, index) => {
                      return (
                        <StyledTableRow key={index} nttID={ntt.id}>
                          <StyledTableCell
                            onClick={(e: any) => {
                              e.stopPropagation()
                            }}
                            index={index}
                            colSpan={1}
                          >
                            <Icon
                              onClick={e => {
                                e.stopPropagation()
                                toggleConfirmModal(ntt, false)
                              }}
                              className={classes.deleteIcon}
                              data-testid="delete-templ-icon"
                            >
                              delete_forever
                            </Icon>
                          </StyledTableCell>
                          <StyledTableCell data-cy="nodeTypeTemplate" index={index} colSpan={rowData.length}>
                            {ntt.name}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })
                  ) : (
                    <TableRow />
                  )
                }
                return <TableCell>No Templates Found</TableCell>
              },
              customSearchRender: (tableSearchText, handleSearch) => {
                return (
                  <CustomSearchBox
                    searchText={tableSearchText}
                    handleSearch={handleSearch}
                    entityPath="nodetype-templates"
                    isValidId={(id: number) =>
                      nodeTypeTemplateMap.current &&
                      !!Array.from(nodeTypeTemplateMap.current.values())
                        .flat()
                        .find(nodeTypeTemplate => nodeTypeTemplate.id === id)
                    }
                  />
                )
              },
            }}
          />
        </Grid>
      </Grid>

      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        confirmModalAction={deleteNodeTypeTemplate}
        closeModal={() => toggleConfirmModal(null, false)}
        dialogContent={nodeTypeTemplateToDelete != null ? DELETING_TEMPLATE(nodeTypeTemplateToDelete) : NO_ROWS_TO_DELETE}
        dialogTitle={CONFIRM_DELETE}
      />
    </>
  )
}

export default NTTList
