import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import Alert from '../../../components/alert'
import FormikTextField from '../../../components/form-elements/text-field'
import { AlertContext } from '../../../providers'
import useSharedFormStyles from '../../../shared-styles/form.styles'
import Api from '../../../utils/api'

interface IFormValues {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const ChangePasswordForm = () => {
  const classes = useSharedFormStyles()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const formInitialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const schema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'Must be 8 characters or more'),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  })

  const changePassword = async (formValues: IFormValues) => {
    Api.post('/api/authentication/changepassword', {
      newPassword: formValues.newPassword,
      oldPassword: formValues.currentPassword,
    }).then(res => {
      if (res.error) {
        addAlert({
          alertType: 'error',
          message: res.message ? res.message : 'Could not change password. Please try again',
        })
      } else {
        history.push('')
        Api.get('/api/logout')
        addAlert({ alertType: 'success', message: 'Password has been changed' })
      }
    })
  }

  const currentPasswordEndAdornment = () => (
    <IconButton
      aria-label="toggle current password visibility"
      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
      data-testid="toggle-current-password"
      size="small"
    >
      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  const newPasswordEndAdornment = () => (
    <IconButton aria-label="toggle new password visibility" onClick={() => setShowNewPassword(!showNewPassword)} data-testid="show-new-password" size="small">
      {showNewPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  const confirmPasswordEndAdornment = () => (
    <IconButton
      aria-label="toggle confirm password visibility"
      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
      data-testid="show-confirm-password"
      size="small"
    >
      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  return (
    <Formik initialValues={formInitialValues} validationSchema={schema} onSubmit={() => {}}>
      {({ values, isValid, dirty }: FormikProps<IFormValues>) => {
        return (
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Typography className={classes.formSubheader} variant="subtitle1">
                Change Password
              </Typography>
            </Grid>

            <Grid item={true} sm={12} xs={12} md={6} lg={6} xl={6}>
              <Grid item={true} xs={12}>
                <FormikTextField
                  name="currentPassword"
                  label="Current Password"
                  required={true}
                  type={showCurrentPassword ? 'text' : 'password'}
                  endAdornment={currentPasswordEndAdornment}
                  formDependent={true}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <FormikTextField
                  name="newPassword"
                  label="New Password"
                  required={true}
                  type={showNewPassword ? 'text' : 'password'}
                  endAdornment={newPasswordEndAdornment}
                  formDependent={true}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <FormikTextField
                  name="confirmPassword"
                  label="Confirm Password"
                  required={true}
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={confirmPasswordEndAdornment}
                  formDependent={true}
                />
              </Grid>

              <Grid item={true} xs={12} container={true} justifyContent="flex-end">
                <Button
                  data-testid="changePasswordBtn"
                  disabled={!isValid || !dirty}
                  color="primary"
                  type="button"
                  variant="outlined"
                  onClick={() => changePassword(values)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid item={true} sm={12} xs={12} md={6} lg={6} xl={6}>
              <Alert alertType="warning" message="You will be logged out and asked to authenticate with your new password." />
            </Grid>
          </Grid>
        )
      }}
    </Formik>
  )
}

export default ChangePasswordForm
