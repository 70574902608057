import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, CircularProgress, Grid, Tooltip } from '@mui/material'

import { ClientSidePaginatedTable } from '../../../components/table'
import TableLink from '../../../components/table-link'
import Api from '../../../utils/api'

import useStyles from './index.styles'

interface IProps {
  nodeId: number
}

interface IUserNotification {
  userId: number
  email: number[]
  phone: number[]
  sms: number[]
  webhook: number[]
}

const UserReactions = (props: IProps) => {
  const { nodeId } = props
  const classes = useStyles()

  const [userNotifications, setUserNotifications] = useState<IUserNotification[]>([])
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [users, setUsers] = useState<IUserType[]>([])

  useEffect(() => {
    setIsLoading(true)
    Api.get(`/api/v2/nodes/${nodeId}/users-notifications`).then(res => {
      setIsLoading(false)

      if (res.error) {
        setError(res?.message ?? 'Something went wrong, we could not load users available for this node')
      } else {
        setError('')

        const usersNotifications = res?.usersNotifications
        setUserNotifications(usersNotifications ?? [])

        const userIds: number[] = res?.usersNotifications.map((item: IUserNotification) => item?.userId)
        const getUserRequests = userIds.map(id => Api.get(`/api/users/${id}`))
        const getUsers = async () => {
          await Promise.all(getUserRequests).then(responses => {
            const successResponses = responses.filter(response => !response.error)
            setUsers(successResponses)
          })
        }
        getUsers()
      }
    })
  }, [nodeId])

  const columns = [
    { name: 'User', options: { filter: false, sort: false } },
    { name: 'Email', options: { filter: false, sort: false } },
    { name: 'SMS Reaction ID', options: { filter: false, sort: false } },
    { name: 'Email Reaction ID', options: { filter: false, sort: false } },
    { name: 'Phone Reaction ID', options: { filter: false, sort: false } },
    { name: 'Webhook Reaction ID', options: { filter: false, sort: false } },
  ]

  const tableData = useMemo(() => {
    const allRowData: Array<Array<number | string | React.ReactNode>> = userNotifications.map(item => {
      const userName = () => {
        const matchedUser = users.find(user => user.id === item.userId)
        return matchedUser ? (
          <TableLink to={`/users/${item.userId}`}>{`${matchedUser.information?.first} ${matchedUser.information?.last}`}</TableLink>
        ) : (
          <Tooltip title="User ID">
            <span>{item.userId}</span>
          </Tooltip>
        )
      }

      const userEmail = () => {
        const matchedUser = users.find(user => user.id === item.userId)
        return matchedUser?.email ?? ''
      }

      const getReactionIds = (ids: number[]) => {
        const linkIds = ids.map((id, idx, arr) => (
          <>
            <Link
              className={classes.link}
              to={`/reactions/${id}`}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {id}
            </Link>

            {arr.length - 1 !== idx && <span>, </span>}
          </>
        ))
        return linkIds
      }

      return [userName, userEmail, getReactionIds(item.sms), getReactionIds(item.email), getReactionIds(item.phone), getReactionIds(item.webhook)]
    })

    return allRowData
  }, [userNotifications, classes, users])

  return (
    <>
      <Grid container={true} spacing={3}>
        {isLoading && !error && (
          <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.topMargin}>
            <CircularProgress />
          </Grid>
        )}

        {!isLoading && error && (
          <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.topMargin}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        {!isLoading && !error && userNotifications.length === 0 && (
          <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.topMargin}>
            <Alert severity="info">This node currently does not have any users that will receive notifications</Alert>
          </Grid>
        )}

        {!isLoading && !error && userNotifications.length > 0 && (
          <Grid item={true} xs={12} className={classes.topMargin}>
            <ClientSidePaginatedTable
              title="User Notifications"
              idList={[]}
              refresh={() => {}}
              columns={columns}
              loading={false}
              error={null}
              data={tableData}
              options={{
                filter: false,
                search: false,
                searchOpen: false,
                selectableRows: 'none',
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default UserReactions
