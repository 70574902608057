import { useEffect } from 'react'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'

import useFilterSelectStyles from '../styles'

interface IFooter {
  items: any[]
  currentPage: number
  setCurrentPage: (page: number) => void
}

const ROWS_PER_PAGE = 10

const Footer = (props: IFooter) => {
  const { items, currentPage, setCurrentPage } = props

  const classes = useFilterSelectStyles()

  useEffect(() => {
    // whenever new items are passed to footer, re-set the page back to the first one
    setCurrentPage(1)
  }, [items, setCurrentPage])

  const totalPages = Math.ceil(items.length / ROWS_PER_PAGE)

  const handleFirstPageButtonClick = () => {
    setCurrentPage(1)
  }

  const handleNextButtonClick = () => {
    setCurrentPage(currentPage + 1)
  }

  const handlePreviousButtonClick = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleLastPageButtonClick = () => {
    setCurrentPage(totalPages)
  }

  return (
    <Grid container={true} justifyContent="center" alignItems="center" className={classes.footerWrapper}>
      <Grid item={true} xs={2}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={currentPage === 1}
          aria-label="first page"
          data-testid="goToFirstPage"
          data-cy="goToFirstPage"
          size="large"
        >
          <FirstPageIcon />
        </IconButton>
      </Grid>

      <Grid item={true} xs={2}>
        <IconButton
          disabled={currentPage === 1}
          aria-label="previous page"
          data-testid="prevPage"
          data-cy="prevPage"
          onClick={handlePreviousButtonClick}
          size="large"
        >
          <KeyboardArrowLeft />
        </IconButton>
      </Grid>

      <Grid item={true} xs={4} container={true} justifyContent="center">
        <span className={classes.footerText}>{items.length === 0 ? null : `Page ${currentPage}/${totalPages}`}</span>
      </Grid>

      <Grid item={true} xs={2}>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={currentPage >= totalPages}
          aria-label="next page"
          data-testid="nextPage"
          data-cy="nextPage"
          size="large"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Grid>

      <Grid item={true} xs={2}>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={currentPage >= totalPages}
          aria-label="last page"
          data-testid="goToLastPage"
          data-cy="goToLastPage"
          size="large"
        >
          <LastPageIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default Footer
