import { useContext, useMemo } from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'

import Alert from '../../../../components/alert'
import FormikTextField from '../../../../components/form-elements/text-field'
import { AlertContext } from '../../../../providers'
import useStyles from '../../../../shared-styles/form.styles'
import api from '../../../../utils/api'

type EditChannelProps = {
  selectedChannel: string
  nodeId: number
  initialValue: any
}

interface IEditChannelValues {
  channelValue: string
}

const EditChannel = ({ selectedChannel, initialValue, nodeId }: EditChannelProps) => {
  const initialValueMemo = useMemo(() => ({ channelValue: JSON.stringify(initialValue), channelName: selectedChannel }), [initialValue, selectedChannel])
  const { addAlert } = useContext(AlertContext)

  const classes = useStyles()

  const saveValue = async (values: IEditChannelValues, helpers: FormikHelpers<any>) => {
    const { setFieldError, setSubmitting } = helpers

    try {
      setSubmitting(true)
      let value = values.channelValue
      try {
        value = JSON.parse(value)
      } catch {
        // do nothing, just use regular value
      }

      const res = await api.post('/api/publish/cloud', {
        channelName: selectedChannel,
        nodeId,
        value,
      })

      if (res.error) {
        setFieldError('channelValue', res.message)
      } else {
        addAlert({ alertType: 'success', message: 'Channel value successfully updated' })
      }
    } catch (e) {
      setFieldError('channelValue', JSON.stringify(e))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik data-testid="user-create-form" initialValues={initialValueMemo} enableReinitialize={true} onSubmit={saveValue}>
      {({ isSubmitting, resetForm, dirty }: FormikProps<IEditChannelValues>) => {
        return (
          <Form>
            <Card>
              <CardHeader title={`Edit Channel Value - ${selectedChannel}`} />

              <CardContent>
                <Typography className={classes.denseFormSubheader} variant="subtitle1">
                  Update Value
                </Typography>
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={true}>
                    <FormikTextField initialized={true} name="channelValue" label="New Value" required={true} multiline={true} />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Alert alertType="warning" message="it will parse the string as JSON if it's valid JSON" />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button type="submit" disabled={isSubmitting} color="primary">
                  save
                </Button>
                <Button color="secondary" onClick={() => resetForm()} disabled={!dirty}>
                  reset
                </Button>
              </CardActions>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditChannel
