import { forwardRef, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { debounce, isEmpty } from 'lodash'
import { MUIDataTableColumn } from 'mui-datatables'

import AddButton from '../../components/add-entity-button'
import { ServerSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import { ServerSideFilterFooter } from '../../components/table-filter-footer/filter-footer'
import TableLink from '../../components/table-link'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'

import { UserPermissionsContext } from './../../providers'
import { useDocumentTitle, usePagination, useTableState } from './../../utils/hooks'

type FilterType<A, B, C> = { fieldName: A; operator: B; value: C }
export type NodesFilterTypes = {
  vanity: FilterType<'vanity', 'like', string>
  uniqueId: FilterType<'uniqueId', 'like', string>
  isActive: FilterType<'isActive', 'eq', boolean>
  nodeTypeId: FilterType<'nodeTypeId', 'eq', number>
}

const NodesList = () => {
  const history = useHistory()
  const pageHook = usePagination()
  const classes = useTableStyles()

  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)

  const [nodeTypes, setNodeTypes] = useState<INodeTypeType[]>([])
  const [currentNodes, setCurrentNodes] = useState<INodeType[] | null>(null)
  const [currentFilters, setCurrentFilters] = useState<Partial<NodesFilterTypes>>({})

  useDocumentTitle('Nodes')

  /* 
    Wrapping the filterList[index] value in an array is unfortunately necessary, I have no idea why its necessary, but problems show up
    when you have values with length greater than one.
   */
  const columns: MUIDataTableColumn[] = [
    {
      name: 'Name',
      options: {
        customFilterListOptions: {
          update: (val: any) => {
            setCurrentFilters(cf => ({ ...cf, vanity: undefined }))
            return val
          },
        },
        filter: true,
        filterType: 'custom' as const,
        // this is the state of the current filter for this column, mui-datatables will show these values as chips
        filterList: currentFilters?.vanity?.value ? [currentFilters?.vanity?.value] : [],
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            return (
              <TextField
                label="Name"
                inputProps={{ 'data-testid': 'nodes-filter-vanity' }}
                value={filterList[index][0] ?? ''}
                size="small"
                variant="standard"
                onChange={event => {
                  filterList[index] = [event?.target?.value]
                  onChange(filterList[index], index, column)
                }}
              />
            )
          },
        },
        sort: true,
      },
    },
    {
      name: 'Unique ID',
      options: {
        customFilterListOptions: {
          update: (val: any) => {
            setCurrentFilters(cf => ({ ...cf, uniqueId: undefined }))
            return val
          },
        },
        filter: true,
        filterType: 'custom' as const,
        // this is the state of the current filter for this column, mui-datatables will show these values as chips
        filterList: currentFilters?.uniqueId?.value ? [currentFilters?.uniqueId?.value] : [],
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            return (
              <TextField
                inputProps={{ 'data-testid': 'nodes-filter-uniqueId' }}
                label="Unique ID"
                size="small"
                variant="standard"
                value={filterList[index][0] ?? ''}
                onChange={event => {
                  filterList[index] = [event?.target?.value]
                  onChange(filterList[index], index, column)
                }}
              />
            )
          },
        },
        sort: true,
      },
    },
    {
      name: 'Node Type',
      options: {
        customFilterListOptions: {
          update: (val: any) => {
            setCurrentFilters(cf => ({ ...cf, nodeTypeId: undefined }))
            return val
          },
          render: (nodeTypeId: string) => {
            if (nodeTypeId) {
              const nodeType = nodeTypes.find(nt => nt.id === Number(nodeTypeId))
              return nodeType?.name ?? nodeTypeId
            }
          },
        },
        filter: true,
        filterType: 'custom' as const,
        // this is the state of the current filter for this column, mui-datatables will show these values as chips
        filterList: currentFilters?.nodeTypeId?.value ? [currentFilters?.nodeTypeId?.value.toString()] : [],
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            const idAndName = nodeTypes
              ? nodeTypes.map(n => {
                  return { name: n.name, id: n.id }
                })
              : []
            return (
              <TextField
                label="Node Type"
                select={true}
                inputProps={{
                  'data-testid': 'nodes-filter-nodeType',
                }}
                size="small"
                variant="standard"
                value={filterList[index][0] ?? 'All'}
                onChange={event => {
                  filterList[index] = event?.target?.value !== 'All' ? [Number(event?.target?.value)] : []
                  onChange(filterList[index], index, column)
                }}
              >
                <MenuItem key={-1} value="All">
                  All
                </MenuItem>
                {idAndName
                  .sort((a, b) => {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a > b ? 1 : 0
                  })
                  .map(({ name, id }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  })}
              </TextField>
            )
          },
        },
        sort: false,
      },
    },
    { name: 'Folder', options: { filter: false, sort: false } },
    {
      name: 'Active',
      options: {
        customFilterListOptions: {
          update: (val: any) => {
            setCurrentFilters(cf => ({ ...cf, isActive: undefined }))
            return val
          },
        },
        filterType: 'custom' as const,
        // this is the state of the current filter for this column, mui-datatables will show these values as chips
        filterList: currentFilters?.isActive?.value != null ? [currentFilters?.isActive?.value ? 'Active' : 'Inactive'] : [],
        filter: true,
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            const options = ['All', 'Active', 'Inactive']
            return (
              <TextField
                label="Active"
                select={true}
                inputProps={{
                  'data-testid': 'nodes-filter-isActive',
                }}
                size="small"
                variant="standard"
                value={filterList[index][0] ?? 'All'}
                onChange={(event: any) => {
                  filterList[index] = event?.target?.value !== 'All' ? [event?.target?.value] : []
                  onChange(filterList[index], index, column)
                }}
              >
                {options.map((text, i) => {
                  return (
                    <MenuItem key={i} value={text}>
                      {text}
                    </MenuItem>
                  )
                })}
              </TextField>
            )
          },
        },
        sort: true,
      },
    },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  // Fetch the nodetypes for filter list dropdown
  useEffect(() => {
    Api.get('/api/nodetypes').then(data => {
      setNodeTypes(data)
    })
  }, [])

  // Takes the plaintext column name and translates to its carbon api representation for sorting / filtering
  const sanitizeColName = (colName: string) => {
    switch (colName) {
      case 'Name':
        return 'vanity'
      case 'Unique ID':
        return 'uniqueId'
      case 'Node Type':
        return 'nodeTypeName'
      case 'Active':
        return 'isActive'
      case 'Modified':
        return 'updatedAt'
      default:
        return ''
    }
  }

  const NewNodeLink = forwardRef((componentProps: any, ref: any) => <Link to="/nodes/new" ref={ref} {...componentProps} />)

  const fetchPaginatedNodesData = async () => {
    const { searchText, offset, limit, currentSort, sortDir } = pageHook

    const currentFilterArray: Array<FilterType<string, 'like' | 'eq', boolean | string>> = Object.values(currentFilters).filter(f => f !== undefined) as Array<
      FilterType<string, 'like' | 'eq', boolean | string>
    >

    const queryBody: IQueryBody = {
      detail: true,
      first: limit,
      orderBy: currentSort ? sanitizeColName(currentSort) : undefined,
      orderDirection: sortDir !== 'none' ? sortDir : undefined,
      filters:
        currentFilterArray.length > 0
          ? currentFilterArray
          : [
              { fieldName: 'vanity', operator: 'like', value: searchText },
              { fieldName: 'uniqueId', operator: 'like', value: searchText },
            ],
      type: currentFilterArray.length > 0 ? 'and' : 'or',
      after: offset ? btoa(String(offset)) : undefined,
    }

    const res = await Api.post('/api/nodes/query', queryBody)
    const { totalCount, results, error } = res

    if (error) {
      return Promise.reject(res)
    }

    const nodes = results
    const totalEntityCount = Number(totalCount)

    const paginatedData: React.ReactNode[][] = nodes.map((node: INodeType) => {
      const nodeVanity = node.vanity
      const nodeUniqueId = node.uniqueId

      const nodeTypeName = (
        <TableLink
          to={`/node-types/${node.nodeTypeId}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {node?.nodeTypeName}
        </TableLink>
      )

      const folderName = (
        <TableLink
          to={`/folders/${node.folderId}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {node.folderName}
        </TableLink>
      )
      const isActive = node.isActive ? (
        <Icon className={classes.greenColor} color="inherit">
          check
        </Icon>
      ) : (
        <Icon className={classes.redColor}>close</Icon>
      )
      return [nodeVanity, nodeUniqueId, nodeTypeName, folderName, isActive, convertTimestampToDate(node.updatedAt)]
    })

    return Promise.resolve({
      data: paginatedData,
      setEntities: () => setCurrentNodes(nodes),
      setTotalRows: () => pageHook.setTotalRows(Number(totalEntityCount)),
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetchPaginatedNodesData = useCallback(fetchPaginatedNodesData, [
    pageHook.searchText,
    pageHook.pageNumber,
    pageHook.rowsPerPage,
    pageHook.rowsDeletedCounter,
    pageHook.currentSort,
    pageHook.sortDir,
    currentFilters.isActive,
    currentFilters.nodeTypeId,
    currentFilters.uniqueId,
    currentFilters.vanity,
  ])

  const tableState = useTableState(memoizedFetchPaginatedNodesData)

  const onFilterChangeHandler = ([[name], [uid], [nodeTypeId], [_folderName], [isActive], [_updatedAt]]: any[][]) => {
    if (nodeTypes) {
      setCurrentFilters({
        vanity: name ? { fieldName: 'vanity', operator: 'like', value: name } : undefined,
        uniqueId: uid ? { fieldName: 'uniqueId', operator: 'like', value: uid } : undefined,
        nodeTypeId: nodeTypeId ? { fieldName: 'nodeTypeId', operator: 'eq', value: nodeTypeId } : undefined,
        isActive: isActive !== 'All' && isActive != null ? { fieldName: 'isActive', operator: 'eq', value: isActive === 'Active' } : undefined,
      })
    }
  }

  return (
    <Grid className={classes.tableContainer} data-cy="nodesList" container={true} justifyContent="flex-end">
      <Grid container={true} justifyContent="flex-end">
        <AddButton
          userPermissions={userPermissions}
          newComponentLink={NewNodeLink}
          entityName="Nodes"
          buttonName="Add Node"
          dataCy="addBtn"
          dataTestId="addBtn"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <ServerSidePaginatedTable
          title="Nodes"
          entityDeleteEndpoint="/api/nodes"
          idList={currentNodes != null ? currentNodes.map(n => ({ id: n.id })) : []}
          pagination={{ ...pageHook }}
          columns={columns}
          loading={tableState.loading}
          error={tableState.error}
          data={tableState.data}
          refresh={pageHook.refreshTable}
          options={{
            searchOpen: isEmpty(currentFilters) === true,
            confirmFilters: true,
            serverSide: true,
            filter: true,
            filterType: 'dropdown' as const,
            sortOrder: pageHook.sortDir !== 'none' ? { name: pageHook.currentSort, direction: pageHook.sortDir } : undefined,
            onSearchChange: debounce((text: string | null) => {
              // Clear filters to avoid logic confusion in rerender
              setCurrentFilters({})
              pageHook.setSearchText(text ? encodeURI(text.trim()) : '')
            }, 1000),
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => {
              if (currentNodes && currentNodes[cellMeta.dataIndex] && currentNodes[cellMeta.dataIndex].id) {
                pageHook.abort.current = true
                history.push({
                  pathname: `/nodes/${currentNodes[cellMeta.dataIndex].id}`,
                  search: `?pageNumber=0&rowsPerPage=50&tab=0`,
                  state: { currentNodes },
                })
              }
            },
            onFilterConfirm: (filterList: string[][]) => {
              onFilterChangeHandler(filterList)
            },
            customFilterDialogFooter: (filterList, applyFilters) => {
              return (
                <ServerSideFilterFooter<NodesFilterTypes>
                  setSearchText={pageHook.setSearchText}
                  setCurrentFilters={setCurrentFilters}
                  applyFilters={applyFilters}
                />
              )
            },
            customSearchRender: (searchText, handleSearch) => {
              return <CustomSearchBox searchText={searchText} handleSearch={handleSearch} entityPath="nodes" abortToken={pageHook.abort} />
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default NodesList
