import { useContext, useEffect, useState } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import 'codemirror/mode/javascript/javascript.js'

import FormikTextField from '../../components/form-elements/text-field'
import ConfirmationModal from '../../components/modal'
import OfficialDocsLink from '../../components/official-doc-link'
import { AlertContext } from '../../providers'
import { ThemeContext } from '../../providers/theme'
import Api from '../../utils/api'
import { getSourceInitValue, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import useEmailEditStyles from './edit.styles'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/gruvbox-dark.css'

interface IEmailTemplateFormValues {
  fromName: string
  fromEmail: string
  subjectTemplate: string
  templateSource: string
}

const EditEmailTemplate = () => {
  const classes = useEmailEditStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ templateId: string }>()
  const templateId = params.templateId

  const [showConfirmModal, toggleConfirmModal] = useToggle(false)
  const { themeKey } = useContext(ThemeContext)

  const [currentTemplate, setCurrentTemplate] = useState<IEmailTemplateType | null>(null)
  const [currentTemplateSource, setCurrentTemplateSource] = useState<string>('')
  const [updatedTemplateValue, setUpdatedTemplateValue] = useState<string>('')

  useDocumentTitle('Email Templates', templateId)

  useEffect(() => {
    let isSubscribed = true
    try {
      Api.get('/api/emailtemplates/' + templateId).then(template => {
        redirectToListViewOnError(template, history, addAlert, '/email-templates')

        if (isSubscribed) {
          setCurrentTemplate(template)

          Api.get('/api/emailtemplates/' + templateId + '/source').then(templSource => {
            setCurrentTemplateSource(templSource)
          })
        }
      })
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not load the template' })
    }
    return () => {
      isSubscribed = false
    }
  }, [addAlert, templateId, history])

  const saveEmailTemplate = async (formValues: IEmailTemplateFormValues, actions: FormikHelpers<IEmailTemplateFormValues>) => {
    const emailTemplatePayload = {
      fromName: formValues.fromName,
      fromEmail: formValues.fromEmail,
      key: templateId,
      subjectTemplate: formValues.subjectTemplate,
    }

    setUpdatedTemplateValue(formValues.templateSource)

    try {
      const templateData = await Api.put(`/api/emailtemplates/${templateId}`, emailTemplatePayload)
      const sourceData = await Api.putFile(`/api/emailtemplates/${templateId}/source`, formValues.templateSource)

      actions.setSubmitting(false)

      addAlert(
        templateData.error
          ? {
              message: `Submission Errored: ${String(templateData.message)}`,
              alertType: 'error',
            }
          : { message: 'Successfully Submitted', alertType: 'success' }
      )

      if (sourceData.error) {
        addAlert({ alertType: 'error', message: sourceData.message || 'Could not update template message' })
      }
    } catch (error) {
      addAlert({ alertType: 'error', message: String(error) || 'Could not submit the template' })
    }
  }

  const handleCancelForm = () => {
    history.push('/email-templates')
  }

  const resetTemplateToDefault = async () => {
    try {
      const res = await Api.del('/api/emailtemplates/', templateId)
      history.push('/email-templates')
      if (!res.error) {
        addAlert({ alertType: 'success', message: 'Template has been successfully reset' })
      } else {
        addAlert({ alertType: 'error', message: 'Something went wrong, and template was not reset' })
      }
    } catch (error) {
      addAlert({ alertType: 'error', message: String(error) || 'Could not reset the template' })
    }
  }

  const initialValues = {
    fromName: currentTemplate && currentTemplate.fromName ? currentTemplate.fromName : '',
    fromEmail: currentTemplate && currentTemplate.fromEmail ? currentTemplate.fromEmail : '',
    subjectTemplate: currentTemplate && currentTemplate.subjectTemplate ? currentTemplate.subjectTemplate : '',
    templateSource: getSourceInitValue(updatedTemplateValue, currentTemplateSource),
  }

  const schema = Yup.object().shape({
    fromName: Yup.string().required('Name is required'),
    fromEmail: Yup.string()
      .required('Name is required')
      .email('Must be a valid email'),
    subjectTemplate: Yup.string().required('Subject Template is required'),
  })

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveEmailTemplate} validationSchema={schema}>
        {({ values, isValid, isSubmitting }: FormikProps<IEmailTemplateFormValues>) => (
          <Form>
            <Card>
              <CardHeader title="Email Templates" data-cy="formTitle" />

              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4} data-cy="fromName">
                    <FormikTextField
                      initialized={true}
                      name="fromName"
                      label="From Name"
                      required={true}
                      helperText="The from name for the email being sent out"
                      data-testid="fromName"
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4} data-cy="fromEmail">
                    <FormikTextField
                      initialized={true}
                      name="fromEmail"
                      label="From Email"
                      required={true}
                      helperText="The email address we will use when sending the email"
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4} data-cy="subjectTemplate">
                    <FormikTextField
                      initialized={true}
                      name="subjectTemplate"
                      label="Subject Template"
                      required={true}
                      helperText="The template to use for the subject of the email"
                    />
                  </Grid>

                  <Grid item={true} xs={12} data-cy="codeMirror">
                    <CodeMirror
                      value={values.templateSource}
                      options={{
                        mode: 'xml',
                        lineWrapping: true,
                        lineNumbers: true,
                        theme: themeKey === 'dark' ? 'gruvbox-dark' : 'default',
                      }}
                      className={classes.border}
                      onChange={(editor, data, value) => {
                        // we can't use setFieldValue('templateSource', value)
                        // because it makes codemirror constantly loose focus
                        values.templateSource = value
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <OfficialDocsLink url="https://github.com/meshifyiot/public-carbon-docs/blob/master/concepts/emailtemplates.md" />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button color="primary" type="submit" disabled={!isValid || isSubmitting} data-cy="saveBtn" data-testid="saveBtn">
                  Save
                </Button>

                <Button type="button" onClick={toggleConfirmModal} data-cy="resetBtn" color="secondary" data-testid="resetBtn">
                  Reset Template to Defaults
                </Button>

                <Button type="button" onClick={handleCancelForm} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                  Cancel
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        show={showConfirmModal}
        confirmModalAction={resetTemplateToDefault}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to reset the template?"
      />
    </>
  )
}

export default EditEmailTemplate
