import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import * as Sentry from '@sentry/browser'
import classNames from 'classnames'

import errorImage from '../../assets/error-page-img.svg'

import { AlertContext, SidenavContext } from './../../providers'
import { styles } from './main.styles'

interface IErrorBoundaryState {
  hasError: boolean
  error: { message: string }
}

type IErrorBoundaryProps = WithStyles<typeof styles> & {
  children: React.ReactNode
} & IAlertContext &
  RouteComponentProps<any>

class MainWithErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    // Can add error interpreters for common errors here.
    return { hasError: true, error }
  }

  public readonly state = {
    hasError: false,
    error: { message: '' },
  }

  constructor(props: IErrorBoundaryProps) {
    super(props)
    const { history } = this.props
    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        })
      }
    })
  }

  public componentDidCatch(error: Error, info: any) {
    // You can also log the error to an error reporting service
    if (/The path .*? does not exist/.test(error?.message)) {
      // lets not log 404 paths to sentry
      return
    }

    if (error?.message?.toLowerCase()?.includes('failed to fetch') || JSON.stringify(error)?.includes('Failed to fetch')) {
      // lets not log Failed to featch error to sentry
      return
    }

    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope(scope => {
        Object.keys(info).forEach(key => {
          scope.setExtra(key, info[key])
        })
        Sentry.captureException(error)
      })
    }
    this.props.addAlert({ alertType: 'error', message: error.message })
  }

  public render() {
    const { classes } = this.props

    if (this.state.hasError && this.state.error != null) {
      const { error } = this.state

      return (
        <main className={classes.mainContentWrapper}>
          <Grid className={classes.messageGrid} alignContent="center" justifyContent="center" container={true}>
            <Grid className={classes.messageGridItem} data-cy="error-message-container" item={true} xs={8}>
              <img src={errorImage} alt="Error" className={classes.errorImage} />

              <Typography align="center" variant="h5">
                Something went wrong, but we have tracked the error.
              </Typography>

              <div className={classes.divider} />

              <Typography align="center" variant="subtitle1">
                The error provided the following message:
              </Typography>

              <Typography color="error" align="center" variant="subtitle1">
                {error != null && error.message}
              </Typography>

              <div className={classes.divider} />

              <Typography align="center" color="textSecondary">
                For help, contact support@meshify.com
              </Typography>
            </Grid>
          </Grid>
        </main>
      )
    }

    return (
      <SidenavContext.Consumer>
        {(sidenavContext: ISidenavContext) => (
          <main
            className={classNames({
              [classes.contentShift]: !sidenavContext.isSidenavOpen,
              [classes.mainContentWrapper]: true,
            })}
          >
            {this.props.children}
          </main>
        )}
      </SidenavContext.Consumer>
    )
  }
}

/**
 * AppAlerts Consumer HOC
 * Use with Caution, any and every change added alert with cause the entire app wrapped in this hoc to rerender
 */
const withAppAlerts = <P extends IAlertContext>(WrappedComponent: React.ComponentType<P>) => (props: Pick<P, Exclude<keyof P, keyof IAlertContext>>) => {
  return <AlertContext.Consumer>{({ addAlert }) => <WrappedComponent {...(props as P)} addAlert={addAlert} />}</AlertContext.Consumer>
}

export default withRouter(withAppAlerts(withStyles(styles)(MainWithErrorBoundary)))
