import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { FastField, Field, FieldArray, FieldProps, FormikErrors, FormikProps } from 'formik'
import { get } from 'lodash'

import FormikTextField from '../../../components/form-elements/text-field'
import { SUPPORTED_LANGUAGES_MAP } from '../../../constants'
import useStyles from '../create-edit.styles'

export interface PreferencesType {
  key: string
  value: string
}

export interface IPreferencesItems {
  preferences: PreferencesType[]
}

export const fieldError = (fieldErrorProps: Pick<FormikProps<IPreferencesItems>, 'touched' | 'errors'>, index: number, key: keyof PreferencesType) => {
  const { touched, errors } = fieldErrorProps

  if (touched.preferences && touched.preferences[index] && errors.preferences && errors.preferences[index]) {
    /* Check if field item has been touched before we deem it to have an error */
    const touchedItem = touched.preferences[index]
    /* Check if field item has errors associated with it */
    const errorItem = errors.preferences[index] as FormikErrors<PreferencesType>
    /* Check if the key is touched */
    const wasTouched = touchedItem != null && touchedItem[key]
    /* Check if there is an error associated with our particular key */
    const error = errorItem != null && errorItem[key]
    return Boolean(wasTouched && error)
  } else {
    return false
  }
}

const togglePreference = (name: string, value: string[], setFieldValue: any) => {
  if (value.includes(name)) {
    setFieldValue(
      'preferencesNotifications',
      value.filter(v => v !== name)
    )
  } else {
    setFieldValue('preferencesNotifications', [...value, name])
  }
}

const UserPreferencesForm = (props: IPreferencesItems) => {
  const { preferences } = props
  const classes = useStyles()

  return (
    <FieldArray name="preferences">
      {arrayHelpers => (
        <div data-testid="preference-fields-container">
          <div>
            <Grid container={true} spacing={3} justifyContent="center">
              <Grid item={true} container={true} spacing={3} xs={9} className={classes.bottomSpacing}>
                <Grid item={true} xs={12} sm={12} md={6} xl={6} lg={6}>
                  <TextField disabled={true} fullWidth={true} variant="outlined" label="Alert Notifications" />
                </Grid>

                <Grid item={true} container={true} alignItems="center" xs={12} sm={12} md={6} xl={6} lg={6}>
                  <FastField name="preferencesNotifications">
                    {({ field, form }: FieldProps<any>) => {
                      return (
                        <Grid item={true} xs={4}>
                          <ButtonGroup variant="outlined" color="secondary" size="large">
                            <Button
                              data-testid="sms-notification"
                              variant={field.value?.includes('sms') ? 'contained' : 'outlined'}
                              onClick={() => togglePreference('sms', field.value, form.setFieldValue)}
                            >
                              SMS
                            </Button>
                            <Button
                              data-testid="push-notification"
                              variant={field.value?.includes('push') ? 'contained' : 'outlined'}
                              onClick={() => togglePreference('push', field.value, form.setFieldValue)}
                            >
                              Push
                            </Button>
                            <Button
                              data-testid="email-notification"
                              variant={field.value?.includes('email') ? 'contained' : 'outlined'}
                              onClick={() => togglePreference('email', field.value, form.setFieldValue)}
                            >
                              Email
                            </Button>
                            <Button
                              data-testid="phone-notification"
                              variant={field.value?.includes('phone') ? 'contained' : 'outlined'}
                              onClick={() => togglePreference('phone', field.value, form.setFieldValue)}
                            >
                              Phone
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      )
                    }}
                  </FastField>
                </Grid>
              </Grid>

              <Grid item={true} container={true} spacing={4} xs={3} />
            </Grid>

            <Grid container={true} spacing={3} justifyContent="center">
              <Grid item={true} container={true} spacing={3} xs={9} className={classes.bottomSpacing}>
                <Grid item={true} xs={12} sm={12} md={6} xl={6} lg={6}>
                  <TextField disabled={true} fullWidth={true} variant="outlined" label="Language" />
                </Grid>

                <Grid item={true} container={true} alignItems="center" xs={6}>
                  <FormikTextField required={true} select={true} name="language" label="Language" variant="outlined" margin="none">
                    {Object.entries(SUPPORTED_LANGUAGES_MAP).map(([languageCode, properName]) => (
                      <option key={languageCode} value={languageCode}>
                        {properName}
                      </option>
                    ))}
                  </FormikTextField>
                </Grid>
              </Grid>

              <Grid item={true} container={true} spacing={4} xs={3} />
            </Grid>
          </div>

          {preferences != null && preferences && preferences.length > 0 ? (
            preferences.map((item, index: number) => (
              <Grid container={true} spacing={3} justifyContent="center" data-testid="preference-field-container" key={index} className={classes.bottomSpacing}>
                <Grid item={true} container={true} spacing={3} xs={8} sm={8} md={9} lg={9} xl={9}>
                  <Grid item={true} xs={6}>
                    <Field name={`preferences.${index}.key`}>
                      {({ field, form }: FieldProps<any>) => {
                        const { touched, errors } = form
                        const fieldDidError = fieldError({ touched, errors }, index, 'key')

                        return (
                          <div>
                            <TextField
                              {...field}
                              error={fieldDidError}
                              variant="outlined"
                              label={`Key #${index + 1}`}
                              fullWidth={true}
                              data-testid={`preferences.${index}.key`}
                            />

                            {fieldDidError === true && (
                              <FormHelperText data-testid="component-error-text">
                                {get(errors, `preferences.${index}.key`, '4 characters or more')}
                              </FormHelperText>
                            )}
                          </div>
                        )
                      }}
                    </Field>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <Field name={`preferences.${index}.value`}>
                      {({ field, form }: FieldProps<any>) => {
                        const { touched, errors } = form
                        const fieldDidError = fieldError({ touched, errors }, index, 'value')

                        return (
                          <div>
                            <TextField
                              {...field}
                              error={fieldDidError}
                              variant="outlined"
                              label={`Value #${index + 1}`}
                              fullWidth={true}
                              data-testid={`preferences.${index}.value`}
                            />

                            {fieldDidError === true && (
                              <FormHelperText id="component-error-text">{get(errors, `preferences.${index}.value`, '4 characters or more')}</FormHelperText>
                            )}
                          </div>
                        )
                      }}
                    </Field>
                  </Grid>
                </Grid>

                <Grid item={true} container={true} spacing={4} xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Grid item={true} xs={4}>
                    <Fab
                      onClick={() => {
                        arrayHelpers.remove(index)
                      }}
                      size="small"
                      data-testid="preferences-remove-field"
                      aria-label="Remove"
                      data-cy="removePreference"
                    >
                      <RemoveIcon />
                    </Fab>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Fab
                      onClick={() => arrayHelpers.insert(index + 1, { key: '', value: '' })}
                      size="small"
                      data-testid="preferences-add-field"
                      aria-label="Add"
                      data-cy="addPreference"
                    >
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item={true} xs={4}>
                    {/* empty grid item to match themeForm */}
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid container={true} item={true} xs={12} className={classes.addPreferences}>
              <Button
                color="secondary"
                data-cy="addPreferenceBtn"
                data-testid="addPreferenceBtn"
                variant="outlined"
                onClick={() => arrayHelpers.push({ key: '', value: '' })}
              >
                Add User Preferences
              </Button>
            </Grid>
          )}
        </div>
      )}
    </FieldArray>
  )
}

export default UserPreferencesForm
