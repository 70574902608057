import moment from 'moment'

export const allDaysOfWeek = [
  { name: 'Sunday', displayName: 'Sunday', id: 0 },
  { name: 'Monday', displayName: 'Monday', id: 1 },
  { name: 'Tuesday', displayName: 'Tuesday', id: 2 },
  { name: 'Wednesday', displayName: 'Wednesday', id: 3 },
  { name: 'Thursday', displayName: 'Thursday', id: 4 },
  { name: 'Friday', displayName: 'Friday', id: 5 },
  { name: 'Saturday', displayName: 'Saturday', id: 6 },
]

export const unpackSchedule = (scheduleString: string): IScheduleDisplayType[] => {
  let schedules: string[] = []
  // Semicolon is used in string to indicate the start of a new schedule
  if (scheduleString.includes(';')) {
    // multiple schdules
    schedules = scheduleString.split(';')
  } else {
    // One schedule only
    schedules.push(scheduleString)
  }

  return schedules.map((schedule: string) => {
    const [days, timerange] = schedule.split('|')
    const [startTime, endTime] = timerange?.split('-')
    return {
      days: days
        .trim()
        .split('')
        .map((id: string) => allDaysOfWeek[+id]),
      startTime: startTime ? moment(startTime.trim(), 'HH:mm') : null,
      endTime: endTime ? moment(endTime.trim(), 'HH:mm') : null,
    }
  })
}
