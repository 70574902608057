import { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { debounce } from 'lodash'

import { AlertContext } from '../../../providers'
import { getExtraParams, SearchState } from '../../../utils/hooks'
import { constructFrontEndTableParams, deleteEntities, getParams } from '../../../utils/table/table-helpers'
import ConfirmDeleteModal from '../../modal'
import { activeColumnIndex, customSearch, InternalProps, modifiedIndex, TableCopy, tableCustomSort } from '../internal-utils'
import StatefulTable from '../stateful-table'
import { CommonPaginatedTableProps } from '..'

import ClientSideFooter from './clientside-footer'

// TODO once search results are saved in all FE paginated tables, make preserveSearchResults required, not optional
type TableProps = CommonPaginatedTableProps & { preserveSearchResults?: SearchState }

export const ClientSidePaginatedTable = (props: TableProps) => {
  const { columns, data, defaultRowsPerPage, entityDeleteEndpoint, error, idList, loading, options, refresh, title, preserveSearchResults } = props

  const { CONFIRM_DELETE, DELETING_N_ROWS, NO_ROWS_TO_DELETE } = TableCopy
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [entitiesToDelete, setEntitiesToDelete] = useState<number[] | null>(null)
  const { addAlert } = useContext(AlertContext)
  const location = useLocation()
  const history = useHistory()
  const params = getParams(location)
  // TODO tableID stuff here is necessary
  const pageNumber = params.pageNumber ? Number(params.pageNumber) : 0
  const rowsPerPage = params.rowsPerPage ? Number(params.rowsPerPage) : 10

  const toggleConfirmModal = useCallback(
    (entityIds: number[] | null, shouldRefresh: boolean) => {
      setConfirmDeleteModal(!showConfirmDeleteModal)
      setEntitiesToDelete(entityIds)
      if (shouldRefresh && refresh) {
        refresh()
      }
    },
    [refresh, setConfirmDeleteModal, showConfirmDeleteModal]
  )

  const tableProps: InternalProps = useMemo(() => {
    return {
      title,
      columns,
      options: {
        ...options,
        customSearch: options?.customSearch ?? customSearch,
        pagination: true,
        viewColumns: false,
        searchOpen: options?.searchOpen ?? true,
        download: data && data?.length > 0 && options?.download ? options.download : false,
        onRowsDelete: (rowsDeleted: any) => {
          /* Collect ids to be deleted and supply them to the confirmation modal */
          const ids = rowsDeleted.data.map((dataItem: { index: number; dataIndex: number }) => {
            return idList && idList[dataItem.dataIndex].id
          })
          toggleConfirmModal(ids, false)
          return false
        },
        filter: options?.filter ?? false,
        filterType: options?.filterType ?? 'textField',
        print: false,
        rowsPerPage: defaultRowsPerPage ?? rowsPerPage ?? 10,
        rowsPerPageOptions: [10, 20, 50],
        page: pageNumber ?? 0,
        onSearchChange: debounce((text: string | null) => {
          if (preserveSearchResults?.setSearchText && text) {
            preserveSearchResults?.setSearchText(encodeURI(text))
          } else if (preserveSearchResults?.setSearchText && text === null) {
            preserveSearchResults?.setSearchText(encodeURI(''))
          }
        }, 1000),
        searchText: preserveSearchResults?.searchText ? preserveSearchResults.searchText : '',
        onSearchClose: () => preserveSearchResults?.setSearchText(encodeURIComponent('')),
        customFooter: options?.customFooter
          ? options?.customFooter
          : (
              totalRowCount: number,
              currentPageNumber: number,
              currentRowsPerPage: number,
              changeRowsPerPage: (n: React.ReactText) => void,
              changePage: (n: number) => void
            ) => {
              return (
                <ClientSideFooter
                  count={totalRowCount}
                  page={pageNumber}
                  rowsPerPage={currentRowsPerPage ?? 10}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  currentPageNumber={currentPageNumber}
                  currentRowsPerPage={currentRowsPerPage}
                  onChangePage={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, pageNum: number) => {
                    changePage(pageNum)
                    const extraParams = getExtraParams(location)
                    const tableParams = constructFrontEndTableParams({ ...params, pageNumber: pageNum ?? 0 })
                    history.push({
                      pathname: location.pathname,
                      search: extraParams ? `${tableParams}&${extraParams}` : tableParams,
                    })
                  }}
                />
              )
            },
        customSort:
          options?.customSort ?? modifiedIndex(columns) > -1
            ? (tableData: any[], index: number, order: string) => tableCustomSort(tableData, index, order, activeColumnIndex(columns), modifiedIndex(columns))
            : undefined,
        setTableProps: () => {
          return {
            size: 'small',
          }
        },
      },
      data: data ?? [[]],
      error: error ? new Error(error.message) : null,
      loading: loading ?? false,
    }
  }, [
    columns,
    idList,
    toggleConfirmModal,
    pageNumber,
    rowsPerPage,
    loading,
    defaultRowsPerPage,
    title,
    data,
    error,
    options,
    preserveSearchResults,
    history,
    location,
    params,
  ])

  return (
    <>
      <StatefulTable {...tableProps} />
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        confirmModalAction={() => deleteEntities(addAlert, entitiesToDelete, entityDeleteEndpoint, toggleConfirmModal)}
        closeModal={() => toggleConfirmModal(null, false)}
        dialogContent={entitiesToDelete && entitiesToDelete.length > 0 ? DELETING_N_ROWS(entitiesToDelete.length) : NO_ROWS_TO_DELETE}
        dialogTitle={CONFIRM_DELETE}
      />
    </>
  )
}

export default ClientSidePaginatedTable
