import { forwardRef, useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router'
import { Link, LinkProps, useHistory } from 'react-router-dom'
import { Grid, MenuItem, TextField } from '@mui/material'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useTableState } from '../../utils/hooks'
import { filterPlainTextColumn, handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'
import useClaimsStyles from './claims.styles'

const ClaimsListView = () => {
  // TODO: implement useSearchData() hook and abort
  const classes = useTableStyles()
  const claimsClasses = useClaimsStyles()
  const history = useHistory()
  const { networkType } = useParams<{ networkType: ClaimNetworkType }>()
  const { addAlert } = useContext(AlertContext)

  const [claims, setClaims] = useState<IClaimType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  useDocumentTitle('Claims')

  const columns = [
    { name: 'ID', options: { filter: false, sort: true } },
    {
      name: 'Function Name',
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          logic: (location: string, filters: string[]) => filterPlainTextColumn(location, filters),
        },
      },
    },
    {
      name: 'Function Version',
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          logic: (location: string, filters: string[]) => filterPlainTextColumn(location, filters),
        },
      },
    },
    { name: 'Network Type', options: { filter: false, sort: true } },
    { name: 'Unique ID', options: { filter: false, sort: true } },
    { name: 'Modified', options: { filter: false, sort: true } },
  ]

  const fetchClaimsData = async () => {
    const retreivedClaims = await Api.get(`/api/claims/${networkType}`)

    if (retreivedClaims.error) {
      addAlert({ alertType: 'error', message: retreivedClaims?.message || 'Could not retrieve claims' })
      return Promise.reject(retreivedClaims)
    } else {
      setClaims(retreivedClaims)
    }

    const claimsList = retreivedClaims.length ? retreivedClaims : []

    const allRowData: Array<Array<number | string | React.ReactNode>> = claimsList.map((claim: IClaimType) => {
      return [claim.id, claim.functionName, claim.functionVersion, claim.networkType, claim.uniqueId, convertTimestampToDate(claim.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setClaims(claimsList) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchClaimsData, [networkType, refreshCounter])

  const state = useTableState(memoizedFetch)

  const NewClaimLink = forwardRef((linkProps: LinkProps, ref: any) => {
    const { ...rest } = linkProps
    return <Link {...rest} to={`/claims/${networkType}/new`} ref={ref} />
  })

  return (
    <Grid justifyContent="flex-end" container={true} data-cy="addClaimContainer">
      <Grid item={true} data-cy="toggleNetworkType" className={claimsClasses.networkTypeWrapper}>
        <TextField
          label="Network Type"
          select={true}
          fullWidth={true}
          value={networkType}
          data-testid="claimsSelect"
          name="claimsSelect"
          size="small"
          InputProps={{
            className: claimsClasses.networkType,
          }}
          onChange={e => {
            history.push(`/claims/${e.target.value}`)
          }}
        >
          <MenuItem value="lora-tracknet">lora-tracknet</MenuItem>
          <MenuItem value="lora-chirpstack">lora-chirpstack</MenuItem>
          <MenuItem value="nbiot-sercomm">nbiot-sercomm</MenuItem>
          <MenuItem value="sidewalk">sidewalk</MenuItem>
        </TextField>
      </Grid>

      <Grid item={true} data-cy="newClaim">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewClaimLink}
              entityName="claim"
              buttonName={`Add Claim for ${networkType}`}
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12} data-cy="claimsList" className={classes.tableContainer}>
        <ClientSidePaginatedTable
          title="Claims"
          idList={
            claims !== null
              ? claims.map(claim => ({
                  id: claim.id,
                }))
              : []
          }
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          refresh={() => setRefreshCounter(c => c + 1)}
          options={{
            filter: true,
            filterType: 'dropdown',
            selectableRows: 'none',
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => {
              handleCellClick(history, claims, `claims/${networkType}`, cellMeta)
            },
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath={`claims/${networkType}`}
                  isValidId={(id: number) => !!claims?.find(claim => claim.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ClaimsListView
