import { useContext, useState } from 'react'
import QRCode from 'react-qr-code'
import { ArrowDropDown, Close } from '@mui/icons-material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Menu, MenuItem, Tooltip, useTheme } from '@mui/material'

import { INSIGHTS_DEV_URL, INSIGHTS_PROD_URL, INSIGHTS_STAGING_URL, PROTECT_DEV_URL, PROTECT_PROD_URL, PROTECT_STAGING_URL } from '../../../constants'
import { AlertContext, ImpersonateContext, UserPermissionsContext } from '../../../providers'
import { useClipboardAPIExists } from '../../../utils/hooks'
import { hasImpersonatePermission } from '../list'

import useStyles from './index.styles'

interface IProps {
  isEditMode: boolean
  currentUser: IUserType | null
  userId: string
  userEmail: string
}

const UserHeaderAction = (props: IProps) => {
  const { isEditMode, currentUser, userId, userEmail } = props

  const classes = useStyles()
  const theme = useTheme()
  const [clipboardAPIExists, clipboardUnsupportedMessage] = useClipboardAPIExists()

  const [menuAnchor, setMenuAnchor] = useState<any>(null)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)
  const impersonatedUserContext = useContext<IImpersonateContext | null>(ImpersonateContext)
  const { addAlert } = useContext(AlertContext)

  const generateProtectUrl = () => {
    const queryParam = `?userToImpersonate=${userId}`
    const { hostname } = window.location
    const domainName = window.location.hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1)

    let url = ''

    switch (domainName) {
      case 'localhost':
      case 'meshbot.co':
        url = PROTECT_DEV_URL + queryParam
        break
      case 'meshify.co':
        url = PROTECT_STAGING_URL + queryParam
        break
      case 'meshify.com':
        url = PROTECT_PROD_URL + queryParam
        break
      default:
        url = PROTECT_DEV_URL + queryParam
    }

    return url
  }

  const generateInsightsUrl = () => {
    const queryParam = `?userToImpersonate=${userId}`
    const { hostname } = window.location
    const domainName = window.location.hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1)

    let url = ''

    switch (domainName) {
      case 'localhost':
      case 'meshbot.co':
        url = INSIGHTS_DEV_URL + queryParam
        break
      case 'meshify.co':
        url = INSIGHTS_STAGING_URL + queryParam
        break
      case 'meshify.com':
        url = INSIGHTS_PROD_URL + queryParam
        break
      default:
        url = INSIGHTS_DEV_URL + queryParam
    }

    return url
  }

  const generateProtectMobileUrl = () => {
    // branch io link that will work across environments
    return 'https://meshifyprotect.app.link/impersonate'
  }

  const copyToClipboard = () => {
    const linkToCopy = `${generateProtectMobileUrl()}?ref=${userId}`

    const copy = (): void => {
      if (clipboardAPIExists) {
        navigator?.clipboard?.writeText(linkToCopy).then(
          () => {
            addAlert({ alertType: 'success', message: `Copied impersonate link to clipboard` })
          },
          err => {
            addAlert({ alertType: 'error', message: `Could not copy to clipboard. ${err}` })
          }
        )
      } else {
        addAlert({ alertType: 'error', message: clipboardUnsupportedMessage as string })
      }
    }

    copy()
  }

  const atLeastOneImpersonate = generateInsightsUrl() || generateProtectUrl() || generateProtectMobileUrl()

  return isEditMode && hasImpersonatePermission(userPermissions) && currentUser?.id !== Number(userId) ? (
    <>
      {atLeastOneImpersonate && (
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={e => setMenuAnchor(e.currentTarget)}
          data-testid="impersonateDropDown"
          endIcon={<ArrowDropDown />}
        >
          Impersonate
        </Button>
      )}
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        {currentUser?.id !== Number(userId) && !currentUser?.impersonatedBy && (
          <MenuItem
            data-testid="impersonate-in-admin"
            onClick={() => {
              setMenuAnchor(null)
              impersonatedUserContext?.setImpersonatedUserId(Number(userId))
            }}
          >
            In Admin
          </MenuItem>
        )}

        {generateInsightsUrl() && (
          <MenuItem component="a" href={generateInsightsUrl()} target="_blank" data-testid="impersonate-in-insights" onClick={() => setMenuAnchor(null)}>
            In Insights
          </MenuItem>
        )}

        {generateProtectUrl() && (
          <MenuItem component="a" href={generateProtectUrl()} target="_blank" data-testid="impersonate-in-protect" onClick={() => setMenuAnchor(null)}>
            In Protect Lite
          </MenuItem>
        )}

        {generateProtectMobileUrl() && (
          <MenuItem
            onClick={() => {
              setShowDialog(true)
              setMenuAnchor(null)
            }}
            data-testid="impersonate-in-protect-mobile"
          >
            In Protect Mobile
          </MenuItem>
        )}
      </Menu>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          Impersonate in Protect Mobile
          <IconButton className={classes.closeButton} onClick={() => setShowDialog(false)}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers={true}>
          <Grid container={true} spacing={3}>
            <Grid container={true} item={true} xs={12} justifyContent="center">
              Scan QR code with your mobile device's camera
            </Grid>

            <Grid container={true} item={true} xs={12} justifyContent="center">
              <Box className={classes.qrContainer}>
                <QRCode value={`${generateProtectMobileUrl()}?ref=${userId}`} size={192} level="M" bgColor={theme.palette.primary.light} />
              </Box>
            </Grid>

            <Grid container={true} item={true} xs={12} justifyContent="center">
              {userEmail}
            </Grid>

            <Grid container={true} item={true} xs={12} justifyContent="center" className={classes.protectMobileLink}>
              <Link href={`${generateProtectMobileUrl()}?ref=${userId}`} target="_blank">
                Or click the link here
              </Link>

              <Tooltip title="Copy impersonate link to clipboard" arrow={true} placement="top">
                <Button onClick={copyToClipboard}>
                  <AssignmentIcon color="secondary" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  ) : null
}

export default UserHeaderAction
