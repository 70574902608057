import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Field, FieldProps } from 'formik'
import { isEqual } from 'lodash'

import useMultiselectStyles from './multiselect.styles'

type MultiSelectProps = {
  listItems: object[]
  required: boolean
  name: string
  label: string
  // In case used multiple times in same form
  idx?: number | string
  helperText?: string
}

const MultiSelect: React.FC<MultiSelectProps> = ({ listItems, required, name, label, idx, helperText }) => {
  const classes = useMultiselectStyles()

  const handleChange = (event: any, setFieldValue: any) => {
    event.preventDefault()
    const addedValues = event.target.value
    const lastElement = addedValues[addedValues.length - 1]
    const valuesWithoutDuplicates = addedValues.filter((item: any) => !isEqual(item, lastElement))
    if (valuesWithoutDuplicates.length === addedValues.length - 2) {
      setFieldValue(name, valuesWithoutDuplicates)
    } else {
      setFieldValue(name, addedValues)
    }
  }

  const handleBlur = (event: any, setFieldTouched: any) => {
    setFieldTouched(name)
  }

  const indexLabel = idx ? `-${idx}` : ''

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        helperText = helperText != null ? helperText : ''
        const { value } = field
        const { touched, errors } = form

        return (
          <FormControl fullWidth={true} required={required} error={Boolean(form.touched[name] && form.errors[name])}>
            <InputLabel id={`${name}-multiselect-label${indexLabel}`} htmlFor={`${name}-selectMultipleChip`}>
              {label}
            </InputLabel>

            <Select
              onChange={evt => handleChange(evt, form.setFieldValue)}
              onBlur={evt => handleBlur(evt, form.setFieldTouched)}
              multiple={true}
              inputProps={{
                'data-cy': `${name}Select`,
                'data-testid': `${name}-multiselect-wrapper${indexLabel}`,
                'aria-label': label,
                'aria-labelledby': `${name}-multiselect-label${indexLabel}`,
              }}
              SelectDisplayProps={
                {
                  'data-testid': `${name}-multiselect-clickable-div${indexLabel}`,
                } as any
              }
              input={<Input data-cy={`multiSelect-${name}`} id={`${name}-selectMultipleChip`} />}
              value={field.value ? field.value : []}
              renderValue={(selected: any) => (
                <div className={classes.chips}>
                  {selected.length &&
                    selected.map((val: any, index: number) => {
                      return <Chip key={index} label={val.name} className={classes.chip} data-cy="dayChip" variant="outlined" color="default" />
                    })}
                </div>
              )}
            >
              {listItems.map((elementToPick: any, index: number) => (
                <MenuItem
                  key={index}
                  value={elementToPick}
                  data-cy="selectionItem"
                  data-cy-checked={value && value.some((element: any) => element.id === elementToPick.id)}
                >
                  <Checkbox color="secondary" checked={value && value.some((element: any) => element.id === elementToPick.id)} />
                  <ListItemText primary={elementToPick.name} />
                </MenuItem>
              ))}
            </Select>

            <FormHelperText>{touched[name] && errors[name] ? errors[name] : helperText}</FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default MultiSelect
