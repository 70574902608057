import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FormikCheckbox from '../../components/form-elements/checkbox'
import FormikTextField from '../../components/form-elements/text-field'
import Api from '../../utils/api'
import { handleFormSubmit } from '../../utils/form/form-helpers'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle } from '../../utils/hooks'

import { AlertContext } from './../../providers'

interface ITenantFormValues {
  name: string
  enableReactions: boolean
  reactionFromEmail: string
  reactionFromName: string
}

const TenantForm = () => {
  const [tenant, setTenant] = useState<ITenantType | null>(null)

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  useDocumentTitle('Tenant')

  useEffect(() => {
    let isSubscribed = true
    Api.get('/api/tenant').then(
      (retreivedTenant: ITenantType) => {
        if (isSubscribed) {
          setTenant(retreivedTenant)
        }
      },
      err => {
        addAlert({ alertType: 'error', message: err })
      }
    )
    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  const saveTenant = async (values: ITenantFormValues, actions: FormikHelpers<ITenantFormValues>) => {
    const tenantPayload: ITenantFormValues = values

    try {
      handleFormSubmit(await Api.put('/api/tenant', tenantPayload), addAlert, history, null, values, actions)
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not save tenant' })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    reactionFromEmail: Yup.string().email('Not a valid email'),
  })

  const initialValues: ITenantFormValues = tenant
    ? {
        name: tenant.name,
        enableReactions: tenant.enableReactions,
        reactionFromEmail: tenant.reactionFromEmail,
        reactionFromName: tenant.reactionFromName,
      }
    : {
        name: '',
        enableReactions: true,
        reactionFromEmail: '',
        reactionFromName: '',
      }

  return (
    <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveTenant} validationSchema={schema}>
      {({ isValid, isSubmitting, dirty }) => (
        <Form>
          <Card>
            <CardHeader
              title="Tenant"
              action={tenant?.updatedAt ? <Typography variant="body2">Last Updated: {convertTimestampToDate(tenant?.updatedAt)}</Typography> : null}
              data-cy="formTitle"
            />

            <CardContent>
              <Grid container={true} spacing={3} data-cy="tenantName">
                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField name="name" label="Name" required={true} id="name" />
                </Grid>

                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Alert alertType="warning" message="Update tenant-wide settings. Only root users are allowed to do this." />
                </Grid>

                <Grid item={true} xs={12}>
                  <FormikCheckbox name="enableReactions" label="Enable Reactions" />
                </Grid>

                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField initialized={Boolean(tenant)} name="reactionFromEmail" label="Default From Email" required={false} />
                </Grid>

                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField name="reactionFromName" label="Default From Name" required={false} />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-testid="saveBtn" data-cy="saveBtn">
                Save
              </Button>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  )
}

export default TenantForm
