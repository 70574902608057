import { useCallback, useContext, useState } from 'react'
import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

const CoresListView = () => {
  const [cores, setCores] = useState<ICoreType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const searchResultsHook = useSearchData()
  const classes = useTableStyles()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  useDocumentTitle('Cores')

  const columns = [
    { name: 'Name', options: { filter: true, sort: true } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchCoresData = async () => {
    const retreivedCores = await Api.get('/api/cores')

    if (retreivedCores.error) {
      addAlert({ alertType: 'error', message: retreivedCores?.message || 'Could no retrieve cores' })
      return Promise.reject(retreivedCores)
    }

    const allRowData: React.ReactNode[][] = retreivedCores.map((core: ICoreType) => {
      return [core.name, convertTimestampToDate(core.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setCores(retreivedCores) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchCoresData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  const NewCoreLink = React.forwardRef((linkProps: any, ref: any) => <Link to="/cores/new" ref={ref} {...linkProps} />)

  return (
    <Grid className={classes.tableContainer} data-testid="rolesGrid" data-cy="coresList" container={true} justifyContent="flex-end">
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewCoreLink}
              entityName="Cores"
              buttonName="Add Core"
              dataCy="addBtn"
              dataTestId="addCoreBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Cores"
          idList={cores != null ? cores.map(core => ({ id: core.id })) : []}
          entityDeleteEndpoint="/api/cores"
          refresh={() => setRefreshCounter(refreshCounter + 1)}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          preserveSearchResults={{ ...searchResultsHook }}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, cores, 'cores', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="cores"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!cores?.find(core => core.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default CoresListView
