import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react'
import fetchIntercept from 'fetch-intercept'

import { AUTH_TOKEN } from '../constants'
import api from '../utils/api'

import { AlertContext } from '.'

export const OwnUserContext = createContext<IUserType | null>(null)

export const ImpersonateContext = createContext<IImpersonateContext | null>(null)

export const UserPermissionsContext = createContext<IPermissionType[]>([])

export const AuthenticationContext = createContext<{
  setIsAuthenticated: (bool: boolean) => void
  isAuthenticated: boolean
}>({ setIsAuthenticated: () => null, isAuthenticated: false })

export const IdentityProviders = (props: { children: React.ReactNode[] | React.ReactNode }) => {
  const [loggedInUser, setLoggedInUser] = useState<IUserType | null>(null)
  const [impersonatedUserId, setImpersonatedUserId] = useState<number | null>(null)
  const [forceUserRefresh, setForceUserRefresh] = useState<boolean>(false)
  const [userPermissions, setUserPermissions] = useState<IPermissionType[]>([])

  const { addAlert } = useContext(AlertContext)

  // Token is intended only to persist login state on domain close and reopen. Not stateful like isAuthenticated boolean.
  const [isAuthenticated, setAuthenticated] = useState<boolean>(localStorage?.getItem(AUTH_TOKEN) === 'meshify-authenticated')

  const setIsAuthenticated = (bool: boolean) => {
    if (bool) {
      localStorage.setItem(AUTH_TOKEN, 'meshify-authenticated')
    } else {
      localStorage.removeItem(AUTH_TOKEN)
    }
    setAuthenticated(bool)
  }

  fetchIntercept.register({
    response(response: Response) {
      if (response.status === 401) {
        setIsAuthenticated(false)
      }
      return response
    },
  })

  useEffect(() => {
    let isSubscribed = true
    if (loggedInUser) {
      // get permissions that a logged in user has
      api.get('/api/permissions?onlyGranted=true').then(data => {
        if (data && !data.error && isSubscribed) {
          setUserPermissions(data)
        } else {
          setUserPermissions([])
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [loggedInUser, impersonatedUserId])

  useEffect(() => {
    let isSubscribed = true
    // Check for authentication to prevent wasted calls
    if (isAuthenticated) {
      const timerId = setInterval(() => {
        api.get('/api/users/me', {}).then((data: IUserType | null) => {
          if (isSubscribed) {
            // Response objects are easier to deal with
            setIsAuthenticated(Boolean(data && !data.error))
          }
        })
      }, 10000)
      return () => {
        isSubscribed = false
        clearInterval(timerId)
      }
    }
  }, [isAuthenticated])

  // Check if user is authenticated before showing login page, separate useEffect because it should only be triggered once.
  useLayoutEffect(() => {
    let isSubscribed = true
    api
      .get('/api/users/me')
      .then((ownData: IUserType) => {
        if (isSubscribed) {
          setIsAuthenticated(Boolean(ownData && !ownData.error))
        }
      })
      .catch(e => {
        console.warn('User is likely not authenticated', e)
      })
    return () => {
      isSubscribed = false
    }
  }, [])

  useLayoutEffect(() => {
    let isSubscribed = true
    if ((isAuthenticated && impersonatedUserId == null) || forceUserRefresh) {
      api.get('/api/users/me').then((ownData: IUserType) => {
        if (isSubscribed) {
          setLoggedInUser(ownData)
          if (ownData.impersonatedBy && ownData.impersonatedBy.id !== impersonatedUserId) {
            setImpersonatedUserId(ownData.impersonatedBy.id)
          }
          setForceUserRefresh(false)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isAuthenticated, impersonatedUserId, forceUserRefresh])

  useEffect(() => {
    if (impersonatedUserId) {
      api.get(`/api/users/${impersonatedUserId}/impersonate`).then(data => {
        if (!data.error) {
          setLoggedInUser(data)
          addAlert({ alertType: 'success', message: 'You are now successfully impersonating the selected user' })
        } else {
          addAlert({ alertType: 'error', message: data?.message ?? 'Could not impersonate this user' })
        }
      })
    }
  }, [impersonatedUserId, addAlert])

  return (
    <AuthenticationContext.Provider
      value={{
        setIsAuthenticated,
        isAuthenticated,
      }}
    >
      <OwnUserContext.Provider value={loggedInUser}>
        <UserPermissionsContext.Provider value={userPermissions}>
          <ImpersonateContext.Provider
            value={{
              impersonatedUserId,
              setImpersonatedUserId,
              setForceUserRefresh,
            }}
          >
            {props.children}
          </ImpersonateContext.Provider>
        </UserPermissionsContext.Provider>
      </OwnUserContext.Provider>
    </AuthenticationContext.Provider>
  )
}
