import { Dispatch, SetStateAction, useContext } from 'react'
import JSONPretty from 'react-json-pretty'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { MUIDataTableColumn } from 'mui-datatables'

import { StatefulTable, StatefulTableProps } from '../../../components/table'
import { AlertContext } from '../../../providers'
import { useClipboardAPIExists } from '../../../utils/hooks'

import { useStyles } from './table.styles'

export const OBJECT_CHANNELS = ['raw', 'temp_report_config']

type SortInfo = { activeColumn: number; sortDir: 'none' | 'asc' | 'desc' }

type IProps = StatefulTableProps & {
  data: string[][] | null
  columns: MUIDataTableColumn[]
  selectedChannel: string | null
  setSelectedChannel?: Dispatch<SetStateAction<string | null>>
  setSortInfo: Dispatch<SetStateAction<SortInfo>>
  // Index where the meaningful data exists in a row for knowledge of what to access
  channelDataIndex: number
}

export const Table = ({ columns, loading, data, options, error, title, channelDataIndex, selectedChannel, setSelectedChannel, setSortInfo }: IProps) => {
  const { addAlert } = useContext(AlertContext)
  const [clipboardAPIExists, clipboardUnsupportedMessage] = useClipboardAPIExists()
  const classes = useStyles()

  const copyDataToClipboard = (channelData: string): void => {
    if (clipboardAPIExists) {
      navigator.clipboard.writeText(channelData).then(
        () => {
          addAlert({ alertType: 'success', message: `Copied raw channel to clipboard` })
        },
        err => {
          addAlert({ alertType: 'error', message: `Could not copy to clipboard. ${err}` })
        }
      )
    } else {
      addAlert({ alertType: 'error', message: clipboardUnsupportedMessage as string })
    }
  }

  return (
    <StatefulTable
      title={title}
      columns={columns}
      loading={loading}
      data={data ?? []}
      error={error}
      options={{
        ...options,
        selectableRows: 'none' as const,
        rowsPerPage: data ? data.length : 10,
        pagination: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        customRowRender: (rowData, index) => {
          return (
            <TableRow
              className={classes.tableRow}
              key={index}
              onClick={() => {
                if (setSelectedChannel) {
                  setSelectedChannel(rowData[0])
                }
              }}
              hover={setSelectedChannel != null}
              selected={selectedChannel === rowData[0]}
            >
              {rowData.map((datum, idx) => {
                if (idx === channelDataIndex && OBJECT_CHANNELS.indexOf(rowData[0]) > -1) {
                  return (
                    <TableCell key={idx}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                          <Grid container={true} alignItems="center" justifyContent="space-between">
                            <Typography className={classes.heading}>Raw JSON Data</Typography>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={evt => {
                                evt.stopPropagation()
                                copyDataToClipboard(datum)
                              }}
                            >
                              Copy
                            </Button>
                          </Grid>
                        </AccordionSummary>

                        <AccordionDetails>
                          <JSONPretty json={datum} />
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  )
                }
                return <TableCell key={idx}>{datum}</TableCell>
              })}
            </TableRow>
          )
        },
        textLabels: {
          body: {
            noMatch: 'No off schema channels',
          },
        },
        onTableChange: (action, props) => {
          if (action === 'sort') {
            if (props?.announceText?.match('descending')) {
              return setSortInfo({ activeColumn: Number(props.activeColumn), sortDir: 'desc' as const })
            }
            if (props?.announceText?.match('ascending')) {
              return setSortInfo({ activeColumn: Number(props.activeColumn), sortDir: 'asc' as const })
            }
            return setSortInfo({ activeColumn: Number(props.activeColumn), sortDir: 'none' as const })
          }
        },
      }}
    />
  )
}
