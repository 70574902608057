import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import { FieldArray, getIn } from 'formik'

import FormikTextField from '../../../components/form-elements/text-field'

import useChannelFormStyles from './channel-form.styles'

const typeOptions: Array<{ value: string; label: string }> = [
  {
    value: 'string',
    label: 'string',
  },
  {
    value: 'boolean',
    label: 'boolean',
  },
  {
    value: 'number',
    label: 'number',
  },
  {
    value: 'alias:string',
    label: 'alias:string',
  },
  {
    value: 'alias:boolean',
    label: 'alias:boolean',
  },
  {
    value: 'alias:number',
    label: 'alias:number',
  },
]

const machineLearningOptions: Array<{ value: string; label: string }> = [
  {
    value: 'ignored',
    label: 'Ignore',
  },
  {
    value: 'attribute',
    label: 'Object Attribute',
  },
  {
    value: 'tracked',
    label: 'Time Series',
  },
]

const channelObject = () => {
  return {
    name: '',
    vanityName: '',
    default: '',
    type: 'string', // defalt selected value in type dropdown
    machineLearningConfig: 'ignored',
  }
}

interface IChannelFormValue extends IChannelType {
  name: string
}

// should return 'number' for 'number' and 'alias:number'
// otherwise returns 'text' type
export const getInputType = (channelsValues: IChannelFormValue[], index: number): string => {
  return channelsValues[index].type === 'number' || channelsValues[index].type === 'alias:number' ? 'number' : 'text'
}

const ChannelForm = () => {
  const classes = useChannelFormStyles()
  return (
    <FieldArray name="channels" validateOnChange={false}>
      {({ remove, insert, form }) => {
        const { setFieldValue, values } = form
        const isBool = (idx: number) => ['boolean', 'alias:boolean'].indexOf(getIn(values, `channels.${idx}.type`)) > -1

        return (
          <div className={classes.channelsContainer} data-testid="channel-fields-container">
            {values.channels?.length > 0 ? (
              values.channels.map((item: any, index: number) => (
                <Grid container={true} spacing={5} key={index}>
                  <Grid item={true} xs={2} data-cy={`channelName${index + 1}`}>
                    <FormikTextField name={`channels.${index}.name`} variant="outlined" required={true} label={`Name #${index + 1}`} formDependent={false} />
                  </Grid>

                  <Grid item={true} xs={2} data-cy={`channelVanityName${index + 1}`}>
                    <FormikTextField name={`channels.${index}.vanityName`} variant="outlined" label="Vanity Name" formDependent={false} />
                  </Grid>

                  <Grid item={true} xs={2} data-cy={`channelType${index + 1}`}>
                    <FormikTextField name={`channels.${index}.type`} variant="outlined" label="Type" required={true} select={true} formDependent={false}>
                      {typeOptions.map(option => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        )
                      })}
                    </FormikTextField>
                  </Grid>

                  <Grid item={true} xs={2} data-cy={`channelDefaultValue${index + 1}`}>
                    {isBool(index) ? (
                      <FormikTextField
                        name={`channels.${index}.default`}
                        variant="outlined"
                        fullWidth={true}
                        label="Default Value"
                        select={true}
                        formDependent={false}
                      >
                        {[
                          { value: 'true', label: 'True' },
                          { value: 'false', label: 'False' },
                        ].map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </FormikTextField>
                    ) : (
                      <FormikTextField
                        name={`channels.${index}.default`}
                        variant="outlined"
                        label="Default Value"
                        formDependent={false}
                        type={getInputType(values.channels, index)}
                        helperText={getInputType(values.channels, index) === 'number' ? 'Must be a number' : ''}
                      />
                    )}
                  </Grid>

                  <Grid item={true} xs={2} data-cy={`channelML${index + 1}`}>
                    <FormikTextField
                      variant="outlined"
                      name={`channels.${index}.machineLearningConfig`}
                      label="Machine Learning"
                      required={true}
                      select={true}
                      formDependent={false}
                    >
                      {machineLearningOptions.map(option => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        )
                      })}
                    </FormikTextField>
                  </Grid>

                  <Grid item={true} xs={2} container={true} alignItems="center" data-cy={`addRemoveChannelBtns${index + 1}`}>
                    <Fab
                      onClick={() => {
                        remove(index)
                      }}
                      size="small"
                      className={classes.channelFormIcon}
                      data-testid={`channel-remove-field-${index}`}
                      aria-label="Remove"
                    >
                      <RemoveIcon />
                    </Fab>

                    <Fab
                      onClick={() => insert(index + 1, channelObject())} // insert an empty object at a position
                      size="small"
                      className={classes.channelFormIcon}
                      data-testid={`channel-add-field-${index}`}
                      aria-label="Add"
                    >
                      <AddIcon />
                    </Fab>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Button color="secondary" variant="outlined" onClick={() => setFieldValue('channels', [channelObject()])} data-cy="addChannelBtn">
                Add Channel
              </Button>
            )}
          </div>
        )
      }}
    </FieldArray>
  )
}

export default ChannelForm
