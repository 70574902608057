import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    dropzoneWrapper: {
      marginBottom: '5px',
      '& div:first-child': {
        border: '1px solid' + theme.palette.grey[400],
        borderRadius: '4px',
        '& div:first-child': {
          border: 'none',
        },
      },
      '& p:first-child': {
        fontSize: '20px',
        color: theme.palette.grey[600],
      },
    },
    floatRight: {
      float: 'right',
    },
    fileName: {
      paddingBottom: '10px',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      overflowWrap: 'break-word',
    },
    selectedFileName: {
      fontWeight: 600,
    },
    filesNameList: {
      listStyleType: 'none',
      marginTop: 0,
      paddingLeft: '0',
      maxHeight: '500px',
      overflowY: 'scroll',
    },
    disabledListItem: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
