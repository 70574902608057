import { darken, lighten, Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/table.styles'

export const styles = (theme: Theme) =>
  createStyles({
    deleteIcon: {
      opacity: 0.5,
      color: lighten(theme.palette.error.light, 0.1),
      '&:hover': {
        opacity: 1,
        color: darken(theme.palette.error.dark, 0.1),
      },
    },
    createFromTemplateBtn: {
      float: 'right',
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)

export default useStyles
