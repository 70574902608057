import { useContext } from 'react'
import ReactGA from 'react-ga'
import { Route, useHistory } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import useAppStyles from './App.styles'
import AuthenticatedApp from './AuthenticatedApp'
import { AllProviders, AuthenticationContext } from './providers'
import UnauthenticatedApp from './UnauthenticatedApp'

import './App.css'

const { REACT_APP_CI_BUILD_NUM, REACT_APP_GA_TAG } = process.env

const AppRoutes = () => {
  if (REACT_APP_CI_BUILD_NUM) {
    console.info('This is build number: ', REACT_APP_CI_BUILD_NUM)
  }

  const { isAuthenticated } = useContext(AuthenticationContext)
  const classes = useAppStyles()
  return <div className={classes.app}>{!isAuthenticated ? <UnauthenticatedApp /> : <AuthenticatedApp />}</div>
}

const App = () => {
  try {
    const history = useHistory()
    if (process.env?.REACT_APP_ENV !== 'local' && REACT_APP_GA_TAG) {
      ReactGA.initialize(REACT_APP_GA_TAG)
      history.listen((location: { pathname: string }) => {
        ReactGA.set({ page: location.pathname }) // Update the user's current page
        ReactGA.pageview(location.pathname) // Record a pageview for the given page
      })
    }
  } catch (e) {
    console.error(e)
  }

  return (
    <AllProviders>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppRoutes />
      </QueryParamProvider>
    </AllProviders>
  )
}
export default App
