import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { isEqual } from 'lodash'

import useMultiSelectStyles from './multiselect.styles'

type MultiSelectProps = {
  listItemsToSelect: Array<{ displayName: string; id: number }> // array of elements to display in dropdown
  setValues: (valueToSend: any) => void // function to set selected values in a parent component
  checkedValues: Array<{ displayName: string; id: number }> // selected values
  setItemTouched?: (valueToSend: boolean) => void // function to set touched state of a multiselect
  multiselectTouched?: boolean // was multiselect touched
  required: boolean // is multiselect a required field,
  label: string // label for multiselect
}

interface ISelectedItem {
  id: number
  value: string
}

const Multiselect = (props: MultiSelectProps) => {
  const { required, multiselectTouched, checkedValues, setItemTouched, setValues, label, listItemsToSelect } = props
  const isItemSelectInvalid = required ? multiselectTouched && checkedValues.length === 0 : false
  const classes = useMultiSelectStyles()

  const handleItemBlur = () => {
    if (setItemTouched) {
      setItemTouched(true)
    }
  }

  /* we need to filter out any duplicate values
   * so that user can uncheck the checkbox in multiselect
   * if they click on it the second time */
  const handleItemChange = (event: any) => {
    event.preventDefault()
    const addedValues = event.target.value
    const lastElement = addedValues[addedValues.length - 1]
    const valuesWithoutDuplicates = addedValues.filter((item: ISelectedItem) => !isEqual(item, lastElement))

    if (valuesWithoutDuplicates.length === addedValues.length - 2) {
      setValues(valuesWithoutDuplicates)
    } else {
      setValues(addedValues)
    }
  }

  // id needs to be unique in case there are several exact same multiselects on page (i.e.  in Notification Schema form)
  const inputId = `${Math.floor(Math.random() * 1000)}-selectMultipleChip`

  return (
    <FormControl fullWidth={true} error={isItemSelectInvalid} onBlur={handleItemBlur} required={required} data-cy="multiselectComponent">
      <InputLabel htmlFor={inputId}>{label}</InputLabel>

      <Select
        multiple={true}
        value={checkedValues}
        onChange={handleItemChange}
        input={<Input id={inputId} />}
        data-cy="multiselectSelect"
        renderValue={(selected: any) => {
          return (
            <div className={classes.chips} data-cy="multiselectChips">
              {selected != null && selected.map((value: any) => <Chip key={value.id} label={value.displayName} className={classes.chip} data-cy="dayChip" />)}
            </div>
          )
        }}
      >
        {listItemsToSelect.map((elementToPick: any) => {
          const checked = checkedValues ? checkedValues.some(element => element.id === elementToPick.id) : undefined

          return (
            <MenuItem key={elementToPick.id} value={elementToPick} data-cy="listItem" data-cy-checked={checked}>
              <Checkbox checked={checked} color="secondary" data-cy="multiselectCheckbox" />
              <ListItemText primary={elementToPick.displayName} data-cy="multiselectListItem" />
            </MenuItem>
          )
        })}
      </Select>

      <FormHelperText>{isItemSelectInvalid ? 'Please select a value' : null}</FormHelperText>
    </FormControl>
  )
}

export default Multiselect
