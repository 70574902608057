import { useContext, useEffect } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'

import { ThemeContext } from '../../../providers/theme'
import useSharedFormStyles from '../../../shared-styles/form.styles'
import { setCodeMirrorSource } from '../../../utils/form/form-helpers'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/gruvbox-dark.css'

type ICodeMirrorProps = {
  formValues: any
  setFieldValue: (fieldName: string, fieldValue: string) => void
  helpText?: string
}

/** returns the outer tag if string has one */
export const findOuterTag = (sourceText: string): string => {
  let outerTag: string = ''
  // We need to trim to remove new lines
  if (sourceText !== '' && sourceText.charAt(0) === '<' && sourceText.charAt(sourceText.trim().length - 1) === '>') {
    const closingAngleBracketPosition = sourceText.indexOf('>')
    // if closing angle bracked is not the last character of string
    if (closingAngleBracketPosition !== sourceText.trim().length - 1) {
      outerTag = sourceText.substring(1, closingAngleBracketPosition)
    }
  }

  return outerTag
}

const CodeMirrorWithTags = (props: ICodeMirrorProps) => {
  const { formValues, setFieldValue, helpText } = props
  const { source, name, disabledNodeTypeName } = formValues

  const classes = useSharedFormStyles()
  const { themeKey } = useContext(ThemeContext)

  useEffect(() => {
    const outerTag = findOuterTag(source)

    if (name) {
      // using > character in Name in Global Nodetype templates was breaking the codemirror
      if (!name || name.includes('>')) {
        return
      }

      // if source has no tags, add them
      if (outerTag === '') {
        // need to escape special characters like backslash so that they don't break codemirror
        // eslint-disable-next-line no-useless-escape
        const withTags = `<${name}>\n${source}\n`.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + `</${name}>`
        setFieldValue('source', withTags)
      }
      // if source already has outer tags, replace them with name value
      else if (outerTag !== '') {
        // when used in nodetype template form, this codemirror needs to add
        // nodetype name + form name as a tag
        // otherwise, use just the form name in tag
        // also escaping special characters here as well
        const withTags = (source as string).replace(
          new RegExp(outerTag.replace(/[-g/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'),
          disabledNodeTypeName ? disabledNodeTypeName + name : name
        )
        setFieldValue('source', withTags)
      }
    }
  }, [setFieldValue, name, source, disabledNodeTypeName])

  return (
    <>
      <CodeMirror
        value={source}
        className={classes.border}
        onBlur={codemirrorInstance => {
          // Types are wrong, ignore, take lines from instance and concatenate them to the parent formik form.
          setCodeMirrorSource(codemirrorInstance, setFieldValue)
        }}
        options={{
          mode: 'xml',
          lineWrapping: true,
          lineNumbers: true,
          theme: themeKey === 'dark' ? 'gruvbox-dark' : 'default',
        }}
      />

      {helpText && (
        <div className={classes.codemirrorHelpText} data-testid="codemirror-help-text">
          The riot tag inside this source can be nested inside any nodetype templates on the dashboard's node display
        </div>
      )}
    </>
  )
}

export default CodeMirrorWithTags
