import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import IconButton from '@mui/material/IconButton'
import MuiTableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import MuiTableRow from '@mui/material/TableRow'

import { usePaginationActionStyles } from '../table.styles'

type FooterProps = {
  count: number
  page: number
  rowsPerPage: number
  changeRowsPerPage: (page: string | number) => void
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
}

const TablePaginationActions = (props: FooterProps) => {
  const classes = usePaginationActionStyles()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  const from = count === 0 ? 0 : page * rowsPerPage + 1
  const to = Math.min(count, (page + 1) * rowsPerPage)

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        data-testid="goToFirstPage"
        data-cy="goToFirstPage"
        size="large"
      >
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" data-testid="prevPage" data-cy="prevPage" size="large">
        <KeyboardArrowLeft />
      </IconButton>

      <span className={classes.pageNum}>{`${from}-${to} of ${count}`}</span>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        data-testid="nextPage"
        data-cy="nextPage"
        size="large"
      >
        <KeyboardArrowRight />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        data-testid="goToLastPage"
        data-cy="goToLastPage"
        size="large"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

// TODO for multiple tables get the test ids associated with unique keys like `${key}-nextPage` etc...
const ServersideFooter = (props: FooterProps) => {
  const rowsPerPageOptions = [10, 20, 50]

  const handleRowChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.changeRowsPerPage(event.target.value)
  }

  // We override functions here with empty functions because we are handling this behavior in the TablePaginationAcitons component
  return (
    <MuiTableFooter>
      <MuiTableRow>
        <TablePagination
          page={props.page}
          rowsPerPage={props.rowsPerPage}
          count={props.count}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleRowChange}
          onPageChange={() => null}
          labelDisplayedRows={() => null}
          ActionsComponent={() => <TablePaginationActions {...props} />}
          data-cy="tableFooter"
        />
      </MuiTableRow>
    </MuiTableFooter>
  )
}
export default ServersideFooter
