import { useCallback, useEffect, useRef, useState } from 'react'
import { Add, CalendarMonth, Delete, Refresh } from '@mui/icons-material'
import { Alert, Button, Card, CardContent, CardHeader, Chip, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment-timezone'
import * as Yup from 'yup'

import DateTimePicker from '../../../components/form-elements/date-timepicker'
import useSharedFormStyles from '../../../shared-styles/form.styles'
import Api from '../../../utils/api'

const options: Highcharts.Options = {
  chart: {
    type: 'line',
  },
  title: { text: '' },
  legend: { symbolRadius: 0 },
  credits: { enabled: false },
  xAxis: { type: 'datetime' },
  yAxis: { title: {}, gridLineDashStyle: 'LongDash' },
  tooltip: {
    formatter() {
      return `${moment(this.x).format('LLL')}<br />${this.series.name}: <b>${this.y}</b>`
    },
  },
  plotOptions: {},
  series: [],
  exporting: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
}

const formatHistoryData = (data: any[]) => {
  try {
    return data.map(d => [new Date(d.timestamp).valueOf(), parseFloat(d.value)])
  } catch {
    return []
  }
}

interface FormValues {
  startTime: moment.Moment
  endTime: moment.Moment
}

const schema = Yup.object().shape({
  startTime: Yup.date().typeError('invalid date'),
  endTime: Yup.date().typeError('invalid date'),
})

const HistoryChart = ({
  nodeId,
  allChannels,
  defaultChannels = [],
  onDelete,
}: {
  nodeId: number
  allChannels: string[]
  defaultChannels?: string[]
  onDelete: () => void
}) => {
  const initialValues = {
    startTime: moment().subtract(1, 'day'),
    endTime: moment(),
  }

  const [channels, setChannels] = useState<string[]>(defaultChannels)
  const [chartData, setChartData] = useState<any>(options)
  const [historyData, setHistoryData] = useState<any>({})
  const [channelMenuAnchor, setChannelMenuAnchor] = useState<any>(null)
  const [showTimeRange, setShowTimeRange] = useState<boolean>(false)
  const [timeRange, setTimeRange] = useState<FormValues>(initialValues)

  const chart: any = useRef()

  useEffect(() => {
    // init Highcharts exporting module
    HC_exporting(Highcharts)
    Highcharts.setOptions({
      chart: {
        style: {},
        events: {
          // disables the ability to click and drag zoom
          selection: () => false,
        },
      },
    })
  }, [])

  const fetchData = useCallback(
    async (channel: string) => {
      const res = await Api.post('/api/data/history', {
        channelName: channel,
        nodeId,
        startTime: timeRange.startTime.toISOString(),
        endTime: timeRange.endTime.toISOString(),
      })

      if (!res.error) {
        setHistoryData((oldData: any) => ({
          ...oldData,
          [channel]: res,
        }))
      }
    },
    [nodeId, timeRange]
  )

  useEffect(() => {
    channels.forEach(channel => {
      if (!historyData[channel]) {
        fetchData(channel)
      }
    })
  }, [channels, fetchData, historyData])

  useEffect(() => {
    setChartData({
      ...options,
      series: Object.keys(historyData).map(channel => ({
        name: historyData[channel].length > 0 ? channel : `${channel} (no data)`,
        data: formatHistoryData(historyData[channel]),
      })),
    })
  }, [historyData])

  const onSubmit = (values: FormValues) => {
    setTimeRange(values)
    setHistoryData({})
  }
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={schema}>
      {() => {
        return (
          <Form>
            <Card>
              <CardHeader
                title={
                  <div>
                    {channels.map(channel => (
                      <Chip
                        key={channel}
                        label={channel}
                        onDelete={() => {
                          setChannels(old => old.filter(c => c !== channel))
                          setHistoryData((old: any) => {
                            const newHistory = { ...old }
                            delete newHistory[channel]
                            return newHistory
                          })
                        }}
                        variant="outlined"
                        data-testid={`chip-${channel}`}
                      />
                    ))}
                  </div>
                }
                action={
                  <>
                    <Button onClick={e => setChannelMenuAnchor(e.currentTarget)} startIcon={<Add />}>
                      New Data Point
                    </Button>
                    <Button onClick={() => onDelete()} startIcon={<Delete />} color="error">
                      Remove Chart
                    </Button>
                    <Button onClick={() => setShowTimeRange(prev => !prev)} startIcon={<CalendarMonth />}>
                      Custom time
                    </Button>
                    {/* TODO: put a triple dot menu if another option shows up with custom time as one of the menus */}
                  </>
                }
              />
              {showTimeRange && (
                <CardHeader
                  action={
                    <Grid container={true} gap={2} alignItems="center">
                      <Grid item={true}>
                        <DateTimePicker label="start time" name="startTime" required={true} />
                      </Grid>
                      <Grid item={true}>
                        <DateTimePicker label="end time" name="endTime" required={true} />
                      </Grid>
                      <Grid item={true}>
                        <IconButton type="submit">
                          <Refresh />
                        </IconButton>
                      </Grid>
                    </Grid>
                  }
                />
              )}
              <Menu anchorEl={channelMenuAnchor} open={Boolean(channelMenuAnchor)} onClose={() => setChannelMenuAnchor(null)}>
                {allChannels
                  .filter(c => !channels.includes(c))
                  .map(channel => (
                    <MenuItem key={channel} onClick={() => setChannels(old => [...old, channel])} data-testid={`menu-item-${channel}`}>
                      {channel}
                    </MenuItem>
                  ))}
              </Menu>
              <CardContent>
                {channels.length > 0 && <HighchartsReact ref={chart} highcharts={Highcharts} options={chartData} />}
                {channels.length === 0 && <Alert severity="info">You should select some data points to view. Currently only numerical values will work.</Alert>}
              </CardContent>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

const NodesHistory = ({ nodeId }: { nodeId: number }) => {
  const classes = useSharedFormStyles()
  const [charts, setCharts] = useState<number[]>([0])
  const nextChartId = useRef<number>(charts.length)
  const [allChannels, setAllChannels] = useState<string[]>([])

  useEffect(() => {
    const fetchChannels = async () => {
      const res = await Api.get(`/api/data/current?nodeId=${nodeId}`, {})

      if (!res.error && res[nodeId]) {
        setAllChannels(Object.keys(res[nodeId]))
      }
    }

    fetchChannels()
  }, [nodeId])

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Typography className={classes.formSubheader} variant="subtitle1">
          History
        </Typography>
      </Grid>

      {charts.map(chartId => (
        <Grid item={true} xs={12} key={chartId} data-testid={`chart-${chartId}`}>
          <HistoryChart
            nodeId={nodeId}
            allChannels={allChannels}
            defaultChannels={chartId === 0 ? ['temp', 'batt'] : []}
            onDelete={() => setCharts(old => old.filter(cid => cid !== chartId))}
          />
        </Grid>
      ))}

      <Grid item={true} xs={12}>
        <Button
          onClick={() => {
            setCharts(old => [...old, nextChartId.current])
            nextChartId.current++
          }}
          startIcon={<Add />}
        >
          New History Chart
        </Button>
      </Grid>
    </Grid>
  )
}

export default NodesHistory
