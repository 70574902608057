import {
  Block,
  ClearRounded,
  CropSquare,
  FiberManualRecordOutlined,
  LocationOnRounded,
  PlayArrowRounded,
  StarRounded,
  WarningRounded,
  Whatshot,
} from '@mui/icons-material'

import useIconStyles from './styles'

type IconPreviewProps = {
  isListView: boolean
  color: string
  shape: string
}

interface IIconStyleType {
  fontSize: number
  color: string
  transform?: string
}

const IconPreview = ({ isListView, shape, color }: IconPreviewProps) => {
  const classes = useIconStyles()
  const getIcon = (iconShape: string, iconColor: string) => {
    let iconStyles: IIconStyleType = { fontSize: 90, color: iconColor }

    if (isListView) {
      iconStyles = { fontSize: 30, color: iconColor }
    }

    if (iconShape === 'triangle') {
      iconStyles.transform = 'rotate(-90deg)'
    }

    switch (shape) {
      case 'square':
        return <CropSquare style={iconStyles} data-testid="square-icon" />
      case 'circle':
        return <FiberManualRecordOutlined style={iconStyles} data-testid="circle-icon" />
      case 'gps':
        return <LocationOnRounded style={iconStyles} data-testid="gps-icon" />
      case 'fire':
        return <Whatshot style={iconStyles} data-testid="fire-icon" />
      case 'star':
        return <StarRounded style={iconStyles} data-testid="star-icon" />
      case 'warning':
        return <WarningRounded style={iconStyles} data-testid="warning-icon" />
      case 'blocked':
        return <Block style={iconStyles} data-testid="blocked-icon" />
      case 'cross':
        return <ClearRounded style={iconStyles} data-testid="cross-icon" />
      case 'triangle':
        return <PlayArrowRounded style={iconStyles} data-testid="triangle-icon" />
      default:
        break
    }
  }

  return isListView ? (
    <div>{getIcon(shape, color)}</div>
  ) : (
    <div className={classes.iconPreviewWrapper}>
      <div className={classes.iconWrapper}>{getIcon(shape, color)}</div>
    </div>
  )
}

export default IconPreview
