import { useEffect, useMemo, useReducer } from 'react'
import * as React from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

type SocketActions = 'subscribe:data' | 'unsubscribe:data' | 'subscribe:event' | 'unsubscribe:event'
export type DataStream = {
  parentNodeId: number
  createdAt: string
  parentNodeUniqueId: string
  nodeTypeId: number
  nodeId: number
  uniqueId: string
  channelName: string
  timestamp: number
  value: number
  traceId: string
}
type NodeStreamState = {
  pendingMessageQueue: Array<{ action: SocketActions; nodeId: number }>
  recentNodeData: DataStream | null
  subscribed: Map<number, boolean>
  selectedNodeId: number | null
  selectedChannel: string | null
  nodeData: DataStream[]
  wsReady: boolean
  readyForSubs: boolean
}

const emptyDispatch = () => {}
export const StreamDataContext = React.createContext<{ state: NodeStreamState }>({
  state: {
    pendingMessageQueue: [],
    subscribed: new Map(),
    selectedChannel: null,
    selectedNodeId: null,
    nodeData: [],
    recentNodeData: null,
    wsReady: false,
    readyForSubs: false,
  },
})

export const StreamChannelContext = React.createContext<{ nodeId: number | null; channelData: DataStream[] }>({ nodeId: null, channelData: [] })

export type DispatchContextType = {
  dispatch: (action: { type: string; payload?: unknown }) => void
  nodeStreaming: {
    subscribed: boolean
    selectedNodeId: number | null
  }
}
export const StreamDispatchContext = React.createContext<DispatchContextType>({
  dispatch: emptyDispatch,
  nodeStreaming: {
    subscribed: false,
    selectedNodeId: null,
  },
})

export const StreamProvider = ({ children }: { children: React.ReactNode[] | React.ReactNode }) => {
  const socketURL = `wss://${window.location.host}/api/stream/all`
  const options = useMemo(() => {
    return {
      share: false,
      shouldReconnect: () => true,
      reconnectInterval: 10000,
      reconnectAttempts: 10,
    }
  }, [])

  const [sendMessage, lastMessage, readyState] = useWebSocket(socketURL, options)
  useEffect(() => {
    const timerId = setInterval(() => {
      if (readyState === ReadyState.OPEN) {
        sendMessage(JSON.stringify({ action: 'keepalive' }))
      }
    }, 30000)
    return () => {
      clearInterval(timerId)
    }
  }, [readyState, sendMessage])

  const reducer = (state: NodeStreamState, action: { type: string; payload?: unknown }) => {
    const { subscribed, wsReady, readyForSubs, pendingMessageQueue } = state
    switch (action.type) {
      case 'acknowledged:ready_for_subscriptions':
        if (wsReady) {
          // Just in case things happened out of order, flush the queue
          for (const { action: unsentAction, nodeId: readyNode } of pendingMessageQueue) {
            sendMessage(JSON.stringify({ action: unsentAction, arguments: { nodeId: Number(readyNode) } }))
          }
          return { ...state, pendingMessageQueue: [], readyForSubs: true }
        }
        return { ...state, readyForSubs: true }

      case 'acknowledged:subscribed':
        const { nodeId: subscribedNodeId } = action.payload as { nodeId: number }
        if (subscribedNodeId) {
          const newSubscribed = subscribed.set(+subscribedNodeId, true)
          return { ...state, subscribed: newSubscribed, selectedNodeId: subscribedNodeId }
        }
        console.warn('acknowledged:unsubscribed sent without id in payload')
        return state

      case 'acknowledged:unsubscribed':
        const { nodeId: unsubscribedNodeId } = action.payload as { nodeId: number }
        if (unsubscribedNodeId) {
          const newSubscribed = subscribed.set(+unsubscribedNodeId, false)
          return { ...state, nodeData: [], subscribed: newSubscribed, selectedNodeId: null }
        }
        console.warn('acknowledged:unsubscribed action sent without id in payload')
        return state

      case 'subscribe:data':
        const { nodeId: subscribedNode } = action.payload as { nodeId: number }
        if (subscribedNode && wsReady && readyForSubs) {
          for (const { action: unsentAction, nodeId: rawNode } of state.pendingMessageQueue.concat({ action: 'subscribe:data', nodeId: subscribedNode })) {
            sendMessage(JSON.stringify({ action: unsentAction, arguments: { nodeId: Number(rawNode) } }))
          }
          return { ...state, pendingMessageQueue: [] }
        }
        if (subscribedNode) {
          return { ...state, pendingMessageQueue: state.pendingMessageQueue.concat({ action: 'subscribe:data', nodeId: subscribedNode }) }
        }
        console.warn('subscribe:data action sent without id in payload')
        return state

      case 'unsubscribe:data':
        const { nodeId: unsubNode } = action.payload as { nodeId: number }
        if (unsubNode && wsReady && readyForSubs) {
          for (const { action: unsentAction, nodeId: rawNode } of state.pendingMessageQueue.concat({ action: 'unsubscribe:data', nodeId: unsubNode })) {
            sendMessage(JSON.stringify({ action: unsentAction, arguments: { nodeId: +rawNode } }))
          }
          return { ...state, pendingMessageQueue: [] }
        }
        if (unsubNode) {
          return { ...state, pendingMessageQueue: state.pendingMessageQueue.concat({ action: 'unsubscribe:data', nodeId: unsubNode }) }
        }
        console.warn('unsubscribe:data action sent without id in payload')
        return state

      case 'data:success':
        const { payload } = action.payload as { payload: DataStream }
        // If this is data coming in from an unsubscribed node, ignore it.
        if (Number(payload.nodeId) !== Number(state.selectedNodeId)) {
          return state
        }
        // We sort node data here such that find always is pulling the freshest timestamp
        return { ...state, recentNodeData: payload, nodeData: state.nodeData.concat([payload]).sort((itemA, itemB) => itemA.timestamp - itemB.timestamp) }
      case 'subscribe:channel':
        const { nodeId: channelNode, channel } = action.payload as { nodeId: number; channel: string }
        return { ...state, selectedNodeId: channelNode, selectedChannel: channel }

      case 'ws:ready':
        return { ...state, wsReady: true }

      default:
        console.warn(`${action.type} has no reducer`)
        return state
    }
  }

  const [socketState, dispatch] = useReducer(reducer, {
    pendingMessageQueue: [],
    selectedChannel: null,
    selectedNodeId: null,
    subscribed: new Map<number, boolean>(),
    nodeData: [],
    recentNodeData: null,
    wsReady: false,
    readyForSubs: false,
  })

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      dispatch({ type: 'ws:ready' })
    }
  }, [readyState])

  useEffect(() => {
    if (lastMessage?.data) {
      const data = JSON.parse(lastMessage?.data)
      switch (data.type) {
        case 'acknowledge':
          if (data.statusMessage && data.statusCode === 200) {
            if (data.statusMessage.match('unsubscribed')) {
              const nodeId: string = data?.payload?.nodeId
              dispatch({ type: 'acknowledged:unsubscribed', payload: { nodeId } })
              break
            }
            if (data.statusMessage.match('subscribed')) {
              const nodeId: string = data?.payload?.nodeId
              dispatch({ type: 'acknowledged:subscribed', payload: { nodeId } })
              break
            }
            if (data.statusMessage.match('now accepting')) {
              dispatch({ type: 'acknowledged:ready_for_subscriptions' })
              break
            }
          }
          break
        case 'data':
          if (data.statusCode === 200) {
            dispatch({
              type: 'data:success',
              payload: {
                payload: data?.payload,
              },
            })
          }
          break
        default:
          console.warn(`No handler for ${data.type}`)
          break
      }
    }
  }, [lastMessage])

  const memoizedState = useMemo(
    () => ({
      state: socketState,
    }),
    [socketState]
  )
  const subbed = useMemo(() => Boolean(socketState.subscribed?.get(socketState.selectedNodeId ?? -1)), [socketState.subscribed, socketState.selectedNodeId])
  const memoizedDispatch = useMemo(() => {
    return {
      dispatch,
      nodeStreaming: {
        subscribed: subbed,
        selectedNodeId: socketState.selectedNodeId,
      },
    }
  }, [dispatch, subbed, socketState.selectedNodeId])
  /* 
    If you only want channel data for a specific channel (e.g. channel history) We keep track of a currently selected channel name
    and node id so that your context doesnt force rerenders for data you dont care about.
  */
  const memoizedChannelData = useMemo(() => {
    return {
      nodeId: socketState.selectedNodeId,
      channelData: socketState.nodeData.filter(item => item?.channelName === socketState.selectedChannel && item.nodeId === socketState.selectedNodeId),
    }
  }, [socketState.nodeData, socketState.selectedChannel, socketState.selectedNodeId])
  return (
    <StreamDispatchContext.Provider value={memoizedDispatch}>
      <StreamDataContext.Provider value={memoizedState}>
        <StreamChannelContext.Provider value={memoizedChannelData}>{children}</StreamChannelContext.Provider>
      </StreamDataContext.Provider>
    </StreamDispatchContext.Provider>
  )
}
