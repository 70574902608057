import { Theme } from '@mui/material'
import { red } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    selectLabel: {
      fontSize: '14px',
    },
    selectWrapper: {
      padding: '10px',
    },
    label: {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[600],
      fontSize: '14px',
      marginBottom: '10px',
    },
    errorText: { fontSize: '0.90rem' },
    listItemsWrapper: {
      height: '340px',
      borderTop: `1px solid ${theme.palette.grey[500]}`,
      padding: `3px 0 0 15px`,
      overflowY: 'scroll',
    },
    footerWrapper: {
      borderTop: `1px solid ${theme.palette.grey[500]}`,
      margin: '5px 0 -10px 0',
    },
    footerText: {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[900],
      fontSize: '14px',
    },
    loadingCircleWrapper: {
      marginTop: '15%',
      textAlign: 'center',
    },
    noItems: {
      textAlign: 'center',
      marginTop: '15%',
      color: theme.palette.grey[700],
    },
    errorState: {
      border: `1px solid ${red[500]}`,
      boxShadow: `0px 2px 4px -1px ${red[100]}, 0px 4px 5px 0px ${red[100]}, 0px 1px 10px 0px ${red[100]}`,
    },
    selectElement: {
      padding: '5px 7px',
    },
    hiddenButton: { marginLeft: '16px', opacity: 0 },
    labelWrapper: {
      '&:hover > button': {
        opacity: 1,
      },
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
