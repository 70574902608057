import { grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedFormStyles } from '../../shared-styles/form.styles'
import { styles as sharedTableStyles } from '../../shared-styles/table.styles'

export const styles = (theme: Theme) =>
  createStyles({
    folderPathWrapper: {
      border: `1px solid ${grey[400]}`,
      borderRadius: '4px',
      padding: '10px 0px',
      display: 'flex',
      flexFlow: 'wrap',
    },
    breadcrumbLink: {
      padding: theme.spacing(0, 1),
    },
    childrenLink: {
      cursor: 'pointer',
    },
    currentFolderLink: {
      fontWeight: 'bold',
    },
    smallAlert: {
      width: 'fit-content',
    },
    ...sharedFormStyles(theme),
    ...sharedTableStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
