import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

import HeaderAction from './header-action'
import TaskTemplateForm from './task-template-form'

const CreateEditTaskTemplate = () => {
  const params = useParams<{ templateId: string }>()

  const templateId = params.templateId
  const isEditMode = templateId !== 'new'

  return (
    <Card>
      <CardHeader
        title={isEditMode ? 'Edit Task Template' : 'Create Task Template'}
        data-cy="taskTemplateTitle"
        action={<HeaderAction isEditMode={isEditMode} templateId={Number(templateId)} />}
      />
      <CardContent>
        <TaskTemplateForm />
      </CardContent>
    </Card>
  )
}

export default CreateEditTaskTemplate
