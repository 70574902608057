import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FormControlLabel } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { FastField, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { cloneDeep } from 'lodash'
import includes from 'lodash/includes'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import MultiFilterSelect from '../../components/form-elements/filter-select/multi-select'
import TextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useClipboardAPIExists, useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import ChannelForm from './channel'
import useNodeTypeFormStyles from './create-edit.styles'
import NodeProperties from './node-properties'

interface INodeTypeFormValues {
  channels: IChannelType[]
  createdAt: string
  driverId?: string | number | null
  id: number | null
  updatedAt: string
  name: string
  vanityName: string
  allowedRoles: number[]
  csvInput: string
  parentNodes: string
  autoActivate: boolean
  autoCreate: boolean
  changeNodeType: boolean
  changeParent: boolean
  syncParentFolder: boolean
  allRoles: boolean
  isAmmonia: boolean
  isBvs: boolean
  isGateway: boolean
  isLora: boolean
  isPhysical: boolean
  isSensor: boolean
  isTing: boolean
  isV3: boolean
}

interface IParentNodes {
  id: string
}

export const NodeTypeForm = () => {
  const classes = useNodeTypeFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ nodetypeId: string }>()
  const nodetypeId = params.nodetypeId
  const isEditMode = nodetypeId !== 'new'

  const parentNodesList = [{ id: 'self' }, { id: 'parent' }]

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)
  const [clipboardAPIExists, clipboardUnsupportedMessage] = useClipboardAPIExists()

  const [currentNodeType, setCurrentNodeType] = useState<INodeTypeType | null>(null)
  const [drivers, setDrivers] = useState<IDriverType[]>([])
  const [driversError, setDriversError] = useState<string>('')
  const [roles, setRoles] = useState<IRoleType[]>([])
  const [rolesLoading, setRolesLoading] = useState<boolean>(false)
  const [rolesError, setRolesError] = useState<string>('')
  const [parentNodes] = useState<IParentNodes[]>(parentNodesList)
  const [submitCounter, setSubmitCounter] = useState<number>(0)

  useDocumentTitle('Node Types', isEditMode ? currentNodeType?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    setRolesLoading(true)

    Api.get('/api/roles').then(rolesData => {
      setRolesLoading(false)

      if (isSubscribed) {
        if (!rolesData.error) {
          setRoles(rolesData)
        } else {
          setRolesError(rolesData.message)
          addAlert({
            alertType: 'error',
            message: rolesData.message ? rolesData.message : 'Could not access roles',
          })
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true

    const setData = async () => {
      const driversData = await Api.get('/api/drivers')

      if (isSubscribed) {
        if (!driversData.error) {
          setDrivers(driversData)
        } else {
          setDriversError(driversData.message)
          addAlert({
            alertType: 'error',
            message: driversData.message ? driversData.message : 'Could not access drivers',
          })
        }
      }
    }

    setData()

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/nodetypes/${nodetypeId}`).then(retrievedNodeType => {
        redirectToListViewOnError(retrievedNodeType, history, addAlert, '/node-types')

        if (isSubscribed) {
          setCurrentNodeType(retrievedNodeType)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [nodetypeId, isEditMode, history, addAlert])

  const saveNodeType = async (values: INodeTypeFormValues, actions: FormikHelpers<INodeTypeFormValues>) => {
    if (roles !== null) {
      const channelsDeepCopy = cloneDeep(values.channels)

      const typeConvertedChannels = channelsDeepCopy.map(channel => {
        if (channel.type === 'number' || channel.type === 'alias:number') {
          channel.default = channel.default === '' || channel.default === null ? null : Number(channel.default)
        }
        if (channel.type === 'boolean' || channel.type === 'alias:boolean') {
          channel.default = channel.default !== '' ? channel.default === 'true' : null
        }
        return channel
      })

      const channelsObj = typeConvertedChannels.reduce((obj, item) => {
        const key = item.name
        // @ts-ignore
        delete item.name
        obj[key] = item
        return obj
      }, {} as { [key: string]: IChannelType })

      // Empty array reflects select all allowed and future roles
      const isFull = values.allowedRoles.length === roles.length
      const allowedRoles = isFull ? [] : values.allowedRoles

      const payload = {
        activationConfig: {
          autoActivate: values.autoActivate,
          autoCreate: values.autoCreate,
          changeNodeType: values.changeNodeType,
          changeParent: values.changeParent,
          setParent: values.parentNodes,
          syncParentFolder: values.syncParentFolder,
        },
        allowedRoles,
        channels: channelsObj,
        driverId: values.driverId === '' || values.driverId == null ? 0 : +values.driverId,
        id: values.id,
        name: values.name,
        vanityName: values.vanityName,
        isAmmonia: values.isAmmonia,
        isBvs: values.isBvs,
        isGateway: values.isGateway,
        isLora: values.isLora,
        isPhysical: values.isPhysical,
        isSensor: values.isSensor,
        isTing: values.isTing,
        isV3: values.isV3,
      }

      let submittedNodetype

      try {
        if (isEditMode) {
          handleFormSubmit((submittedNodetype = await Api.put(`/api/nodetypes/${nodetypeId}`, payload)), addAlert, history, null, values, actions)

          setSubmitCounter(submitCounter + 1)

          // to prevent form reset on submit in edit mode
          const updatedNodetype = {
            ...payload,
            createdAt: submittedNodetype.createdAt,
            updatedAt: submittedNodetype.updatedAt,
            id: submittedNodetype.id,
          }
          setCurrentNodeType(updatedNodetype)
        } else {
          handleFormSubmit(
            (submittedNodetype = await Api.post('/api/nodetypes', payload)),
            addAlert,
            history,
            `/node-types/${submittedNodetype.id}`,
            values,
            actions
          )
        }
      } catch (e) {
        const message = (e as Error).message
        actions.setSubmitting(false)
        addAlert({ alertType: 'error', message: String(message) || 'Could not submit the nodetype' })
      }
    }
  }

  const importCsvData = (values: INodeTypeFormValues) => {
    const importedChannels = values.csvInput.split('\n').map((line: string) => {
      const fields = line.split(',').map((val: string) => {
        return val.trim()
      })

      let type: string = fields[2]
      if (!includes(['string', 'number', 'boolean', 'alias:string', 'alias:number', 'alias:boolean'], type)) {
        type = 'string'
      }

      let machineLearningConfig = fields[4]
      if (!includes(['ignored', 'attribute', 'tracked'], machineLearningConfig)) {
        machineLearningConfig = 'ignored'
      }

      return {
        name: fields[0],
        vanityName: fields[1],
        type,
        default: fields[3],
        machineLearningConfig,
      }
    })

    // convert lists into objects with [name] as the key
    const originalChannels = Object.keys(values.channels).reduce((result, key) => {
      const val = values.channels[Number(key)]
      result[val.name] = { ...val }
      return result
    }, {} as Record<string, IChannelType>)
    const newChannels = Object.keys(importedChannels).reduce((result, key) => {
      const val = importedChannels[Number(key)]
      result[val.name] = { ...val }
      return result
    }, {} as Record<string, IChannelType>)

    // merge the two objects
    const updatedChannels = {
      ...originalChannels,
      ...newChannels,
    }

    // turn the channels back into a list and return
    return Object.values(updatedChannels)
  }

  /** this function will convert nodetype channels to a format we will be able to use when using import csv button */
  const exportCsvData = (nodetypeChannels: IChannelType[]) => {
    let channelsToCopy = ''

    if (nodetypeChannels.length > 0) {
      nodetypeChannels.forEach((channel, index, array) => {
        // for all elements except the last one we'll be adding new line character to the channelsToCopy srtring
        // it will allow import csv button to work properly
        const stringifiedChannelData = `${channel.name}, ${channel.vanityName}, ${channel.type}, ${channel.default}, ${channel.machineLearningConfig}`
        if (index !== array.length - 1) {
          channelsToCopy = channelsToCopy.concat(stringifiedChannelData + '\n')
        } else {
          channelsToCopy = channelsToCopy.concat(stringifiedChannelData)
        }
      })
    }

    if (clipboardAPIExists) {
      navigator.clipboard.writeText(channelsToCopy).then(
        () => {
          addAlert({ alertType: 'success', message: `Copied channels to clipboard` })
        },
        err => {
          addAlert({ alertType: 'error', message: `Could not copy channels to clipboard. ${err}` })
        }
      )
    } else {
      addAlert({ alertType: 'error', message: clipboardUnsupportedMessage as string })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .matches(/^\S+$/, { message: 'Spaces are not allowed in the name.' }),
    vanityName: Yup.string(),
    drivers: Yup.string(),
    autoActivate: Yup.boolean(),
    autoCreate: Yup.boolean(),
    changeNodeType: Yup.boolean(),
    changeParent: Yup.boolean(),
    parentNode: Yup.string(),
    channels: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required('Required')
          .matches(/^\S+$/, { message: 'Spaces are not allowed in the name.' }),
        vanityName: Yup.string().nullable(),
        default: Yup.string().nullable(),
        type: Yup.string(),
        machineLearningConfig: Yup.string(),
      })
    ),
    allowedRoles: Yup.array().required('Required'),
    isAmmonia: Yup.boolean(),
    isBvs: Yup.boolean(),
    isGateway: Yup.boolean(),
    isLora: Yup.boolean(),
    isPhysical: Yup.boolean(),
    isSensor: Yup.boolean(),
    isTing: Yup.boolean(),
    isV3: Yup.boolean(),
  })

  const deleteNodeType = async () => {
    try {
      handleDelete(await Api.del('/api/nodetypes', nodetypeId), addAlert, history, '/node-types')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete the nodetype' })
    }
  }

  const initialValues: INodeTypeFormValues = {
    createdAt: isEditMode && currentNodeType && currentNodeType.createdAt ? currentNodeType.createdAt : Date(),
    csvInput: '',
    id: isEditMode && currentNodeType && currentNodeType.id ? currentNodeType.id : null,
    updatedAt: isEditMode && currentNodeType && currentNodeType.updatedAt ? currentNodeType.updatedAt : Date(),
    name: isEditMode && currentNodeType && currentNodeType.name ? currentNodeType.name : '',
    vanityName: isEditMode && currentNodeType && currentNodeType.vanityName ? currentNodeType.vanityName : '',
    driverId: isEditMode && currentNodeType && currentNodeType.driverId ? currentNodeType.driverId : '',
    allowedRoles:
      isEditMode && currentNodeType && currentNodeType.allowedRoles
        ? currentNodeType.allowedRoles.length === 0
          ? roles.map(item => item.id)
          : currentNodeType.allowedRoles
        : [],
    parentNodes:
      isEditMode && currentNodeType && currentNodeType.activationConfig && currentNodeType.activationConfig.setParent
        ? currentNodeType.activationConfig.setParent
        : 'parent',
    autoActivate:
      isEditMode && currentNodeType && currentNodeType.activationConfig && currentNodeType.activationConfig.autoActivate
        ? currentNodeType.activationConfig.autoActivate
        : false,
    autoCreate:
      isEditMode && currentNodeType && currentNodeType.activationConfig && currentNodeType.activationConfig.autoCreate
        ? currentNodeType.activationConfig.autoActivate
        : false,
    changeNodeType:
      isEditMode && currentNodeType && currentNodeType.activationConfig && currentNodeType.activationConfig.changeNodeType
        ? currentNodeType.activationConfig.changeNodeType
        : false,
    changeParent:
      isEditMode && currentNodeType && currentNodeType.activationConfig && currentNodeType.activationConfig.changeParent
        ? currentNodeType.activationConfig.changeParent
        : false,
    syncParentFolder:
      isEditMode && currentNodeType && currentNodeType.activationConfig && currentNodeType.activationConfig.syncParentFolder
        ? currentNodeType.activationConfig.syncParentFolder
        : false,
    channels:
      isEditMode && currentNodeType && currentNodeType.channels
        ? Object.entries(currentNodeType.channels).map(([key, value]) => {
            if (key === 'machineLearningConfig') {
              if (!includes(['ignored', 'attribute', 'tracked'], value)) {
                value = 'ignored'
              }
            }
            if (key === 'default' && value == null) {
              value = ''
            }
            return {
              ...value,
              name: String(key),
            }
          })
        : [],
    allRoles: Boolean(isEditMode && currentNodeType && currentNodeType.allowedRoles && currentNodeType.allowedRoles.length === 0),
    isAmmonia: currentNodeType?.isAmmonia ?? false,
    isBvs: currentNodeType?.isBvs ?? false,
    isGateway: currentNodeType?.isGateway ?? false,
    isLora: currentNodeType?.isLora ?? false,
    isPhysical: currentNodeType?.isPhysical ?? false,
    isSensor: currentNodeType?.isSensor ?? false,
    isTing: currentNodeType?.isTing ?? false,
    isV3: currentNodeType?.isV3 ?? false,
  }

  return (
    <>
      {!driversError && !rolesError && (
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={saveNodeType}
          isInitialValid={isEditMode && submitCounter > 0}
        >
          {({ isValid, isSubmitting, values, setFieldValue, dirty }: FormikProps<INodeTypeFormValues>) => {
            const handleEnterKeypress = (charCode: number, fieldName: string, fieldValue: boolean) => {
              if (charCode === 13) {
                setFieldValue(fieldName as keyof INodeTypeFormValues, fieldValue)
              }
            }

            return (
              <Form>
                <Card>
                  <CardHeader title={isEditMode ? 'Edit Node Type' : 'Create Node Type'} data-cy="nodeTypeTitle" />

                  <CardContent>
                    <Grid spacing={3} container={true}>
                      <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField initialized={isEditMode} label="Name" name="name" required={true} />
                      </Grid>

                      <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField initialized={isEditMode} data-cy="vanityNameInput" label="Vanity Name" name="vanityName" />
                      </Grid>

                      <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField initialized={isEditMode} data-cy="driversSelectInput" name="driverId" label="Drivers" select={true}>
                          {drivers != null && Array.isArray(drivers) ? (
                            drivers
                              .sort((a, b) => {
                                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a > b ? 1 : 0
                              })
                              .map(driver => {
                                return (
                                  <option key={driver.id} value={driver.id}>
                                    {driver.name}
                                  </option>
                                )
                              })
                          ) : (
                            <option data-cy="driversSelectInputError">No drivers found</option>
                          )}
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Allowed Roles
                      </Typography>
                    </Grid>

                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} sm={12} md={8} lg={6} xl={6} data-cy="allowedRolesInput">
                        <MultiFilterSelect<IRoleType, INodeTypeFormValues>
                          canSelectAll={true}
                          name="allowedRoles"
                          required={true}
                          items={roles}
                          loading={rolesLoading}
                          initialValues={currentNodeType?.allowedRoles}
                          entityName="Roles"
                          displayField="name"
                        />
                      </Grid>

                      <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Alert
                          alertType="warning"
                          message="Allowed roles can: Edit this node type in the Admin application, create nodes with this node type and view nodes with this node type in the Dashboard application."
                        />
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Node Properties
                      </Typography>
                      <Typography variant="subtitle1" className={classes.nodePropertiesText}>
                        These are characteristics to help define/categorize node types by specific properties.
                      </Typography>
                    </Grid>

                    <Grid container={true} item={true} xs={12}>
                      <NodeProperties />
                    </Grid>

                    <Grid item={true} xs={12} container={true} justifyContent="flex-end">
                      <Tooltip title="Copy channels to clipboard in CSV format" arrow={true}>
                        <span>
                          <Button
                            variant="outlined"
                            color="secondary"
                            disabled={values?.channels.length === 0}
                            onClick={() => exportCsvData(values.channels)}
                            data-testid="exportCsvBtn"
                            className={classes.exportBtn}
                          >
                            Copy Channels as CSV
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Channels
                      </Typography>
                    </Grid>

                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <ChannelForm />
                      </Grid>

                      <Grid item={true} container={true} xs={12}>
                        <Grid item={true} xs={12}>
                          <TextField
                            initialized={isEditMode}
                            fullWidth={true}
                            data-cy="csvInput"
                            data-testid="csvInput"
                            variant="outlined"
                            multiline={true}
                            id="csvInput"
                            label="Name, Vanity Name, Channel Type, Default Value, Machine Learning"
                            name="csvInput"
                            helperText="Paste a CSV list of channel properties here"
                          />
                        </Grid>

                        <Grid item={true} container={true} xs={12} justifyContent="flex-end" spacing={2}>
                          <Grid item={true}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              data-cy="deleteChannelsBtn"
                              data-testid="deleteChannelsBtn"
                              onClick={event => {
                                event.preventDefault()
                                setFieldValue('channels', [])
                              }}
                            >
                              Delete all channels
                            </Button>
                          </Grid>
                          <Grid item={true}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              data-cy="csvInputBtn"
                              data-testid="csvInputBtn"
                              disabled={values.csvInput === ''}
                              onClick={event => {
                                event.preventDefault()
                                setFieldValue('channels', importCsvData(values))
                                setFieldValue('csvInput', '')
                              }}
                            >
                              Import CSV Data
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Activation Settings
                      </Typography>
                    </Grid>

                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField initialized={isEditMode} data-cy="parentNodesSelectInput" name="parentNodes" label="Parent Node" select={true}>
                          {parentNodes != null ? (
                            parentNodes.map((parent, index: number) => (
                              <option key={index} value={parent.id}>
                                {parent.id}
                              </option>
                            ))
                          ) : (
                            <option>No Parent Node Options Found</option>
                          )}
                        </TextField>
                      </Grid>

                      <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FastField name="autoActivate">
                          {({ field }: FieldProps) => {
                            return (
                              <Grid item={true}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      data-cy="autoActivateInput"
                                      onKeyPress={() => handleEnterKeypress}
                                      color="secondary"
                                      checked={field != null && field.value === true}
                                      {...field}
                                      value="autoActivate"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="Auto Activate"
                                />
                                <FormHelperText className={classes.checkBoxHelperText}>Whether or not the node will activate itself.</FormHelperText>
                              </Grid>
                            )
                          }}
                        </FastField>

                        <FastField name="autoCreate">
                          {({ field }: FieldProps) => {
                            return (
                              <Grid item={true}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      data-cy="autoCreateInput"
                                      onKeyPress={() => handleEnterKeypress}
                                      color="secondary"
                                      checked={field != null && field.value === true}
                                      {...field}
                                      value="autoCreate"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="Auto Create"
                                />
                                <FormHelperText className={classes.checkBoxHelperText}>Whether or not the node will create itself.</FormHelperText>
                              </Grid>
                            )
                          }}
                        </FastField>

                        <FastField name="changeNodeType">
                          {({ field }: FieldProps) => {
                            return (
                              <Grid item={true}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      data-cy="changeNodeTypeInput"
                                      onKeyPress={event => {
                                        event.preventDefault()
                                        // Handle enter keypress (charCode == 13)
                                        handleEnterKeypress(event.charCode, 'changeNodeTypeInput', !field.value)
                                      }}
                                      checked={field != null && field.value === true}
                                      {...field}
                                      color="secondary"
                                      value="changeNodeType"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="Change Node Type"
                                />
                                <FormHelperText className={classes.checkBoxHelperText}>
                                  Whether or not the node type will be updated if the mqtt topic is different then what is currently configured.
                                </FormHelperText>
                              </Grid>
                            )
                          }}
                        </FastField>

                        <FastField name="changeParent">
                          {({ field }: FieldProps) => {
                            return (
                              <Grid item={true}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      data-cy="changeParentInput"
                                      onKeyPress={event => {
                                        event.preventDefault()
                                        handleEnterKeypress(event.charCode, 'changeParent', !field.value)
                                      }}
                                      checked={field != null && field.value === true}
                                      {...field}
                                      color="secondary"
                                      value="changeParent"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="Change Parent"
                                />
                                <FormHelperText className={classes.checkBoxHelperText}>
                                  Whether or not the parent node will be updated if the mqtt topic is different then what is currently configured.
                                </FormHelperText>
                              </Grid>
                            )
                          }}
                        </FastField>

                        <Grid item={true} xs={12}>
                          <FastField name="syncParentFolder">
                            {({ field }: FieldProps) => {
                              return (
                                <Grid item={true}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        data-cy="syncParentFolderInput"
                                        onKeyPress={event => {
                                          event.preventDefault()
                                          handleEnterKeypress(event.charCode, 'syncParentFolder', !field.value)
                                        }}
                                        checked={field != null && field.value === true}
                                        {...field}
                                        color="secondary"
                                        value="syncParentFolder"
                                      />
                                    }
                                    labelPlacement="end"
                                    label="Sync Parent"
                                  />
                                  <FormHelperText className={classes.syncParentCheckbox}>
                                    Whether or not the parent Folder will be updated if the mqtt topic is different then what is currently configured.
                                  </FormHelperText>
                                </Grid>
                              )
                            }}
                          </FastField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    <Button
                      color="primary"
                      type="submit"
                      data-cy="saveBtn"
                      disabled={!isValid || isSubmitting || (!isEditMode && !dirty)}
                      data-testid="saveBtn"
                    >
                      Save
                    </Button>

                    <Button type="button" onClick={() => history.push('/node-types')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                      Cancel
                    </Button>

                    {isEditMode ? (
                      <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                        Delete
                      </Button>
                    ) : null}
                  </CardActions>
                </Card>
              </Form>
            )
          }}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteNodeType}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this node type?"
      />

      {(driversError || rolesError) && (
        <PermissionsFormError
          errorMessages={combineErrorMessages(driversError, rolesError)}
          formName="Node Types Form"
          listViewRoute="/node-types"
          hasGoBackButton={true}
        />
      )}
    </>
  )
}

export default NodeTypeForm
