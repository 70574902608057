import { grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    noMarginSubheader: {
      fontSize: '1.5 rem',
      color: grey[600],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    sourceLabel: {
      color: grey[600],
      marginBottom: '5px',
    },
    docsLink: {
      float: 'right',
      marginBottom: '4px',
    },
    testResult: {
      whiteSpace: 'pre-wrap',
    },
    lambdaFormSubheader: {
      color: grey[600],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    testOutputWrapper: {
      border: '1px solid' + theme.palette.grey[400],
      borderRadius: '4px',
      padding: '0 10px',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    lambdaCodemirror: {
      '& .CodeMirror-wrap': {
        height: '682px',
      },
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
