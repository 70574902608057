import { red } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    helperText: {
      marginTop: 0,
    },
    errorText: {
      marginTop: 0,
      color: red[600],
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
