import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Form } from 'formik'

import FormikTextField from '../form-elements/text-field'

import { useStyles } from './index.styles'

interface IProps {
  isFormValid: boolean
}

export interface ITimeRangeFormValues {
  startTime: string
  endTime: string
}

const TimeRangeSelectorForm = (props: IProps) => {
  const classes = useStyles()

  return (
    <Form>
      <Grid item={true} xs={12} container={true} spacing={6}>
        <Grid item={true} xs={6} sm={4} md={4} lg={4} xl={4} data-cy="startTime">
          <FormikTextField label="Start Time" name="startTime" required={true} fullWidth={true} type="date" />
        </Grid>

        <Grid item={true} xs={6} sm={4} md={4} lg={4} xl={4} data-cy="endTime">
          <FormikTextField label="End Time" name="endTime" required={true} fullWidth={true} type="date" />
        </Grid>

        <Grid
          item={true}
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          container={true}
          justifyContent="flex-end"
          alignItems="center"
          className={classes.updateBtnContainer}
        >
          <Button
            color="secondary"
            variant="outlined"
            type="submit"
            disabled={!props.isFormValid}
            data-testid="updateTimeRangeBtn"
            data-cy="updateTimeRangeBtn"
            className={classes.updateBtn}
          >
            Update Time Range
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

export default TimeRangeSelectorForm
