import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import loginBackground from '../../assets/login-bg.png'

export const styles = (theme: Theme) =>
  createStyles({
    copyright: {
      color: theme.palette.grey[400],
      [theme.breakpoints.down('md')]: {
        color: theme.palette.grey[600],
      },
    },
    loginImage: {
      backgroundImage: `url(${loginBackground})`,
      height: '100vh',
    },
    meshifySpan: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '64px',
      letterSpacing: '0.055em',
      color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
      textTransform: 'uppercase',
    },
    adminSpan: {
      fontWeight: 'normal',
      fontSize: '30px',
      lineHeight: '64px',
      letterSpacing: '0.015em',
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
      marginLeft: '5px',
    },
    meshifyTextWrapper: {
      marginTop: '30%',
    },
    welcomeText: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
      marginTop: '10%',
    },
    loginText: {
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    },
    smallTopMargin: {
      marginTop: '5%',
    },
    footer: {
      paddingBottom: theme.spacing(),
      fontSize: '14px',
      [theme.breakpoints.up('md')]: {
        height: '100vh',
      },
      [theme.breakpoints.down('md')]: {
        position: 'fixed',
        bottom: 0,
      },
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      color: theme.palette.grey[400],
      [theme.breakpoints.down('md')]: {
        color: theme.palette.grey[600],
      },
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
