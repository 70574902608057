import { useEffect, useRef, useState } from 'react'
import { Shortcut } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Field, FieldProps, FormikTouched } from 'formik'

import Footer from './footer/footer'
import SearchField from './search/search'
import useFilterSelectStyles from './styles'

interface IProps<GenericItem extends IBackendEntityType, FormValues extends object> {
  name: string & keyof FormValues // formik field name
  items: GenericItem[] // an array of items we will display in multi select
  required: boolean
  displayField: string & keyof GenericItem // a field we want to display as label, most commonly used is "name"
  loading: boolean
  initialValues: number[] | undefined // values that was previously saved on server, we will use it to display saved value on top of list in edit mode
  entityName: string // name displayed next to checkbox to select all items, i.e. Folders, Users etc
  onItemChecked?: (id: number) => void
  onItemUnChecked?: (id: number) => void
  canSelectAll: boolean
  shortcutCallback?: (id: number) => void
}

type IMultiSelectBody<GenericItem extends IBackendEntityType, FormValues extends object> = IProps<GenericItem, FormValues> & {
  form: FieldProps<FormValues>['form']
}

export const ROWS_PER_PAGE = 10

const MultiSelectBody = <GenericItem extends IBackendEntityType, FormValues extends object>(props: IMultiSelectBody<GenericItem, FormValues>) => {
  const {
    canSelectAll,
    name,
    items,
    required,
    form,
    displayField,
    loading,
    initialValues,
    entityName,
    onItemChecked,
    onItemUnChecked,
    shortcutCallback,
  } = props

  const [currentlyCheckedValues, setCurrentlyCheckedValues] = useState<number[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [itemsOnCurrentPage, setItemsOnCurrentPage] = useState<any[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [searchResults, setSearchResults] = useState<GenericItem[]>([])
  const [sortedItems, setSortedItems] = useState<GenericItem[]>([])
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const itemsMap = useRef(new Map<number, { index: number; item: GenericItem }>())
  const classes = useFilterSelectStyles()

  useEffect(() => {
    // we will check item by id
    if (form.values && form.values[name]) {
      const checkedVals = (form.values[name] as unknown) as number[]
      setCurrentlyCheckedValues(checkedVals)
    }
  }, [form.values, name])

  useEffect(() => {
    setAllSelected(currentlyCheckedValues.length === sortedItems.length)
  }, [currentlyCheckedValues, sortedItems])

  // here we set which items will be displayed on each page
  useEffect(() => {
    const itemsToRender = searchText
      ? searchResults.slice(currentPage * ROWS_PER_PAGE - ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE)
      : sortedItems.slice(currentPage * ROWS_PER_PAGE - ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE)

    setItemsOnCurrentPage(itemsToRender)
  }, [currentPage, sortedItems, searchText, searchResults])

  useEffect(() => {
    const searchedItems: GenericItem[] = []
    const lowerCaseSearchText = searchText.toLowerCase()

    items.forEach(item => {
      const displayText = (item[displayField] as unknown) as string
      if (displayText.toLowerCase().includes(lowerCaseSearchText)) {
        searchedItems.push(item)
      }
    })

    setSearchResults(searchedItems)
  }, [searchText, displayField, items])

  // We are using this to track in linear time what index an item with a particular id shows up in.
  // We can look up an id and get its index and the item.
  useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      itemsMap.current.set(items[i].id, { index: i, item: items[i] })
    }
  }, [items])

  // Here we make sure that selected items are displayed on top of the items list in edit mode
  useEffect(() => {
    if (initialValues) {
      const sorted: GenericItem[] = []
      const ignoredIndices = {} as { [key: number]: true | undefined }
      // We iterate over initial values, look up each item by its id to get its index.
      // We add that index to the set of ignored indices since we've already pushed that item to the beginning.
      for (const initialVal of initialValues) {
        const mapData = itemsMap.current.get(initialVal)
        if (mapData) {
          const { item, index } = mapData
          sorted.push(item)
          ignoredIndices[index] = true
        }
      }
      for (let j = 0; j < items.length; j++) {
        // This item has already been pushed, skip it
        if (ignoredIndices[j] === true) {
          // eslint-disable-next-line no-continue
          continue
        } else {
          sorted.push(items[j])
        }
      }
      setSortedItems(sorted)
      setCurrentPage(1)
    } else {
      setSortedItems(items)
    }
  }, [initialValues, items, itemsMap])

  const selectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkBoxValue = Number((event.target as HTMLInputElement).value)
    const selectedValuesCopy = [...currentlyCheckedValues]
    if (selectedValuesCopy?.includes(checkBoxValue)) {
      const index = selectedValuesCopy.indexOf(checkBoxValue)
      selectedValuesCopy.splice(index, 1)
      if (onItemUnChecked) {
        onItemUnChecked(checkBoxValue)
      }
    } else {
      selectedValuesCopy?.push(checkBoxValue)
      if (onItemChecked) {
        onItemChecked(checkBoxValue)
      }
    }

    setCurrentlyCheckedValues(selectedValuesCopy)
    form.setFieldValue(name, selectedValuesCopy)
    form.setFieldTouched(name, true, false)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkBoxValue = (event.target as HTMLInputElement).checked

    setAllSelected(checkBoxValue)
    form.setTouched({ [name]: true } as FormikTouched<FormValues>)

    if (checkBoxValue) {
      const allItemsIds: number[] = sortedItems.map(item => {
        if (onItemChecked) {
          onItemChecked(item.id)
        }
        return item.id
      })

      setCurrentlyCheckedValues(allItemsIds)
      form.setFieldValue(name, allItemsIds)
    } else {
      if (onItemUnChecked) {
        sortedItems.forEach(item => onItemUnChecked(item.id))
      }
      setCurrentlyCheckedValues([])
      form.setFieldValue(name, [])
    }
  }

  const shouldError = () => {
    if (!required) {
      return false
    }

    const touched = form.touched[name as string & keyof FormValues]
    const checked = (form.values[name as string & keyof FormValues] as unknown) as number[]

    return touched && checked.length === 0
  }

  return (
    <div>
      <div className={classes.label}>
        Please select {entityName}
        {required && <span data-testid="required-asterisk">*</span>}
      </div>

      {canSelectAll && (
        <div>
          <FormControlLabel
            label={<span className={classes.selectLabel}>Select All {entityName}</span>}
            control={
              <Checkbox
                checked={allSelected}
                data-cy-checked={allSelected}
                onChange={handleSelectAll}
                value={allSelected}
                color="secondary"
                data-testid={`${name}-select-all-checkbox`}
                data-cy="checkAll"
              />
            }
          />
        </div>
      )}

      <Paper elevation={4} className={shouldError() ? classes.errorState : undefined} data-cy-error={shouldError()}>
        <div className={classes.selectWrapper} data-testid={`${name.toLowerCase()}-multi-select-container`}>
          <SearchField searchText={searchText} setSearchText={setSearchText} />

          <div className={classes.listItemsWrapper} data-cy-isloading={loading}>
            {loading && (
              <div className={classes.loadingCircleWrapper} data-testid="spinning-circle" data-cy="spinningCircle">
                <CircularProgress color="secondary" />
              </div>
            )}

            {!loading && ((searchText && searchResults.length) || !searchText) && (
              <FormGroup row={false}>
                {itemsOnCurrentPage &&
                  itemsOnCurrentPage.map(
                    (item, idx) =>
                      item && (
                        <FormControlLabel
                          data-cy="selectionItem"
                          key={idx}
                          label={
                            <span className={classes.labelWrapper}>
                              <span className={classes.selectLabel} data-testid="item-label">
                                {item[displayField]}
                              </span>
                              {shortcutCallback && (
                                <IconButton className={classes.hiddenButton} size="small" onClick={() => shortcutCallback?.(item.id)}>
                                  <Shortcut fontSize="small" color="primary" />
                                </IconButton>
                              )}
                            </span>
                          }
                          control={
                            <Checkbox
                              data-cy-checked={currentlyCheckedValues?.includes(item.id)}
                              checked={currentlyCheckedValues?.includes(item.id)}
                              onChange={selectItem}
                              value={item.id}
                              color="secondary"
                              inputProps={{ 'data-testid': `${name}-item-${idx}` } as any}
                              className={classes.selectElement}
                            />
                          }
                        />
                      )
                  )}
              </FormGroup>
            )}

            {!loading && searchText && !searchResults.length && <div className={classes.noItems}>No items found</div>}

            {!loading && !searchText && !items.length && <div className={classes.noItems}>Something went wrong</div>}
          </div>

          <Footer items={searchText ? searchResults : items} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </Paper>
      <Grid container={true} justifyContent="flex-start">
        <Typography className={classes.errorText} color="error" component="p" gutterBottom={true}>
          {shouldError() ? form.errors[name] : ''}
        </Typography>
      </Grid>
    </div>
  )
}

const MultiSelect = <GenericItem extends IBackendEntityType, FormValues extends object>(props: IProps<GenericItem, FormValues>) => {
  const { name, displayField, ...rest } = props
  const displayFieldWithDefault = displayField ?? 'name'

  return (
    <Field name={name}>
      {({ form }: FieldProps) => {
        return (
          <MultiSelectBody<GenericItem, FormValues> displayField={displayFieldWithDefault as string & keyof GenericItem} form={form} name={name} {...rest} />
        )
      }}
    </Field>
  )
}

export default MultiSelect
