import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FormikTextField from '../../components/form-elements/text-field'
import ThemeForm, { validateThemeItems } from '../../components/form-elements/theme'
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import { AlertContext } from '../../providers'
import useSharedFormStyles from '../../shared-styles/form.styles'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

interface IApplicationFormValues {
  basePath: string
  core: number
  createdAt: string
  name: string
  themeItems: Array<{ key: string; value: string }>
  updatedAt: string
}
interface IApplication {
  basePath: string
  coreId: number
  createdAt: string
  name: string
  theme: Array<{ key: string; value: string }>
  updatedAt: string
}

const ApplicationForm = () => {
  const classes = useSharedFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ applicationId: string }>()
  const applicationId = params.applicationId
  const isEditMode = applicationId !== 'new'

  const [currentApplication, setCurrentApplication] = useState<any | null>(null)
  const [showConfirmDeletionModal, toggleConfirmDeletionModal] = useToggle(false)
  const [cores, setCores] = useState<ICoreType[]>([])
  const [coresError, setCoresError] = useState<string>('')

  useDocumentTitle('Applications', isEditMode ? currentApplication?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    Api.get('/api/cores').then(coreData => {
      if (isSubscribed && !coreData.error) {
        setCores(coreData)
      } else {
        addAlert({ alertType: 'error', message: coreData?.message || 'Could not retrieve cores' })
        setCoresError(coreData.message)
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/applications/${applicationId}`).then(retrievedApplication => {
        redirectToListViewOnError(retrievedApplication, history, addAlert, '/applications')

        if (isSubscribed) {
          setCurrentApplication(retrievedApplication)
        }
      })
    }

    return () => {
      isSubscribed = false
    }
  }, [isEditMode, applicationId, history, addAlert])

  const saveApplication = async (values: IApplicationFormValues, helpers: FormikHelpers<IApplicationFormValues>) => {
    const themeItems =
      values.themeItems.length > 0
        ? values.themeItems.reduce((acc: any, curr: any) => {
            acc[curr.key] = curr.value
            return acc
          }, {})
        : {}
    const postObj: IApplication = {
      basePath: values.basePath,
      coreId: Number(values.core),
      createdAt: values.createdAt,
      name: values.name,
      theme: themeItems,
      updatedAt: Date(),
    }

    let submittedApplication

    try {
      if (isEditMode) {
        handleFormSubmit(await Api.put(`/api/applications/${applicationId}`, postObj), addAlert, history, null, values, helpers)
      } else {
        handleFormSubmit(
          (submittedApplication = await Api.post('/api/applications', postObj)),
          addAlert,
          history,
          `/applications/${submittedApplication.id}`,
          values,
          helpers
        )
      }
    } catch (e) {
      const message = (e as Error)?.message
      addAlert({ alertType: 'error', message: String(message) || 'We could not load the list of applications' })
    }

    helpers.setSubmitting(false)
  }

  const schema = Yup.object().shape({
    basePath: Yup.string().required('Required'),
    core: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    themeItems: Yup.array().of(
      Yup.object().shape({
        key: Yup.string()
          .min(3, 'Not Enough Characters')
          .required('Required'), // these constraints take precedence
        value: Yup.string()
          .min(3, 'Not Enough Characters')
          .required('Required'), // these constraints take precedence
      })
    ),
  })

  const deleteApplication = async () => {
    try {
      handleDelete(await Api.del('/api/applications', applicationId), addAlert, history, '/applications')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'We could not delete selected application(s)' })
    }
  }

  const initialValues =
    isEditMode && currentApplication
      ? {
          name: currentApplication.name,
          core: currentApplication.coreId,
          createdAt: currentApplication.createdAt,
          updatedAt: currentApplication.updatedAt,
          basePath: currentApplication.basePath,
          themeItems: currentApplication.theme
            ? Object.entries(currentApplication.theme).map(item => ({
                key: String(item[0]),
                value: String(item[1]),
              }))
            : [],
        }
      : {
          name: '',
          core: '',
          createdAt: Date(),
          updatedAt: Date(),
          basePath: '',
          themeItems: [],
        }

  return (
    <>
      {!coresError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveApplication} validationSchema={schema} validate={validateThemeItems}>
          {({ values, isValid, isSubmitting, dirty }: FormikProps<IApplicationFormValues>) => (
            <Form>
              <Card>
                <CardHeader title={isEditMode ? 'Edit Application' : 'Create Application'} data-cy="formTitle" />

                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="applicationName">
                      <FormikTextField initialized={isEditMode} name="name" label="Name" required={true} />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="basePath">
                      <FormikTextField
                        initialized={isEditMode}
                        name="basePath"
                        label="Base Path"
                        required={true}
                        helperText="e.g. https://carbon.meshify.com/"
                      />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="coreSelect">
                      <FormikTextField select={true} initialized={isEditMode} label="Core" name="core" hasEmptyOption={true} required={true}>
                        {cores && cores.length > 0 ? (
                          cores
                            .sort((a, b) => {
                              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a > b ? 1 : 0
                            })
                            .map((core: ICoreType) => (
                              <option key={core.id} value={core.id}>
                                {core.name}
                              </option>
                            ))
                        ) : (
                          <option>No Cores Found.</option>
                        )}
                      </FormikTextField>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Alert
                        alertType="warning"
                        message="Applications are how users interact with Cores. Create an application, set the core that it links to, and then provide the base path where it can be accessed."
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.denseFormSubheader} variant="subtitle1">
                        Theming
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={9} lg={6} xl={6}>
                      <ThemeForm data-cy="themeForm" themeItems={values.themeItems} />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                    Save
                  </Button>

                  <Button type="button" onClick={() => history.push('/applications')} data-cy="cancelBtn" data-testid="cancelBtn" color="inherit">
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmDeletionModal}>
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteApplication}
        closeModal={toggleConfirmDeletionModal}
        dialogTitle="Are you sure you want to delete this application?"
      />

      {coresError && (
        <PermissionsFormError
          errorMessages={combineErrorMessages(coresError)}
          formName="Applications Form"
          listViewRoute="/applications"
          hasGoBackButton={true}
        />
      )}
    </>
  )
}

export default ApplicationForm
