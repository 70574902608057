import { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { useStyles } from './styles'

export const TableLink: FC<{ dead?: boolean } & LinkProps> = ({ dead, to, children, ...rest }) => {
  const classes = useStyles()

  return dead ? (
    <span className={classes.deadLinkwrapper} onClick={e => e.stopPropagation()}>
      {children}
    </span>
  ) : (
    <div className={classes.wrapper}>
      <Link
        {...rest}
        className={classes.link}
        to={to}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {children}
      </Link>
    </div>
  )
}

export default TableLink
