import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    topMargin: {
      marginTop: theme.spacing(4),
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:visited': {
        color: theme.palette.primary.dark,
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
