import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress, Grid } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import Alert from '../../../components/alert'
import SingleFilterSelect from '../../../components/form-elements/filter-select/single-select'
import ConfirmDeletionModal from '../../../components/modal'
import FormError, { combineErrorMessages } from '../../../components/permissions-form-error/form-error-message'
import { AlertContext } from '../../../providers'
import Api from '../../../utils/api'
import { useDocumentTitle } from '../../../utils/hooks'
import useWhatsInsideStyles from '../whats-inside/whats-inside.styles'

import useStyles from './primary-contact.styles'

interface IProps {
  folderId: string | undefined
}
interface IPrimaryContactForm {
  primaryContactId: number
}

export const getFolderLocation = (currentFolder: IFolderType | null) => {
  let addressDetails = ''

  const address = currentFolder?.information?.address
  if (address && address.locality && address.region && address.country) {
    addressDetails = `${address.locality}, ${address.region}, ${address.country}`
  } else if (address && address.locality && address.region && !address.country) {
    addressDetails = `${address.locality}, ${address.region}`
  } else if (address && address.locality && !address.region && !address.country) {
    addressDetails = address.locality
  } else if (address && address.locality && !address.region && address.country) {
    addressDetails = `${address.locality}, ${address.country}`
  }

  return addressDetails
}

export const getDisplayName = (user: IUserType): string => {
  let displayName

  if (user?.information?.first && user?.information?.last) {
    displayName = `${user?.information?.first} ${user?.information?.last}`
  } else {
    displayName = user.email
  }

  return displayName
}

const PrimaryContact = (props: IProps) => {
  const { folderId } = props
  const { addAlert } = useContext(AlertContext)
  const history = useHistory()
  const classes = useStyles()
  const whatsInsideClasses = useWhatsInsideStyles()

  const [primaryContact, setPrimaryContact] = useState<IUserType | undefined>(undefined)
  const [folder, setFolder] = useState<IFolderType | null>(null)
  const [folderError, setFolderError] = useState<string>('')
  const [folderLoading, setFolderLoading] = useState<boolean>(false)

  const [users, setUsers] = useState<IUserType[]>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [usersError, setUsersError] = useState<string>('')

  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  useDocumentTitle('Folders', folder?.name)

  useEffect(() => {
    if (folder) {
      setUsersLoading(true)

      // only get users who belong to this folder
      // and have an email address since primary contact should have an email
      Api.post('/api/users/query', {
        filters: [
          { fieldName: 'email', operator: 'neq', value: '' },
          { fieldName: 'folders', operator: 'contains', value: folder.id },
        ],
        type: 'and',
      }).then(res => {
        setUsersLoading(false)
        if (!res.error) {
          setUsers(res?.results)
        } else {
          setUsersError(res?.detail ? res.detail : 'Could not load users')
        }
      })
    }
  }, [refreshCounter, folder])

  useEffect(() => {
    if (folderId) {
      setFolderLoading(true)
      Api.get(`/api/folders/${folderId}`).then(folderRes => {
        setFolderLoading(false)

        if (!folderRes.error) {
          setFolder(folderRes)

          if (folderRes?.primaryContactUserId) {
            Api.get(`/api/users/${folderRes.primaryContactUserId}`).then(res => {
              if (!res.error) {
                setPrimaryContact(res)
              } else {
                setPrimaryContact(undefined)
              }
            })
          } else if (folderRes.primaryContactUserId === 0) {
            setPrimaryContact(undefined)
          }
        } else {
          setFolderError(folderRes?.detail ? folderRes.detail : 'Could not load this folder')
        }
      })
    }
  }, [folderId, refreshCounter, addAlert])

  const initialValues = {
    primaryContactId: primaryContact ? primaryContact.id : 0,
  }

  const schema = Yup.object().shape({
    primaryContactId: Yup.number().required('required'),
  })

  const submitPrimaryContact = async (values: IPrimaryContactForm, actions: FormikHelpers<IPrimaryContactForm>) => {
    actions.setSubmitting(true)
    const res = await Api.post(`/api/v2/folders/${folderId}/primary_contact`, {
      folderId,
      userId: values.primaryContactId,
    })
    actions.setSubmitting(false)

    if (res.error) {
      const errorMessage = res?.message ? `Primary contact was not added. ${res.message}` : 'Primary contact was not added'
      addAlert({ alertType: 'error', message: errorMessage })
    } else {
      addAlert({ alertType: 'success', message: 'Primary contact was successfully added' })
      setRefreshCounter(refreshCounter + 1)
    }
  }

  const deletePrimaryContact = async () => {
    const res = await Api.post(`/api/v2/folders/${folderId}/primary_contact`, {
      folderId,
      userId: 0,
    })
    setShowDeleteModal(false)

    if (res.error) {
      const errorMessage = res?.message ? `Primary contact was not deleted. ${res.message}` : 'Primary contact was not deleted'
      addAlert({ alertType: 'error', message: errorMessage })
    } else {
      addAlert({ alertType: 'success', message: 'Primary contact was successfully deleted' })
      setRefreshCounter(refreshCounter + 1)
    }
  }

  return (
    <>
      {(usersError || folderError) && (
        <div className={classes.errorWrapper}>
          <FormError formName="Primary Contanct Tab" errorMessages={combineErrorMessages(usersError, folderError)} hasGoBackButton={true} />
        </div>
      )}

      {(usersLoading || folderLoading) && (
        <Grid container={true} justifyContent="center" className={classes.loadingSpinner}>
          <CircularProgress color="secondary" data-testid="loading" />
        </Grid>
      )}

      {!usersError && !folderError && !usersLoading && !folderLoading && (
        <>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <p className={whatsInsideClasses.business}>Business</p>
              <p className={whatsInsideClasses.address}>{folder?.name}</p>
              <p className={whatsInsideClasses.address}>{folder?.information?.address?.address}</p>
              <p className={whatsInsideClasses.address}>{getFolderLocation(folder)}</p>
              <p className={whatsInsideClasses.address}>{folder?.information?.address?.code}</p>
            </Grid>

            <Grid item={true} xs={12}>
              <div className={classes.info}>
                {primaryContact ? (
                  <div className={classes.primaryContactDescription}>
                    <b className={classes.primaryContactEmail}>{getDisplayName(primaryContact)}</b>
                    &nbsp;
                    <span>
                      is the primary contact for this location. If you would like to select somebody else, please select a different user from the list below
                    </span>
                  </div>
                ) : users?.length ? (
                  'This location currently does not have a primary contact. If you would like to add a primary contact to this location, please select a user from the list below'
                ) : (
                  'This location currently does not have a primary contact'
                )}
              </div>
            </Grid>

            <Grid item={true} xs={12}>
              {users?.length > 0 ? (
                <Formik initialValues={initialValues} onSubmit={submitPrimaryContact} validationSchema={schema} enableReinitialize={true}>
                  {(formikBag: FormikProps<IPrimaryContactForm>) => {
                    const { isValid, isSubmitting, dirty } = formikBag

                    return (
                      <Form>
                        <Grid container={true} spacing={2}>
                          <Grid item={true} xs={12} sm={12} md={8} lg={4} xl={4}>
                            <SingleFilterSelect<IUserType, IPrimaryContactForm>
                              name="primaryContactId"
                              label="Please select a primary contact"
                              required={true}
                              items={users}
                              loading={usersLoading}
                              displayField="email"
                              initialValue={primaryContact?.id}
                            />
                          </Grid>

                          <Grid item={true} xs={12}>
                            <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} size="large">
                              {primaryContact ? 'Update Primary Contact' : 'Add Primary Contact'}
                            </Button>

                            <Button type="button" color="inherit" onClick={() => history.push('/folders')}>
                              Cancel
                            </Button>

                            {primaryContact && (
                              <Button type="button" onClick={() => setShowDeleteModal(true)} className={classes.deleteBtn}>
                                Remove Primary Contact
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              ) : (
                <Grid item={true} xs={12} sm={12} md={12} lg={10} xl={7}>
                  <Alert
                    alertType="warning"
                    message="This folder does not have users that can be selected as a primary contact. Please create users that belong to this folder first"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <ConfirmDeletionModal
            show={showDeleteModal}
            confirmModalAction={deletePrimaryContact}
            closeModal={() => setShowDeleteModal(false)}
            dialogContent="You can always add another primary contact later"
            dialogTitle="Are you sure you want to delete primary contact for this location?"
          />
        </>
      )}
    </>
  )
}

export default PrimaryContact
