import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'
import IconPreview from './icon-preview'

const materialIcons = [
  { shape: 'square', icon: 'CropSquare' },
  { shape: 'gps', icon: 'LocationOn' },
  { shape: 'star', icon: 'Star' },
  { shape: 'fire', icon: 'WhatsHot' },
  { shape: 'warning', icon: 'Warning' },
  { shape: 'blocked', icon: 'Block' },
  { shape: 'cross', icon: 'Clear' },
  { shape: 'triangle', icon: 'PlayArrow' },
  { shape: 'circle', icon: 'FiberManualRecord' },
]

const IconsListView = () => {
  const [icons, setIcons] = useState<IIconType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const searchResultsHook = useSearchData()
  const history = useHistory()
  const classes = useTableStyles()
  const { addAlert } = useContext(AlertContext)

  useDocumentTitle('Icons')

  const columns = [
    { name: 'Name', options: { filter: true, sort: true } },
    { name: 'Level', options: { filter: true, sort: true } },
    { name: 'Shape', options: { filter: true, sort: true } },
    { name: 'Color', options: { filter: true, sort: true } },
    { name: 'Preview', options: { filter: true, sort: false } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchIconData = async () => {
    const retrievedIcons = await Api.get('/api/icons')

    if (retrievedIcons.error) {
      addAlert({ alertType: 'error', message: retrievedIcons?.message || 'Could not load icons' })
      return Promise.reject(retrievedIcons)
    }

    const allRowData: React.ReactNode[][] = retrievedIcons.map((icon: IIconType) => {
      const iconImage = materialIcons
        .map((materialIcon: any) => {
          if (icon.shape === materialIcon.shape) {
            return <IconPreview key={icon.name} isListView={true} color={icon.color} shape={icon.shape} />
          }
          return null
        })
        .filter(item => item != null)

      return [icon.name, icon.level, icon.shape, icon.color, iconImage, convertTimestampToDate(icon.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setIcons(retrievedIcons) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchIconData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  const NewIconLink = forwardRef((linkProps: any, ref: any) => <Link to="/icons/new" ref={ref} {...linkProps} />)

  return (
    <Grid className={classes.tableContainer} data-testid="iconsGrid" container={true} justifyContent="flex-end" data-cy="iconsList">
      <Grid className="above-table-container" container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewIconLink}
              entityName="Icons"
              buttonName="Add Icon"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Icons"
          entityDeleteEndpoint="/api/icons/"
          idList={icons != null ? icons.map((i: IIconType) => ({ id: i.id })) : []}
          refresh={() => setRefreshCounter(refreshCounter + 1)}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          preserveSearchResults={{ ...searchResultsHook }}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => {
              handleCellClick(history, icons, 'icons', cellMeta, searchResultsHook.abort)
            },
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="icons"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!icons?.find(icon => icon.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default IconsListView
