import { grey, orange } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    conditionCodemirror: {
      '& > :first-child': {
        height: '35px',
        border: '1px solid ' + grey[400],
      },
    },
    prettyTestOutputWrapper: {
      width: '100%',
      height: '100px',
      border: '1px solid' + grey[400],
      borderRadius: '4px',
      paddingLeft: '10px',
      overflowY: 'scroll',
    },
    testButton: {
      marginBottom: '10px',
    },
    helpText: {
      margin: '4px',
      float: 'right',
      fontSize: '.8 rem',
      color: orange[900],
    },
    docsLinkWrapper: {
      marginTop: '5px',
    },
    divider: {
      marginTop: '25px',
    },
    testHeader: {
      textTransform: 'capitalize',
      color: grey[600],
      fontWeight: 600,
      marginTop: '63px',
    },
    topMargin: {
      marginTop: theme.spacing(),
    },
    updatedBy: {
      display: 'inline-block',
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
