import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { Field, FieldArray, FieldProps, FormikProps } from 'formik'
import { get } from 'lodash'
import moment from 'moment'

import Alert from '../../../components/alert'

export interface ChannelOverrideType {
  channelName: string
  value: boolean | string | number
  timeStamp: string
}

export interface IChannelOverrideItems {
  channelOverrideItems: ChannelOverrideType[]
}

type IChannelFormProps = IChannelOverrideItems & {
  nodeTypeChannels: string[]
}

const channelOverrideObject = () => {
  return {
    channelName: '',
    value: '',
    timeStamp: moment().format(),
  }
}

export const fieldError = (
  { touched, errors }: Pick<FormikProps<IChannelOverrideItems>, 'touched' | 'errors'>,
  index: number,
  key: keyof ChannelOverrideType
) => {
  if (get(touched, `channelOverrideItems.${index}`) && get(errors, `channelOverrideItems.${index}`)) {
    /* Check if field item has been touched before we deem it to have an error */
    const touchedItem = get(touched, `channelOverrideItems.${index}`)
    /* Check if field item has errors associated with it */
    const errorItem = get(errors, `channelOverrideItems.${index}`)
    /* Check if the key is touched */
    const wasTouched = touchedItem && touchedItem[key]
    /* Check if there is an error associated with our particular key */
    const error = errorItem && errorItem[key]
    return Boolean(wasTouched && error)
  } else {
    return false
  }
}

const ChannelOverride = ({ channelOverrideItems, nodeTypeChannels }: IChannelFormProps & IChannelOverrideItems) => {
  // channelNames will exist only if a nodeType has been selected,
  // and then only if channels exist for the selected nodeType
  let channelNamesExist = false

  // display a message if no channels exist to override
  if (nodeTypeChannels) {
    if (nodeTypeChannels.length > 0) {
      channelNamesExist = true
    }
  }

  if (channelNamesExist) {
    return (
      <FieldArray name="channelOverrideItems">
        {arrayHelpers => (
          <>
            {channelOverrideItems && channelOverrideItems.length > 0 ? (
              channelOverrideItems.map((item: any, index: number) => (
                <Grid container={true} spacing={3} justifyContent="space-between" data-testid="channel-field-container" key={index}>
                  <Grid item={true} container={true} spacing={3} xs={9}>
                    <Grid item={true} xs={4}>
                      <Field name={`channelOverrideItems.${index}.channelName`}>
                        {({ field, form }: FieldProps<any>) => {
                          const { touched, errors } = form
                          const fieldDidError = fieldError({ touched, errors }, index, 'channelName')

                          return (
                            <>
                              <TextField
                                {...field}
                                error={fieldDidError}
                                variant="outlined"
                                label="Name"
                                select={true}
                                onChange={field.onChange}
                                fullWidth={true}
                              >
                                {nodeTypeChannels.map((option: any) => {
                                  return (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                })}
                              </TextField>

                              {fieldDidError && (
                                <FormHelperText id="component-error-text">{get(errors, `channelOverrideItems.${index}.channelName`, '')}</FormHelperText>
                              )}
                            </>
                          )
                        }}
                      </Field>
                    </Grid>

                    <Grid item={true} xs={4}>
                      <Field name={`channelOverrideItems.${index}.value`}>
                        {({ field, form }: FieldProps<any>) => {
                          const { touched, errors } = form
                          const fieldDidError = fieldError({ touched, errors }, index, 'value')

                          return (
                            <>
                              <TextField {...field} error={fieldDidError} variant="outlined" label="Test Value" placeholder="Enter a value" fullWidth={true} />

                              {fieldDidError && (
                                <FormHelperText data-testid="component-error-text">{get(errors, `channelOverrideItems.${index}.value`, '')}</FormHelperText>
                              )}
                            </>
                          )
                        }}
                      </Field>
                    </Grid>

                    <Grid item={true} xs={4}>
                      <Field name={`channelOverrideItems.${index}.timeStamp`}>
                        {({ field, form }: FieldProps<any>) => {
                          const { touched, errors } = form
                          const fieldDidError = fieldError({ touched, errors }, index, 'timeStamp')

                          return (
                            <>
                              <TextField {...field} error={fieldDidError} variant="outlined" label="Timestamp" fullWidth={true} />

                              {fieldDidError && (
                                <FormHelperText id="component-error-text">{get(errors, `channelOverrideItems${index}.timeStamp`, '')}</FormHelperText>
                              )}
                            </>
                          )
                        }}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid item={true} container={true} spacing={3} xs={3}>
                    <Grid item={true} xs={6}>
                      <Fab onClick={() => arrayHelpers.remove(index)} size="small" data-testid="channel-remove-field" aria-label="Remove">
                        <RemoveIcon />
                      </Fab>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <Fab onClick={() => arrayHelpers.push(channelOverrideObject())} size="small" data-testid="channel-add-field" aria-label="Add">
                        <AddIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Button data-testid="addChannelOverride" color="secondary" variant="outlined" onClick={() => arrayHelpers.push(channelOverrideObject())}>
                Add Channel Override
              </Button>
            )}
          </>
        )}
      </FieldArray>
    )
  } else {
    return <Alert alertType="warning" message="No channels exist to override. Please ensure that you have selected a Node Type in the Definitions section." />
  }
}

export default ChannelOverride
