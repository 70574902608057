import { QuestionMark } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Grid, Tooltip } from '@mui/material'
import { FastField, FieldProps } from 'formik'

const NodeProperties = () => {
  return (
    <>
      <FastField name="isAmmonia">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isAmmonia" checked={field != null && field.value === true} {...field} color="secondary" value="isAmmonia" />}
                labelPlacement="end"
                label="Is Ammonia"
              />
            </Grid>
          )
        }}
      </FastField>
      <FastField name="isBvs">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isBvs" checked={field != null && field.value === true} {...field} color="secondary" value="isBvs" />}
                labelPlacement="end"
                label="Is Shutoff Valve"
              />
            </Grid>
          )
        }}
      </FastField>
      <FastField name="isGateway">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isGateway" checked={field != null && field.value === true} {...field} color="secondary" value="isGateway" />}
                labelPlacement="end"
                label="Is Gateway"
              />
            </Grid>
          )
        }}
      </FastField>
      <FastField name="isLora">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isLora" checked={field != null && field.value === true} {...field} color="secondary" value="isLora" />}
                labelPlacement="end"
                label="Is Lora"
              />
            </Grid>
          )
        }}
      </FastField>
      <FastField name="isPhysical">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} container={true} alignItems="center" xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isPhysical" checked={field != null && field.value === true} {...field} color="secondary" value="isPhysical" />}
                labelPlacement="end"
                label="Is Physical"
              />
              <Tooltip title="If this node type belongs to a physical device. Gateways are physical. Attributes/weather information are not physical">
                <QuestionMark color="secondary" fontSize="small" />
              </Tooltip>
            </Grid>
          )
        }}
      </FastField>
      <FastField name="isSensor">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isSensor" checked={field != null && field.value === true} {...field} color="secondary" value="isSensor" />}
                labelPlacement="end"
                label="Is Sensor"
              />
            </Grid>
          )
        }}
      </FastField>
      <FastField name="isTing">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isTing" checked={field != null && field.value === true} {...field} color="secondary" value="isTing" />}
                labelPlacement="end"
                label="Is Ting"
              />
            </Grid>
          )
        }}
      </FastField>
      <FastField name="isV3">
        {({ field }: FieldProps) => {
          return (
            <Grid item={true} xs={6} md={4}>
              <FormControlLabel
                control={<Checkbox data-cy="isV3" checked={field != null && field.value === true} {...field} color="secondary" value="isV3" />}
                labelPlacement="end"
                label="Is V3"
              />
            </Grid>
          )
        }}
      </FastField>
    </>
  )
}
export default NodeProperties
