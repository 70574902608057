import { Dispatch, SetStateAction } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import useTableStyles from '../../shared-styles/table.styles'

type FilterFooterProps<T> = {
  setSearchText: (text: string) => void // comes from usePageHook
  setCurrentFilters: Dispatch<SetStateAction<Partial<T>>>
  applyFilters?: ((...args: any[]) => any) | undefined
}

/**
 * This is used just for server-side tables that have filter panels
 * For client-side paginated tables, filtering is entirely handled on front end by mui-datatables
 * and no custom components are needed there
 */
// eslint-disable-next-line func-style
export function ServerSideFilterFooter<T>(props: FilterFooterProps<T>) {
  const classes = useTableStyles()

  const { setSearchText, setCurrentFilters, applyFilters } = props

  return (
    <Grid className={classes.applyButtonContainer} container={true} justifyContent="flex-end">
      <Grid item={true} xs={2}>
        <Button
          data-testid="filter-reset-btn"
          variant="outlined"
          color="primary"
          onClick={() => {
            // Clear filters on reset
            setSearchText('')
            setCurrentFilters({})
          }}
        >
          Reset
        </Button>
      </Grid>

      <Grid item={true}>
        <Button
          data-testid="filter-apply-btn"
          variant="outlined"
          color="secondary"
          onClick={() => {
            // Set next filters to current filters and
            // ensure search text is empty to avoid confusion in POST
            setSearchText('')
            applyFilters?.()
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  )
}
