import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    protectMobileLink: {
      alignItems: 'center',
    },
    qrContainer: {
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
