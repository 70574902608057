import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    channelFormIcon: {
      margin: theme.spacing(),
    },
    channelsContainer: {
      '& p': {
        textAlign: 'right',
      },
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
