import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { debounce, isEmpty } from 'lodash'
import { MUIDataTableColumn } from 'mui-datatables'

import AddButton from '../../components/add-entity-button'
import { ServerSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import { ServerSideFilterFooter } from '../../components/table-filter-footer/filter-footer'
import TableLink from '../../components/table-link'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'
import { useDocumentTitle, usePagination, useTableState } from './../../utils/hooks'

type FilterType = { fieldName: 'type'; operator: 'eq'; value: string }
export type FolderFilterType = { type: FilterType }

/** generate values that we will pass to filters array in POST request payload to get table filters */
export const getFilterListValue = (val: string) => {
  if (val === 'All') {
    return []
  } else if (val === 'No Type') {
    return ['']
  } else {
    return [val]
  }
}

/** the data we send to backend and the datsa we show in Folder Type dropdown in fitlers panel is different
 * we need to map it
 */
export const getFilterDropdownValue = (val: any) => {
  if (val === '') {
    return 'No Type'
  } else if (val === undefined) {
    return 'All'
  } else {
    return val
  }
}

const FoldersListView = () => {
  const [currentFolders, setCurrentFolders] = useState<IFolderType[] | null>(null)
  const [currentFilters, setCurrentFilters] = useState<Partial<FolderFilterType>>({})

  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)
  const { addAlert } = useContext(AlertContext)
  const classes = useTableStyles()
  const pageHook = usePagination()
  const history = useHistory()

  useDocumentTitle('Folders')

  const typeFilterOptions = ['All', 'No Type', 'Client Company', 'Participant', 'Program']

  /* 
    Wrapping the filterList[index] value in an array is unfortunately necessary, I have no idea why its necessary, but problems show up
    when you have values with length greater than one.
   */
  const columns: MUIDataTableColumn[] = [
    { name: 'Name', options: { filter: false, sort: true } },
    { name: '# Users', options: { searchable: false, filter: false, sort: false } },
    { name: 'Parent Folder', options: { searchable: false, filter: false, sort: false } },
    {
      name: 'Folder Type',
      options: {
        searchable: false,
        filter: true,
        sort: false,
        customFilterListOptions: {
          update: (val: any) => {
            setCurrentFilters(() => ({ type: undefined }))
            return val
          },
          // this is to improve chips for selected filter
          render: (arr: string[]) => (arr[0] === '' ? 'No Type' : arr[0]),
        },
        filterType: 'custom' as const,
        // this is the state of the current filter for this column, mui-datatables will show these values as chips
        filterList: currentFilters?.type?.value != null ? [currentFilters?.type?.value] : [],
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            return (
              <TextField
                label="Folder Type"
                select={true}
                inputProps={{
                  'data-testid': 'folder-filter-type',
                }}
                value={getFilterDropdownValue(filterList[index][0])}
                size="small"
                fullWidth={true}
                variant="standard"
                onChange={event => {
                  filterList[index] = getFilterListValue(event?.target?.value)
                  onChange(filterList[index], index, column)
                }}
              >
                {typeFilterOptions.map((typeName, idx) => {
                  return (
                    <MenuItem key={idx} value={typeName}>
                      {typeName}
                    </MenuItem>
                  )
                })}
              </TextField>
            )
          },
        },
      },
    },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const sanitizeColName = (colName: string) => {
    switch (colName) {
      case 'Name':
        return 'name'
      case 'Modified':
        return 'updatedAt'
      default:
        return ''
    }
  }

  const fetchPaginatedFoldersData = async () => {
    const { searchText, offset, limit, currentSort, sortDir } = pageHook

    let folders: IFolderType[]

    const currentFilterArray: FilterType[] = Object.values(currentFilters).filter(f => f !== undefined) as FilterType[]

    const queryBody: IQueryBody = {
      type: 'or',
      first: limit,
      orderBy: currentSort ? sanitizeColName(currentSort) : undefined,
      orderDirection: sortDir !== 'none' ? sortDir : undefined,
      after: offset ? btoa(String(offset)) : undefined,
      filters:
        currentFilterArray.length > 0
          ? currentFilterArray
          : [
              { fieldName: 'metadata', operator: 'like', value: searchText },
              { fieldName: 'name', operator: 'like', value: searchText },
            ],
    }

    const res = await Api.post('/api/folders/query?detail=true', queryBody)

    if (res.error) {
      folders = []
      return Promise.reject(res)
    }

    const { totalCount, results } = res
    folders = results
    const totalEntityCount = Number(totalCount)

    const paginatedData: React.ReactNode[][] = folders.map(folder => {
      const parentFolderLink = (
        <TableLink
          data-cy="parentFolderLink"
          to={`/folders/${folder?.parentFolderId}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {folder?.parentFolderName}
        </TableLink>
      )

      return [folder.name, folder.usercount, parentFolderLink, folder.type, convertTimestampToDate(folder.updatedAt)]
    })

    return Promise.resolve({
      data: paginatedData,
      setEntities: () => setCurrentFolders(folders),
      setTotalRows: () => pageHook.setTotalRows(Number(totalEntityCount)),
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetchPaginatedFoldersData = useCallback(fetchPaginatedFoldersData, [
    pageHook.searchText,
    pageHook.pageNumber,
    pageHook.rowsPerPage,
    pageHook.currentSort,
    pageHook.sortDir,
    pageHook.rowsDeletedCounter,
    currentFilters.type,
    addAlert,
  ])

  const tableState = useTableState(memoizedFetchPaginatedFoldersData)

  const NewFolderLink = forwardRef((linkProps: any, ref: any) => <Link to="/folders/new" ref={ref} {...linkProps} />)

  return (
    <Grid justifyContent="center" container={true}>
      <Grid className={classes.tableContainer} data-cy="foldersList" container={true} justifyContent="flex-end">
        <Grid container={true} justifyContent="flex-end">
          <AddButton
            userPermissions={userPermissions}
            newComponentLink={NewFolderLink}
            entityName="Folders"
            buttonName="Add Folder"
            dataCy="addBtn"
            dataTestId="addBtn"
          />
        </Grid>

        <Grid item={true} xs={12}>
          <ServerSidePaginatedTable
            title="Folders"
            entityDeleteEndpoint="/api/folders"
            idList={currentFolders != null ? currentFolders.map(folder => ({ id: folder.id })) : []}
            pagination={{ ...pageHook }}
            columns={columns}
            loading={tableState.loading}
            error={tableState.error}
            data={tableState.data}
            refresh={pageHook.refreshTable}
            options={{
              onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, currentFolders, 'folders', cellMeta, pageHook.abort),
              searchOpen: isEmpty(currentFilters) === true,
              confirmFilters: true,
              serverSide: true,
              filter: true,
              sortOrder: pageHook.sortDir !== 'none' ? { name: pageHook.currentSort, direction: pageHook.sortDir } : undefined,
              onSearchChange: debounce((text: string | null) => {
                // Clear filters to avoid logic confusion in rerender
                setCurrentFilters({})
                pageHook.setSearchText(text ? encodeURI(text.trim()) : '')
              }, 1000),
              onFilterConfirm: (filterList: string[][]) => {
                const folderType = filterList[3][0]
                setCurrentFilters({
                  type: folderType !== undefined ? { fieldName: 'type', operator: 'eq', value: folderType === 'No Type' ? '' : folderType } : undefined,
                })
              },
              customFilterDialogFooter: (filterList, applyFilters) => {
                return (
                  <ServerSideFilterFooter<FolderFilterType>
                    setSearchText={pageHook.setSearchText}
                    setCurrentFilters={setCurrentFilters}
                    applyFilters={applyFilters}
                  />
                )
              },
              customSearchRender: (searchText, handleSearch) => {
                return <CustomSearchBox searchText={searchText} handleSearch={handleSearch} entityPath="folders" abortToken={pageHook.abort} />
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FoldersListView
