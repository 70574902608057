import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
    actionButtons: {
      marginTop: theme.spacing(),
    },
    cardActions: {
      flex: 'initial',
    },
    btnMargin: {
      marginLeft: '10px',
      [theme.breakpoints.only('xs')]: {
        margin: '0 0 2px 0',
      },
    },
    docsLinkWrapper: {
      margin: '7px 0',
    },
    clipboard: {
      paddingTop: 20,
      margin: 10,
    },
    densePadding: {
      paddingTop: '0px !important',
    },
    nodeFolderAddress: {
      margin: 0,
    },
    protectPhoto: {
      maxWidth: '100%',
      maxHeight: '400px',
    },
    nodeParentButton: {
      alignSelf: 'center',
    },
    nodeParentFakeContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    nodeParentFakePaper: {
      display: 'flex',
      height: '440px',
      alignContent: 'center',
      justifyContent: 'center',
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
