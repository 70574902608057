import { createContext, useEffect, useMemo, useState } from 'react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { createTheme, darken, lighten, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles'
import { createGenerateClassName, StylesProvider } from '@mui/styles'

const lightModePrimaryColor = '#00AFE1'
const lightModeSecondaryColor = '#FF7832'

const darkModePrimaryColor = '#FF7832'
const darkModeSecondaryColor = '#00AFE1'
const darkModeBackgroundColor = '#343333'
const darkModePaperColor = '#424242'

export const staticLightPalette = {
  mode: 'light' as const,
  primary: {
    main: lightModePrimaryColor,
    light: lightModePrimaryColor,
    dark: lightModePrimaryColor,
    contrastText: 'white',
  },
  secondary: {
    main: lightModeSecondaryColor,
    light: '#f28735',
    dark: '#f28735',
    contrastText: 'white',
  },
  common: {
    white: '#fff',
    black: '#252525',
    lightGrey: '#c6c4c4',
  },
  background: {
    default: 'rgb(255,255,255)',
    paper: 'rgb(254,254,254)',
  },
  warning: {
    main: '#FB8C00',
    light: '#fff176',
    dark: '#fff176',
    contrastText: '#fff176',
  },
}

export const staticDarkPalette = {
  // type: 'dark' as 'dark',
  mode: 'dark' as const,
  primary: {
    main: darkModePrimaryColor,
    light: '#ffb072',
    dark: '#d75f00',
    contrastText: 'white',
  },
  secondary: {
    main: darkModeSecondaryColor,
    light: darkModeSecondaryColor,
    dark: darkModeSecondaryColor,
    contrastText: darkModeSecondaryColor,
  },
  background: {
    default: darkModeBackgroundColor,
    paper: darkModePaperColor,
  },
  common: {
    white: '#fff',
    black: '#252525',
    darkGrey: '#757575',
    lightGrey: '#c6c4c4',
  },
  warning: {
    main: '#fff176',
    light: '#fff176',
    dark: '#fff176',
    contrastText: '#fff176',
  },
}

const overrides = (staticPalette: Partial<Theme['palette']>) => {
  return {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: 'white',
        },
        containedSecondary: {
          color: staticPalette.background?.default,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          maxWidth: 150,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '0.8125rem',
          padding: '0.7rem',
        },
        head: {
          color: staticPalette.mode === 'light' ? lighten(staticPalette?.common?.black ?? '#fff', 0.1) : darken(staticPalette?.common?.white ?? '#fff', 0.1),
          fontSize: '0.75rem',
        },
        stickyHeader: {
          backgroundColor: `${darken('#9e9e9e', 0.15)}`,
          borderRadius: `0px 0px 2px 2px`,
          fontWeight: 800,
          padding: '4px',
          color: staticPalette?.common?.white ?? 'white',
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        // table filter panel width and position
        filterPaper: {
          left: '900px',
          width: '600px',
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MUIDataTableFilter: {
      styleOverrides: {
        resetLink: {
          // Themes need to be static and this seems to be the only way to hide the filter.
          visibility: 'hidden',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          '&:nth-of-type(1)': {
            width: 50,
          },
        },
        checked: {
          color: `${staticPalette.secondary?.main} !important`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: staticPalette.mode === 'dark' ? staticPalette?.common?.white : staticPalette?.common?.black,
          fontSize: '0.75rem',
        },
        caption: {
          fontSize: '0.75rem',
        },
        selectLabel: {
          fontSize: '0.75rem',
        },
      },
    },
    // set styles for MuiDatepicker
    MuiPickersToolbarText: {
      styleOverrides: {
        toolbarTxt: {
          color: staticPalette.mode === 'light' ? lighten(staticPalette?.common?.black ?? '#fff', 0.2) : darken(staticPalette?.common?.white ?? '#fff', 0.2),
        },
        toolbarBtnSelected: {
          color: staticPalette.mode === 'light' ? lighten(staticPalette?.common?.black ?? '#fff', 0.2) : darken(staticPalette?.common?.white ?? '#fff', 0.2),
        },
      },
    },
    // set styles for MuiDatepicker
    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          color: staticPalette.mode === 'light' ? lighten(staticPalette?.common?.black ?? '#fff', 0.2) : staticDarkPalette.common.darkGrey,
        },
      },
    },
    // set styles for MuiDatepicker
    MuiPickersClockNumber: {
      styleOverrides: {
        clockNumberSelected: {
          color: staticPalette.mode === 'light' ? lighten(staticPalette?.common?.black ?? '#fff', 0.2) : staticDarkPalette.common.darkGrey,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        // chips that we use in nodetypes and domain list view tables
        root: {
          margin: '1px 5px 0 1px',
          color: staticPalette?.primary?.main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8em',
        },
      },
    },
  }
}

// In the future, we can abstract this to a theme.js file
export const lightTheme = () =>
  createTheme({
    palette: {
      ...staticLightPalette,
    },
    components: overrides(staticLightPalette),
  })

// In the future, we can abstract this to a theme.js file
export const darkTheme = () =>
  createTheme({
    palette: {
      ...staticDarkPalette,
    },
    components: overrides(staticDarkPalette),
  })

export const themes = {
  light: lightTheme(),
  dark: darkTheme(),
}

type ThemeContextType = {
  themeKey: keyof typeof themes
  setTheme?: (themeKey: keyof typeof themes) => void
}

export const ThemeContext = createContext<ThemeContextType>({
  themeKey: 'light',
})

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

// we added custom prefix to material classnames
// because autogenerated classNames were for some reason same as some classes that mui-datatables package generated
const generateClassName = createGenerateClassName({
  productionPrefix: 'muiCustom',
})

export const MaterialThemeProvider: React.FC = ({ children }) => {
  const [themeKey, setTheme] = useState<keyof typeof themes>((window?.localStorage?.getItem('admin_theme') as keyof typeof themes) ?? ('light' as const))

  const theme = useMemo(() => {
    return themes[themeKey]
  }, [themeKey])
  const localStorageTheme = localStorage?.getItem('admin_theme') ?? 'light'

  useEffect(() => {
    setTheme(localStorageTheme as keyof typeof themes)
  }, [localStorageTheme])

  useEffect(() => {
    if (theme) {
      document.body.style.backgroundColor = theme?.palette?.background?.default
    }
  }, [theme])

  return (
    <StyledEngineProvider injectFirst={true}>
      <CacheProvider value={muiCache}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeContext.Provider
            value={{
              themeKey,
              setTheme: (currentThemeKey: keyof typeof themes) => {
                window?.localStorage.setItem('admin_theme', currentThemeKey)
                window?.location?.reload()
              },
            }}
          >
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
          </ThemeContext.Provider>
        </StylesProvider>
      </CacheProvider>
    </StyledEngineProvider>
  )
}
