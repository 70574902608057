import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    menuItem: {
      textDecoration: 'none',
      outline: 'none',
      color: theme.palette.text.primary,
    },
    dashboardLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    unimpersonateMenuItem: {
      color: theme.palette.secondary.main,
    },
  })

export const useStyles = makeStyles(styles)
