import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedFormStyles } from '../../../shared-styles/form.styles'
import { styles as sharedTableStyles } from '../../../shared-styles/table.styles'

export const styles = (theme: Theme) =>
  createStyles({
    noItems: {
      fontStyle: 'italic',
    },
    impersonateLink: {
      float: 'right',
      cursor: 'pointer',
    },
    whatsInsideItemWrapper: {
      marginBottom: '0.7rem',
    },
    usersContainer: {
      overflowY: 'auto',
      padding: '0.7rem',
      height: '400px',
    },
    nodesContainer: {
      width: '100%',
    },
    nodesListWrapper: {
      maxHeight: '540px',
      overflowY: 'auto',
      background:
        theme.palette.mode === 'dark'
          ? `linear-gradient(to top, rgb(76, 76, 76)  5%, rgba(76, 76, 76, 0)) local, 
             linear-gradient(to top, rgba(76, 76, 76, 0), rgb(76, 76, 76) 100%) 100% center local, 
             linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 5%) scroll, 
             linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 5%) scroll`
          : `linear-gradient(to top, rgb(255, 255, 255) 5%, rgba(255, 255, 255, 0)) local, 
             linear-gradient(to top, rgba(255, 255, 255, 0), rgb(255, 255, 255) 100%) 100% center local, 
             linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 5%) scroll, 
             linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 5%) scroll`,
    },
    spinnerWrapper: {
      textAlign: 'center',
      width: '100%',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    accordionWrapper: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    accordionTitle: {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[600],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    totalCount: {
      marginLeft: 'auto',
      alignSelf: 'center',
      fontSize: '13px',
      color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[800],
    },
    nodesHelperText: {
      marginBottom: theme.spacing(2),
    },
    business: {
      fontSize: '0.9rem',
      fontWeight: 600,
      marginBottom: '0.3rem',
    },
    address: {
      margin: 0,
    },
    nodeListItem: {
      backgroundColor: 'transparent',
      padding: theme.spacing(2, 1),
      marginRight: theme.spacing(2),
    },
    truncatedEmail: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '215px',
      display: 'inline-block',
    },
    ...sharedFormStyles(theme),
    ...sharedTableStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
