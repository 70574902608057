import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { debounce } from 'lodash'

import { AlertContext } from '../../../providers'
import { getExtraParams, IPaginationState } from '../../../utils/hooks'
import { constructTableParams, defaultTableParams, deleteEntities, getParams } from '../../../utils/table/table-helpers'
import ConfirmDeleteModal from '../../modal'
import { InternalProps, TableCopy } from '../internal-utils'
import StatefulTable from '../stateful-table'
import { CommonPaginatedTableProps } from '..'

import CustomFooter from './serverside-footer'

type TableProps = CommonPaginatedTableProps & { pagination: IPaginationState }

export const ServersidePaginatedTable = (props: TableProps) => {
  const { columns, data, entityDeleteEndpoint, error, idList, loading, options, pagination, refresh, title } = props
  const { setSearchText, searchText, totalRowCount, setPageNumber, setRowsPerPage, sortDir, setCurrentSort } = pagination
  const { CONFIRM_DELETE, DELETING_N_ROWS, NO_ROWS_TO_DELETE } = TableCopy

  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [entitiesToDelete, setEntitiesToDelete] = useState<number[] | null>(null)
  const { addAlert } = useContext(AlertContext)
  const history = useHistory()
  const location = useLocation()
  const params = getParams(location)

  // TODO tableID stuff here is necessary
  const pageNumber = params.pageNumber ? Number(params.pageNumber) : 0
  const rowsPerPage = params.rowsPerPage ? Number(params.rowsPerPage) : 10

  useEffect(() => {
    const paramSet = new Set(Object.keys(getParams(location)))
    // by default, we show user the first page, first page has index 0
    // If any param missing, push!
    if (Object.keys(defaultTableParams).some((item: string) => !paramSet.has(item))) {
      // TODO add key here when we want to fix unique call problem
      history.replace({ pathname: location.pathname, search: constructTableParams('', { pageNumber: 0, rowsPerPage: 10 }) })
    }
  }, [history, pagination, location])

  const toggleConfirmModal = (entityIds: number[] | null, shouldRefresh: boolean) => {
    setConfirmDeleteModal(!showConfirmDeleteModal)
    setEntitiesToDelete(entityIds)
    if (shouldRefresh && refresh) {
      refresh()
    }
  }

  const tableProps: InternalProps = {
    title,
    columns,
    options: {
      ...options,
      pagination: true,
      viewColumns: false,
      searchOpen: options?.searchOpen ?? true,
      download: data && data?.length > 0 && options?.download ? options.download : false,
      onRowsDelete: (rowsDeleted: any) => {
        /* Collect ids to be deleted and supply them to the confirmation modal */
        const ids = rowsDeleted.data.map((dataItem: { index: number; dataIndex: number }) => {
          return idList && idList[dataItem.dataIndex].id
        })
        toggleConfirmModal(ids, false)
        return false
      },
      filter: options?.filter ?? false,
      filterType: options?.filterType ?? 'textField',
      print: false,
      rowsPerPageOptions: [10, 20, 50],
      onChangeRowsPerPage: (numberOfRows: number) => {
        const extraParams = getExtraParams(location)
        const pageAndRows = `?pageNumber=${pageNumber}&rowsPerPage=${numberOfRows}`
        history.push({
          pathname: location.pathname,
          search: extraParams ? `${pageAndRows}&${extraParams}` : pageAndRows,
        })
      },
      customFooter: () => {
        return (
          <CustomFooter
            count={totalRowCount}
            page={pageNumber ?? 0}
            rowsPerPage={rowsPerPage ?? 10}
            changeRowsPerPage={setRowsPerPage}
            onChangePage={setPageNumber}
          />
        )
      },
      onSearchChange: options?.onSearchChange ?? debounce((text: string | null) => setSearchText(text ? encodeURI(text.trim()) : ''), 1000),
      searchText,
      onSearchClose: () => setSearchText(''),
      onColumnSortChange: (columnName: any, _: any) => {
        const sortDirs = ['none', 'asc', 'desc']
        const newIdx = (sortDirs.indexOf(sortDir) + 1) % sortDirs.length
        setCurrentSort(columnName, sortDirs[newIdx] as IPaginationState['sortDir'])
      },
      count: totalRowCount,
      serverSide: true,
      selectableRows: options?.selectableRows ?? 'multiple',
      setTableProps: () => {
        return {
          size: 'small',
        }
      },
    },
    data: data ?? [[]],
    error: error ? new Error(error.message) : null,
    loading: loading ?? false,
  }

  return (
    <>
      <StatefulTable {...tableProps} />
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        confirmModalAction={() => deleteEntities(addAlert, entitiesToDelete, entityDeleteEndpoint, toggleConfirmModal)}
        closeModal={() => toggleConfirmModal(null, false)}
        dialogContent={entitiesToDelete && entitiesToDelete.length > 0 ? DELETING_N_ROWS(entitiesToDelete.length) : NO_ROWS_TO_DELETE}
        dialogTitle={CONFIRM_DELETE}
      />
    </>
  )
}

export default ServersidePaginatedTable
