import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { get } from 'lodash'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import MultiFilterSelect from '../../components/form-elements/filter-select/multi-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError from '../../components/permissions-form-error/form-error-message'
import { AlertContext } from '../../providers'
import useStyles from '../../shared-styles/form.styles'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

interface IPayload {
  template: {
    config: {
      action: string
      icon: string
      link: string
      title: string
    }
    createRules: string[]
    description: string
    id?: number
    title: string
  }
}

interface IFormValues {
  title: string
  description: string
  configAction: string
  configIcon: string
  configLink: string
  configTitle: string
  createRules: number[]
}

const CreateTaskTemplate = () => {
  const classes = useStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ templateId: string }>()
  const templateId = params.templateId
  const isEditMode = templateId !== 'new'

  const [retrievedTemplate, setRetrievedTemplate] = useState<ITaskTemplateType | null>(null)
  const [rules, setRules] = useState<ITaskRuleType[]>([])
  const [rulesError, setRulesError] = useState<string>('')
  const [rulesLoading, setRulesLoading] = useState<boolean>(true)

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  useDocumentTitle('Task Templates', isEditMode ? retrievedTemplate?.title : 'new')

  useEffect(() => {
    let isSubscribed = true
    setRulesLoading(true)

    Api.get('/api/v2/task-templates/rules').then(rulesData => {
      if (isSubscribed) {
        if (!rulesData.error) {
          setRulesLoading(false)
          setRules((rulesData.rules as string[]).map((r, i) => ({ id: i + 1, name: r })))
        } else {
          setRulesError(rulesData.message)
          addAlert({ alertType: 'error', message: rulesData.message })
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/v2/task-templates/${templateId}`).then(templateData => {
        redirectToListViewOnError(templateData, history, addAlert, '/task-templates')

        if (isSubscribed) {
          setRetrievedTemplate(templateData.template)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, templateId, history, addAlert])

  const saveTemplate = async (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
    const templatePayload: IPayload = {
      template: {
        title: values.title,
        description: values.description,
        config: {
          action: values.configAction,
          icon: values.configIcon,
          link: values.configLink,
          title: values.configTitle,
        },
        createRules: values.createRules.map(r => rules.find(rule => rule.id === r)?.name).filter(x => x) as string[],
      },
    }

    try {
      let newTemplate
      if (isEditMode) {
        handleFormSubmit(
          (newTemplate = await Api.put(`/api/v2/task-templates`, {
            ...templatePayload,
            template: { ...templatePayload.template, id: Number(templateId as string) },
          })),
          addAlert,
          history,
          null,
          values,
          actions
        )
        if (!newTemplate.error) {
          setRetrievedTemplate(newTemplate)
        }
      } else {
        handleFormSubmit(
          (newTemplate = await Api.post('/api/v2/task-templates', templatePayload)),
          addAlert,
          history,
          `/task-templates/${newTemplate.id}`,
          values,
          actions
        )
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit the task template' })
    }
  }

  const deleteTaskTemplate = async () => {
    try {
      handleDelete(await Api.del(`/api/v2/task-templates`, templateId), addAlert, history, '/task-templates')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete the task template' })
      history.push('/task-templates')
    }
  }

  const createTaskTemplateAlertMessage = 'Task Templates allow tasks to be created for users to view and complete in the Protect mobile app'

  const initialValues: IFormValues = useMemo(
    () =>
      isEditMode && retrievedTemplate
        ? {
            title: get(retrievedTemplate, 'title'),
            description: get(retrievedTemplate, 'description'),
            configAction: get(retrievedTemplate, 'config.action'),
            configIcon: get(retrievedTemplate, 'config.icon'),
            configLink: get(retrievedTemplate, 'config.link'),
            configTitle: get(retrievedTemplate, 'config.title'),
            createRules: retrievedTemplate.createRules.map(r => rules.find(rule => rule.name === r)?.id).filter(x => x) as number[],
          }
        : {
            title: '',
            description: '',
            configAction: '',
            configIcon: '',
            configLink: '',
            configTitle: '',
            createRules: [],
          },
    [isEditMode, retrievedTemplate, rules]
  )

  const schema = Yup.object().shape({
    title: Yup.string().required('Required'),
    configAction: Yup.string().required('Required'),
    configIcon: Yup.string().required('Required'),
    configLink: Yup.string().required('Required'),
    configTitle: Yup.string().required('Required'),
  })

  return (
    <>
      {!rulesError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveTemplate} validationSchema={schema}>
          {({ isValid, isSubmitting, dirty }) => {
            return (
              <Form>
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
                      <Grid item={true} data-cy="taskTemplateTitle">
                        <FormikTextField initialized={isEditMode} name="title" label="Title" required={true} />
                      </Grid>

                      <Grid item={true} data-cy="taskTemplateDescription">
                        <FormikTextField initialized={isEditMode} name="description" label="Description" multiline={true} required={false} />
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
                      <Alert alertType="warning" message={createTaskTemplateAlertMessage} />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6} data-cy="configInput">
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Config
                      </Typography>

                      <FormikTextField
                        initialized={isEditMode}
                        name="configTitle"
                        label="Button Title"
                        required={true}
                        helperText="Short description used for the button."
                      />

                      <FormikTextField
                        formDependent={true}
                        initialized={isEditMode}
                        name="configAction"
                        label="Button Action"
                        required={true}
                        select={true}
                        hasEmptyOption={true}
                        helperText="Navigate will open a screen in the Protect app. Link will open a link in a web browser. In-app popup will show a html/pdf within the app"
                      >
                        <option value="navigate">navigate</option>
                        <option value="link">link</option>
                        <option value="in-app-popup">in-app popup</option>
                      </FormikTextField>

                      <FormikTextField
                        initialized={isEditMode}
                        name="configLink"
                        label="Button Link"
                        required={true}
                        helperText="For a navigate action, the link should be a relative router path (e.g., /account/profile). For a link and in-app popup action, the link should be the full url path."
                      />

                      <FormikTextField
                        initialized={isEditMode}
                        name="configIcon"
                        label="Button Icon"
                        required={true}
                        helperText="Must be one of the Material Community Icons used by React Native Vector Icons."
                      />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6} data-cy="rulesInput">
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Creation Rules
                      </Typography>

                      <MultiFilterSelect<ITaskRuleType, IFormValues>
                        canSelectAll={true}
                        name="createRules"
                        required={false}
                        items={rules}
                        loading={rulesLoading}
                        initialValues={initialValues.createRules}
                        entityName="Creation Rules"
                        displayField="name"
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" data-cy="saveBtn" data-testid="saveBtn" disabled={!isValid || isSubmitting || !dirty}>
                    Save
                  </Button>

                  <Button type="button" data-cy="cancelBtn" data-testid="cancelBtn" color="inherit" onClick={() => history.push('/task-templates')}>
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button data-testid="deleteBtn" type="button" data-cy="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Form>
            )
          }}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteTaskTemplate}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this task template and all associated tasks?"
      />

      {rulesError && <PermissionsFormError errorMessages={rulesError} formName="Task Templates Form" listViewRoute="/task-templates" hasGoBackButton={true} />}
    </>
  )
}

export default CreateTaskTemplate
