import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables'

import { ErrorTable } from './error-table'
import LoadingTable from './loading-table'

type StatefulTableProps = Omit<MUIDataTableProps, 'data' | 'options'> & {
  data: React.ReactNode[][] | null
  loading: boolean
  error: Error | { message: string } | null
} & { options?: Partial<MUIDataTableOptions> }

const StatefulTable = ({ loading, data, error, options, title, ...rest }: StatefulTableProps) => {
  if (error) {
    return <ErrorTable title={title} error={error} />
  }
  if (loading) {
    return <LoadingTable title={title} elevation={options?.elevation} />
  }
  return <MUIDataTable title={title} data={data ?? []} {...rest} options={{ ...options, responsive: 'standard', elevation: options?.elevation ?? 1 }} />
}

export default StatefulTable
