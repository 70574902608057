import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Divider, Fab, FormControl, Grid, TextField, Typography } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { FieldArray, FormikErrors } from 'formik'
import moment from 'moment'

import MultiSelect from '../../../components/form-elements/multiselect/formik-multiselect'
import { ISubscriptionFormValues, IUserSubscriptionDisplayType } from '../create-edit'

import useScheduleEditorStyles from './schedule-editor.styles'
import { allDaysOfWeek, unpackSchedule } from './subscriptions-helpers'

type ScheduleEditorProps = {
  subscriptionIndex: number
}

const ScheduleEditor = (props: ScheduleEditorProps) => {
  const { subscriptionIndex } = props
  const classes = useScheduleEditorStyles()

  const ErrorMessage = (errors: FormikErrors<ISubscriptionFormValues>) => {
    return errors && <div />
  }

  return (
    <FieldArray name={`subscriptions.${subscriptionIndex}.schedules`}>
      {({ remove, insert, form }) => {
        const { values, errors, setFieldValue } = form
        const { subscriptions }: { subscriptions: IUserSubscriptionDisplayType[] } = values
        const schedules = subscriptions[subscriptionIndex].schedules

        const handleTimeChange = (event: any, fieldName: string, scheduleIndex: number) => {
          if (moment.isMoment(event)) {
            const newTime = moment(event).format('HH:mm')
            setFieldValue(`subscriptions[${subscriptionIndex}].schedules[${scheduleIndex}].${fieldName}`, moment(newTime, 'HH:mm'))
          }
        }

        return schedules.length > 0 ? (
          <Grid container={true} direction="column" className={classes.dateScheduleContainer} justifyContent="space-between" alignItems="center">
            {schedules.map((schedule: IScheduleDisplayType, index: number) => {
              return (
                <FormControl key={index} fullWidth={true} required={true}>
                  <Grid
                    container={true}
                    direction="row"
                    className={classes.dateScheduleContainer}
                    justifyContent="space-between"
                    alignItems="center"
                    key={index}
                    data-testid={`dateScheduler-${index}`}
                  >
                    <Grid item={true} container={true} alignItems="center" justifyContent="space-between" xs={12}>
                      <Grid item={true} xs={9} data-cy="schemaDropDown">
                        <MultiSelect
                          name={`subscriptions[${subscriptionIndex}].schedules[${index}].days`}
                          listItems={allDaysOfWeek}
                          required={false}
                          data-testid={`days-${index}`}
                          idx={`${subscriptionIndex}-${index}`}
                          label="Days"
                        />
                      </Grid>

                      <Grid item={true}>
                        <Fab
                          aria-label="Delete"
                          data-testid={`remove-schedule-${index}`}
                          onClick={() => remove(index)}
                          className={classes.deleteScheduleButton}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </Fab>
                      </Grid>
                    </Grid>

                    <Grid item={true} container={true} alignItems="center" justifyContent="space-between" className={classes.timeSelectionContainer} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          label="Start Time"
                          inputFormat="HH:mm"
                          ampm={false}
                          value={schedule.startTime}
                          onChange={event => handleTimeChange(event, 'startTime', index)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="standard"
                              className={classes.timePicker}
                              data-cy="startTime"
                              data-testid={`schedule-startTime-${index}`}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <Typography className={classes.formSubheader} variant="subtitle1">
                        To
                      </Typography>

                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          label="End Time"
                          inputFormat="HH:mm"
                          ampm={false}
                          value={schedule.endTime}
                          data-testid={`schedule-endTime-${index}`}
                          onChange={event => handleTimeChange(event, 'endTime', index)}
                          renderInput={params => <TextField {...params} variant="standard" className={classes.timePicker} data-cy="endTime" />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {ErrorMessage(errors)}

                    <Grid item={true} container={true} alignItems="center" justifyContent="center" xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                  </Grid>

                  <Grid item={true} container={true} alignItems="center">
                    {index === schedules.length - 1 && (
                      <Grid item={true}>
                        <Button
                          className={classes.scheduleButton}
                          color="secondary"
                          variant="outlined"
                          aria-label="Add"
                          onClick={() => insert(index, unpackSchedule('0123456|00:00-23:59')[0])}
                          data-testid="add-schedule-btn"
                        >
                          Add Schedule
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </FormControl>
              )
            })}
          </Grid>
        ) : (
          <Grid item={true} container={true} alignItems="center">
            <Grid item={true}>
              <Button
                className={classes.scheduleButton}
                color="secondary"
                variant="outlined"
                aria-label="Add"
                onClick={() => insert(0, unpackSchedule('0123456|00:00-23:59')[0])}
                data-testid="addScheduleBtn"
              >
                Add Schedule
              </Button>
            </Grid>
          </Grid>
        )
      }}
    </FieldArray>
  )
}

export default ScheduleEditor
