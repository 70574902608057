import { useMemo } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { FieldArray } from 'formik'

import Alert from '../../../components/alert'
import FilterSelect from '../../../components/form-elements/filter-select/multi-select'
import { ISubscriptionFormValues, IUserSubscriptionDisplayType } from '../create-edit'

import ScheduleEditor from './schedule-editor'
import useScheduleListStyles from './subscription-schedule-list.styles'
import { unpackSchedule } from './subscriptions-helpers'

type SubscriptionsProps = {
  notificationSchemas: INotificationSchemaType[]
  initialSchemas: number[]
}

const Subscriptions = (props: SubscriptionsProps) => {
  const classes = useScheduleListStyles()
  const { notificationSchemas, initialSchemas } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoInitialSchemas = useMemo(() => initialSchemas, [JSON.stringify(initialSchemas)])

  return (
    <FieldArray name="subscriptions">
      {({ remove, insert, form }) => {
        const { values } = form
        const { subscriptions } = values

        return (
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Alert
                  alertType="warning"
                  message="Users can subscribe to any number of Notification Schemas. Each schema has a default schedule for sending out notifications, but you can override or remove it on a per-user basis."
                />
              </Grid>
            </Grid>

            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="schemas">
                <Typography variant="subtitle1">Select Subscribed Schemas</Typography>

                <FilterSelect<INotificationSchemaType, ISubscriptionFormValues>
                  canSelectAll={false}
                  displayField="name"
                  loading={notificationSchemas.length === 0}
                  initialValues={memoInitialSchemas}
                  entityName=""
                  items={notificationSchemas}
                  name="selectedSchemas"
                  required={false}
                  onItemUnChecked={(schemaId: number) => {
                    const idx = subscriptions.findIndex((sub: IUserSubscriptionDisplayType) => sub.notificationSchemaId === schemaId)
                    if (idx > -1) {
                      remove(idx)
                    }
                  }}
                  onItemChecked={(schemaId: number) => {
                    const notificationSchema = notificationSchemas.find((schemaItem: INotificationSchemaType) => schemaItem.id === schemaId)
                    if (notificationSchema) {
                      const newSubscription: IUserSubscriptionDisplayType = {
                        name: notificationSchema.name,
                        notificationSchemaId: notificationSchema.id,
                        schedule: notificationSchema.defaultSchedule,
                        schedules: unpackSchedule(notificationSchema.defaultSchedule),
                        userId: 10,
                      }
                      insert(subscriptions.length + 1, newSubscription)
                    }
                  }}
                />
              </Grid>

              <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="subscriptions">
                <Typography className={classes.denseFormSubheader} variant="subtitle1">
                  Subscription List
                </Typography>

                {subscriptions.length > 0 ? (
                  subscriptions.map((item: IUserSubscriptionDisplayType, subscriptionIndex: number) => (
                    <div key={subscriptionIndex} className={classes.subscriptionListItem} data-cy="subscription">
                      <Accordion data-testid={`user-subscription-item-panel-${subscriptionIndex}`} key={subscriptionIndex}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${subscriptionIndex}bh-content`} id={`${subscriptionIndex}bh-header`}>
                          <Typography variant="body1">{item.name}</Typography>
                        </AccordionSummary>

                        <Divider />

                        <AccordionDetails data-testid={`user-subscription-item-${subscriptionIndex}`}>
                          <Grid container={true}>
                            <Grid item={true} xs={12}>
                              <p className={classes.fullDayWarningCopy}>To set 24 hour range, please set start time to 00:00 and end time to 23:59</p>
                            </Grid>

                            <Grid item={true} xs={12}>
                              {/* Schedule creator is a formik field array, we are dealing with arrays of arrays here subscriptions[{ schedules: [] }] */}
                              <ScheduleEditor subscriptionIndex={subscriptionIndex} />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))
                ) : (
                  <Typography variant="subtitle1">No subscriptions available. Click a schema to begin.</Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        )
      }}
    </FieldArray>
  )
}

export default Subscriptions
