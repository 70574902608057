import { useContext, useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { isEmpty } from 'lodash'

import { useClipboardAPIExists } from '../../../utils/hooks'
import { getSelectedNodeType } from '../create-edit'

import { AlertContext } from './../../../providers'
import useChannelListStyles from './../create-edit.styles'

type IChannelsList = {
  nodeTypeId: number | null
  nodeTypes: INodeTypeType[]
}

const ChannelsList = (props: IChannelsList) => {
  const { nodeTypeId, nodeTypes } = props
  const { addAlert } = useContext(AlertContext)

  const classes = useChannelListStyles()
  const [clipboardAPIExists, clipboardUnsupportedMessage] = useClipboardAPIExists()
  const [availableChannelsNames, setAvailableChannelsNames] = useState<string[]>([])

  useEffect(() => {
    if (nodeTypeId && nodeTypes) {
      const selectedNodeType = getSelectedNodeType(nodeTypes, nodeTypeId)

      if (selectedNodeType && !isEmpty(selectedNodeType.channels)) {
        setAvailableChannelsNames(Object.keys(selectedNodeType.channels))
      } else {
        setAvailableChannelsNames([])
      }
    }
  }, [nodeTypeId, nodeTypes])

  const copyChannelToClipboard = (channelName: string): void => {
    const copiedValue = `{ opts.node.channels["${channelName}"].value }`
    if (clipboardAPIExists) {
      navigator.clipboard.writeText(copiedValue).then(
        () => {
          addAlert({ alertType: 'success', message: `Copied ${copiedValue} to clipboard` })
        },
        err => {
          addAlert({ alertType: 'error', message: `Could not copy to clipboard. ${err}` })
        }
      )
    } else {
      addAlert({ alertType: 'error', message: clipboardUnsupportedMessage as string })
    }
  }

  return (
    <List className={classes.channelsList}>
      {availableChannelsNames.length !== 0 &&
        availableChannelsNames.map((channel: string, index: number) => (
          <ListItem key={index} button={true} onClick={() => copyChannelToClipboard(channel)} data-cy="channelListItem">
            <ListItemText primary={channel} />
          </ListItem>
        ))}

      {(!nodeTypeId || availableChannelsNames.length === 0) && <div className={classes.noChannels}>No Channels</div>}
    </List>
  )
}

export default ChannelsList
