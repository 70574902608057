import { useContext, useEffect, useMemo, useRef } from 'react'
import JSONPretty from 'react-json-pretty'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'

import { ClientSidePaginatedTable, StatefulTableProps } from '../../../../components/table'
import { AlertContext } from '../../../../providers'
import { StreamChannelContext, StreamDispatchContext } from '../../../../providers/websocket-stream'
import { useClipboardAPIExists } from '../../../../utils/hooks'
import { OBJECT_CHANNELS } from '../current-values-table'

interface IChannelHistoryData {
  createdAt: string
  timestamp: string
  value: any
}

type HistoryTableProps = {
  nodeId: number
  downloadFileName: string
  startTime: moment.Moment
  endTime: moment.Moment
  selectedChannel: string
  isNumericData: boolean
  data: IChannelHistoryData[] | null
  channelDataIndex: number
} & Omit<StatefulTableProps, 'title'>

export const ChannelHistoryTable = ({ downloadFileName, error, loading, data, nodeId, selectedChannel }: HistoryTableProps) => {
  // This is a hacky approach and it'd be nice if we did the right thing based on the data somehow but I dont know how that would work.
  const isObject = OBJECT_CHANNELS.indexOf(selectedChannel) > -1
  // ref used to keep track of object values in the data set. if there is an object, we want the rows to be expandable
  const hasObjectInData = useRef<boolean>(false)
  const momentFormat = 'MMMM Do YYYY, h:mm:ss a'
  const { addAlert } = useContext(AlertContext)
  const { dispatch } = useContext(StreamDispatchContext)
  const { channelData, nodeId: selectedNodeId } = useContext(StreamChannelContext)
  const [clipboardAPIExists, clipboardUnsupportedMessage] = useClipboardAPIExists()

  const copyDataToClipboard = (channelHistoryData: string): void => {
    if (clipboardAPIExists) {
      navigator.clipboard.writeText(channelHistoryData).then(
        () => {
          addAlert({ alertType: 'success', message: `Copied raw channel to clipboard` })
        },
        err => {
          addAlert({ alertType: 'error', message: `Could not copy to clipboard. ${err}` })
        }
      )
    } else {
      addAlert({ alertType: 'error', message: clipboardUnsupportedMessage as string })
    }
  }
  // we disabled sorting in this table because merging sorted data and websocket data is doable, but pretty complicated
  // so since live data comes pre-sorted with most recent items being on top, we decided to disable sorting in this table
  const columns = [
    { name: 'Created At', options: { filter: false, sort: false } },
    { name: 'Values', options: { filter: false, sort: false } },
    { name: 'Timestamp', options: { filter: false, sort: false } },
    { name: 'rawValue', options: { filter: false, sort: false, display: 'excluded' as const } },
  ]

  useEffect(() => {
    if (nodeId && selectedChannel) {
      dispatch({ type: 'subscribe:channel', payload: { nodeId, channel: selectedChannel } })
    }
  }, [dispatch, nodeId, selectedChannel])

  const formattedData = useMemo(() => {
    if (selectedNodeId !== nodeId && selectedNodeId != null) {
      console.warn('Something has seriously gone wrong with stream state management')
    }
    const dataToMerge: IChannelHistoryData[] = channelData.map(({ timestamp, value }) => ({
      timestamp: moment.unix(timestamp).format(momentFormat),
      // websocket payloads don't have a "createAt" because that is a database field, we'll just say it was created at the same time as the timestamp
      createdAt: moment.unix(timestamp).toISOString(),
      value,
    }))

    const formattedTimestampData = data
      ? data.map(({ timestamp, createdAt, value }) => {
          return {
            timestamp: moment(timestamp).format(momentFormat),
            createdAt,
            value,
          }
        })
      : []

    hasObjectInData.current = false
    return dataToMerge
      .concat(formattedTimestampData)
      .sort((itemA, itemB) => moment(itemB.timestamp, momentFormat).unix() - moment(itemA.timestamp, momentFormat).unix())
      .map((item: IChannelHistoryData) => {
        const isNull = item.value === null
        let renderedValue = null
        try {
          if (typeof item.value === 'object') {
            renderedValue = JSON.stringify(item.value)
            hasObjectInData.current = true
          } else {
            renderedValue = isNull ? '' : isObject ? JSON.stringify(item) : item.value.toString()
          }
        } catch {
          renderedValue = item.value.toString()
        }

        return [moment(item.createdAt).format(momentFormat), renderedValue, item.timestamp, item.value]
      })
  }, [channelData, data, selectedNodeId, nodeId, isObject])

  return (
    <ClientSidePaginatedTable
      title="Channel History"
      idList={[]}
      columns={columns}
      error={error}
      loading={loading}
      refresh={() => {}}
      data={formattedData ?? [[]]}
      options={{
        searchOpen: false,
        selectableRows: 'none' as const,
        rowsPerPage: 50,
        pagination: true,
        expandableRows: hasObjectInData.current || isObject,
        renderExpandableRow: rowData => {
          return (
            <TableRow>
              <TableCell colSpan={rowData.length}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={evt => {
                    evt.stopPropagation()
                    copyDataToClipboard(rowData[1])
                  }}
                >
                  Copy
                </Button>
                <JSONPretty json={rowData[3]} mainStyle="overflow-x: auto" />
              </TableCell>
            </TableRow>
          )
        },
        isRowExpandable: (dataIndex: number) => {
          const value = formattedData?.[dataIndex]?.[3]
          return isObject || typeof value === 'object'
        },
        download: true,
        downloadOptions: {
          filename: downloadFileName,
        },
        textLabels: {
          body: {
            noMatch: 'This channel does not have any history records for selected date range',
            toolTip: 'There is no data to display',
          },
        },
      }}
    />
  )
}
