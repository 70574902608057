import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import TableLink from '../../components/table-link'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

const RolesListView = () => {
  const [roles, setRoles] = useState<IRoleType[] | null>(null)
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const history = useHistory()
  const classes = useTableStyles()
  const searchResultsHook = useSearchData()
  const { addAlert } = useContext(AlertContext)

  useDocumentTitle('Roles')

  const columns = [
    { name: 'Name', options: { filter: true, sort: true } },
    { name: 'Allowed Roles', options: { filter: true, sort: false } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchRoleData = async () => {
    const tempRoles = await Api.get('/api/roles')

    if (tempRoles.error) {
      addAlert({ alertType: 'error', message: tempRoles.message })
      return Promise.reject(tempRoles)
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = tempRoles.map((role: IRoleType) => {
      let allowedRolesTitle: any

      if (!role.allowedRoles.length) {
        allowedRolesTitle = (
          <TableLink to="" dead={true} onClick={e => e.stopPropagation()}>
            All
          </TableLink>
        )
      } else if (role.allowedRoles.length === 1) {
        tempRoles.forEach((renderedRole: IRoleType) => {
          if (role.allowedRoles[0] === renderedRole.id) {
            allowedRolesTitle = (
              <TableLink to={`/roles/${renderedRole.id}`} onClick={e => e.stopPropagation()}>
                {renderedRole.name}
              </TableLink>
            )
          }
        })
      } else if (role.allowedRoles.length > 1) {
        tempRoles.forEach((renderedRole: IRoleType) => {
          if (role.allowedRoles[0] === renderedRole.id) {
            allowedRolesTitle = (
              <Grid container={true}>
                <TableLink to={`/roles/${renderedRole.id}`} onClick={e => e.stopPropagation()}>
                  {renderedRole.name}
                </TableLink>
                <span>, ...</span>
              </Grid>
            )
          }
        })
      }

      return [role.name, allowedRolesTitle, convertTimestampToDate(role.updatedAt)]
    })
    return Promise.resolve({ data: allRowData, setEntities: () => setRoles(tempRoles) })
  }

  const NewRoleLink = forwardRef((linkProps: any, ref: any) => <Link to="/roles/new" ref={ref} {...linkProps} />)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchRoleData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  return (
    <Grid className={classes.tableContainer} data-testid="rolesGrid" data-cy="rolesList" container={true} justifyContent="flex-end">
      <Grid className="above-table-container" container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewRoleLink}
              entityName="Roles"
              buttonName="Add Role"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Roles"
          data-cy="rolesList"
          entityDeleteEndpoint="/api/roles"
          idList={roles != null ? roles.map(r => ({ id: r.id })) : []}
          refresh={() => setRefreshCounter(c => c + 1)}
          preserveSearchResults={{ ...searchResultsHook }}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, roles, 'roles', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="roles"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!roles?.find(role => role.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default RolesListView
