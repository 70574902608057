import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import App from './App'
import * as serviceWorker from './serviceWorker'

import './index.css'

const environment = process.env.REACT_APP_ENV
console.info(environment)
// this key is public so I don't see any reason not to commit it
if (process.env.REACT_APP_ENV !== 'local' && process.env.REACT_APP_ENV !== 'development') {
  const userAgent = (window as any)?.navigator?.userAgent
  console.info(userAgent)
  if (userAgent) {
    console.info(`Initializing sentry for ${environment}`)
    Sentry.init({
      dsn: 'https://4090b58672b443fdb5ce0f7b11fcf1e3@o114884.ingest.sentry.io/1424233',
      environment,
      release: `admin-react@${process.env.REACT_APP_CI_BUILD_NUM}`,
    })
  }
}

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
