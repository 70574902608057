import { useContext, useEffect } from 'react'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import useMediaQuery from '@mui/material/useMediaQuery'
import classNames from 'classnames'
import { camelCase } from 'lodash'

import { SMALL_SCREEN_TRIGGER_WIDTH } from '../../constants'
import { READ_PERMISSIONS_LIST } from '../../constants/permissions'
import { OwnUserContext, SidenavContext, UserPermissionsContext } from '../../providers'

import { DisabledListItem, ListItemLink } from './list-item'
import useSidenavStyles from './sidenav.styles'

const tableSearchQuery = '?pageNumber=0&rowsPerPage=10'

// IMPORTANT!!
// if you are going to change name of menu item list, or add a new menu item to the sidenav in future
// make sure to add or update appropriate permission key name in READ_PERMISSIONS_LIST constant in constants/permissions.ts file
const menuItems = [
  {
    subheader: 'ACCESS',
    menuItems: [
      { name: 'Folders', url: { pathname: '/folders', search: tableSearchQuery } },
      { name: 'Roles', url: { pathname: '/roles', search: tableSearchQuery } },
      { name: 'Users', url: { pathname: '/users', search: tableSearchQuery } },
    ],
  },
  {
    subheader: 'CONTENT',
    menuItems: [
      { name: 'Applications', url: { pathname: '/applications', search: tableSearchQuery } },
      { name: 'Cores', url: { pathname: '/cores', search: tableSearchQuery } },
      { name: 'Domains', url: { pathname: '/domains', search: tableSearchQuery } },
      { name: 'Email Templates', url: { pathname: '/email-templates', search: tableSearchQuery } },
      { name: 'Files', url: { pathname: '/files', search: '?tab=0' } },
    ],
  },
  {
    subheader: 'IOT',
    menuItems: [
      { name: 'Alias Channels', url: { pathname: '/alias-channels', search: tableSearchQuery } },
      { name: 'Drivers', url: { pathname: '/drivers', search: tableSearchQuery } },
      { name: 'Nodes', url: { pathname: '/nodes', search: tableSearchQuery } },
      { name: 'Node Types', url: { pathname: '/node-types', search: tableSearchQuery } },
      { name: 'Node Type Templates', url: '/nodetype-templates' },
      { name: 'Global Node Type Templates', url: '/global-nodetype-templates' },
      { name: 'Claims', url: { pathname: '/claims/lora-chirpstack', search: tableSearchQuery } },
      {
        name: 'Task Templates',
        url: { pathname: '/task-templates', search: tableSearchQuery },
      },
    ],
  },
  {
    subheader: 'ALARMS',
    menuItems: [
      { name: 'Icons', url: { pathname: '/icons', search: tableSearchQuery } },
      { name: 'Lambdas', url: { pathname: '/lambdas', search: tableSearchQuery } },
      { name: 'Reactions', url: { pathname: '/reactions', search: tableSearchQuery } },
      { name: 'Rules', url: { pathname: '/rules', search: tableSearchQuery } },
    ],
  },
  {
    subheader: 'NOTIFICATIONS',
    menuItems: [
      {
        name: 'Notification Schemas',
        url: { pathname: '/notification-schemas', search: tableSearchQuery },
      },
      { name: 'Subscriptions', url: { pathname: '/subscriptions', search: tableSearchQuery } },
    ],
  },
  {
    subheader: 'SETTINGS',
    menuItems: [{ name: 'Tenant', url: '/tenant' }],
  },
]

/** disable sidenav items for users that don't have permission to read given entity
 * however a root user will overwrite permissions,
 * so root user without read permission will still see the sidenav item enabled
 */
export const isSidenavItemDisabled = (menuItemName: string, userPermissions: IPermissionType[], loggedInUser: IUserType | null): boolean => {
  // let's convert sidenav item name to camelcase
  // so that we can grab it by name from READ_PERMISSIONS_LIST constant
  const camelCaseItemName = camelCase(menuItemName)

  if (loggedInUser?.isRoot) {
    return false
  } else {
    if (userPermissions.length === 0) {
      return true
    } else if (READ_PERMISSIONS_LIST[camelCaseItemName]) {
      return userPermissions?.find(item => item.id === READ_PERMISSIONS_LIST[camelCaseItemName].id) === undefined
    }
  }

  return false
}

const Sidenav = () => {
  const classes = useSidenavStyles()
  const smallScreenMatches = useMediaQuery(`(max-width:${SMALL_SCREEN_TRIGGER_WIDTH}px)`)

  const currentUser = useContext<IUserType | null>(OwnUserContext)
  const sidenavContext = useContext<ISidenavContext>(SidenavContext)
  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)

  const isRoot = currentUser != null ? currentUser.isRoot : false

  useEffect(() => {
    // on small screens sidenav should be collapsed by default, and user has to explicitly open it
    if (smallScreenMatches) {
      sidenavContext.toggleSidenav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallScreenMatches])

  return (
    <div
      className={classNames({ [classes.root]: sidenavContext.isSidenavOpen && !smallScreenMatches, [classes.mobileRoot]: smallScreenMatches })}
      data-cy="drawerRoot"
    >
      <Drawer
        className={classes.menuDrawer}
        data-cy="sidenav"
        variant={smallScreenMatches ? 'temporary' : 'persistent'}
        open={sidenavContext.isSidenavOpen}
        onClose={() => sidenavContext.toggleSidenav()}
        classes={{
          paper: smallScreenMatches ? classes.drawerPaperSmallScreen : classes.drawerPaper,
        }}
      >
        {menuItems.map(menuItem => {
          if (menuItem.subheader === 'SETTINGS' && !isRoot) {
            return null
          }

          return (
            <div key={menuItem.subheader}>
              <List>
                <ListSubheader className={classes.navbarSubheader}>{menuItem.subheader}</ListSubheader>

                {menuItem.menuItems.map(item =>
                  isSidenavItemDisabled(item.name, userPermissions, currentUser) ? (
                    <DisabledListItem itemName={item.name} key={item.name} />
                  ) : (
                    <ListItemLink to={item.url} linkName={item.name} key={item.name} />
                  )
                )}
              </List>
              <Divider />
            </div>
          )
        })}
      </Drawer>
    </div>
  )
}

export default Sidenav
