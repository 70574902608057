import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    syncParentCheckbox: {
      margin: '0 0 15px 0',
    },
    exportBtn: {
      marginTop: '5px',
    },
    nodePropertiesText: {
      marginBottom: theme.spacing(1),
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
