import { ErrorOutline } from '@mui/icons-material'
import MUIDataTable from 'mui-datatables'

import { TableCopy } from './internal-utils'
import useTableStyles from './table.styles'

const TableError = ({ error }: { error: Error | { message: string } }) => {
  const classes = useTableStyles()
  return (
    <div data-testid="table-error" className={classes.tableLoader}>
      <ErrorOutline />
      {error.message ? <p className={classes.errorMessage}>{error.message}</p> : <p className={classes.errorMessage}>{TableCopy.DEFAULT_ERROR_MESSAGE}</p>}
    </div>
  )
}

export const ErrorTable = ({ error, title }: { title: string | React.ReactNode; error: Error | { message: string } }) => {
  const props = {
    title,
    columns: [
      {
        name: ' ',
        options: {
          customBodyRender: () => <TableError error={error} />,
        },
      },
    ],
    options: {
      customFooter: () => {
        return <tbody />
      },
      elevation: 1,
      filter: false,
      print: false,
      viewColumns: false,
      download: false,
      search: false,
      selectableRows: 'none' as const,
    },
    data: [['We need data here to show the row renderer']],
  }
  return <MUIDataTable {...props} />
}

export default ErrorTable
