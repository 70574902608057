import { Redirect, Route, Switch } from 'react-router-dom'

import Routes from './routes'

export default () => {
  return (
    <Switch>
      <Route path="/reset/:token" component={Routes.ResetPassword} />
      <Route path="/" exact={true} component={Routes.Login} />
      <Route
        path="*"
        render={routeProps => {
          return <Redirect to={{ pathname: '/', state: { from: routeProps.location } }} />
        }}
      />
    </Switch>
  )
}
