import { Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    channelToggleBtn: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      float: 'right',
      marginBottom: '5px',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    channelsList: {
      border: '1px solid' + grey[400],
      height: '285px',
      overflowY: 'scroll',
    },
    channelsLabel: {
      fontSize: '14px',
      float: 'right',
      color: grey[600],
      marginTop: '-20px',
    },
    noChannels: {
      padding: '20px 0 20px 10px',
      backgroundColor: grey[200],
    },
    disabledNodeTypeName: {
      '& input': {
        textAlign: 'right',
      },
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
