import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { AlertContext } from '../../providers'
import Api from '../../utils/api'
import { redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle } from '../../utils/hooks'
import { constructTableParams } from '../../utils/table/table-helpers'

import NodesHistory from './history/history'
import OrderDetails from './order-details/order-details'
import Reactions from './reactions-tab/reactions'
import useStyles from './create-edit.styles'
import CurrentValues from './current-values-tab'
import NodeForm from './node-form'
import NodeGoToButtons from './node-go-to-buttons'
import SensorConfiguration from './sensor-configuration'
import UserReactions from './user-reactions-tab'

const DUPLICATE_NODE_TYPES = ['orderNode', 'returnNode', 'attributesNode', 'weatherNode']

const CreateEditNode = () => {
  const params = useParams<{ nodeId: string }>()
  const nodeId = params.nodeId
  const isEditMode = nodeId !== 'new'
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  const classes = useStyles()

  const [nodeTypes, setNodeTypes] = useState<INodeTypeType[]>([])
  const [nodeTypesError, setNodeTypesError] = useState<string>('')
  const [currentNode, setCurrentNode] = useState<INodeType | null>(null)
  const [dashboardUrl, setDashboardUrl] = useState<string>('')
  const [nodeTypesLoading, setNodeTypesLoading] = useState<boolean>(false)

  const { search } = useLocation()
  const { push, replace } = useHistory()
  const searchContainsTab = search && search.split('=').find(item => item.includes('tab')) != null

  let suffix
  if (isEditMode) {
    if (DUPLICATE_NODE_TYPES.includes(currentNode?.vanity ?? '')) {
      suffix = currentNode?.uniqueId
    } else if (currentNode?.vanity) {
      suffix = currentNode?.vanity
    }
  } else {
    suffix = 'new'
  }

  useDocumentTitle('Nodes', suffix)

  const isOrderNode = currentNode?.nodeTypeName === 'order'

  /* If the search doesn't exist or isn't fully populated, send it to the default */
  if (!searchContainsTab) {
    // Important! Changes to default table params can cause problems here!
    replace({ search: `${constructTableParams('', { rowsPerPage: 50 })}&tab=0` })
  }

  const defaultSelectedTab = 0
  const [, /* The tab string converted to a number in the first index */ selectedTabIndex] = search
    .split('&')
    .find((str: string) => str.includes('tab'))
    ?.split('=')
    .map(Number) ?? ['tab', defaultSelectedTab]

  useEffect(() => {
    let isSubscribed = true
    setNodeTypesLoading(true)

    Api.get('/api/nodetypes').then(data => {
      if (isSubscribed) {
        setNodeTypesLoading(false)
        if (!data.error) {
          setNodeTypes(data)
        } else {
          setNodeTypesError(data.message)
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      const fetchData = async () => {
        const retrievedNode = await Api.get(`/api/nodes/${nodeId}`)
        const domain = window.location.href.substr(0, window.location.href.indexOf(''))
        const nodeDashboardUrl = domain + `/dashboard3#nodes?folderId=${retrievedNode.folderId}&nodeId=${retrievedNode.id}`

        redirectToListViewOnError(retrievedNode, history, addAlert, '/nodes')

        if (isSubscribed) {
          setDashboardUrl(nodeDashboardUrl)
          setCurrentNode(retrievedNode)
        }
      }
      fetchData()
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, nodeId, history, addAlert])

  // this is to simulate node activity, needed for easier testing of Protect
  // this functionality should be available only in dev or in localhost
  const buildNodetypeSimulationPayload = () => {
    const nt = nodeTypes.find(n => n.id === Number(currentNode?.nodeTypeId))
    let simulatedChannel
    let simulatedValue

    switch (nt?.name) {
      case 'gateway':
        simulatedChannel = 'voltage'
        simulatedValue = '1'
        break
      // Almost all other device types use the below payload
      default:
        simulatedChannel = 'rssi'
        simulatedValue = '10'
    }
    return {
      channelName: simulatedChannel,
      value: simulatedValue,
      nodeId: currentNode?.id,
    }
  }

  const isDevelop = () => {
    const { hostname } = window.location
    return hostname === 'localhost' || hostname === 'carbon.meshbot.co'
  }

  const handleClickSimulateDevice = async () => {
    const payload = buildNodetypeSimulationPayload()
    // Using raw fetch here because /api/publish/cloud doesn't return a response,
    // and our API object does not return the full response, only the data
    const response = await fetch('/api/publish/cloud', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify(payload),
    })

    if (response.ok && response.status === 200) {
      addAlert({ alertType: 'success', message: 'Message successfully sent' })
    } else {
      const body = await response.json()
      addAlert({ alertType: 'error', message: body.detail ? body.detail : `Something went wrong, please try again.` })
    }
  }

  return (
    <>
      {!isEditMode && (
        <Card>
          <CardHeader title="Create Node" data-cy="formTitle" />

          <NodeForm
            nodeTypes={nodeTypes}
            isEditMode={isEditMode}
            currentNode={currentNode}
            setCurrentNode={setCurrentNode}
            nodeTypesError={nodeTypesError}
            nodeTypesLoading={nodeTypesLoading}
          />
        </Card>
      )}

      {isEditMode && (
        <Card>
          <CardHeader
            classes={{
              action: classes.cardActions,
            }}
            action={
              isEditMode ? (
                <>
                  <NodeGoToButtons node={currentNode} />

                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    target="_blank"
                    href={dashboardUrl}
                    data-testid="dashboardBtn"
                    data-cy="dashboardBtn"
                    className={classes.btnMargin}
                  >
                    View in Dashboard
                  </Button>

                  {isDevelop() && (
                    <Button type="button" variant="outlined" color="secondary" onClick={() => handleClickSimulateDevice()} className={classes.btnMargin}>
                      Send Message
                    </Button>
                  )}
                </>
              ) : null
            }
            title={`Edit Node: ${currentNode ? currentNode.vanity : ''}`}
            data-cy="formTitle"
          />

          <Tabs
            value={selectedTabIndex ? selectedTabIndex : defaultSelectedTab}
            onChange={(event: React.ChangeEvent<{}>, value: number) => {
              // TODO tab needs to be in here, potentially safeguard
              const newParams = search
                .split('&')
                .map(str => (str.includes('tab') ? `tab=${value}` : str))
                .join('&')
              push({ search: newParams })
            }}
            textColor="secondary"
            indicatorColor="secondary"
            className={classes.tabs}
          >
            <Tab data-cy="nodeConfigTab" label="Node Config" data-testid="nodeConfigTab" />
            <Tab data-cy="currentValuesTab" label="Current Values" data-testid="currentValuesTab" />
            <Tab label="History" data-testid="node-history-tab" />
            <Tab data-cy="reactionsTab" label="Reactions" data-testid="reactionsTab" />
            <Tab label="Sensor Configuration" data-testid="sensor-config-tab" />
            <Tab label="User Reactions" />
            {isOrderNode && <Tab label="Order Details" data-testid="order-details-tab" />}
          </Tabs>

          {selectedTabIndex === 0 && (
            <NodeForm
              nodeTypes={nodeTypes}
              isEditMode={isEditMode}
              currentNode={currentNode}
              setCurrentNode={setCurrentNode}
              nodeTypesError={nodeTypesError}
              nodeTypesLoading={nodeTypesLoading}
            />
          )}
          {isEditMode && isOrderNode && selectedTabIndex === 6 && <OrderDetails node={currentNode} />}
        </Card>
      )}

      {isEditMode && selectedTabIndex === 1 && <CurrentValues />}
      {isEditMode && selectedTabIndex === 2 && <NodesHistory nodeId={Number(nodeId)} />}
      {isEditMode && selectedTabIndex === 3 && <Reactions nodeId={Number(nodeId)} />}
      {isEditMode && selectedTabIndex === 4 && <SensorConfiguration nodeId={Number(nodeId)} />}
      {isEditMode && selectedTabIndex === 5 && <UserReactions nodeId={Number(nodeId)} />}
    </>
  )
}

export default CreateEditNode
