import { Grid } from '@mui/material'

import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../constants'

import useLoginStyles from './login.styles'

const LoginFooter = () => {
  const classes = useLoginStyles()

  const currentYear = new Date().getFullYear()

  return (
    <Grid container={true} justifyContent="space-evenly" alignItems="flex-end" className={classes.footer}>
      <div className={classes.copyright}>©{currentYear} Meshify, Inc. | HSB</div>

      <a className={classes.link} href={PRIVACY_POLICY_LINK} target="blank" rel="noreferrer">
        Privacy Policy
      </a>

      <a className={classes.link} href={TERMS_OF_USE_LINK} target="blank" rel="noreferrer">
        Terms of Use
      </a>
    </Grid>
  )
}

export default LoginFooter
