// Files here arent meant to be exported to anything other than table components

import { isArray } from 'lodash'
import moment from 'moment'
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables'

import { StatefulTableProps } from '.'

export type InternalProps = {
  columns: MUIDataTableColumn[]
  title: string | React.ReactNode
  options: MUIDataTableOptions
  data: React.ReactNode[][]
} & Omit<StatefulTableProps, 'width'>

export const TableCopy = {
  CONFIRM_DELETE: 'Are you sure you want to delete these rows?',
  DELETING_N_ROWS: (n: number) => `You will be deleting ${n} row(s).`,
  NO_ROWS_TO_DELETE: 'There are no rows to delete',
  DEFAULT_ERROR_MESSAGE: 'Something went wrong. You might be logged out. Try logging out and logging back in.',
}

export const modifiedIndex = (columns: MUIDataTableColumn[]) =>
  columns
    ? columns.findIndex(column => column.name === 'Modified' || column.name === 'Updated At' || column.name === 'Timestamp' || column.name === 'End Time')
    : -1

export const activeColumnIndex = (columns: MUIDataTableColumn[]) => (columns ? columns.findIndex(column => column.name === 'Active') : -1)

export const sortActiveColumn = (tableData: any[], index: number, leastFirst: boolean) => {
  const newData = tableData.sort((itemA, itemB) => {
    const item1 = itemA.data[index].props.children
    const item2 = itemB.data[index].props.children

    if (leastFirst) {
      if (item1 < item2) {
        return -1
      }
      if (item1 > item2) {
        return 1
      }
      return 0
    } else {
      if (item1 < item2) {
        return 1
      }
      if (item1 > item2) {
        return -1
      }
      return 0
    }
  })
  return newData
}

export const tableCustomSort = (tableData: any[], index: number, order: string, activeColumnIdx: number, modifiedIdx: number) => {
  const leastFirst = order === 'asc'

  if (activeColumnIdx === index) {
    return sortActiveColumn(tableData, index, leastFirst)
  } else if (index === modifiedIdx) {
    const newData = tableData.sort((itemA, itemB) => {
      const dateA = Date.parse(itemA.data[index])
      const dateB = Date.parse(itemB.data[index])

      // safari doesn't handle Date.parse() with our date formats.
      // this is just the quickest way to fix the bigger issue
      if (isNaN(dateA) || isNaN(dateB)) {
        const momentA = moment(itemA.data[index], 'MM-DD-YYYY').valueOf()
        const momentB = moment(itemB.data[index], 'MM-DD-YYYY').valueOf()
        return leastFirst ? momentA - momentB : momentB - momentA
      } else {
        return leastFirst ? dateA - dateB : dateB - dateA
      }
    })
    return newData
  } else {
    return tableData.sort((itemA, itemB) => {
      if (typeof itemA.data[index] === 'string') {
        return leastFirst ? itemA.data[index].localeCompare(itemB.data[index]) : itemB.data[index].localeCompare(itemA.data[index])
      }
      return leastFirst ? itemA.data[index] - itemB.data[index] : itemB.data[index] - itemA.data[index]
    })
  }
}

export const customSearch = (searchText: string, row: React.ReactNode[], columns: any): boolean => {
  let isFound = false
  /* Search the react tree for text nodes */
  const finds: string[] = []
  const recurseEl = (el: React.ReactNode): null => {
    if (el) {
      if (typeof el === 'string' || typeof el === 'number' || typeof el === 'boolean') {
        finds.push(String(el))
      } else if ((el as React.ReactElement<any>).props && (el as React.ReactElement<any>).props.children != null) {
        const children = (el as React.ReactElement<any>).props.children
        if (isArray(children)) {
          children.forEach((child: React.ReactNode) => {
            recurseEl(child)
          })
        } else {
          return recurseEl(children)
        }
      }
    }
    return null
  }

  const includesAllSubStrings = (searchStrings: string[]) => {
    return finds.some(element => {
      return searchStrings.every(subString => element.toLowerCase().includes(subString.toLowerCase()))
    })
  }

  row.forEach((el, index) => {
    if (columns[index].searchable) {
      recurseEl(el)
      if (includesAllSubStrings(searchText.split(' '))) {
        isFound = true
      }
    }
  })
  return isFound
}
