import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    color: theme.palette.common.white,
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -7,
    left: -6.5,
    zIndex: 1,
  },
  icon: {
    color: 'white',
  },
}))
