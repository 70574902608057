import { useEffect, useState } from 'react'
import { Shortcut } from '@mui/icons-material'
import { CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'

import Footer from './footer/footer'
import SearchField from './search/search'
import useFilterSelectStyles from './styles'

interface IProps<GenericItem extends IBackendEntityType, GenericFormValues extends object> {
  name: string & keyof GenericFormValues // formik field name
  items: GenericItem[] // an array of items we will display in single select
  required: boolean
  label: string
  displayField: string & keyof GenericItem // a field we want to display as label. Defaults to 'name'
  loading: boolean
  initialValue: number | string | undefined // value that was previously saved on server, we will use it to display saved value on top of list in edit mode
  shortcutCallback?: (id: number) => void
}

interface ISingleSelectBody<GenericItem extends IBackendEntityType, GenericFormValues extends object> extends IProps<GenericItem, GenericFormValues> {
  form: FieldProps<GenericFormValues>['form']
}

export const ROWS_PER_PAGE = 10

const SingleSelectBody = <GenericItem extends IBackendEntityType, GenericFormValues extends object>(
  props: ISingleSelectBody<GenericItem, GenericFormValues>
) => {
  const { name, items, required, label, form, displayField, loading, initialValue, shortcutCallback } = props

  const [currentlyCheckedValue, setCurrentlyCheckedValue] = useState<string | number | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [itemsOnCurrentPage, setItemsOnCurrentPage] = useState<any[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [searchResults, setSearchResults] = useState<GenericItem[]>([])
  const [sortedItems, setSortedItems] = useState<GenericItem[]>([])

  const classes = useFilterSelectStyles()

  useEffect(() => {
    // we will check item by id
    if (form.values && form.values[name]) {
      setCurrentlyCheckedValue((form.values[name] as unknown) as string)
    }
  }, [form.values, name])

  // here we set which items will be displayed on each page
  useEffect(() => {
    const itemsToRender = searchText
      ? searchResults.slice(currentPage * ROWS_PER_PAGE - ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE)
      : sortedItems.slice(currentPage * ROWS_PER_PAGE - ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE)

    setItemsOnCurrentPage(itemsToRender)
  }, [currentPage, sortedItems, searchText, searchResults])

  useEffect(() => {
    const searchedItems: GenericItem[] = []
    const lowerCaseSearchText = searchText.toLowerCase()

    items?.forEach(item => {
      const displayText = (item[displayField] as unknown) as string
      if (displayText.toLowerCase().includes(lowerCaseSearchText)) {
        searchedItems.push(item)
      }
    })

    setSearchResults(searchedItems)
  }, [searchText, displayField, items])

  // here we make sure that saelected item is displayed on top of the items list in edit mode
  useEffect(() => {
    if (initialValue) {
      const initialValueIndex = items?.findIndex(item => Number(item.id) === initialValue)
      const initialValueObject = items[initialValueIndex]

      const itemsCopy = [...items]
      itemsCopy.splice(initialValueIndex, 1)
      itemsCopy.unshift(initialValueObject)

      setSortedItems(itemsCopy)
      setCurrentPage(1)
    } else {
      setSortedItems(items)
    }
  }, [initialValue, items])

  const selectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number((event.target as HTMLInputElement).value)
    setCurrentlyCheckedValue(value)
    form.setFieldTouched(name as string, true)
    form.setFieldValue(name as string, value)
  }
  const shouldError = () => {
    if (!required) {
      return false
    }

    const touched = form.touched[name]
    const checked = (form.values[name] as unknown) as number | undefined
    const error = (form.errors[name] as unknown) as string | undefined
    return Boolean(touched && checked != null && error != null)
  }

  return (
    <div>
      <div className={classes.label}>
        {label}
        {required && <span data-testid="required-asterisk">*</span>}
      </div>

      <Paper elevation={4} className={shouldError() ? classes.errorState : undefined} data-cy-error={shouldError()}>
        <div className={classes.selectWrapper} data-testid={`${name.toLowerCase()}-single-select-container`}>
          <SearchField searchText={searchText} setSearchText={setSearchText} />

          <div className={classes.listItemsWrapper}>
            {loading && (
              <div className={classes.loadingCircleWrapper} data-testid="spinning-circle" data-cy="spinningCircle">
                <CircularProgress color="secondary" />
              </div>
            )}

            {!loading && ((searchText && searchResults.length) || !searchText) && (
              <FormControl component="fieldset" fullWidth={true}>
                <RadioGroup row={false}>
                  {itemsOnCurrentPage &&
                    itemsOnCurrentPage.map(
                      (item, idx) =>
                        item && (
                          <FormControlLabel
                            data-cy="selectionItem"
                            control={
                              <Radio
                                color="secondary"
                                checked={Number(currentlyCheckedValue) === item.id}
                                data-cy-checked={Number(currentlyCheckedValue) === item.id}
                                onChange={selectItem}
                                value={item.id}
                                className={classes.selectElement}
                              />
                            }
                            label={
                              <span className={classes.labelWrapper}>
                                <span className={classes.selectLabel} data-testid="item-label">
                                  {item[displayField]}
                                </span>
                                {shortcutCallback && (
                                  <IconButton className={classes.hiddenButton} size="small" onClick={() => shortcutCallback?.(item.id)}>
                                    <Shortcut fontSize="small" color="primary" />
                                  </IconButton>
                                )}
                              </span>
                            }
                            labelPlacement="end"
                            key={idx}
                            data-testid={`${name.toLowerCase()}-filter-select-item-${idx}`}
                          />
                        )
                    )}
                </RadioGroup>
              </FormControl>
            )}

            {!loading && searchText && !searchResults.length && <div className={classes.noItems}>No items found</div>}
          </div>

          <Footer items={searchText ? searchResults : items} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </Paper>

      <Grid container={true} justifyContent="flex-start">
        <Typography className={classes.errorText} color="error" component="span" gutterBottom={true}>
          {shouldError() ? form.errors[name] : ''}
        </Typography>
      </Grid>
    </div>
  )
}

const SingleSelect = <GenericItem extends IBackendEntityType, GenericFormValues extends object>(props: IProps<GenericItem, GenericFormValues>) => {
  const { name, items, required, label, loading, initialValue, shortcutCallback } = props
  const displayField = props.displayField ? props.displayField : ('name' as string & keyof GenericItem)

  return (
    <Field name={name}>
      {({ form }: FieldProps<GenericFormValues>) => {
        return (
          <SingleSelectBody<GenericItem, GenericFormValues>
            items={items}
            form={form}
            name={name as string & keyof GenericFormValues}
            displayField={displayField}
            label={label}
            required={required}
            loading={loading}
            initialValue={initialValue}
            shortcutCallback={shortcutCallback}
          />
        )
      }}
    </Field>
  )
}

export default SingleSelect
