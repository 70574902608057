import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:visited': {
        color: theme.palette.primary.dark,
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    link: {
      color: 'inherit',
      textDecoration: 'inherit',
    },
    deadLinkwrapper: {
      color: 'default',
    },
  })

export const useStyles = makeStyles(styles)
