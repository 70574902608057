import { Theme } from '@mui/material'
import { grey, red } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    checkboxLabel: {
      color: grey[600],
      fontWeight: 500,
    },
    headerTemplate: {
      border: '1px solid ' + grey[400],
      '& div.CodeMirror-wrap': {
        height: '50px',
      },
    },
    codemirrorError: {
      float: 'right',
      fontSize: '14px',
      color: red[600],
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
