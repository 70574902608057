import { useCallback, useEffect, useState } from 'react'
import { Alert, Button, Grid, TableCell, TableRow } from '@mui/material'
import moment from 'moment'

import { StatefulTable } from '../../../../components/table'
import Api from '../../../../utils/api'
import useStyles from '../../folder-form.styles'

import EditAttributes from './edit-attributes'

interface Props {
  folderId: string | null
}

interface IFolderAttributesType {
  accountStatus: string
  clientAccountNumber: string
  defaultShippingAddress: object
  deliveredDate: string
  folderId: number
  installOption: string
  notifyPriority: number[]
  participantAgreementDate: string
  participantAgreementSignedByName: string
  signupKey: string
  stepConfirmUser: number
  stepContactInformationUser: number
  stepParticipationAgreementUser: number
  updatedBy: number
}

const columns = ['Name', 'Value']
const NON_EDITABLE_FIELDS = ['folderId', 'participantAgreementDate', 'participantAgreementSignedByName', 'updatedBy']
const DATE_FIELDS = ['participantAgreementDate', 'deliveredDate']

const InsideAttributes = ({ folderId }: Props) => {
  const [data, setData] = useState<IFolderAttributesType>({} as IFolderAttributesType)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [selectedAttribute, setSelectedAttribute] = useState<string | null>(null)

  const classes = useStyles()

  const getFolderAttributes = useCallback(async () => {
    setNotFound(false)
    setError('')
    const retrieved = await Api.get(`/api/v2/folders/${folderId}/attributes`)
    if (retrieved.statusCode === 404) {
      setNotFound(true)
    } else if (!retrieved.error && !retrieved.code) {
      setData(retrieved)
    } else {
      setError(retrieved.message)
    }
  }, [folderId])

  useEffect(() => {
    getFolderAttributes()
  }, [getFolderAttributes])

  const createAttributes = async () => {
    const res = await Api.post(`/api/v2/folders/${folderId}/attributes`, {})
    if (!res.error) {
      getFolderAttributes()
    } else {
      setError(res.error ?? res.message)
    }
  }

  const tableData = Object.keys(data).map(key => [key, data[key as keyof IFolderAttributesType]])

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        {error && <Alert severity="error">{error}</Alert>}
        {notFound && (
          <Alert
            severity="info"
            action={
              <Button size="small" onClick={createAttributes}>
                Create Attributes
              </Button>
            }
            className={classes.smallAlert}
          >
            This participant folder does not have any attributes yet. Do you want to create the default ones?
          </Alert>
        )}
        {tableData.length > 0 && (
          <StatefulTable
            title=""
            columns={columns}
            loading={false}
            error={null}
            data={tableData}
            options={{
              rowsPerPage: 50,
              // disable table border - makes it looks more seamless in the accordion
              elevation: 0,
              selectableRows: 'none' as const,
              // disable all toolbar items - makes the table more dense
              pagination: false,
              print: false,
              download: false,
              filter: false,
              viewColumns: false,
              search: false,
              customRowRender: (rowData, index) => {
                return (
                  <TableRow
                    key={index}
                    hover={true}
                    selected={selectedAttribute === rowData[0]}
                    onClick={() => {
                      if (!NON_EDITABLE_FIELDS.includes(rowData[0])) {
                        setSelectedAttribute(selectedAttribute !== rowData[0] ? rowData[0] : null)
                      }
                    }}
                  >
                    {rowData.map((datum, idx) => (
                      <TableCell key={idx}>
                        {/* idx 0 is the names */}
                        {idx === 0 && datum}
                        {/* idx 1 is the values */}
                        {idx === 1 &&
                          (Array.isArray(datum) || typeof datum === 'object'
                            ? JSON.stringify(datum)
                            : DATE_FIELDS.includes(rowData[0])
                            ? moment(datum).format('LLL')
                            : datum)}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              },
            }}
          />
        )}
      </Grid>
      <Grid item={true} xs={12}>
        {selectedAttribute && (
          <EditAttributes
            folderId={Number(folderId)}
            selectedAttribute={selectedAttribute}
            initialValue={data[selectedAttribute as keyof IFolderAttributesType]}
            updateValue={(attribute, value) => setData(old => ({ ...old, [attribute]: value }))}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default InsideAttributes
