import { useContext, useEffect, useState } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Card, CardActions, CardContent, CardHeader, Divider, Grid, Link, TextField, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { get } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import Checkbox from '../../components/form-elements/checkbox'
import SingleFilterSelect from '../../components/form-elements/filter-select/single-select'
import FormikTextField from '../../components/form-elements/text-field'
import Metadata, { IMetadataItems, validateMetadataItems } from '../../components/metadata'
import ConfirmDeletionModal from '../../components/modal'
import OfficialDocsLink from '../../components/official-doc-link'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import { ThemeContext } from '../../providers/theme'
import api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'
import { pack, parseInitialValue } from '../folders/folder-form'

import { AlertContext } from './../../providers'
import TestOutput from './test-output/test-output'
import ChannelOverride, { IChannelOverrideItems } from './channel-override'
import useRulesStyles from './create-edit.styles'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/gruvbox-dark.css'

export type IRuleFormValues = {
  active: boolean
  description: string
  alwaysReact: boolean
  channelName: string
  condition: string
  cronTime: number
  debounceTime: number
  iconId: number
  name: string
  nodeTypeId: number
  selectedNode: number
  testOutput: string
  payloadTimeStamp: string
  testValue: string
  showAdvancedConfig: boolean
} & IChannelOverrideItems &
  IMetadataItems

// eslint-disable-next-line func-style
function cronValid(this: Yup.NumberSchema, message: string) {
  return this.test({
    name: 'cronValid',
    message,
    test: value => value === 0 || (value > 29 && value < 10080),
  })
}
Yup.addMethod(Yup.number, 'cronValid', cronValid)

const debounceErrorMessage = 'Must be between 0 and 60'

const schema = Yup.object().shape({
  active: Yup.boolean(),
  alwaysReact: Yup.boolean(),
  channelName: Yup.string().required('Please select a channel'),
  condition: Yup.string().required('Please input a condition'),
  cronTime: Yup.number().cronValid('Value should either be 0 for real time or between 30 and 10,080'),
  debounceTime: Yup.number()
    .min(0, debounceErrorMessage)
    .max(60, debounceErrorMessage),
  iconId: Yup.number().moreThan(0, 'Please select an icon'),
  name: Yup.string()
    .min(2, 'Not Enough Characters')
    .required('Please add a name'),
  nodeTypeId: Yup.number()
    .required()
    .positive('Please select a node type'), // -1 is used to indicate that no value is selected in signle-select, so we need postiive() validation here
})

const getChannelNames = (nodeTypes: INodeTypeType[], nodeTypeId: number): string[] => {
  const selectedNodeType = nodeTypes.filter((nodeType: INodeTypeType) => nodeType.id === nodeTypeId)
  if (selectedNodeType && selectedNodeType.length > 0) {
    return Object.keys(selectedNodeType[0].channels)
  } else {
    return ['No Channels']
  }
}

const FilterSelectDynamicNodes = (props: { nodeTypeId: number; isEditMode: boolean }) => {
  const [nodes, setNodes] = useState<INodeType[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    const queryBody = {
      filters: [{ fieldName: 'nodeTypeId', operator: 'eq', value: props.nodeTypeId }],
      numberOfResults: 100,
    }

    if (props.nodeTypeId) {
      setLoading(true)
      api.post(`/api/nodes/query`, queryBody).then(retrievedNodes => {
        setLoading(false)
        if (isSubscribed) {
          setNodes(retrievedNodes.results)
        }
      })
    }

    return () => {
      isSubscribed = false
    }
  }, [props.nodeTypeId])

  return (
    <SingleFilterSelect<INodeType, IRuleFormValues>
      name="selectedNode"
      required={true}
      items={nodes}
      loading={loading}
      initialValue={undefined}
      label="Select a Node for testing:"
      displayField="vanity"
    />
  )
}

const cronAlertMessage =
  'Rules normally only run when nodes send new data into the pipeline. A Cron Rule runs continuously at the chosen interval, however, checking its condition against the latest value in the database. The Reaction will fire each time that the rules condition is met. Cron Rules are intended to be used for getting a heartbeat from a node. The interval is in minutes, either 0 or between 30 and 10,080.'
const debouceTimerMessage =
  'The debounce timer is in seconds, between 0 and 60. It refers to the number of seconds that will pass between the node triggering this rule and then entering into an alarm state. When the debounce timer is over, the rule will be evaluated again, and the node will enter into the alarm state only if the rules condition is still true.'

export const RulesForm = () => {
  const classes = useRulesStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ ruleId: string }>()
  const ruleId = params.ruleId
  const isEditMode = ruleId !== 'new'

  const [retrievedRule, setRetrievedRule] = useState<IRuleType | null>(null)
  const [nodeTypes, setNodeTypes] = useState<INodeTypeType[]>([])
  const [nodetypesError, setNodetypesError] = useState<string>('')
  const [nodetypesLoading, setNodetypesLoading] = useState<boolean>(false)
  const [icons, setIcons] = useState<IIconType[] | null>(null)
  const [iconsError, setIconsError] = useState<string>('')
  const [submitCounter, setSubmitCounter] = useState<number>(0)
  const [updatedByUser, setUpdatedByUser] = useState<IUserType | null>(null)
  const [payloadTimestampDatepickerOpen, setPayloadTimestampDatepickerOpen] = useState<boolean>(false)

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)
  const { themeKey } = useContext(ThemeContext)

  useDocumentTitle('Rules', isEditMode ? retrievedRule?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    setNodetypesLoading(true)

    api.get('/api/nodetypes').then(nodeTypesData => {
      setNodetypesLoading(false)
      if (isSubscribed && !nodeTypesData.error) {
        setNodeTypes(nodeTypesData)
      } else {
        addAlert({ alertType: 'error', message: nodeTypesData.message })
        setNodetypesError(nodeTypesData.message)
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    const setData = async () => {
      const iconsData = await api.get('/api/icons')
      if (isSubscribed && !iconsData.error) {
        setIcons(iconsData)
      } else {
        addAlert({ alertType: 'error', message: iconsData.message })
        setIconsError(iconsData.message)
      }
    }

    setData()

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      api.get(`/api/rules/${ruleId}`).then(rule => {
        redirectToListViewOnError(rule, history, addAlert, '/rules')

        if (isSubscribed) {
          setRetrievedRule(rule)

          if (rule?.updatedBy && rule.updatedBy !== -1) {
            api.get(`/api/users/${rule.updatedBy}`).then(user => {
              if (!user.error) {
                setUpdatedByUser(user)
              }
            })
          }
        }
      })
    }

    return () => {
      isSubscribed = false
    }
  }, [isEditMode, ruleId, history, addAlert])

  const saveRule = async (values: IRuleFormValues, actions: FormikHelpers<any>) => {
    const rulePayload = {
      active: values.active,
      description: values.description,
      alwaysReact: values.alwaysReact,
      channelName: values.channelName,
      condition: values.condition,
      cronTime: +values.cronTime,
      debounceTime: +values.debounceTime,
      iconId: +values.iconId,
      name: values.name,
      nodeTypeId: values.nodeTypeId,
      metadata: pack(values.metadataItems),
    }

    let submittedRule

    try {
      if (isEditMode) {
        handleFormSubmit((submittedRule = await api.put(`/api/rules/${ruleId}`, rulePayload)), addAlert, history, null, values, actions)

        setSubmitCounter(submitCounter + 1)

        // to prevent form reset on submit in edit mode
        const updatedRule: IRuleType = {
          ...rulePayload,
          createdAt: submittedRule.createdAt,
          updatedAt: submittedRule.updatedAt,
          id: submittedRule.id,
          createdBy: submittedRule.createdBy,
          updatedBy: submittedRule.updatedBy,
        }
        setRetrievedRule(updatedRule)
      } else {
        handleFormSubmit((submittedRule = await api.post('/api/rules', rulePayload)), addAlert, history, `/rules/${submittedRule.id}`, values, actions)
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit rule' })
    }
  }

  const deleteRule = async () => {
    try {
      handleDelete(await api.del('/api/rules', ruleId), addAlert, history, '/rules')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete rule' })
      history.push('/rules')
    }
  }

  const formInitialValues =
    isEditMode && retrievedRule
      ? {
          active: retrievedRule.active,
          description: retrievedRule.description,
          alwaysReact: get(retrievedRule, 'alwaysReact', false),
          channelName: retrievedRule.channelName,
          condition: retrievedRule.condition,
          cronTime: get(retrievedRule, 'cronTime', 0),
          debounceTime: get(retrievedRule, 'debounceTime', 0),
          iconId: retrievedRule.iconId,
          name: retrievedRule.name,
          nodeTypeId: retrievedRule.nodeTypeId,
          selectedNode: 0,
          testOutput: '',
          payloadTimeStamp: moment().format(),
          testValue: '',
          channelOverrideItems: [],
          showAdvancedConfig: get(retrievedRule, 'debounceTime') > 0 || get(retrievedRule, 'cronTime') > 0 ? true : false,
          metadataItems: retrievedRule?.metadata
            ? Object.entries(retrievedRule.metadata).map(item => ({
                key: item[0],
                value: parseInitialValue(item[1]),
              }))
            : [],
        }
      : {
          active: false,
          description: '',
          alwaysReact: false,
          channelName: '',
          condition: 'false',
          cronTime: 0,
          debounceTime: 0,
          iconId: 0,
          name: '',
          nodeTypeId: 0,
          selectedNode: 0,
          testOutput: '',
          payloadTimeStamp: moment().format(),
          testValue: '',
          channelOverrideItems: [],
          showAdvancedConfig: false,
          metadataItems: [],
        }

  return (
    <>
      {!iconsError && !nodetypesError && (
        <Formik
          data-testid="rule-create-form"
          initialValues={formInitialValues}
          enableReinitialize={isEditMode}
          onSubmit={saveRule}
          validate={validateMetadataItems}
          validationSchema={schema}
          // initialValid has to be set to true in forms with codemirror to avoid codemirror messing with form validation in edit mode
          isInitialValid={true}
        >
          {({ values, isValid, isSubmitting, setFieldValue, dirty }: FormikProps<IRuleFormValues>) => {
            return (
              <>
                <Form>
                  <Card elevation={1}>
                    <CardHeader title={isEditMode ? 'Edit Rule' : 'Create Rule'} data-cy="ruleTitle" />

                    <CardContent>
                      <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="ruleName">
                          <FormikTextField initialized={isEditMode} name="name" label="Name" required={true} />
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Alert
                            alertType="warning"
                            message="
                          Rules are conditions tied to a Node Type's channels. When a node of that Node Type updates one of its channels, Rules are checked to see if the new channel value meets any of their conditions. If the new value matches, the matching Rule's corresponding Reaction will send out notifications."
                          />
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormikTextField initialized={isEditMode} label="Description" name="description" multiline={true} data-testid="description" />
                        </Grid>

                        <Grid item={true} xs={12}>
                          <Checkbox name="active" label="Active" data-cy="isActive" />
                        </Grid>
                      </Grid>

                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Definitions
                      </Typography>

                      <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="nodeTypeSelection">
                          <SingleFilterSelect<INodeTypeType, IRuleFormValues>
                            name="nodeTypeId"
                            required={true}
                            items={nodeTypes}
                            loading={nodetypesLoading}
                            initialValue={retrievedRule?.nodeTypeId}
                            label="Node Type"
                            displayField="name"
                          />
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="channelNameSelection">
                          <FormikTextField initialized={isEditMode} select={true} label="Channel Name*" name="channelName">
                            {values.nodeTypeId && nodeTypes && nodeTypes.length > 0 ? (
                              getChannelNames(nodeTypes, values.nodeTypeId).map((channelName: string, idx: number) => (
                                <option key={idx} value={channelName}>
                                  {channelName}
                                </option>
                              ))
                            ) : (
                              <option>No Channels Found.</option>
                            )}
                          </FormikTextField>

                          <div className={classes.codemirrorLabel}>Condition*</div>

                          <div data-cy="codeMirror">
                            <CodeMirror
                              value={values.condition}
                              className={classes.conditionCodemirror}
                              options={{
                                mode: 'xml',
                                lineWrapping: true,
                                lineNumbers: true,
                                theme: themeKey === 'dark' ? 'gruvbox-dark' : 'default',
                              }}
                              onChange={(editor, data, value) => {
                                values.condition = value
                              }}
                            />
                          </div>

                          <Checkbox name="alwaysReact" label="Always React" />

                          <Alert
                            alertType="warning"
                            message="Always react when data on the Rule's channel is received, regardless of condition or enter/exit state."
                          />

                          <div className={classes.docsLinkWrapper} data-cy="docs">
                            <OfficialDocsLink url="https://github.com/meshifyiot/public-carbon-docs/blob/master/concepts/rules.md" />
                          </div>
                        </Grid>
                      </Grid>

                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Config
                      </Typography>

                      <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="iconSelection">
                          <FormikTextField
                            initialized={isEditMode}
                            data-cy="icon-field"
                            name="iconId"
                            label="Select a Icon"
                            required={true}
                            select={true}
                            hasEmptyOption={true}
                          >
                            {icons ? (
                              icons.map((icon: IIconType, index: number) => (
                                <option key={index} value={icon.id}>
                                  {icon.name}
                                </option>
                              ))
                            ) : (
                              <option>No icons found</option>
                            )}
                          </FormikTextField>
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Alert alertType="warning" message="A node will take this icon after triggering this rule." />
                        </Grid>
                      </Grid>

                      <Checkbox name="showAdvancedConfig" label="Show Advanced Config" />

                      {values.showAdvancedConfig && (
                        <>
                          <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="cronTimer">
                              <FormikTextField initialized={isEditMode} name="cronTime" label="Cron Timer" />
                            </Grid>

                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Alert alertType="warning" message={cronAlertMessage} />
                            </Grid>
                          </Grid>

                          <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="debounceTimer">
                              <FormikTextField initialized={isEditMode} name="debounceTime" label="Debounce Timer" />
                            </Grid>

                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Alert alertType="warning" message={debouceTimerMessage} />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12}>
                          <Typography className={classes.formSubheader} variant="subtitle1">
                            Metadata
                          </Typography>
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={8} lg={6} xl={6}>
                          <Metadata metadataItems={values.metadataItems} />
                        </Grid>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Typography className={classes.formSubheader} variant="subtitle1">
                          Rule Testing
                        </Typography>
                      </Grid>

                      <Grid container={true} spacing={3} alignContent="center">
                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="nodeSelection">
                          <FilterSelectDynamicNodes data-testid="selected-nodes" nodeTypeId={values.nodeTypeId} isEditMode={isEditMode} />
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid container={true} spacing={3} alignItems="flex-end">
                            <Grid item={true} xs={12}>
                              <Typography variant="subtitle1" className={classes.testHeader}>
                                {`${values.channelName} Test Payload`}
                              </Typography>
                            </Grid>

                            <Grid item={true} xs={11} data-cy="payloadTimeStamp">
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                  open={payloadTimestampDatepickerOpen}
                                  onOpen={() => setPayloadTimestampDatepickerOpen(true)}
                                  onClose={() => setPayloadTimestampDatepickerOpen(false)}
                                  label="Payload Timestamp"
                                  value={values.payloadTimeStamp}
                                  onChange={(date: any) => setFieldValue('payloadTimeStamp', date)}
                                  renderInput={renderParams => (
                                    <TextField
                                      {...renderParams}
                                      variant="standard"
                                      fullWidth={true}
                                      onKeyDown={e => e.preventDefault()}
                                      onClick={() => setPayloadTimestampDatepickerOpen(true)}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>

                            <Grid item={true} xs={1}>
                              <Tooltip
                                placement="top"
                                arrow={true}
                                title="Choose a payload value to test against the current rule. You can also optionally choose a timestamp for the value. It defaults to now."
                              >
                                <InfoOutlinedIcon />
                              </Tooltip>
                            </Grid>

                            <Grid item={true} xs={11} data-cy="testValue">
                              <FormikTextField initialized={isEditMode} name="testValue" label="Test Value" />
                            </Grid>

                            <Grid item={true} xs={1}>
                              <Tooltip
                                placement="top"
                                arrow={true}
                                title="Override the values for specific channels to test specific cases. Current channel values for the selected node will be used if no overrides are provided. Timestamps are optional and default to the current time"
                              >
                                <InfoOutlinedIcon />
                              </Tooltip>
                            </Grid>

                            {nodeTypes && values.nodeTypeId ? (
                              <Grid item={true} xs={12}>
                                <ChannelOverride
                                  channelOverrideItems={values.channelOverrideItems}
                                  nodeTypeChannels={getChannelNames(nodeTypes, values.nodeTypeId)}
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Typography className={classes.formSubheader} variant="subtitle1">
                          Test Output
                        </Typography>
                      </Grid>

                      <Grid item={true} xs={12} data-cy="testOutput">
                        <TestOutput values={values} />
                      </Grid>

                      {updatedByUser && isEditMode && (
                        <Grid item={true} xs={12} className={classes.topMargin}>
                          <Typography className={classes.updatedBy}>Last Updated By:</Typography>
                          &nbsp;
                          <Link to={`/users/${updatedByUser.id}`} color="primary" component={RouterLink}>
                            {updatedByUser?.information?.first && updatedByUser?.information?.last
                              ? `${updatedByUser?.information?.first} ${updatedByUser?.information?.last}`
                              : updatedByUser.email}
                          </Link>
                        </Grid>
                      )}
                    </CardContent>

                    <CardActions>
                      <Button data-testid="saveBtn" data-cy="saveBtn" disabled={!isValid || isSubmitting || !dirty} color="primary" type="submit">
                        Save
                      </Button>

                      <Button data-testid="cancelBtn" type="button" color="inherit" onClick={() => history.push('/rules')} data-cy="cancelBtn">
                        Cancel
                      </Button>

                      {isEditMode && (
                        <Button data-testid="deleteBtn" type="button" data-cy="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                          Delete
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Form>

                <ConfirmDeletionModal
                  show={isEditMode && showConfirmDeletionModal}
                  confirmModalAction={deleteRule}
                  closeModal={toggleConfirmModal}
                  dialogTitle="Are you sure you want to delete this Rule?"
                  dialogContent="The process is not reversible."
                />
              </>
            )
          }}
        </Formik>
      )}

      {(iconsError || nodetypesError) && (
        <PermissionsFormError
          errorMessages={combineErrorMessages(iconsError, nodetypesError)}
          formName="Rule Form"
          listViewRoute="/rules"
          hasGoBackButton={true}
        />
      )}
    </>
  )
}

export default RulesForm
