import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DropzoneArea } from 'mui-file-dropzone'

import { NO_FILE_API_COPY } from '../../constants'
import Api from '../../utils/api'
import { useFileAPIExists } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import useStyles from './file-upload.styles'

type FileUploaderProps = {
  setFiles: (files: any) => void // function to set coreFiles in a parent component,
  filesEndpoint: string
}

const FileUploader = (props: FileUploaderProps) => {
  const classes = useStyles()
  const fileAPSupported = useFileAPIExists()
  const { addAlert } = useContext(AlertContext)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [newFilesDraggedToUpload, setNewFilesDraggedToUpload] = useState<boolean>(false)
  // workaround to make sure previously uploaded files are cleared from Preview
  // until this bug is fixed in dropzone library
  // https://github.com/Yuvaleros/material-ui-dropzone/issues/9
  const [triggerDropzoneClear, setTriggerDropzoneClear] = useState<number>(1)

  const handleFilesUpload = (files: any) => {
    setUploadedFiles(files)
    setNewFilesDraggedToUpload(true)
  }

  /** rename file before uploading */
  const renameFile = (event: React.SyntheticEvent, fileIndex: number) => {
    const target = event.target as HTMLInputElement
    const uploadedFilesCopy = [...uploadedFiles]
    const updatedFile = new File([uploadedFilesCopy[fileIndex]], target.value)
    uploadedFilesCopy.splice(fileIndex, 1, updatedFile)
    setUploadedFiles(uploadedFilesCopy)
  }

  const handleResponse = (data: { error?: string; message?: string }) => {
    addAlert(
      data.error ? { message: `Submission Errored: ${String(data.message)}`, alertType: 'error' } : { message: 'Successfully Uploaded', alertType: 'success' }
    )
  }

  /** each file is sent in a separate API request */
  const uploadFiles = async () => {
    uploadedFiles.forEach(async (file: any) => {
      try {
        const encodedFileName = encodeURI(file.name)

        handleResponse(await Api.putFile(props.filesEndpoint + encodedFileName, file))
        const allCoreFiles = await Api.get(props.filesEndpoint)
        setNewFilesDraggedToUpload(false)
        props.setFiles(allCoreFiles)
        setTriggerDropzoneClear(triggerDropzoneClear + 1)
      } catch (e) {
        const message = (e as Error).message
        addAlert({ alertType: 'error', message: String(message) ?? 'Something went wrong and the file(s) upload failed' })
      }
    })
  }

  /** max allowed file size is 10MB */
  const handleDropRejected = (rejectedDroppedFiles: File[]) => {
    if (rejectedDroppedFiles.length) {
      rejectedDroppedFiles.forEach(file => {
        const fileName = file.name ? file.name : ''
        if (file.size >= 10000000) {
          addAlert({
            alertType: 'error',
            message: `The file ${fileName} is bigger than 10MB`,
          })
        } else {
          addAlert({ alertType: 'error', message: `Could not upload file ${fileName}` })
        }
      })
    }
  }

  return (
    <>
      <Grid item={true} xs={12}>
        <Typography className={classes.formSubheader} variant="subtitle1">
          Upload Files
        </Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <div data-cy="fileDropZone" className={classes.dropzoneWrapper}>
          {fileAPSupported ? (
            <DropzoneArea
              data-cy="fileDropZone"
              key={triggerDropzoneClear}
              onChange={handleFilesUpload}
              acceptedFiles={['text/*', 'image/*', 'application/*']} // list of accepted MIME types
              filesLimit={5}
              showAlerts={false}
              showPreviews={false}
              showFileNamesInPreview={false}
              showPreviewsInDropzone={newFilesDraggedToUpload}
              dropzoneText="Drag and Drop files here or click the button below"
              maxFileSize={10000000} // 10MB is a max file size our backend accepts
              onDropRejected={files => handleDropRejected(files)}
              fileObjects={undefined}
            />
          ) : (
            <Typography>{NO_FILE_API_COPY}</Typography>
          )}
        </div>
      </Grid>

      {uploadedFiles.length > 0 &&
        newFilesDraggedToUpload &&
        uploadedFiles.map((file, index) => (
          <Grid item={true} xs={12} key={index} data-testid="fileNameWrapper">
            <TextField label="File Name" defaultValue={file.name} size="small" onChange={e => renameFile(e, index)} fullWidth={true} />
          </Grid>
        ))}

      <Grid item={true} xs={12}>
        {uploadedFiles.length > 0 && newFilesDraggedToUpload ? (
          <Button color="secondary" data-cy="uploadFileBtn" data-testid="uploadFileBtn" onClick={uploadFiles} className={classes.floatRight} variant="outlined">
            {uploadedFiles.length === 1 ? 'Upload 1 file' : `Upload ${uploadedFiles.length} files`}
          </Button>
        ) : null}
      </Grid>
    </>
  )
}

export default FileUploader
