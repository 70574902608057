import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    updateBtnContainer: {
      [theme.breakpoints.only('xs')]: {
        paddingTop: '0 !important',
        marginBottom: theme.spacing(2),
      },
    },
    updateBtn: {
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
  })

export const useStyles = makeStyles(styles)
