import { grey } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    iconPreviewWrapper: {
      textAlign: 'center',
      backgroundColor: grey[100],
      border: '1px solid' + grey[400],
      minHeight: '110px',
      position: 'relative',
      marginTop: '20px',
    },
    iconWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
