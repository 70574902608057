import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Alert from '../../components/alert'
import FilesList from '../../components/file-upload/file-list'
import FileUploader from '../../components/file-upload/file-uploader'
import Api from '../../utils/api'

import useFilesStyles from './files.styles'

const PrivateFiles = () => {
  const [privateFiles, setPrivateFiles] = useState<string[]>([])
  const [filesError, setFilesError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const classes = useFilesStyles()

  useEffect(() => {
    let isSubscribed = true
    setLoading(true)
    Api.get('/api/files/private').then(res => {
      setLoading(false)
      if (isSubscribed && !res.error) {
        setPrivateFiles(res)
      } else {
        setFilesError(res?.message || 'Private files can not be viewed')
        setPrivateFiles([])
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [])

  const handlePrivateFiles = (files: any) => {
    setPrivateFiles(files)
  }

  return (
    <div className={classes.tabContentWrapper}>
      <Grid container={true} spacing={3}>
        {!filesError && !loading && (
          <Grid item={true} xs={12}>
            <Alert
              alertType="warning"
              message="Private files are visible only to Users with the corresponding read and write permissions. 
                     A User must also be authenticated in order to access them."
            />
          </Grid>
        )}

        {filesError && !loading && (
          <Grid item={true} xs={12}>
            <Alert alertType="warning" message="It is still possible to upload private files, however viewing files is disabled" />
          </Grid>
        )}

        <FileUploader setFiles={handlePrivateFiles} filesEndpoint="/api/files/private/" />

        <Grid item={true} xs={12}>
          <Typography className={classes.denseFormSubheader} variant="subtitle1">
            Files
          </Typography>
        </Grid>

        {privateFiles.length === 0 && !filesError && !loading && <Alert alertType="warning" message="No private files. Upload one to begin" />}

        {privateFiles.length > 0 && !filesError && !loading && (
          <FilesList filesList={privateFiles} setAllFiles={(val: any) => setPrivateFiles(val)} fileEndpoint="/api/files/private" />
        )}

        {filesError && !loading && (
          <Grid item={true} xs={6}>
            <Alert alertType="error" message={filesError} />
          </Grid>
        )}

        {loading && (
          <Grid item={true} xs={12} container={true} justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default PrivateFiles
