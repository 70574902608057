import { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Header from './components/header'
import MainWithErrorBoundary from './components/main-with-error-boundary'
import Sidenav from './components/sidenav'
import api from './utils/api'
import useAppStyles from './App.styles'
import { AuthenticationContext, SidenavContext } from './providers'
import Routes from './routes'

const AuthenticatedResetRedirect = () => {
  const { setIsAuthenticated, isAuthenticated } = useContext(AuthenticationContext)

  useEffect(() => {
    let isSubscribed = true
    api.get('/api/logout').then(() => {
      if (isSubscribed) {
        setIsAuthenticated(false)
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [setIsAuthenticated, isAuthenticated])

  return <div />
}

const PrivateRouteWrapper = (props: { children: React.ReactNode }) => {
  const [isSidenavOpen, setIsSidenavOpen] = useState<boolean>(true)
  const classes = useAppStyles()

  return (
    <SidenavContext.Provider value={{ isSidenavOpen, toggleSidenav: () => setIsSidenavOpen(!isSidenavOpen) }}>
      <div className={classes.layoutWrapper}>
        <Header />

        <div className={classes.mainItemsWrapper}>
          <Sidenav />
          <MainWithErrorBoundary>{props.children}</MainWithErrorBoundary>
        </div>
      </div>
    </SidenavContext.Provider>
  )
}

export default () => {
  return (
    <PrivateRouteWrapper>
      <Switch>
        <Redirect path="/" to="/users" exact={true} />
        <Redirect path="/login" to="/users" />
        <Redirect path="/claims" to="/claims/lora-chirpstack" exact={true} />
        <Route path="/reset/:token" component={AuthenticatedResetRedirect} />
        <Route path="/alias-channels" exact={true} component={Routes.AliasChannels.List} />
        <Route path="/alias-channels/:aliasChannelId" component={Routes.AliasChannels.CreateEdit} />
        <Route path="/users" exact={true} component={Routes.Users.List} />
        <Route path="/domains" exact={true} component={Routes.Domains.List} />
        <Route path="/drivers" exact={true} component={Routes.Drivers.List} />
        <Route path="/nodes" exact={true} component={Routes.Nodes.List} />
        <Route path="/rules" exact={true} component={Routes.Rules.List} />
        <Route path="/subscriptions" exact={true} component={Routes.Subscriptions.List} />
        <Route path="/global-nodetype-templates" exact={true} component={Routes.GlobalNodetypeTemplates.List} />
        <Route path="/icons" exact={true} component={Routes.Icons.List} />
        <Route path="/applications" exact={true} component={Routes.Applications.List} />
        <Route path="/folders" exact={true} component={Routes.Folders.List} />
        <Route path="/roles" exact={true} component={Routes.Roles.List} />
        <Route path="/cores" exact={true} component={Routes.Cores.List} />
        <Route path="/reactions" exact={true} component={Routes.Reactions.List} />
        <Route path="/email-templates" exact={true} component={Routes.EmailTemplates.List} />
        <Route path="/claims/:networkType" exact={true} component={Routes.Claims.List} />
        <Route path="/notification-schemas" exact={true} component={Routes.NotificationSchemas.List} />
        <Route path="/node-types" exact={true} component={Routes.NodeTypes.List} />
        <Route path="/lambdas/" exact={true} component={Routes.Lambdas.List} />
        <Route path="/nodetype-templates" exact={true} component={Routes.NodeTypeTemplates.List} />
        <Route path="/lambdas/:lambdaId" component={Routes.Lambdas.LambdaForm} />
        <Route path="/files" exact={true} component={Routes.Files.FilesForm} />
        <Route path="/users/:userId" component={Routes.Users.UsersForm} />
        <Route path="/reactions/:reactionId" component={Routes.Reactions.ReactionsForm} />
        <Route path="/roles/:roleId" component={Routes.Roles.RolesForm} />
        <Route path="/domains/:domainId" component={Routes.Domains.DomainForm} />
        <Route path="/claims/:networkType/:claimId" component={Routes.Claims.ClaimForm} />
        <Route path="/nodes/:nodeId" component={Routes.Nodes.NodeForm} />
        <Route path="/drivers/:driverId" component={Routes.Drivers.DriverForm} />
        <Route path="/rules/:ruleId" component={Routes.Rules.RulesForm} />
        <Route path="/subscriptions/:subscriptionId" component={Routes.Subscriptions.SubscriptionForm} />
        <Route path="/notification-schemas/:schemaId" component={Routes.NotificationSchemas.SchemaForm} />
        <Route path="/email-templates/edit/:templateId" component={Routes.EmailTemplates.Edit} />
        <Route path="/applications/:applicationId" component={Routes.Applications.ApplicationForm} />
        <Route path="/folders/:folderId" component={Routes.Folders.FolderForm} />
        <Route path="/node-types/:nodetypeId" component={Routes.NodeTypes.NodeTypeForm} />
        <Route path="/nodetype-templates/:nodetypeTemplateId/:templateId?" component={Routes.NodeTypeTemplates.NodetypeTemplateForm} />
        <Route path="/cores/:coreId" component={Routes.Cores.CoreForm} />
        <Route path="/nodes/:nodeId" component={Routes.Nodes.NodeForm} />
        <Route path="/icons/:iconId" component={Routes.Icons.IconForm} />
        <Route path="/global-nodetype-templates/:globalNodetypeTemplateId" component={Routes.GlobalNodetypeTemplates.GlobalNodetypeTemplateForm} />
        <Route path="/task-templates" exact={true} component={Routes.TaskTemplates.List} />
        <Route path="/task-templates/:templateId" component={Routes.TaskTemplates.TaskTemplateForm} />
        <Route path="/tenant" component={Routes.Tenant.TenantForm} />
        <Route component={Routes.RouteError} />
      </Switch>
    </PrivateRouteWrapper>
  )
}
