export const QUERY_MAX = 100

export const AUTH_TOKEN = 'adminAuthToken'
export const PINNED_CHANNELS_LS = 'pinned_channels'

export const NO_FILE_API_COPY =
  'File interactivity is not supported in your browser. \n Please use a recent version of Chrome, Edge, Firefox, or Safari to interact with files'

export const PROTECT_DEV_URL = 'https://dev.meshifyprotect.com'
export const PROTECT_STAGING_URL = 'https://staging.meshifyprotect.com/'
export const PROTECT_PROD_URL = 'https://meshifyprotect.com/'

export const INSIGHTS_DEV_URL = 'https://meshifyinsightsdev.co/'
export const INSIGHTS_STAGING_URL = 'https://meshifyinsights.co/'
export const INSIGHTS_PROD_URL = 'https://meshifyinsights.com/'

export const PRIVACY_POLICY_LINK = 'https://www.munichre.com/hsb/en/general/legal/privacy-statement.html'
export const TERMS_OF_USE_LINK = 'https://hsbprod.carbon.meshify.com/api/files/public/legal/termsofuse.pdf'

export const SMALL_SCREEN_TRIGGER_WIDTH = 600

export const SUPPORTED_LANGUAGES_MAP: Record<string, string> = {
  'en-US': 'English (United States)',
  'de-DE': 'Deutsch',
  'fr-CA': 'Français (Canada)',
}
