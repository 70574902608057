import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'
import { Button, Card, CardActions, CardContent, CardHeader, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik'
import { uniqBy } from 'lodash'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import { AlertContext } from '../../providers'
import api from '../../utils/api'
import { handleDelete, handleFormSubmit } from '../../utils/form/form-helpers'
import { useDocumentTitle } from '../../utils/hooks'

import useStyles from './claims.styles'

type ParserFunctionType = {
  name: string
  version: string
}

type FormValues = { functionName?: string; functionVersion?: string; uniqueId?: string; appKey?: string; vaultKey?: string; productLine?: string }

// generates a random hex string for the vault key
const randomHex = (numberOfBytes: number) => {
  const alphabet = '1234567890abcdef'
  let returnValue = ''
  for (let x = 0; x < numberOfBytes * 2; x++) {
    returnValue += alphabet.charAt(Math.floor(Math.random() * alphabet.length))
  }

  return returnValue
}

export const SHOW_VAULT_ENTRY_FORM = ['', 'dev', 'development', 'local', 'qa'].includes(process.env.REACT_APP_ENV || '')
const VAULT_FUNCTION_NAMES = ['UDUS', 'cxamna', 'MDUS', 'TDUS', 'LDUS']

const FormikProductLineRadioButtons = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const isLoraV2 = ['MDUS', 'LDUS', 'TDUS'].includes(values.functionName ?? '')
  const isV4 = values.functionVersion?.toString().startsWith('4')
  const productLineName = `${values.functionName?.charAt(0)}DUS2`

  useEffect(() => {
    if (values.functionName === 'cxamna') {
      setFieldValue('productLine', 'CALYX')
    } else if (isLoraV2) {
      setFieldValue('productLine', productLineName)
    } else if (isV4) {
      setFieldValue('productLine', 'LDUS4S')
    } else {
      setFieldValue('productLine', '')
    }
  }, [values.functionName, setFieldValue, isLoraV2, productLineName, isV4])

  return (
    <RadioGroup name="productLine" onChange={val => setFieldValue('productLine', val.target.value)} value={values.productLine}>
      {values.functionName === 'UDUS' ? (
        <>
          {isV4 && <FormControlLabel value="LDUS4S" control={<Radio />} label="LDUS4S" />}
          {!isV4 && <FormControlLabel value="LDUS3" control={<Radio />} label="LDUS3" />}
          {!isV4 && <FormControlLabel value="TDUS3" control={<Radio />} label="TDUS3" />}
        </>
      ) : isLoraV2 ? (
        <FormControlLabel value={productLineName} control={<Radio />} label={productLineName} />
      ) : (
        <FormControlLabel value="CALYX" control={<Radio />} label="CALYX" />
      )}
    </RadioGroup>
  )
}

const CreateEdit = () => {
  const [claim, setClaim] = useState<IClaimType | null>(null)
  const [parserFunctions, setParserFunctions] = useState<ParserFunctionType[]>([])
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState<boolean>(false)
  const [shortCode, setShortCode] = useState<string>('')

  const { addAlert } = useContext(AlertContext)
  const { networkType, claimId } = useParams<{ networkType: ClaimNetworkType; claimId: string }>()

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const uniqueId = params.get('uniqueId')
  const history = useHistory()
  const isEditMode = claimId !== 'new'
  const classes = useStyles()

  useDocumentTitle('Claims', isEditMode ? claim?.uniqueId : 'new')

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true)
    const { functionName, functionVersion, uniqueId: uuid, appKey } = values
    if (isEditMode) {
      const resp = await api.put(`/api/claims/${networkType}/${claimId}`, { functionName, functionVersion, uniqueId: uuid })

      handleFormSubmit(resp, addAlert, history, `/claims/${networkType}`, values, actions)
    } else {
      if (functionName == null || functionVersion == null || uuid == null) {
        throw new Error('Function name, version, or uuid missing')
      }
      const resp = await api.post(`/api/claims/${networkType}`, {
        functionName,
        functionVersion,
        uniqueId: uuid,
        appKey: networkType === 'lora-chirpstack' || networkType === 'sidewalk' ? appKey : undefined,
      })

      handleFormSubmit(resp, addAlert, history, `/claims/${networkType}`, values, actions)
    }
    actions.setSubmitting(false)
  }

  const handleCreateVaultEntry = async (values: FormValues) => {
    const { vaultKey, uniqueId: uuid, productLine } = values
    try {
      const resp = await api.post(`/api/claims/${networkType}/vault`, {
        uniqueId: uuid,
        appKey: vaultKey,
        productLine,
      })

      if (resp.error) {
        throw resp
      }
      setShortCode(resp.shortCode)
      addAlert({ alertType: 'success', message: 'Vault Entry successfully created' })
    } catch (e) {
      addAlert({ alertType: 'error', message: 'Something went wrong when creating Vault Entry' })
    }
  }

  const deleteClaim = async () => {
    if (isEditMode) {
      const resp = await api.del(`/api/claims/${networkType}/${Number(claimId)}`)
      if (resp.error) {
        addAlert({ alertType: 'error', message: resp?.message || 'We could not delete this claim' })
      } else {
        handleDelete(resp, addAlert, history, `/claims/${networkType}`)
      }
    }
  }

  // We only show the appKey for lora-chirpstack and sidewalk
  let showAppKey = false
  let schema: unknown

  if (isEditMode) {
    schema = Yup.object().shape({
      functionName: Yup.string().required(),
      functionVersion: Yup.number().required(),
      uniqueId: Yup.string().required(),
    })
  } else {
    if (networkType === 'lora-chirpstack' || networkType === 'sidewalk') {
      showAppKey = true
      schema = Yup.object().shape({
        appKey: Yup.string().required('An app key is required, must be 32 characters or a special key, like `meshify` or `vault`'),
        vaultKey: Yup.string().notRequired(),
        productLine: Yup.string().notRequired(),
        functionName: Yup.string().required(),
        functionVersion: Yup.number().required(),
        uniqueId: Yup.string().required('A 16 character hexidemical string is required'),
      })
    } else {
      schema = Yup.object().shape({
        functionName: Yup.string().required(),
        functionVersion: Yup.number().required(),
        uniqueId: Yup.string().required(),
      })
    }
  }

  useEffect(() => {
    const getClaim = async () => {
      if (isEditMode) {
        const resp = await api.get(`/api/claims/${networkType}/${Number(claimId)}`)
        if (resp.error) {
          addAlert({ alertType: 'error', message: resp?.message || 'We could not retreive the claim' })
        }

        setClaim(resp)
      }
    }
    getClaim()
  }, [networkType, claimId, isEditMode, addAlert])

  useEffect(() => {
    const getParserFunctions = async () => {
      const resp = await api.get(`/api/claims/${networkType}/parserfunctions`)
      if (resp.error) {
        addAlert({ alertType: 'error', message: resp?.message || 'We could not retreive parser functions' })
      }

      setParserFunctions(resp)
    }

    getParserFunctions()
  }, [networkType, addAlert])

  const initialValues = useMemo(
    () =>
      claim != null && isEditMode === true
        ? {
            functionName: claim?.functionName,
            functionVersion: claim?.functionVersion,
            uniqueId: claim?.uniqueId,
          }
        : {
            // This is one of the special keys allowed
            appKey: 'vault',
            vaultKey: randomHex(16),
            productLine: '',
            functionName: '',
            functionVersion: '',
            uniqueId: uniqueId ?? '',
          },
    [claim, isEditMode, uniqueId]
  )

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12}>
        <Typography variant="body1" className={classes.claimsHelperText}>
          To create a new claim on a different network type, please select one from the dropdown
        </Typography>
      </Grid>

      <Grid item={true} xs={12} sm={12} md={6} lg={3} xl={3} data-testid="toggleNetworkType" data-cy="toggleNetworkType" className={classes.dropdownMargin}>
        <TextField
          select={true}
          fullWidth={true}
          value={networkType}
          data-testid="claimsSelect"
          name="claimsSelect"
          variant="standard"
          SelectProps={{
            native: true,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            history.push({ pathname: `/claims/${e.target.value as ClaimNetworkType}/new`, search })
          }}
        >
          <option value="lora-tracknet">lora-tracknet</option>
          <option value="lora-chirpstack">lora-chirpstack</option>
          <option value="nbiot-sercomm">nbiot-sercomm</option>
          <option value="sidewalk">sidewalk</option>
        </TextField>
      </Grid>

      <Grid item={true} xs={12}>
        <Formik enableReinitialize={true} onSubmit={handleSubmit} validationSchema={schema} initialValues={initialValues}>
          {({ values, isValid, isSubmitting, dirty }) => (
            <Form>
              <Card>
                <CardHeader title={isEditMode ? 'Edit Claim' : 'Create Claim'} data-cy="claimFormTitle" />

                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      {showAppKey && <FormikTextField name="appKey" variant="outlined" required={true} label="Application Key" formDependent={false} />}
                    </Grid>

                    <Grid item={true} xs={12}>
                      <FormikTextField hasEmptyOption={true} name="functionName" select={true} required={true} label="Function Name" formDependent={true}>
                        {parserFunctions.length > 0 &&
                          uniqBy(parserFunctions, 'name').map(({ name }, idx) => (
                            <option key={idx} value={name}>
                              {name}
                            </option>
                          ))}
                      </FormikTextField>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <FormikTextField name="functionVersion" select={true} hasEmptyOption={true} required={true} label="Function Version" formDependent={true}>
                        {parserFunctions.length > 0 ? (
                          parserFunctions
                            ?.filter(({ name }) => {
                              return values.functionName === name
                            })
                            .sort((a, b) => a.version.localeCompare(b.version))
                            .map(({ version }, idx) => (
                              <option key={idx} value={version}>
                                {version}
                              </option>
                            ))
                        ) : (
                          <option>You have to select a function name to choose a function version</option>
                        )}
                      </FormikTextField>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <FormikTextField name="uniqueId" required={true} label="Unique ID" formDependent={false} />
                    </Grid>

                    {SHOW_VAULT_ENTRY_FORM && values.appKey === 'vault' && VAULT_FUNCTION_NAMES.includes(values.functionName) && (
                      <Grid item={true} container={true} xs={12} spacing={3}>
                        <Grid item={true} xs={12}>
                          <Alert alertType="warning">
                            <b>DEVELOPMENT ONLY:</b>
                            <br />
                            This is a randomly generated vault key that you can assign to the uid above if you press the "Create Vault Entry" button. You can
                            change the random value if you'd like or just ignore all of this entirely. This will not override any existing vault key.
                          </Alert>
                        </Grid>
                        {shortCode && (
                          <Grid item={true}>
                            <TextField value={shortCode} variant="outlined" disabled={true} label="Short Code" margin="normal" />
                          </Grid>
                        )}
                        <Grid item={true} xs={true}>
                          <FormikTextField name="vaultKey" variant="outlined" required={true} label="Vault Key" formDependent={false} />
                        </Grid>
                        <Grid item={true} container={true} xs={3} alignContent="center">
                          <FormikProductLineRadioButtons />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                    Save
                  </Button>

                  {SHOW_VAULT_ENTRY_FORM && values.appKey === 'vault' && VAULT_FUNCTION_NAMES.includes(values.functionName) && (
                    <Button
                      color="primary"
                      onClick={() => {
                        handleCreateVaultEntry(values)
                      }}
                      disabled={!values.vaultKey || !values.uniqueId || !values.productLine}
                      data-cy="vaultKeySaveBtn"
                      data-testid="vaultKeySaveBtn"
                    >
                      Create Vault Entry
                    </Button>
                  )}

                  <Button type="button" onClick={() => history.push(`/claims/${networkType}`)} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button
                      className={classes.deleteBtn}
                      type="button"
                      color="secondary"
                      onClick={() => setShowConfirmDeletionModal(true)}
                      data-cy="deleteBtn"
                      data-testid="deleteBtn"
                    >
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteClaim}
        closeModal={() => setShowConfirmDeletionModal(false)}
        dialogContent="This action is not reversible."
        dialogTitle="Are you sure you want to delete this claim?"
      />
    </Grid>
  )
}

export default CreateEdit
