import AliasChannels from './alias-channels'
import Applications from './applications'
import Claims from './claims'
import Cores from './cores'
import Domains from './domains'
import Drivers from './drivers'
import EmailTemplates from './email-templates'
import Files from './files'
import Folders from './folders'
import GlobalNodetypeTemplates from './global-nodetype-templates'
import Icons from './icons'
import Lambdas from './lambdas'
import Login from './login'
import NodeTypes from './node-types'
import Nodes from './nodes'
import NodeTypeTemplates from './nodetype-templates'
import NotificationSchemas from './notification-schemas'
import Reactions from './reactions'
import ResetPassword from './reset-password'
import Roles from './roles'
import RouteError from './route-error'
import Rules from './rules'
import Subscriptions from './subscriptions'
import TaskTemplates from './task-templates'
import Tenant from './tenant'
import Users from './users'

export default {
  AliasChannels,
  Applications,
  Claims,
  Cores,
  Folders,
  Icons,
  Login,
  Users,
  Roles,
  NotificationSchemas,
  NodeTypes,
  Nodes,
  Domains,
  EmailTemplates,
  Drivers,
  RouteError,
  Lambdas,
  Files,
  NodeTypeTemplates,
  Reactions,
  GlobalNodetypeTemplates,
  Rules,
  Subscriptions,
  TaskTemplates,
  Tenant,
  ResetPassword,
}
