import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik'
import moment, { Moment } from 'moment'
import * as Yup from 'yup'

import DateTimePicker from '../../components/form-elements/date-timepicker/index'
import SingleSelect from '../../components/form-elements/filter-select/single-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import useSharedFormStyles from '../../shared-styles/form.styles'
import api from '../../utils/api'
import { handleDelete, handleFormSubmit } from '../../utils/form/form-helpers'
import { useDocumentTitle, useTableState, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'

type IAliasChannelFormValues = Partial<
  Pick<IAliasChannelType, 'aliasNodeId' | 'aliasChannelName' | 'sourceChannelName' | 'sourceNodeId'> & {
    startTime: Moment | undefined
    endTime: Moment | undefined
  }
>

const NodeFilterSelect = ({
  tableState,
  initialValue,
  fieldName,
  label,
}: {
  tableState: { loading: boolean; data: any[][] | null; error: { message: string } | null }
  label: string
  initialValue: number | undefined
  fieldName: keyof IAliasChannelFormValues
}) => {
  return (
    <SingleSelect<INodeType, IAliasChannelFormValues>
      initialValue={initialValue}
      label={label}
      required={true}
      name={fieldName}
      displayField="vanity"
      items={((tableState?.data as unknown) as INodeType[]) ?? []}
      {...tableState}
    />
  )
}

type ChannelListProps = {
  initialized: boolean
  nodeTypeId: string | undefined
  name: string
  required: boolean
  label: string
  isAlias: boolean
  setNodetypesError: Dispatch<SetStateAction<string>>
}

const ChannelList = ({ isAlias, initialized, label, required, name, nodeTypeId, setNodetypesError }: ChannelListProps) => {
  const [nodeType, setNodeType] = useState<INodeTypeType | null>(null)
  const { addAlert } = useContext(AlertContext)

  useEffect(() => {
    const getNodeType = async () => {
      if (nodeTypeId) {
        const resp = await api.get(`/api/nodetypes/${nodeTypeId}`)
        if (resp.error) {
          setNodetypesError(resp.message ?? 'The nodetypes did not load')
          addAlert({ alertType: 'error', message: resp.message ?? 'The nodetypes did not load' })
        } else {
          setNodeType(resp)
        }
      }
    }
    getNodeType()
  }, [nodeTypeId, setNodeType, addAlert, setNodetypesError])

  const channels = nodeType?.channels
    ? Object.entries(nodeType.channels)
        .filter(([, data]) => {
          // If this is an alias channel list, filter out all channels that dont have type alias in the name
          // Else return all alias channels that d
          if (isAlias) {
            return data.type.match(/alias/gi) != null
          } else {
            return data.type.match(/alias/gi) == null
          }
        })
        .map(([key]) => {
          return key
        })
    : []

  return (
    <FormikTextField hasEmptyOption={true} formDependent={true} initialized={initialized} label={label} select={true} name={name} required={required}>
      {nodeType ? (
        channels
          .sort((a, b) => {
            return a.toLowerCase() < b.toLowerCase() ? -1 : a > b ? 1 : 0
          })
          .map((channelName: string, idx: number) => (
            <option key={idx} value={channelName}>
              {channelName}
            </option>
          ))
      ) : (
        <option key={0} value={undefined}>
          Please Select a Node First
        </option>
      )}
    </FormikTextField>
  )
}

export const CreateEdit = () => {
  const history = useHistory()
  const params = useParams<{ aliasChannelId: string }>()
  const { addAlert } = useContext(AlertContext)
  const classes = useSharedFormStyles()

  const aliasChannelId = params.aliasChannelId
  const isEditMode = Boolean(aliasChannelId && aliasChannelId !== 'new')

  const [nodes, setNodes] = useState<INodeType[]>([])
  const [aliasChannel, setAliasChannel] = useState<IAliasChannelType | null>(null)
  const [showConfirmDeletionModal, toggleConfirmDeletionModal] = useToggle(false)

  const [aliasChannelError, setAliasChannelError] = useState<string>('')
  const [nodesError, setNodesError] = useState<string>('')
  const [nodetypesError, setNodetypesError] = useState<string>('')

  useDocumentTitle('Alias Channels', aliasChannelId)

  const fetchNodesData = async () => {
    const resp = await api.get('/api/nodes')
    if (resp.error) {
      addAlert({ alertType: 'error', message: resp.message })
      setNodesError(resp.message ?? 'The nodes did not load')
      return { data: [], error: resp.message }
    }
    return { data: resp, setEntities: () => setNodes(resp) }
  }

  const memoedFetch = useCallback(fetchNodesData, [addAlert])
  const nodeFetchState = useTableState(memoedFetch)

  useEffect(() => {
    const getData = async () => {
      const resp = await api.get(`/api/aliaschannels/${aliasChannelId}`)
      if (resp.error) {
        setAliasChannelError(resp.message ?? 'Cannot access alias channel form at this time')
        addAlert({ message: resp.message ?? 'Cannot access alias channel form at this time', alertType: 'error' })
      } else {
        setAliasChannel(resp as IAliasChannelType)
      }
    }
    if (isEditMode) {
      getData()
    }
  }, [isEditMode, aliasChannelId, addAlert])

  const saveAliasChannel = async (values: IAliasChannelFormValues, actions: FormikHelpers<IAliasChannelFormValues>) => {
    actions.setSubmitting(true)
    try {
      const { sourceNodeId, aliasNodeId, startTime, endTime, aliasChannelName, sourceChannelName } = values
      if (isEditMode) {
        const putBody = {
          aliasNodeId,
          sourceNodeId,
          aliasChannelName,
          sourceChannelName,
          startTime: startTime ? startTime.toISOString() : undefined,
          endTime: endTime ? endTime.toISOString() : undefined,
        }
        const resp = await api.put(`/api/aliaschannels/${aliasChannelId}`, putBody)
        handleFormSubmit(resp, addAlert, history, '/alias-channels', values, actions)
      } else {
        const postBody = {
          aliasNodeId,
          sourceNodeId,
          aliasChannelName,
          sourceChannelName,
          startTime: startTime ? startTime.toISOString() : undefined,
          endTime: endTime ? endTime.toISOString() : undefined,
        }
        const resp = await api.post('/api/aliaschannels', postBody)
        handleFormSubmit(resp, addAlert, history, '/alias-channels', values, actions)
      }
    } catch (e) {
      addAlert({ alertType: 'error', message: String(e) || 'Alias channel could not be submitted' })
    }
    actions.setSubmitting(false)
  }

  const deleteAliasChannel = async (setSubmitting: (bool: boolean) => void) => {
    setSubmitting(true)
    try {
      if (isEditMode) {
        const resp = await api.del(`/api/aliaschannels/${aliasChannelId}`)
        handleDelete(resp, addAlert, history, '/alias-channels')
      }
    } catch (e) {
      addAlert({ alertType: 'error', message: String(e) || 'Alias Channel could not be deleted' })
    }
    setSubmitting(false)
  }

  const initialValues: IAliasChannelFormValues = isEditMode
    ? {
        aliasChannelName: aliasChannel?.aliasChannelName,
        aliasNodeId: aliasChannel?.aliasNodeId,
        endTime: aliasChannel?.endTime ? moment(aliasChannel?.endTime) : undefined,
        sourceChannelName: aliasChannel?.sourceChannelName,
        sourceNodeId: aliasChannel?.sourceNodeId,
        startTime: aliasChannel?.startTime ? moment(aliasChannel?.startTime) : undefined,
      }
    : {
        aliasChannelName: '',
        aliasNodeId: undefined,
        endTime: undefined,
        sourceChannelName: '',
        sourceNodeId: undefined,
        startTime: undefined,
      }

  const schema = Yup.object().shape({
    aliasNodeId: Yup.number().required('An Alias Node is required'),
    sourceNodeId: (Yup.number() as Yup.NumberSchema<number> & { notEqualTo: any }).required('A Source Node is required'),
    aliasChannelName: Yup.string().required('Alias Channel Name is required'),
    startTime: Yup.date(),
    endTime: Yup.date(),
  })

  return aliasChannelError || nodesError || nodetypesError ? (
    <PermissionsFormError
      errorMessages={combineErrorMessages(aliasChannelError, nodesError, nodetypesError)}
      formName="Alias Channels Form"
      listViewRoute="/alias-channels"
      hasGoBackButton={true}
    />
  ) : (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={saveAliasChannel}
      validationSchema={schema}
      validate={({ aliasNodeId, sourceNodeId, startTime, endTime }) => {
        const errors: FormikErrors<IAliasChannelFormValues> = {}
        if (aliasNodeId && sourceNodeId) {
          const errorMessage = 'The source node cannot equal the alias node for aliasing to work'
          if (aliasNodeId === sourceNodeId) {
            errors.aliasNodeId = errorMessage
          }
        }
        if (endTime) {
          if (startTime) {
            if (startTime.isAfter(endTime)) {
              errors.endTime = 'The start time must precede the end time if provided'
            }
          } else {
            if (moment(Date.now()).isAfter(endTime)) {
              errors.endTime = 'The end time must be after the current time'
            }
          }
        }
        return errors
      }}
    >
      {({ values, setSubmitting, isValid, isSubmitting, dirty }: FormikProps<IAliasChannelFormValues>) => {
        const sourceNode = nodes.find(node => node.id === values.sourceNodeId)
        const aliasNode = nodes.find(node => node.id === values.aliasNodeId)

        return (
          <Form>
            <Card>
              <CardHeader title={isEditMode ? 'Edit Alias Channel' : 'Create Alias Channel'} data-cy="aliasChannelsTitle" />

              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="startTime">
                    <DateTimePicker required={false} name="startTime" label="Start Time" />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="endTime">
                    <DateTimePicker required={false} name="endTime" label="End Time" />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="sourceNodeFilterSelect">
                    <NodeFilterSelect tableState={nodeFetchState} initialValue={initialValues.sourceNodeId} label="Source Node" fieldName="sourceNodeId" />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="aliasNodeFilterSelect">
                    <NodeFilterSelect tableState={nodeFetchState} initialValue={initialValues.aliasNodeId} label="Alias Node" fieldName="aliasNodeId" />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="sourceChannelName">
                    <ChannelList
                      isAlias={false}
                      nodeTypeId={sourceNode?.nodeTypeId}
                      initialized={isEditMode}
                      name="sourceChannelName"
                      label="Source Channel Name"
                      required={true}
                      setNodetypesError={setNodetypesError}
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="aliasChannelName">
                    <ChannelList
                      isAlias={true}
                      nodeTypeId={aliasNode?.nodeTypeId}
                      initialized={isEditMode}
                      name="aliasChannelName"
                      label="Alias Channel Name"
                      required={true}
                      setNodetypesError={setNodetypesError}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                  Save
                </Button>
                <Button type="button" onClick={() => history.push('/alias-channels')} data-cy="cancelBtn" data-testid="cancelBtn" color="inherit">
                  Cancel
                </Button>

                {isEditMode && (
                  <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmDeletionModal}>
                    Delete
                  </Button>
                )}

                <ConfirmDeletionModal
                  show={isEditMode && showConfirmDeletionModal}
                  confirmModalAction={() => {
                    deleteAliasChannel(setSubmitting)
                  }}
                  closeModal={toggleConfirmDeletionModal}
                  dialogTitle="Are you sure you want to delete this alias channel?"
                />
              </CardActions>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateEdit
