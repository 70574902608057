import { grey } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    border: {
      border: '1px solid' + grey[400],
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
