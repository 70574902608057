import { forwardRef, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import TableLink from '../../components/table-link'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

export const GlobalNodetypeTemplateList = () => {
  const history = useHistory()
  const classes = useTableStyles()
  const { addAlert } = useContext(AlertContext)

  const [roles, setRoles] = useState<IRoleType[]>([])
  const [templates, setTemplates] = useState<IGlobalNodeTypeTemplateType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const searchResultsHook = useSearchData()

  useDocumentTitle('Global Nodetype Templates')

  const columns = [
    { name: 'Name', options: { filter: true, sort: true } },
    { name: 'Allowed Roles', options: { filter: false, sort: false } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  useEffect(() => {
    let isSubscribed = true
    Api.get('/api/roles').then(rolesData => {
      if (isSubscribed && rolesData.error) {
        addAlert({ alertType: 'error', message: rolesData?.message ?? 'Could not get roles' })
      } else {
        setRoles(rolesData)
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  const fetchGlobalNodetypeTemplatesData = async () => {
    const globalNodeTypeTemplates = await Api.get('/api/globalnodetypetemplates')

    if (globalNodeTypeTemplates.error) {
      addAlert({ alertType: 'error', message: globalNodeTypeTemplates.message })
      return Promise.reject(globalNodeTypeTemplates)
    }

    const allRowData: React.ReactNode[][] = globalNodeTypeTemplates.map((globalTemplate: IGlobalNodeTypeTemplateType) => {
      const rolesNameList =
        roles && roles.length > 0 && globalTemplate.allowedRoles.length > 0 ? (
          <Grid container={true}>
            {globalTemplate.allowedRoles.length === roles.length ? (
              <span
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                All Roles
              </span>
            ) : (
              <>
                <TableLink
                  to={`/roles/${globalTemplate.allowedRoles[0]}`}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  {roles.find(item => item.id === globalTemplate.allowedRoles[0])?.name}
                </TableLink>

                {globalTemplate.allowedRoles.length > 1 ? (
                  <span
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    , ...
                  </span>
                ) : null}
              </>
            )}
          </Grid>
        ) : (
          '...'
        )

      return [globalTemplate.name, rolesNameList, convertTimestampToDate(globalTemplate.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setTemplates(globalNodeTypeTemplates) })
  }

  const NewGlobalTemplateLink = forwardRef((linkProps: any, ref: any) => <Link to="/global-nodetype-templates/new" ref={ref} {...linkProps} />)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchGlobalNodetypeTemplatesData, [refreshCounter, roles])
  const state = useTableState(memoizedFetch)

  return (
    <Grid className={classes.tableContainer} container={true} justifyContent="flex-end" data-cy="globalNodeTypeTemplatesList">
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewGlobalTemplateLink}
              entityName="Global Node Type Templates"
              buttonName="Add Template"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Global Nodetype Templates"
          entityDeleteEndpoint="/api/globalnodetypetemplates"
          idList={templates != null ? templates.map(t => ({ id: t.id })) : []}
          refresh={() => setRefreshCounter(c => c + 1)}
          preserveSearchResults={{ ...searchResultsHook }}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) =>
              handleCellClick(history, templates, 'global-nodetype-templates', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="global-nodetype-templates"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!templates?.find(template => template.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default GlobalNodetypeTemplateList
