import { Grid, TextField, Typography } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Field, FieldProps } from 'formik'

import useStyles from '../date-schedule/date-schedule.styles'

const FormikTimePicker = <FormValues extends object>(props: { label: string; name: string & keyof FormValues; required: boolean; fullWidth?: boolean }) => {
  const { name, required, label, fullWidth } = props
  const classes = useStyles()

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Field name={name}>
        {({ field, form }: FieldProps<FormValues>) => {
          const shouldError = () => {
            const touched = form.touched[name]
            const value = (form.values[name] as unknown) as number | undefined
            const error = (form.errors[name] as unknown) as string | undefined
            return Boolean(touched && value != null && error != null)
          }

          return (
            <Grid container={true} direction="column" item={true} xs={12}>
              <Grid item={true} xs={12}>
                <DateTimePicker
                  ampm={true}
                  label={required ? `${label}*` : label}
                  value={field.value}
                  onChange={event => {
                    form.setFieldTouched(name, true)
                    form.setFieldValue(name, event)
                  }}
                  data-cy={`${name}-datetimepicker`}
                  renderInput={params => <TextField {...params} variant="standard" fullWidth={fullWidth} />}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <Typography className={classes.errorText} color="error" component="span" gutterBottom={true}>
                  {shouldError() ? form.errors[name] : ''}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      </Field>
    </LocalizationProvider>
  )
}

export default FormikTimePicker
