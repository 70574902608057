import { uniqBy } from 'lodash'

import Api from '../../../utils/api'

export interface ISuggestionType {
  title?: string
  entities?: unknown
  label?: string
}

/**
 * query()
 * @description calls api.post to get the suggestions
 * @param {string} value query string entered in input field
 * @param {string} dataType the type of data queried (nodes, folders, users)
 * @param {string} fieldName the field name to search
 */
const query = (value: string, dataType: string, fieldName: string) => {
  return new Promise((resolve, reject) => {
    // TODO FIXME numberOfResults is not an accepted key.
    const body: IQueryBody = {
      filters: [
        {
          fieldName,
          operator: 'multilike',
          value,
        },
      ],
      numberOfResults: 5,
      orderDirection: 'asc',
    }

    Api.post(`/api/${dataType}/query`, body)
      .then(response => {
        const dataArray = response.results.map((entity: any) => {
          let labelText = entity[fieldName]
          const isSearchedByMetadata = fieldName === 'metadata' || fieldName === 'metadata.iccid'
          // when we search folders by metadata value, we want to show
          // folder name or node vanity in autosuggest dropdown
          if (isSearchedByMetadata) {
            labelText = dataType === 'folders' ? entity.name : entity.vanity
          }
          return {
            label: labelText,
            id: entity.id,
            type: dataType,
            metadataSearch: isSearchedByMetadata,
          }
        })
        resolve(dataArray)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * fetches data from server
 */
const fetchSuggestions = (
  value: string
): Promise<{
  results: {
    total: number
    suggestions: ISuggestionType[]
  }
}> => {
  const promises: any[] = []
  // set up queries
  promises.push(
    // Nodes queries
    query(value, 'nodes', 'vanity'),
    query(value, 'nodes', 'uniqueId'),
    // Folders queries
    query(value, 'folders', 'name'),
    query(value, 'folders', 'metadata'),
    // Users queries
    query(value, 'users', 'email'),
    query(value, 'users', 'phone')
  )

  // test that the value is at least 19 digits long and is only numbers.
  if (value.length > 18 && /^[0-9]+$/.test(value)) {
    promises.push(query(value, 'nodes', 'metadata.iccid'))
  }

  return new Promise((resolve, reject) => {
    Promise.all(promises)
      .then(data => {
        let results: {
          total: number
          suggestions: ISuggestionType[]
        } | null = null
        // summarize and format the data
        const nodes = data.filter(d => d?.[0]?.type === 'nodes').flat()
        const folders = data.filter(d => d?.[0]?.type === 'folders').flat()
        const users = data.filter(d => d?.[0]?.type === 'users').flat()

        const uniqueFolders = uniqBy(folders, 'id')
        const uniqueNodes = uniqBy(nodes, 'id')
        const uniqueUsers = uniqBy(users, 'id')

        const folderCount = uniqueFolders.length
        const nodeCount = uniqueNodes.length
        const userCount = uniqueUsers.length

        const total = folderCount + nodeCount + userCount

        if (total === 0) {
          results = {
            total: 0,
            suggestions: [
              {
                title: 'No Results',
                entities: [
                  {
                    label: '',
                  },
                ],
              },
            ],
          }
        } else {
          results = {
            total,
            suggestions: [
              {
                title: `Folders (${folderCount})`,
                entities: uniqueFolders,
              },
              {
                title: `Nodes (${nodeCount})`,
                entities: uniqueNodes,
              },
              {
                title: `Users (${userCount})`,
                entities: uniqueUsers,
              },
            ],
          }
        }

        resolve({ results })
      })
      .catch(err => {
        if (err.message === 'The user aborted a request.') {
          // this error is fine to ignore, we don't want to show the user.
          return
        }
        // TODO: log or export this condition
        console.error(err)
        reject(err)
      })
  })
}

export default fetchSuggestions
