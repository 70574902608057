import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, LinkProps, useHistory } from 'react-router-dom'
import { ButtonProps } from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { filterActiveColumn, filterLinkColumn, filterPlainTextColumn, handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

/**
 * get array of unique node type names for lambdas,
 *  we will display them in Node Types dropdown in fitler panel
 */
export const getUniqueNodetypeNames = (allLambdas: ILambdaType[], allNodetypes: INodeTypeType[]): string[] => {
  const uniqueNodetypeNames: string[] = []

  allLambdas.forEach(lambda => {
    allNodetypes.forEach(nodetype => {
      // add nodetype name for rule, if doesn't exist already
      if (lambda.nodeTypeId === nodetype.id && uniqueNodetypeNames.indexOf(nodetype.name) === -1) {
        uniqueNodetypeNames.push(nodetype.name)
      }
    })
  })

  return uniqueNodetypeNames
}

export const LambdasListView = () => {
  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const [lambdas, setLambdas] = useState<ILambdaType[]>([])
  const [nodetypes, setNodetypes] = useState<INodeTypeType[]>([])

  const classes = useTableStyles()
  const { addAlert } = useContext(AlertContext)
  const history = useHistory()
  const searchResultsHook = useSearchData()

  useDocumentTitle('Lambdas')

  const columns = [
    { name: 'Name', options: { filter: false, sort: true } },
    {
      name: 'Node Type',
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          names: getUniqueNodetypeNames(lambdas, nodetypes),
          logic: (location: any, filters: string[]) => filterLinkColumn(location, filters),
        },
      },
    },
    {
      name: 'Channel',
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          logic: (location: string, filters: string[]) => filterPlainTextColumn(location, filters),
        },
      },
    },
    {
      name: 'Active',
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          names: ['Active', 'Not Active'],
          logic: (location: any, filters: string[]) => filterActiveColumn(location, filters),
        },
      },
    },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchLambdasData = async () => {
    const retrievedLambdas = await Api.get('/api/lambdas')
    const retrievedNodeTypes = await Api.get('/api/nodeTypes')

    if (retrievedLambdas.error) {
      addAlert({ alertType: 'error', message: retrievedLambdas.message })
      return Promise.reject(retrievedLambdas)
    }

    if (retrievedNodeTypes.error) {
      addAlert({ alertType: 'error', message: retrievedNodeTypes.message })
    } else {
      setNodetypes(retrievedNodeTypes)
    }

    const allRowData: React.ReactNode[][] = retrievedLambdas.map((lambda: ILambdaType) => {
      let nodeTypeName: string
      if (!retrievedNodeTypes.error) {
        nodeTypeName = retrievedNodeTypes
          .map((nodetype: INodeTypeType) => {
            if (nodetype.id === lambda.nodeTypeId) {
              return nodetype.name
            }
            return null
          })
          .find((el: string | null) => el != null)
      } else {
        nodeTypeName = ''
      }

      const nodeType = (
        <Link
          to={`/node-types/${lambda.nodeTypeId}`}
          className={classes.applicationLink}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {nodeTypeName}
        </Link>
      )

      const channel = lambda.channelName
      const isActive = lambda.active ? <Icon className={classes.greenColor}>check</Icon> : <Icon className={classes.redColor}>close</Icon>

      return [lambda.name, nodeType, channel, isActive, convertTimestampToDate(lambda.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setLambdas(retrievedLambdas) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchLambdasData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  const NewLambdaLink = forwardRef((linkProps: ButtonProps, ref: any) => <Link ref={ref} {...(linkProps as LinkProps)} to="/lambdas/new" />)

  return (
    <Grid className={classes.tableContainer} data-cy="lambdasList" container={true} justifyContent="flex-end">
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewLambdaLink}
              entityName="Lambdas"
              buttonName="Add Lambda"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Lambdas"
          entityDeleteEndpoint="/api/lambdas/"
          idList={lambdas != null ? lambdas.map(l => ({ id: l.id })) : []}
          refresh={() => setRefreshCounter(c => c + 1)}
          preserveSearchResults={{ ...searchResultsHook }}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          options={{
            filter: true,
            filterType: 'dropdown',
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, lambdas, 'lambdas', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="lambdas"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!lambdas?.find(lambda => lambda.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default LambdasListView
