import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import CodemirrorWithTags from '../../components/form-elements/codemirror-with-tag-set'
import FilterSelect from '../../components/form-elements/filter-select/multi-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import OfficialDocsLink from '../../components/official-doc-link'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import Api from '../../utils/api'
import { getSourceInitValue, handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import ChannelsList from './form-elements/channels-list'
import NodeTypeIdFilterSelect from './form-elements/nodetype-filterselect'
import UseNTTFormStyles from './create-edit.styles'

interface INodetypeTemplatePayload {
  allowedRoles: number[]
  name: string
  nodeTypeId: number
  position: number
  vanityName: string
}

type FormValues = INodetypeTemplatePayload

export const getSelectedNodeType = (nodetypesList: INodeTypeType[], nodeTypeId: number): INodeTypeType => {
  const selectedNodetype = nodetypesList.filter((nodetype: INodeTypeType) => nodetype.id === nodeTypeId)[0]

  return selectedNodetype
}

// names come from backend in format like "m1-hello" where m1 is a nodetype name and hello is a template name
// this function will convert "m1-hello" to just "hello" so that we can set form initial values properly
export const generateInitialName = (templateName: string, nodetype: INodeTypeType): string => {
  if (nodetype && templateName.includes(nodetype.name)) {
    return templateName.substring(nodetype.name.length + 1)
  } else {
    return templateName
  }
}

export const NodeTypeTemplateForm = () => {
  const params = useParams<{ nodetypeTemplateId: string; templateId?: string }>()
  const { nodetypeTemplateId, templateId } = params
  const isEditMode = nodetypeTemplateId !== 'new'
  const createFromExistingTemplate = templateId != null

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  const classes = UseNTTFormStyles()

  const [currentTemplate, setCurrentTemplate] = useState<INodeTypeTemplateType | null>(null)
  const [currentSource, setCurrentSource] = useState<string>('')
  const [updatedSourceValue, setUpdatedSourceValue] = useState<string>('')
  const [nodetypes, setNodetypes] = useState<INodeTypeType[]>([])
  const [nodetypesError, setNodetypesError] = useState<string>('')
  const [nodetypesLoading, setNodetypesLoading] = useState<boolean>(false)
  const [roles, setRoles] = useState<IRoleType[]>([])
  const [rolesError, setRolesError] = useState<string>('')
  const [isChannelsPanelOpen, setIsChannelsPanelOpen] = useState<boolean>(true)

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  useDocumentTitle('Nodetype Templates', isEditMode ? currentTemplate?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    setNodetypesLoading(true)

    Api.get('/api/roles').then(rolesData => {
      if (isSubscribed) {
        if (rolesData.error) {
          setRolesError(rolesData.message)
          addAlert({ alertType: 'error', message: rolesData?.message || 'Could not load roles' })
        } else {
          setRoles(rolesData)
        }
      }
    })

    Api.get('/api/nodetypes').then(nodetypesData => {
      if (isSubscribed) {
        setNodetypesLoading(false)
        if (nodetypesData.error) {
          setNodetypesError(nodetypesData.message)
          addAlert({ alertType: 'error', message: nodetypesData?.message || 'Could not load nodetypes' })
        } else {
          setNodetypes(nodetypesData)
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true

    if (isEditMode) {
      Api.get(`/api/nodetypetemplates/${nodetypeTemplateId}`).then((retrievedTempl: INodeTypeTemplateType) => {
        redirectToListViewOnError(retrievedTempl, history, addAlert, '/nodetype-templates')

        if (isSubscribed) {
          setCurrentTemplate(retrievedTempl)

          Api.get(`/api/nodetypetemplates/${nodetypeTemplateId}/source`).then(retrievedSource => {
            if (typeof retrievedSource === 'string') {
              setCurrentSource(retrievedSource)
            }
            // in case of currupted data, we can stringify an object, unless it's an object with error
            else if (typeof retrievedSource === 'object' && !retrievedSource.error) {
              const source = JSON.stringify(retrievedSource)
              setCurrentSource(source)
            }
          })
        }
      })
    }

    return () => {
      isSubscribed = false
    }
  }, [isEditMode, nodetypeTemplateId, history, addAlert])

  const saveTemplate = async (values: any, actions: FormikHelpers<any>) => {
    setUpdatedSourceValue(values.source)

    let allowedRoles
    if (roles != null && values.allowedRoles != null) {
      const isFull = values.allowedRoles.length === roles.length
      allowedRoles = isFull ? [] : values.allowedRoles
    }

    const payload: INodetypeTemplatePayload = {
      name: createFromExistingTemplate ? `${values.disabledNodeTypeName}${values.name}` : values.name,
      allowedRoles,
      nodeTypeId: values.nodeTypeId,
      position: values.position || null,
      vanityName: values.vanityName,
    }

    let submittedTemplate

    try {
      if (!isEditMode) {
        handleFormSubmit(
          (submittedTemplate = await Api.post(`/api/nodetypetemplates`, payload)),
          addAlert,
          history,
          `/nodetype-templates/${submittedTemplate.id}`,
          values,
          actions
        )

        await Api.putFile(`/api/nodetypetemplates/${submittedTemplate.id}/source`, values.source)
      } else {
        payload.name = getSelectedNodeType(nodetypes, values.nodeTypeId).name + '-' + values.name

        handleFormSubmit((submittedTemplate = await Api.put(`/api/nodetypetemplates/${nodetypeTemplateId}`, payload)), addAlert, history, null, values, actions)

        await Api.putFile(`/api/nodetypetemplates/${nodetypeTemplateId}/source`, values.source)

        // to prevent form reset on submit in edit mode
        const updatedTemplate: INodeTypeTemplateType = {
          ...payload,
          createdAt: submittedTemplate.createdAt,
          updatedAt: submittedTemplate.updatedAt,
          id: submittedTemplate.id,
          usage: submittedTemplate.usage,
        }
        setCurrentTemplate(updatedTemplate)
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Something went wrong. Please try again.' })
    }

    actions.setSubmitting(false)
  }

  const toggleChannelPanel = () => {
    setIsChannelsPanelOpen(!isChannelsPanelOpen)
  }

  const deleteNodetypeTemplate = async () => {
    try {
      handleDelete(await Api.del(`/api/nodetypetemplates`, nodetypeTemplateId), addAlert, history, '/nodetype-templates')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete nodetype template' })
    }
  }

  const schema = Yup.object().shape({
    position: Yup.number().min(0, "Can't be less than 0"),
    name: Yup.string().required('Name is required'),
    vanityName: Yup.string().required('Vanity name is required'),
    nodeTypeId: Yup.number()
      .required()
      .positive('Please select a node type'), // -1 is used to indicate that no value is selected in signle-select, so we need postiive() validation here
    allowedRoles: Yup.string().required('At least one role is required'),
  })

  const initialAllowedRoles =
    isEditMode && currentTemplate && currentTemplate.allowedRoles && currentTemplate.allowedRoles.length === 0
      ? roles && roles.map(role => role.id)
      : currentTemplate && currentTemplate.allowedRoles

  const initialValues =
    isEditMode && currentTemplate
      ? {
          name: generateInitialName(currentTemplate.name, getSelectedNodeType(nodetypes, currentTemplate.nodeTypeId)),
          vanityName: currentTemplate.vanityName,
          position: currentTemplate.position,
          source: getSourceInitValue(updatedSourceValue, currentSource),
          allowedRoles: initialAllowedRoles,
          nodeTypeId: currentTemplate.nodeTypeId,
          disabledNodeTypeName:
            currentTemplate.nodeTypeId &&
            getSelectedNodeType(nodetypes, currentTemplate.nodeTypeId) &&
            getSelectedNodeType(nodetypes, currentTemplate.nodeTypeId).name
              ? getSelectedNodeType(nodetypes, currentTemplate.nodeTypeId).name + '-'
              : '',
        }
      : createFromExistingTemplate && templateId
      ? {
          name: '',
          vanityName: '',
          position: '',
          source: '',
          allowedRoles: [],
          nodeTypeId: +templateId,
          disabledNodeTypeName:
            getSelectedNodeType(nodetypes, +templateId) && getSelectedNodeType(nodetypes, +templateId).name
              ? getSelectedNodeType(nodetypes, +templateId).name + '-'
              : '',
        }
      : {
          name: '',
          vanityName: '',
          position: '',
          source: '',
          allowedRoles: [],
          nodeTypeId: null,
          disabledNodeTypeName: '',
        }

  const rolesMessage = 'Users with these roles can edit this node type template in the Admin application and view this template in the Dashboard application.'

  return (
    <>
      {!rolesError && !nodetypesError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveTemplate} validationSchema={schema}>
          {({ values, isValid, setFieldValue, isSubmitting, dirty }) => (
            <Form>
              <Card>
                <CardHeader title={isEditMode ? 'Edit Nodetype Template' : 'Create Nodetype Template'} data-cy="nodetypeTemplatesTitle" />

                <CardContent>
                  <Grid container={true} spacing={3} data-cy="nodeTypeTemplatesCEView">
                    <Grid item={true} xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Grid container={true} alignItems="center" justifyContent="center">
                        {(isEditMode || createFromExistingTemplate) && (
                          <Grid item={true} xs={4}>
                            <FormikTextField
                              initialized={isEditMode}
                              data-testid="nodeTypeName"
                              name="disabledNodeTypeName"
                              label=" "
                              disabled={true}
                              className={classes.disabledNodeTypeName}
                              helperText=" " // empty helperText to maintain items alingment after material v4 update
                            />
                          </Grid>
                        )}

                        <Grid item={true} xs={isEditMode || createFromExistingTemplate ? 8 : 12}>
                          <FormikTextField
                            initialized={isEditMode}
                            id="name"
                            name="name"
                            data-cy="templateName"
                            label="Name"
                            required={true}
                            helperText="Not visible in the dashboard"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={6} sm={6} md={3} lg={4} xl={4}>
                      <FormikTextField
                        initialized={isEditMode}
                        id="vanity"
                        name="vanityName"
                        label="Vanity Name"
                        data-cy="vanityName"
                        required={true}
                        helperText="Visible in the dashboard"
                      />
                    </Grid>

                    <Grid item={true} xs={6} sm={6} md={3} lg={4} xl={4}>
                      <FormikTextField
                        initialized={isEditMode}
                        id="position"
                        name="position"
                        label="Position"
                        data-cy="position"
                        required={false}
                        helperText="The order this template is listed in the dashboard"
                        type="number"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <span className={classes.codemirrorLabel}>Source</span>

                      <div className={classes.channelToggleBtn} onClick={toggleChannelPanel} data-cy="toggleChannelsBtn">
                        Show/Hide Channels
                      </div>
                    </Grid>

                    <Grid
                      item={true}
                      xs={12}
                      sm={12}
                      md={isChannelsPanelOpen ? 6 : 12}
                      lg={isChannelsPanelOpen ? 8 : 12}
                      xl={isChannelsPanelOpen ? 8 : 12}
                      data-cy="codeMirror"
                    >
                      <CodemirrorWithTags
                        formValues={values}
                        setFieldValue={setFieldValue}
                        helpText="One riot tag must have the same name as its nodetype template. That tag and any children will render in the node's tabs on the dashboard"
                      />
                    </Grid>

                    {isChannelsPanelOpen && (
                      <Grid item={true} xs={12} sm={12} md={6} lg={4} xl={4} data-cy="channelsWindow">
                        <div className={classes.channelsLabel}>Click a channel to copy it to the clipboard</div>

                        <ChannelsList nodeTypeId={values.nodeTypeId} nodeTypes={nodetypes} />
                      </Grid>
                    )}

                    <Grid item={true} xs={12}>
                      <OfficialDocsLink url="https://github.com/meshifyiot/public-carbon-docs/tree/master/cms/templates" />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.denseFormSubheader} variant="subtitle1">
                        Node Type
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={7} lg={6} xl={6} data-cy="nodeType">
                      <NodeTypeIdFilterSelect
                        nodeTypeId={values.nodeTypeId}
                        nodeTypes={nodetypes}
                        createFromExistingTemplate={createFromExistingTemplate}
                        isEditMode={isEditMode}
                        setFieldValue={setFieldValue}
                        loading={nodetypesLoading}
                        currentTemplate={currentTemplate}
                      />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={5} lg={6} xl={6}>
                      <Alert alertType="warning" message="Make sure to store any globally accessible Riot tags in Global Nodetype Templates." />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Allowed Roles
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={7} lg={6} xl={6} data-cy="allowedRoles">
                      <FilterSelect<IRoleType, FormValues>
                        items={roles}
                        name="allowedRoles"
                        canSelectAll={true}
                        displayField="name"
                        entityName="Allowed Roles"
                        loading={roles.length === 0}
                        initialValues={initialValues.allowedRoles ? initialValues.allowedRoles : undefined}
                        required={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={5} lg={6} xl={6}>
                      <Alert alertType="warning" message={rolesMessage} />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" disabled={!isValid || isSubmitting || (!isEditMode && !dirty)} data-cy="saveBtn" data-testid="saveBtn">
                    Save
                  </Button>

                  <Button type="button" onClick={() => history.push('/nodetype-templates')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteNodetypeTemplate}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this nodetype template?"
      />

      {(rolesError || nodetypesError) && (
        <PermissionsFormError
          errorMessages={combineErrorMessages(rolesError, nodetypesError)}
          formName="Nodetype Template Form"
          listViewRoute="/nodetype-templates"
          hasGoBackButton={true}
        />
      )}
    </>
  )
}

export default NodeTypeTemplateForm
