import { grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) => {
  return createStyles({
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      margin: '2px 0 0 0',
      left: 0,
      right: 0,
      maxHeight: 400,
      minWidth: 200,
      overflowY: 'auto',
      paddingBottom: theme.spacing(),
    },
    suggestion: {
      display: 'block',
      color: theme.palette.common.white,
      margin: 0,
      padding: 0,
      '& div': {
        padding: theme.spacing(0.5),
      },
      '& span': {
        fontWeight: 600,
      },
      '& strong': {
        fontWeight: 300,
      },
      '& a': {
        textDecoration: 'none',
      },
    },
    suggestionsList: {
      margin: 0,
      listStyleType: 'none',
      padding: theme.spacing(),
      paddingBottom: 0,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
      margin: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(),
        width: 300,
      },
    },
    searchIconWrapper: {
      width: theme.spacing(9),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(),
      justifyContent: 'left',
    },
    searchIcon: {
      color: theme.palette.mode === 'light' ? grey[600] : grey[200],
    },
    sectionContainer: {
      paddingBottom: 0,
    },
    inputRoot: {
      color: grey[500],
      width: '100%',
    },
    sectionTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme?.palette?.background?.default ?? undefined,
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
      fontWeight: 'bolder',
      height: 30,
      padding: '0px',
    },
    inputInput: {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(4),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '10px',
        paddingTop: '6px',
      },
      '&::placeholder': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
        },
      },
    },
    metadataChip: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '10px',
      backgroundColor: theme.palette.background.paper,
      fontSize: '11px',
      padding: '3px 6px',
      marginLeft: '5px',
    },
    loadingPaperContainer: {
      position: 'absolute',
      left: '212px',
      top: '50px',
      height: '100px',
      width: '300px',
      [theme.breakpoints.only('xs')]: {
        left: '57px',
        top: '43px',
        width: '259px',
      },
    },
    loadingPaper: {
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
    clearSearchIcon: {
      position: 'absolute',
      top: '9px',
      right: '9px',
      cursor: 'pointer',
      color: grey[800],
    },
    suggestionsWrapper: {
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    },
  })
}

const useStyles = makeStyles(styles)

export default useStyles
