import OpenInNew from '@mui/icons-material/OpenInNew'

import useOfficialDocStyles from './official-doc-link.styles'

type Props = {
  url: string
}

const OfficialDocLink = ({ url }: Props) => {
  const classes = useOfficialDocStyles()
  return (
    <a target="_blank" rel="noopener noreferrer" href={url} className={classes.documentationLink}>
      Open Documentation
      <OpenInNew className={classes.icon} />
    </a>
  )
}

export default OfficialDocLink
