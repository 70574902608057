import { useContext, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import { NumberParam, useQueryParam } from 'use-query-params'

import { READ_PERMISSIONS_LIST } from '../../constants/permissions'
import { OwnUserContext, UserPermissionsContext } from '../../providers'
import useSharedFormStyles from '../../shared-styles/form.styles'
import { useDocumentTitle } from '../../utils/hooks'

import PrivateFiles from './private-files'
import PublicFiles from './public-files'

export const isPrivateTabDisabled = (userPermissions: IPermissionType[], loggedInUser: IUserType | null): boolean => {
  if (loggedInUser?.isRoot) {
    return false
  } else {
    if (userPermissions.length === 0) {
      return true
    } else {
      return userPermissions.find(item => item.id === READ_PERMISSIONS_LIST.privateFiles.id) === undefined
    }
  }
}

const Files = () => {
  const classes = useSharedFormStyles()
  const defaultTabIndex = 0

  const [selectedTabIndex, setSelectedTabIndex] = useQueryParam('tab', NumberParam)

  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)
  const currentUser = useContext<IUserType | null>(OwnUserContext)

  useDocumentTitle('Files')

  useEffect(() => {
    if (!selectedTabIndex) {
      setSelectedTabIndex(0)
    }
  }, [selectedTabIndex, setSelectedTabIndex])

  return (
    <Card>
      <CardHeader title="Files" />

      <CardContent>
        <Tabs
          value={selectedTabIndex ? selectedTabIndex : defaultTabIndex}
          onChange={(event: React.ChangeEvent<{}>, value: number) => setSelectedTabIndex(value, 'push')}
          textColor="secondary"
          indicatorColor="secondary"
          className={classes.tabs}
        >
          <Tab label="Public" data-cy="public-tab" />

          {isPrivateTabDisabled(userPermissions, currentUser) ? (
            <Tooltip title="You do not have permissions to read private files" placement="right">
              <span className={classes.noCursor}>
                {' '}
                {/* span needed for tooltip to be displayed on disabled button (tab is a button) */}
                <Tab label="Private" disabled={true} />
              </span>
            </Tooltip>
          ) : (
            <Tab label="Private" data-cy="private-tab" />
          )}
        </Tabs>

        {selectedTabIndex === 0 && <PublicFiles />}
        {selectedTabIndex === 1 && <PrivateFiles />}
      </CardContent>
    </Card>
  )
}

export default Files
