import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Chip, Grid, Typography } from '@mui/material'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'
import useDomainStyles from '../domains/domains.styles'

import { AlertContext, UserPermissionsContext } from './../../providers'

/*
It is expected that a nodetype has at least one role,
the empty list signifies that all roles are selected.
*/
export const getRoles = (allowedRoles: number[], allRoles: IRoleType[]): IRoleType[] => {
  if (allowedRoles.length === 0) {
    return []
  } else {
    const allowedRolesList: IRoleType[] = []
    allRoles.forEach(role => {
      allowedRoles.forEach(allowedRoleId => {
        if (role.id === allowedRoleId) {
          allowedRolesList.push(role)
        }
      })
    })

    return allowedRolesList
  }
}

const AllowedRoleChip = ({ role }: { role: IRoleType }) => {
  const classes = useDomainStyles()
  const history = useHistory()

  return (
    <Chip
      label={role.name}
      clickable={true}
      variant="outlined"
      onClick={e => {
        e.stopPropagation()
        history.push(`/roles/${role.id}`)
      }}
      key={role.id}
      className={classes.chips}
    />
  )
}

const NodeTypesList = () => {
  const [nodetypes, setNodetypes] = useState<INodeTypeType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const searchResultsHook = useSearchData()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const classes = useTableStyles()

  useDocumentTitle('Node Types')

  const columns = [
    { name: 'Name', options: { filter: true, sort: true } },
    { name: 'Vanity Name', options: { filter: true, sort: true } },
    { name: 'Allowed Roles', options: { filter: true, sort: false } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchNodeTypesData = async () => {
    const retreivedNodetypes = await Api.get('/api/nodetypes')
    const roles = await Api.get('/api/roles')

    if (retreivedNodetypes.error) {
      addAlert({ alertType: 'error', message: retreivedNodetypes.message })
      return Promise.reject(retreivedNodetypes)
    }

    if (roles.error) {
      addAlert({ alertType: 'error', message: roles.message })
    }

    const allRowData: React.ReactNode[][] = retreivedNodetypes.map((nodeType: INodeTypeType) => {
      const allowedRoles = !roles.error ? getRoles(nodeType.allowedRoles, roles) : null

      const allowedRolesMaxCount = 5
      let rolesLink
      if (!roles.error && allowedRoles) {
        if (allowedRoles.length > 0) {
          if (allowedRoles.length < allowedRolesMaxCount) {
            rolesLink = allowedRoles.map(role => {
              return <AllowedRoleChip role={role} />
            })
          } else {
            rolesLink = (
              <>
                {allowedRoles.slice(0, allowedRolesMaxCount).map(role => {
                  return <AllowedRoleChip role={role} />
                })}
                <Typography variant="caption">+{allowedRoles.length - allowedRolesMaxCount} more</Typography>
              </>
            )
          }
        } else {
          rolesLink = (
            <Link to="/roles" className={classes.applicationLink} onClick={e => e.stopPropagation()}>
              All
            </Link>
          )
        }
      } else {
        rolesLink = ''
      }

      return [nodeType.name, nodeType.vanityName, rolesLink, convertTimestampToDate(nodeType.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setNodetypes(retreivedNodetypes) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchNodeTypesData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  const NewNodeTypeLink = forwardRef((buttonProps: any, ref: any) => <Link to="/node-types/new" ref={ref} {...buttonProps} />)

  return (
    <Grid container={true} justifyContent="flex-end" data-cy="nodeTypesList" className={classes.tableContainer}>
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewNodeTypeLink}
              entityName="Node Types"
              buttonName="Add Node Type"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Node Types"
          entityDeleteEndpoint="/api/nodetypes"
          idList={nodetypes ? nodetypes.map(n => ({ id: n.id })) : []}
          refresh={() => setRefreshCounter(refreshCounter + 1)}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          preserveSearchResults={{ ...searchResultsHook }}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, nodetypes, 'node-types', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="node-types"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!nodetypes?.find(nodetype => nodetype.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default NodeTypesList
