import { useContext, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { isNaN } from 'lodash'

import { AlertContext } from '../../../providers'
import Api from '../../../utils/api'
import { useToggle } from '../../../utils/hooks'
import { IRuleFormValues } from '../create-edit'
import useRulesStyles from '../create-edit.styles'

type ITestOutputProps = {
  values: IRuleFormValues
}

interface ITestRulePayload {
  alwaysReact: boolean
  channelName: string
  channelValueOverride: object[]
  condition: string
  nodeId: number
  timestamp: string
  value: boolean | string | number
}

export const parseTestPayload = (testValue: string): boolean | string | number => {
  if (testValue.toLocaleLowerCase() === 'true' || testValue.toLowerCase() === 'false') {
    return (testValue === 'false') !== Boolean(testValue)
  } else if (!isNaN(+testValue)) {
    return +testValue
  }
  return testValue
}

const TestOutput = (props: ITestOutputProps) => {
  const { values } = props

  const [isRuleTested, toggleIsRuleTested] = useToggle(false)
  const [testRuleResult, setRuleTestResult] = useState<string>('')

  const classes = useRulesStyles()
  const { addAlert } = useContext(AlertContext)

  const testRule = async (valuesParam: IRuleFormValues) => {
    if (isRuleTested) {
      setRuleTestResult('')
      toggleIsRuleTested()
    }

    const parsedChannelOverrides =
      valuesParam.channelOverrideItems.map.length > 0
        ? valuesParam.channelOverrideItems.map((channelOverride: any) => {
            return {
              channelName: channelOverride.channelName,
              timestamp: channelOverride.timestamp ? new Date(channelOverride.timestamp).toISOString() : new Date().toISOString(),
              value: parseTestPayload(channelOverride.value),
            }
          })
        : []

    const body: ITestRulePayload = {
      alwaysReact: valuesParam.alwaysReact,
      channelName: valuesParam.channelName,
      channelValueOverride: parsedChannelOverrides,
      condition: valuesParam.condition,
      nodeId: valuesParam.selectedNode,
      timestamp: valuesParam.payloadTimeStamp ? new Date(valuesParam.payloadTimeStamp).toISOString() : new Date().toISOString(),
      value: parseTestPayload(valuesParam.testValue),
    }

    try {
      const result = await Api.put('/api/rules/test', body)
      toggleIsRuleTested()
      setRuleTestResult(result)

      if (result.error) {
        addAlert({ message: 'Tested with error', alertType: 'error' })
      } else {
        addAlert({ message: 'Test Rule Completed', alertType: 'success' })
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not test the rule' })
    }
  }

  return (
    <>
      <Button
        className={classes.testButton}
        data-testid="testRuleBtn"
        data-cy="testRuleBtn"
        color="secondary"
        variant="outlined"
        disabled={values.selectedNode <= 0 || values.testValue === ''}
        onClick={() => testRule(values)}
      >
        Test Rule
      </Button>

      <div className={classes.prettyTestOutputWrapper}>
        <JSONPretty data-testid="test-result" id="json-pretty" json={testRuleResult} />
      </div>

      <>
        {values.selectedNode === 0 && (
          <Typography variant="caption" className={classes.helpText}>
            Please select a node.
          </Typography>
        )}

        {values.testValue === '' && (
          <Typography variant="caption" className={classes.helpText}>
            Please enter a test value.
          </Typography>
        )}
      </>
    </>
  )
}

export default TestOutput
