import { grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    mainContentWrapper: {
      height: '100%',
      padding: '50px',
      overflow: 'hidden',
      width: '100%',
    },
    messageGrid: {
      height: '80%',
      overflow: 'hidden',
    },
    messageGridItem: {
      overflow: 'hidden',
      padding: 60,
      backgroundColor: grey[100],
      borderRadius: 5,
    },
    divider: {
      margin: 10,
    },
    errorImage: {
      marginBottom: '15px',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
