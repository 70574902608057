import { grey, red } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    recommendedKey: {
      color: theme.palette.primary.main,
      marginLeft: '5px',
      cursor: 'pointer',
    },
    recommendedKeysWrapper: {
      color: grey[600],
      fontSize: '13px',
      margin: '30px 0 0 27px',
    },
    errorText: {
      color: red[600],
    },
    rowWrapper: {
      marginBottom: '15px',
    },
    tooltipWidth: {
      maxWidth: '850px',
    },
    logoTooltipWrapper: {
      backgroundColor: 'white',
      padding: theme.spacing(1),
    },
    logoImg: {
      border: '1px dashed black',
      maxWidth: '800px',
      color: 'black',
      objectFit: 'contain',
    },
    colorBox: {
      width: '100px',
      height: '60px',
      color: 'black',
    },
    agreementBox: {
      color: 'black',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
