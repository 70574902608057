import { useCallback, useContext, useRef, useState } from 'react'
import * as React from 'react'
import NotificationSystem from 'react-notification-system'
import { green, red, yellow } from '@mui/material/colors'

import { AlertTypes, titles } from './../components/alert'
import { ThemeContext } from './theme'

export const AlertContext = React.createContext<IAlertContext>({
  addAlert: () => {},
})

const { Provider } = AlertContext

export const AppAlerts = (props: { children: React.ReactNode | React.ReactNode[] }) => {
  const [notifications, setNotifications] = useState<Array<{ alertType: AlertTypes; message: string; timestamp: number }>>([])
  const notificationSystem = useRef<any>()
  const { themeKey } = useContext(ThemeContext)

  const addAlert = useCallback((alert: { alertType: AlertTypes; message: string }) => {
    const timestamp = Date.now()
    const { alertType, message } = alert

    const dedupe = ({ alertType: type, message: msg, timestamp: ts }: { alertType: AlertTypes; message: string; timestamp: number }) => {
      if (type === alertType && message === msg) {
        // If within 20 ms, dont show another message
        if (Math.abs(ts - timestamp) < 20000) {
          return true
        }
      } else {
        return false
      }
    }

    /** Adds a global alert and creates a removeAlert function keyed off of the timeStamp fo the alerts */
    const ns = notificationSystem.current
    if (ns && notifications.find(dedupe) == null) {
      setNotifications(notifications.concat([{ ...alert, timestamp }]))

      ns.addNotification({
        title: titles[alert.alertType],
        level: alert.alertType,
        message: alert.message,
      })
    }
    // we don't want to add notifications in dependence  array
    // it causes unneeded extra alerts in some views
    // eslint-disable-next-line
  }, [])

  // for the light mode use the package default colors, for the dark mode override with darker, more contrast colors
  const style = {
    Containers: {
      DefaultStyle: {},
      tr: {
        top: '85px',
        bottom: 'auto',
        left: 'auto',
        right: '0px',
      },
    },
    NotificationItem: {
      DefaultStyle: {
        wordBreak: 'break-word' as const,
      },
      error: {
        backgroundColor: themeKey === 'dark' ? red[500] : 'rgb(244, 233, 233)',
      },
      warning: {
        backgroundColor: themeKey === 'dark' ? yellow[500] : 'rgb(249, 246, 240)',
      },
      success: {
        backgroundColor: themeKey === 'dark' ? green[500] : 'rgb(240, 245, 234)',
        color: themeKey === 'dark' ? 'black' : 'rgb(75, 88, 58)',
      },
    },
    Title: {
      error: {
        color: themeKey === 'dark' ? 'black' : 'rgb(236, 61, 61)',
      },
      warning: {
        color: themeKey === 'dark' ? 'black' : 'rgb(235, 173, 26)',
      },
      success: {
        color: themeKey === 'dark' ? 'black' : 'rgb(94, 164, 0)',
      },
    },
    Dismiss: {
      error: {
        color: themeKey === 'dark' ? 'black' : '#f4e9e9',
        backgroundColor: themeKey === 'dark' ? 'none' : '#e4bebe',
      },
      warning: {
        color: themeKey === 'dark' ? 'black' : '#f9f6f0',
        backgroundColor: themeKey === 'dark' ? 'none' : '#e1cfac',
      },
      success: {
        color: themeKey === 'dark' ? 'black' : '#f0f5ea',
        backgroundColor: themeKey === 'dark' ? 'none' : '#b0ca92',
      },
    },
  }

  const memoizedValue = React.useMemo(() => ({ addAlert }), [addAlert])

  return (
    <Provider value={memoizedValue}>
      <NotificationSystem style={style} ref={notificationSystem} />
      {props.children}
    </Provider>
  )
}
