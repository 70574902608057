import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Field } from 'formik'

interface ICheckboxProps {
  name: string // name of a form control
  label: string // checkbox label
  disabled?: boolean // is checkbox disabled
  color?: 'primary' | 'secondary' | 'default'
}

export default ({ name, label, disabled, color }: ICheckboxProps) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                color={color != null ? color : 'secondary'}
                name={name}
                disabled={disabled}
                onKeyPress={e => {
                  e.preventDefault()
                  // Handle enter keypress (charCode == 13)
                  if (e.charCode === 13) {
                    form.setFieldValue(name, !field.value)
                  }
                }}
                checked={field.value}
                onChange={(event: object, checked: boolean) => {
                  form.setFieldValue(name, checked)
                }}
                inputProps={
                  {
                    id: `${name}-checkbox`,
                    'data-testid': `${name}-checkbox`,
                    'aria-labelledby': `${name}-checkbox-label`,
                    'aria-label': label,
                    value: field.value,
                    'data-cy-checked': field.value,
                  } as any
                }
              />
            }
            label={label}
          />
        )
      }}
    </Field>
  )
}
