import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Alert from '../../components/alert'
import FilesList from '../../components/file-upload/file-list'
import FileUploader from '../../components/file-upload/file-uploader'
import Api from '../../utils/api'

import useFileStyles from './files.styles'

const PublicFiles = () => {
  const [publicFiles, setPublicFiles] = useState<string[]>([])
  const [filesError, setFilesError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const classes = useFileStyles()

  useEffect(() => {
    let isSubscribed = true
    setLoading(true)
    Api.get('/api/files/public').then(res => {
      setLoading(false)
      if (isSubscribed && !res.error) {
        setPublicFiles(res)
      } else {
        setFilesError(res?.message || 'Public files can not be viewed')
        setPublicFiles([])
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [])

  const handlePublicFiles = (files: string[]) => {
    setPublicFiles(files)
  }

  const getFilesMessage = (): string => {
    const url = window.location.origin.includes('localhost') ? 'https://carbon.meshbot.co/api/files/public' : `${window.location.origin}/api/files/public`

    return `Authentication is not required, public files can be freely accessed at ${url}`
  }

  return (
    <div className={classes.tabContentWrapper}>
      <Grid container={true} spacing={3}>
        {!filesError && !loading && (
          <Grid item={true} xs={12}>
            <Alert alertType="warning" message={getFilesMessage()} />
          </Grid>
        )}

        {filesError && !loading && (
          <Grid item={true} xs={12}>
            <Alert alertType="warning" message="It is still possible to upload public files, however viewing files is disabled" />
          </Grid>
        )}

        <FileUploader setFiles={handlePublicFiles} filesEndpoint="/api/files/public/" />

        <Grid item={true} xs={12}>
          <Typography className={classes.denseFormSubheader} variant="subtitle1">
            Files
          </Typography>
        </Grid>

        {publicFiles.length === 0 && !filesError && !loading && <Alert alertType="warning" message="No public files. Upload one to begin" />}

        {publicFiles.length > 0 && !filesError && !loading && (
          <FilesList filesList={publicFiles} setAllFiles={(val: any) => setPublicFiles(val)} fileEndpoint="/api/files/public" />
        )}

        {filesError && !loading && (
          <Grid item={true} xs={6}>
            <Alert alertType="error" message={filesError} />
          </Grid>
        )}

        {loading && (
          <Grid item={true} xs={12} container={true} justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default PublicFiles
