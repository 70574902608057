import { orange } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    warningOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: `${orange[800]} !important`,
    },
    cssLabel: {
      color: orange[800],
    },
    warningNotification: {
      color: orange[800],
    },
    rowWrapper: {
      marginBottom: '15px',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
