import { grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    scheduleButton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      float: 'left',
    },
    deleteScheduleButton: {
      margin: theme.spacing(),
    },
    timeSelectionContainer: {
      border: '1px solid' + grey[400],
      marginTop: theme.spacing(),
    },
    timePicker: {
      width: '30%',
      margin: theme.spacing(),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      width: '100%',
    },
    fullDayMargin: {
      marginTop: '-25px',
    },
    errorText: {
      fontSize: '0.90rem',
    },
    label: {
      lineHeight: '23px',
      letterSpacing: '0.15px',
      fontSize: '1.1rem',
      margin: '5px 0 20px 0',
      fontWeight: 500,
      marginTop: theme.spacing(),
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)

export default useStyles
