import { grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    menuItem: {
      color: theme.palette.primary.main,
    },
    activeMenuItem: {
      backgroundColor: grey[200],
    },
    disabledSidenavItem: {
      cursor: 'not-allowed',
      color: grey[500],
    },
    listItem: {
      padding: '0.7rem 1rem',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
