import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { NumberParam, useQueryParam } from 'use-query-params'

import useSharedFormStyles from '../../shared-styles/form.styles'

import RoleForm from './role-form'
import RoleUsers from './role-users'

const CreateEditRole = () => {
  const classes = useSharedFormStyles()
  const params = useParams<{ roleId: string }>()

  const roleId = params.roleId
  const isEditMode = roleId !== 'new'
  const defaultTabIndex = 0

  const [selectedTabIndex, setSelectedTabIndex] = useQueryParam('tab', NumberParam)

  useEffect(() => {
    if (!selectedTabIndex) {
      setSelectedTabIndex(0)
    }
  }, [selectedTabIndex, setSelectedTabIndex])

  return (
    <>
      {!isEditMode && (
        <Card>
          <CardHeader title="Create Role" data-testid="formTitle" data-cy="formTitle" />

          <CardContent data-testid="formTitle">
            <RoleForm />
          </CardContent>
        </Card>
      )}

      {isEditMode && (
        <Card>
          <CardHeader title="Edit Role" data-testid="formTitle" data-cy="formTitle" />

          <CardContent data-testid="formTitle">
            <Tabs
              value={selectedTabIndex ? selectedTabIndex : defaultTabIndex}
              onChange={(event: React.ChangeEvent<{}>, value: number) => setSelectedTabIndex(value, 'push')}
              textColor="secondary"
              indicatorColor="secondary"
              className={classes.tabs}
            >
              <Tab label="Role Info" />
              <Tab label="Role Users" />
            </Tabs>

            {selectedTabIndex === 0 && <RoleForm />}
            {selectedTabIndex === 1 && <RoleUsers roleId={Number(roleId)} />}
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default CreateEditRole
