import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    applicationLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:visited': {
        color: theme.palette.primary.main,
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    formSubheader: {
      margin: '40px 0 15px 0',
      color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[600],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    denseFormSubheader: {
      margin: '10px 0',
      color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[600],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    helperText: {
      margin: 0,
    },
    deleteBtn: {
      color: theme.palette.error.main,
    },
    checkBoxHelperText: {
      margin: 0,
    },
    noPadding: {
      padding: 0,
    },
    codemirror: {
      border: '1px solid' + theme.palette.grey[400],
    },
    fileActionsWrapper: {
      marginTop: '10px',
    },
    imageFile: {
      maxWidth: '100%',
    },
    tabs: {
      borderBottom: '1px solid' + theme.palette.grey[300],
    },
    border: {
      border: '1px solid' + theme.palette.grey[400],
    },
    codemirrorLabel: {
      color: theme.palette.grey[600],
      marginBottom: '5px',
    },
    codemirrorHelpText: {
      color: theme.palette.grey[600],
      fontSize: '0.8rem',
      float: 'right',
    },
    testOutputMessage: {
      fontFamily: 'monospace',
    },
    centerItem: {
      margin: '0 auto',
    },
    permissionsAlertWrapper: {
      margin: '20px',
    },
    noCursor: {
      cursor: 'not-allowed',
    },
    fullDayWarningCopy: {
      fontSize: '13px',
      color: theme.palette.warning.main,
    },
    warningMargin: {
      marginTop: '1rem',
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
