import { MutableRefObject } from 'react'
import { History } from 'history'

import api from '../api'
import { IPaginationState } from '../hooks'

type TableParams = Pick<IPaginationState, 'pageNumber' | 'rowsPerPage' | 'searchText' | 'currentSort' | 'sortDir'>

/** default params for back end paginated tables */
export const defaultTableParams: TableParams = { pageNumber: 0, rowsPerPage: 10, searchText: '', currentSort: '', sortDir: 'none' }

/** default params for front end paginated tables */
export const defaultFrontEndTableParams = { pageNumber: 0, rowsPerPage: 10, searchText: '' }

export const constructTableParams = (tableId: string, customValues?: Partial<TableParams>) => {
  const params = {} as { [key: string]: number | string }
  // Zip and merge custom values with default
  for (const key of Object.keys(defaultTableParams)) {
    const uniqueKey = tableId !== '' ? `${tableId}-${key}` : key
    if (customValues && customValues[key as keyof TableParams] !== undefined) {
      params[uniqueKey] = customValues[key as keyof TableParams]!
    } else {
      params[uniqueKey] = defaultTableParams[key as keyof TableParams]
    }
  }
  // Turn object params into a string by getting entries and reducing array into string
  return Object.entries(params).reduce((acc, [key, value], idx, arr) => {
    // Dont add an ampersand for the last item
    return acc.concat(
      idx !== arr.length - 1
        ? `${key}=${encodeURIComponent(value !== undefined ? value : '')}&`
        : `${key}=${encodeURIComponent(value !== undefined ? value : '')}`
    )
  }, '?')
}

/** construct url params for Front End paginated tables */
export const constructFrontEndTableParams = (customValues: Partial<TableParams> & { tab?: string }) => {
  const params = {} as { [key: string]: number | string }

  for (const key of Object.keys(defaultFrontEndTableParams)) {
    if (customValues && customValues[key as keyof TableParams] !== undefined) {
      params[key] = customValues[key as keyof TableParams]!
    } else {
      params[key] = defaultTableParams[key as keyof TableParams]
    }
  }

  // Turn object params into a string by getting entries and reducing array into string
  let constructedParam = Object.entries(params).reduce((acc, [key, value], idx, arr) => {
    // Dont add an ampersand for the last item
    return acc.concat(
      idx !== arr.length - 1
        ? `${key}=${encodeURIComponent(value !== undefined ? value : '')}&`
        : `${key}=${encodeURIComponent(value !== undefined ? value : '')}`
    )
  }, '?')

  // if table is displayed within a form tab and tab param exists, we should preserve it
  if (customValues?.tab) {
    constructedParam = `?tab=${customValues.tab}&` + constructedParam.substring(1)
  }

  return constructedParam
}

export const getParams = (location: { pathname: string; search: string }) => {
  // TODO potentially refactor use URLSearchParams builtin
  const params = location?.search.split('&')
  const keyValue = params.map(param => param.split('='))

  const paramStore = keyValue.reduce((acc, [key, value]) => {
    const sanitizedKey = key.replace('?', '')
    if (sanitizedKey) {
      acc[sanitizedKey] = decodeURIComponent(value)
    }
    return acc
  }, {} as { [key: string]: string | number | undefined })

  // Rpp and pageNumber should be assigned defaults and converted to number types for mui-datatables

  return paramStore
}

/**
 * Function to handle click of table row.
 * @param history - React Router history function that will be used to push to new link.
 * @param statefulEntities - A subset of some backend entities that will have an id to be referenced
 * @param entityPath - Path to where this entity exists in our route declaration hierarchy
 * @param colData - Unused param included to match onCellClick api
 * @param cellMeta - Object containing dataindex of particular entity in cell click function.
 * @param search - searchParams to be added
 *
 */
export const handleCellClick = (
  history: History<any>,
  statefulEntities: IBackendEntityType[] | null,
  entityPath: string,
  cellMeta: MuiTableCellMetaType,
  abortToken?: MutableRefObject<boolean>,
  search?: string
) => {
  if (statefulEntities) {
    if (abortToken?.current !== undefined) {
      abortToken.current = true
    }
    const pathname = `/${entityPath}/${statefulEntities[cellMeta.dataIndex]?.id}`
    if (cellMeta.event.ctrlKey || cellMeta.event.metaKey) {
      window.open(`${window.location.pathname}#${pathname}`, '_blank')
    } else {
      history.push({
        pathname,
        search,
      })
    }
  }
}

/**
 * Function to delete items from table or from nodetype templates list view.
 * @param props - component props
 * @param entitiesToDelete - items to delete
 * @param entityDeleteEndpoint - an endpoint for deletion
 * @param toggleConfirmModal - a function to toggle confirmat deletion modal and refresh view after deletion
 *
 */
export const deleteEntities = async (
  addAlert: IAlertContext['addAlert'],
  entitiesToDelete: any,
  entityDeleteEndpoint: string | undefined,
  toggleConfirmModal: any
) => {
  if (entityDeleteEndpoint == null) {
    addAlert({
      alertType: 'warning',
      message: 'This table does not allow for the deletion of rows',
    })
  } else {
    if (entitiesToDelete) {
      const deletionResults = await Promise.all(entitiesToDelete.map((id: number) => api.del(entityDeleteEndpoint, id)))
      let rowsDeleted = 0
      let errMessage = ''
      deletionResults.forEach((res: any) => {
        if (!res.error) {
          rowsDeleted++
        } else {
          errMessage = res.message ? res.message : 'Deletion failed'
        }
      })
      if (rowsDeleted === entitiesToDelete.length) {
        addAlert({
          alertType: 'success',
          message: `${rowsDeleted} out of ${entitiesToDelete.length} rows succesfully deleted.`,
        })
      } else if (rowsDeleted > 0 && rowsDeleted < entitiesToDelete.length) {
        addAlert({
          alertType: 'warning',
          message: `${rowsDeleted} out of ${entitiesToDelete.length} rows succesfully deleted.`,
        })
      } else if (entitiesToDelete.length > 1 && rowsDeleted === 0) {
        addAlert({
          alertType: 'error',
          message: `All rows failed to delete.`,
        })
      } else {
        addAlert({ alertType: 'error', message: errMessage })
      }
    }
  }
  toggleConfirmModal(null, true)
}

/** offset uset for table back-end pagination */
export const getOffset = ({ pageNumber, rowsPerPage }: { rowsPerPage: number; pageNumber: number }): number => {
  let offset: number
  if (pageNumber && rowsPerPage) {
    offset = pageNumber * rowsPerPage
  } else {
    offset = 0
  }

  return offset
}

/** limit used for table back-end pagination */
export const getLimit = (queryParams: any): number => {
  let limit: number
  if (queryParams.rowsPerPage) {
    limit = queryParams.rowsPerPage
  } else {
    limit = 10
  }

  return limit
}

export const filterPlainTextColumn = (location: string, filters: string[]) => {
  if (filters.length) {
    return !filters.includes(location)
  }
  return false
}

/** filter table column that renders links */
export const filterLinkColumn = (location: any, filters: string[]) => {
  if (filters.length) {
    return !filters.includes(location.props.children)
  }
  return false
}

/** Active column renders 2 icons - either check or close icon
 *  we want to be able to filter by those icons based on selected value from Active dropdown in filters panel
 */
export const filterActiveColumn = (location: any, filters: string[]) => {
  if (filters.length) {
    if (filters[0] === 'Active') {
      return !(location.props.children === 'check')
    }

    if (filters[0] === 'Not Active') {
      return !(location.props.children === 'close')
    }
  }
  return false
}
