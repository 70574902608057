import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface ModalDialogProps {
  show: boolean
  closeModal: () => void
  confirmModalAction: () => void
  dialogTitle: string | null
  dialogContent?: string
}

const ModalDialog: React.FC<ModalDialogProps> = props => {
  const dialogTitle = props.dialogTitle && <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>

  return (
    <Dialog
      open={props.show}
      onClose={props.closeModal}
      data-cy="confirmation-modal"
      data-testid="confirmation-modal"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {dialogTitle}

      {props.dialogContent && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.dialogContent}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={props.closeModal} color="inherit" data-testid="cancel-modal">
          Cancel
        </Button>

        <Button data-cy="confirmDialogBtn" data-testid="confirmDialogBtn" onClick={props.confirmModalAction} color="primary" autoFocus={true}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDialog
