import { Link } from 'react-router-dom'
import { Check, Close } from '@mui/icons-material'
import { Checkbox, Grid, Paper, Typography } from '@mui/material'
import { FastField } from 'formik'

import useWhatsInsideStyles from '../whats-inside.styles'

interface GenericNodeListItemProps {
  index: number
  node: INodeType
  checkboxDisabled: boolean
}

const GenericNodeListItem = ({ node, index, checkboxDisabled }: GenericNodeListItemProps) => {
  const classes = useWhatsInsideStyles()

  return (
    <FastField
      name={`selectedNodes.${index}`}
      meta={{
        disabled: checkboxDisabled,
      }}
      shouldUpdate={(nextProps: any, currentProps: any) => {
        return (
          // if the list goes from being populated back to zero, update all the fields
          (nextProps.formik.values.selectedNodes.length === 0 && currentProps.formik.values.selectedNodes.length > 0) ||
          // if the name changes (index changes), update the field
          nextProps.name !== currentProps.name ||
          // if the disabled prop changes (due to max selection limit), update the field
          nextProps.meta.disabled !== currentProps.meta.disabled ||
          // if the value of the field changes, update it so the checkbox is selected/unselected
          nextProps.formik.values.selectedNodes[index] !== currentProps.formik.values.selectedNodes[index]
        )
      }}
    >
      {({ form, meta }: any) => {
        return (
          <Paper variant="outlined" className={classes.nodeListItem}>
            <Grid container={true} spacing={1} alignItems="center">
              <Grid item={true} xs="auto">
                <Checkbox
                  checked={Boolean(meta.value)}
                  value={meta.value}
                  color="secondary"
                  disabled={checkboxDisabled}
                  onChange={() => {
                    form.setFieldValue(`selectedNodes.${index}`, meta.value ? 0 : node.id)
                  }}
                  inputProps={{ 'data-testid': `selectedNodes.${index}-checkbox` } as any}
                />
              </Grid>
              <Grid item={true} xs="auto">
                {node.isActive ? <Check color="success" /> : <Close color="error" />}
              </Grid>
              <Grid item={true} xs={8} lg={3}>
                <Link className={classes.applicationLink} to={{ pathname: `/nodes/${node.id}`, search: '?tab=0' }} data-cy="node">
                  {node.vanity}
                </Link>
                <br />
                {node.uniqueId}
              </Grid>
              <Grid item={true} xs={6} lg={2}>
                <Typography variant="body2">{node.nodeTypeName}</Typography>
              </Grid>
              <Grid item={true} xs={12} lg={true}>
                <Typography variant="body2">
                  {[node.building, node.floor, node.area].filter(x => x).join(' - ')}
                  <br />
                  {[node?.metadata?.device_location, node?.metadata?.device_location_note].filter(x => x).join(' - ')}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )
      }}
    </FastField>
  )
}

export default GenericNodeListItem
