interface IReadPermissionsConst {
  [key: string]: { id: number; name: string }
}

export const READ_PERMISSIONS_LIST: IReadPermissionsConst = {
  aliasChannels: { id: 313, name: 'Read Alias Channels' },
  applications: { id: 31, name: 'Read Apps' },
  cores: { id: 41, name: 'Read Cores' },
  currentValues: { id: 61, name: 'Read Nodes' },
  domains: { id: 81, name: 'Read Domains' },
  drivers: { id: 91, name: 'Read Drivers' },
  emailTemplates: { id: 271, name: 'Read Email Templates' },
  folders: { id: 101, name: 'Read Folders' },
  globalNodeTypeTemplates: { id: 166, name: 'Read Global Node Type Templates' },
  icons: { id: 141, name: 'Read Icons' },
  impersonate: { id: 202, name: 'Impersonate Users' },
  lambdas: { id: 191, name: 'Read Lambdas' },
  nodes: { id: 61, name: 'Read Nodes' },
  nodeTypes: { id: 71, name: 'Read Node Types' },
  nodeTypeTemplates: { id: 161, name: 'Read Node Type Templates' },
  notificationSchemas: { id: 131, name: 'Read Notification Schemas' },
  privateFiles: { id: 212, name: 'Read Private Files' },
  reactions: { id: 11, name: 'Read Reactions' },
  reactionsLog: { id: 350, name: 'Read Reaction History' },
  roles: { id: 151, name: 'Read Roles' },
  rules: { id: 21, name: 'Read Rules' },
  sensorsAndGateways: { id: 330, name: 'Read Claims' },
  subscriptions: { id: 242, name: 'Read User Subscriptions' },
  taskTemplates: { id: 7000, name: 'read all task templates' },
  tasks: { id: 7002, name: 'read all tasks, skips user id check on get task api' },
  tenant: { id: 171, name: 'Read Tenants' },
  users: { id: 201, name: 'Read Users' },
}

export const WRITE_PERMISSIONS_LIST: IReadPermissionsConst = {
  aliasChannels: { id: 314, name: 'Write Alias Channels' },
  applications: { id: 30, name: 'Write Apps' },
  cores: { id: 40, name: 'Write Cores' },
  domains: { id: 80, name: 'Write Domains' },
  drivers: { id: 90, name: 'Write Drivers' },
  emailTemplates: { id: 272, name: 'Write Email Templates' },
  files: { id: 211, name: 'Write Private Files' },
  folders: { id: 100, name: 'Write Folders' },
  globalNodeTypeTemplates: { id: 165, name: 'Write Global Node Type Templates' },
  icons: { id: 140, name: 'Write Icons' },
  lambdas: { id: 190, name: 'Write Lambdas' },
  nodes: { id: 60, name: 'Write Nodes' },
  nodeTypes: { id: 70, name: 'Write Node Types' },
  nodeTypeTemplates: { id: 160, name: 'Write Node Type Templates' },
  notificationSchemas: { id: 130, name: 'Write Notification Schemas' },
  notifications: { id: 7004, name: 'permission to send notifications' },
  reactions: { id: 10, name: 'Write Reactions' },
  roles: { id: 150, name: 'Write Roles' },
  rules: { id: 20, name: 'Write Rules' },
  sensorsAndGateways: { id: 331, name: 'Write Claims' },
  subscriptions: { id: 241, name: 'Write User Subscriptions' },
  taskTemplates: { id: 7001, name: 'create task templates' },
  tasks: { id: 7003, name: 'create all tasks for any user of same tenant' },
  tenant: { id: 170, name: 'Write Tenants' },
  users: { id: 200, name: 'Write Users' },
}
