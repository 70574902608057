import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { CardContent, Grid, Icon, Typography } from '@mui/material'
import moment from 'moment'

import { ClientSidePaginatedTable } from '../../../components/table'
import TableLink from '../../../components/table-link'
import useTableStyles from '../../../shared-styles/table.styles'
import Api from '../../../utils/api'
import { convertTimestampToDate } from '../../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../../utils/hooks'
import { handleCellClick } from '../../../utils/table/table-helpers'

interface IProps {
  shortOrderNumber: string
}

const ClientNodeList = ({ shortOrderNumber }: IProps) => {
  const classes = useTableStyles()
  const history = useHistory()
  const searchResultsHook = useSearchData()

  const [nodes, setNodes] = useState<INodeType[]>([])

  const columns = [
    { name: 'Name' },
    { name: 'Unique ID' },
    { name: 'Node Type', options: { sort: false } },
    { name: 'Folder', options: { sort: false } },
    { name: 'Active', options: { sort: false } },
    { name: 'Modified' },
  ]

  const fetchNodesForOrder = async () => {
    const filters = [
      {
        fieldName: 'metadata.shortOrderNumber',
        operator: 'eq',
        value: shortOrderNumber,
      },
    ]

    const queryBody: IQueryBody = {
      detail: true,
      first: 500,
      filters,
    }

    const res = await Api.post('/api/nodes/query', queryBody)
    const { results, error } = res

    if (error) {
      return Promise.reject(res)
    }

    const allRows = results.map((node: INodeType) => {
      const nodeVanity = node.vanity
      const nodeUniqueId = node.uniqueId

      const nodeTypeName = (
        <TableLink
          to={`/node-types/${node.nodeTypeId}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {node?.nodeTypeName}
        </TableLink>
      )

      const folderName = (
        <TableLink
          to={`/folders/${node.folderId}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {node.folderName}
        </TableLink>
      )
      const isActive = node.isActive ? (
        <Icon className={classes.greenColor} color="inherit">
          check
        </Icon>
      ) : (
        <Icon className={classes.redColor}>close</Icon>
      )
      return [nodeVanity, nodeUniqueId, nodeTypeName, folderName, isActive, convertTimestampToDate(node.updatedAt)]
    })

    return Promise.resolve({ data: allRows, setEntities: () => setNodes(results) })
  }

  const memoizedFetch = useCallback(fetchNodesForOrder, [shortOrderNumber, classes])
  const state = useTableState(memoizedFetch)

  return (
    <ClientSidePaginatedTable
      title="Devices within this order"
      idList={[]}
      refresh={() => {}}
      preserveSearchResults={{ ...searchResultsHook }}
      columns={columns}
      loading={state.loading}
      error={state.error}
      data={state.data}
      options={{
        textLabels: {
          body: {
            noMatch: 'No devices are found. Only orders after Feburary 2022 are linked to the order node.',
          },
        },
        selectableRows: 'none',
        onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, nodes, 'nodes', cellMeta, searchResultsHook.abort),
      }}
    />
  )
}

const OrderDetails = ({ node }: { node: INodeType }) => {
  const [channels, setChannels] = useState<Record<string, IValueType>>()

  const shortOrderNumber = channels?.short_order_number?.value ?? node.uniqueId.split('shp-')[1] ?? ''

  useDocumentTitle('Order Details', shortOrderNumber ?? '')

  useEffect(() => {
    const fetchChannels = async () => {
      const res: ICurrentValueType = await Api.get(`/api/data/current?nodeId=${node.id}`)

      if (res && res[node.id]) {
        setChannels(res[node.id])
      }
    }

    fetchChannels()
  }, [node.id])

  let address
  try {
    address = JSON.parse(channels?.shipping_address?.value)
  } catch {
    // do nothing, we just won't show the address
  }

  return (
    <CardContent>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <Typography variant="body2">
            <b>Order Number:</b> {channels?.order_number?.value}
          </Typography>
          <Typography variant="body2">
            <b>Status:</b> {channels?.order_status?.value}
          </Typography>
          <Typography variant="body2">
            <b>Fulfilled Date:</b> {channels?.fulfillment_date?.value ? moment(channels?.fulfillment_date?.value).format('LLL') : 'N/A'}
          </Typography>
          <Typography variant="body2">
            <b>Shipped Date:</b> {channels?.delivered_date?.value ? moment(channels?.shipped_date?.value).format('LLL') : 'N/A'}
          </Typography>
          <Typography variant="body2">
            <b>Delivered Date:</b> {channels?.delivered_date?.value ? moment(channels?.delivered_date?.value).format('LLL') : 'N/A'}
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography variant="body2">
            <b>Name:</b> {address?.name}
          </Typography>
          <Typography variant="body2">
            <b>Ship to Email Address:</b> {channels?.ship_to_email?.value}
          </Typography>
          <Typography variant="body2">
            <b>Address:</b> {address?.address1} {address?.address2}
          </Typography>
          <Typography variant="body2">
            <b>City, State, Zip:</b> {[address?.city, address?.province, address?.zip].filter(x => x).join(', ')}
          </Typography>
          <Typography variant="body2">
            <b>Tracking Number:</b> {channels?.tracking_number?.value}
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <ClientNodeList shortOrderNumber={shortOrderNumber} />
        </Grid>
      </Grid>
    </CardContent>
  )
}

export default OrderDetails
