import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

export const TaskTemplatesListView = () => {
  const [taskTemplates, setTaskTemplates] = useState<ITaskTemplateType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const searchResultsHook = useSearchData()
  const history = useHistory()
  const classes = useTableStyles()
  const { addAlert } = useContext(AlertContext)

  useDocumentTitle('Task Templates')

  const columns = [
    { name: 'Title', options: { filter: false, sort: true } },
    { name: 'Description', options: { filter: false, sort: false } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchTaskTemplatesData = async () => {
    const res = await Api.get('/api/v2/task-templates')
    const templates = res.templates

    if (res.error) {
      addAlert({ alertType: 'error', message: res.message })
      return Promise.reject(res)
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = templates.map((template: ITaskTemplateType) => {
      return [template.title, template.description, convertTimestampToDate(template.updatedAt)]
    })
    return Promise.resolve({ data: allRowData, setEntities: () => setTaskTemplates(templates) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchTaskTemplatesData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  const NewTaskTemplateLink = forwardRef((buttonProps: any, ref: any) => <Link to="/task-templates/new" ref={ref} {...buttonProps} />)

  return (
    <Grid container={true} justifyContent="flex-end" data-cy="taskTemplatesList" className={classes.tableContainer}>
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewTaskTemplateLink}
              entityName="Task Templates"
              buttonName="Add Task Template"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Task Templates"
          entityDeleteEndpoint="/api/v2/task-templates/"
          idList={taskTemplates ? taskTemplates.map(s => ({ id: s.id })) : []}
          refresh={() => setRefreshCounter(refreshCounter + 1)}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          preserveSearchResults={{ ...searchResultsHook }}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) =>
              handleCellClick(history, taskTemplates, 'task-templates', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="task-templates"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!taskTemplates?.find(taskTemplate => taskTemplate.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default TaskTemplatesListView
