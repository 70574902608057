import { grey } from '@mui/material/colors'
import { darken, lighten, Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    headerWrapper: {
      flex: '0 0 64px',
    },
    desktopLogo: {
      paddingTop: theme.spacing(),
      height: '40px',
      width: '128px',
      marginRight: theme.spacing(),
    },
    toolBar: {
      backgroundColor: theme.palette.mode === 'light' ? grey[200] : grey[800],
    },
    toolbarButtons: {
      display: 'flex',
      paddingLeft: theme.spacing(),
      marginLeft: 'auto',
      marginRight: -12,
      color: grey[500],
      wrap: 'nowrap',
      alignItems: 'center',
    },
    iconButton: {
      padding: '6px',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      margin: 0,
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : undefined,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 300,
      },
    },
    searchIcon: {
      width: theme.spacing(9),
      color: grey[500],
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(),
      justifyContent: 'left',
    },
    inputInput: {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(4),
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    sectionDesktop: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    menuPlaceholder: {
      display: 'none',
    },
    arrowDown: {
      verticalAlign: 'middle',
    },
    smallScreenArrowDown: {
      fontSize: '0.8rem',
    },
    profileMenuButton: {
      textTransform: 'uppercase',
      cursor: 'pointer',
      fontWeight: 500,
      color: grey[500],
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    smallScreenMenuButton: {
      fontSize: '10px',
    },
    noImpersonateProfileMenuBtn: {
      display: 'flex',
    },
    themeButton: {
      margin: theme.spacing(0, 1),
      cursor: 'pointer',
      color: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.mode === 'light' ? darken(theme.palette.primary.dark, 0.1) : lighten(theme.palette.primary.light, 0.6),
      },
    },
    impersonatingUserEmail: {
      display: 'block',
      textTransform: 'lowercase',
      fontSize: '0.9rem',
      marginBottom: '3px',
    },
    impersonatedUserDisplayValue: {
      color: theme.palette.secondary.main,
    },
    menuIcon: {
      paddingRight: '20px',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    selectedLanguage: {
      '& span': {
        fontWeight: 600,
      },
    },
  })

export const useStyles = makeStyles(styles)
