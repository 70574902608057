import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/table.styles'

export const styles = (theme: Theme) =>
  createStyles({
    chips: {
      textDecoration: 'none',
      color: `${theme.palette.primary.main}`,
      marginBottom: '3px',
      '&:visited': {
        color: theme.palette.primary.main,
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
