import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    dropdownMargin: {
      margin: '-10px 0 25px 0',
    },
    claimsHelperText: {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[800],
      fontSize: '0.9rem',
    },
    networkType: {
      height: '37px',
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(1),
      },
    },
    networkTypeWrapper: {
      marginRight: theme.spacing(),
      [theme.breakpoints.only('xs')]: {
        marginRight: 0,
      },
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
