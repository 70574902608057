import { Dispatch, SetStateAction, useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import FormikTextField from '../../../components/form-elements/text-field'
import { AlertContext } from '../../../providers'
import Api from '../../../utils/api'

import useStyles from './index.styles'

interface IProps {
  open: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

interface IForgotPasswordValues {
  email: string
}

const ForgotPassword = (props: IProps) => {
  const { open, setVisible } = props
  const classes = useStyles()
  const { addAlert } = useContext(AlertContext)

  const handleSubmit = async (values: IForgotPasswordValues, helpers: FormikHelpers<IForgotPasswordValues>) => {
    helpers.setSubmitting(true)
    const res = await Api.get(`/api/passwords/resetpassword?email=${encodeURIComponent(values.email)}`)
    helpers.setSubmitting(false)

    if (!res.error) {
      setVisible(false)
      addAlert({ alertType: 'success', message: 'If the account exists, an email will be sent with further instructions' })
    } else {
      addAlert({ alertType: 'error', message: res?.body?.detail ?? 'Something went wrong and we could not send you reset email. Please try again later' })
    }
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Must be a valid email address'),
  })

  return (
    <Dialog open={open} onClose={() => setVisible(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <div className={classes.wrapper}>
        <h2 className={classes.title} data-testid="modal-title">
          Forgot Password
        </h2>

        <CloseIcon className={classes.closeIcon} onClick={() => setVisible(false)} data-testid="close-icon" />

        <p className={classes.text}>
          Enter the email address associated with your account. If your email address exists in our system, a password reset email will be sent.
        </p>

        <Formik initialValues={{ email: '' }} onSubmit={handleSubmit} validationSchema={schema}>
          {({ isValid, isSubmitting, dirty }) => {
            return (
              <Form className={classes.form}>
                <FormikTextField data-cy="recoveryEmailInput" name="email" required={true} label="Email" type="email" />

                <Button
                  data-cy="sendResetEmailBtn"
                  data-testid="forgot-password-btn"
                  type="submit"
                  disabled={!isValid || isSubmitting || !dirty}
                  fullWidth={true}
                  color="secondary"
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Forgot Password
                </Button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Dialog>
  )
}

export default ForgotPassword
