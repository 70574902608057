import { useEffect, useState } from 'react'
import { Visibility } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import { Field, FieldArray, FieldProps, FormikErrors, FormikProps } from 'formik'
import { get } from 'lodash'
import tinycolor from 'tinycolor2'

import Api from '../../../utils/api'

import useThemeFormStyles from './theme-form.styles'

export interface ThemeType {
  key: string
  value: string
}

export interface IThemeItems {
  themeItems: ThemeType[]
}

const CompanyLogoBox = ({ path }: { path: string }) => {
  const classes = useThemeFormStyles()

  const imgPath = `api/files/public/${path}`

  return (
    <div className={classes.logoTooltipWrapper}>
      <img src={imgPath} alt="PATH IS WRONG OR LOGO MISSING" className={classes.logoImg} />
    </div>
  )
}

const ColorBox = ({ color, opacity }: { color: string; opacity: string | number }) => {
  const classes = useThemeFormStyles()

  const safeColor = tinycolor(color).isValid() ? tinycolor(color).toHexString() : undefined
  const safeOpacity = !isNaN(Number(opacity)) ? Number(opacity) : 0.07

  return (
    <div className={classes.logoTooltipWrapper}>
      <div style={{ backgroundColor: safeColor, opacity: safeColor ? safeOpacity : 1 }} className={classes.colorBox}>
        {!safeColor && 'Invalid color value'}
      </div>
    </div>
  )
}

const ParticipationAgreementBox = ({ clientCompanyPath, programPath }: { clientCompanyPath: string; programPath: string }) => {
  const classes = useThemeFormStyles()

  const [agreementExists, setAgreementExists] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)

  const agreementPath = `/${clientCompanyPath}/${programPath}/agreement.html`

  useEffect(() => {
    Api.get('/api/files/private').then(res => {
      if (!res.error) {
        const found = res.find((item: string) => item === agreementPath)
        setAgreementExists(found)
      } else {
        setError(true)
      }
      setLoading(false)
    })
  }, [agreementPath])

  return (
    <div className={classes.logoTooltipWrapper}>
      <div className={classes.agreementBox}>
        <Typography variant="body1">{agreementPath}</Typography>
      </div>
      {loading && <CircularProgress />}
      <div className={classes.agreementBox}>
        {!error ? (
          <Typography variant="body2">
            {!loading && !agreementExists && 'could not find the agreement file'}
            {!loading && agreementExists && 'found the agreement file'}
          </Typography>
        ) : (
          <Typography variant="body2">error finding files</Typography>
        )}
      </div>
    </div>
  )
}

export const validateThemeItems = (values: IThemeItems) => {
  const { themeItems } = values
  const errors: any = {
    themeItems: [],
  }

  themeItems.forEach(item => {
    if (item.value) {
      // If it doesnt exist, initialize it
      if (!errors.themeItems) {
        errors.themeItems = {}
      }
    } else {
      // Push a null item here so that value appear in the appropriate order (kind of hacky)
      errors.themeItems.push(null)
    }
  })

  // If no items have errored, return empty object
  const filter = errors.themeItems.filter((item: any) => item != null)
  if (filter.length <= 0) {
    return {}
  }
  return errors
}

export const fieldError = ({ touched, errors }: Pick<FormikProps<IThemeItems>, 'touched' | 'errors'>, index: number, key: keyof ThemeType) => {
  if (touched.themeItems && touched.themeItems[index] && errors.themeItems && errors.themeItems[index]) {
    /* Check if field item has been touched before we deem it to have an error */
    const touchedItem = touched.themeItems[index]
    /* Check if field item has errors associated with it */
    const errorItem = errors.themeItems[index] as FormikErrors<ThemeType>
    /* Check if the key is touched */
    const wasTouched = touchedItem != null && touchedItem[key]
    /* Check if there is an error associated with our particular key */
    const error = errorItem != null && errorItem[key]
    return Boolean(wasTouched && error)
  } else {
    return false
  }
}

const ThemeForm = ({ themeItems }: IThemeItems) => {
  const classes = useThemeFormStyles()

  const addRecommendedThemeItem = (arrayHelpers: any, keyName: string, defaultValue: string) => {
    arrayHelpers.push({ key: keyName, value: defaultValue })
  }

  // default values from MeshifyProtect/protect-web
  const primaryColor = themeItems?.find(item => item.key === 'primaryColor')?.value ?? '#0078EC'
  const waveColor = themeItems?.find(item => item.key === 'waveColor')?.value ?? 'black'
  const waveOpacity = themeItems?.find(item => item.key === 'waveOpacity')?.value ?? 0.07

  const clientCompanyPath = themeItems?.find(item => item.key === 'ClientCompanyPath')?.value ?? ''
  const programPath = themeItems?.find(item => item.key === 'ProgramPath')?.value ?? ''

  return (
    <FieldArray name="themeItems">
      {arrayHelpers => (
        <div data-testid="theme-fields-container">
          {themeItems != null && themeItems && themeItems.length > 0 ? (
            <>
              {themeItems.map((item, index: number) => (
                <Grid
                  container={true}
                  spacing={3}
                  justifyContent="center"
                  data-testid="theme-field-container"
                  data-cy="themeContainer"
                  key={index}
                  className={classes.rowWrapper}
                >
                  <Grid item={true} container={true} spacing={3} xs={8} sm={8} md={9} lg={9} xl={9}>
                    <Grid item={true} xs={6}>
                      <Field name={`themeItems.${index}.key`}>
                        {({ field, form }: FieldProps<any>) => {
                          const { touched, errors } = form
                          const fieldDidError = fieldError({ touched, errors }, index, 'key')

                          return (
                            <div>
                              <TextField
                                {...field}
                                error={fieldDidError}
                                fullWidth={true}
                                variant="outlined"
                                data-cy={`themeKey${index + 1}`}
                                label={`Key #${index + 1}`}
                                data-testid={`themeItems.${index}.key`}
                              />

                              {fieldDidError === true && (
                                <FormHelperText data-testid="component-error-text" data-cy="requiredText" className={classes.errorText}>
                                  {get(errors, `themeItems.${index}.key`, '4 characters or more')}
                                </FormHelperText>
                              )}
                            </div>
                          )
                        }}
                      </Field>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <Field name={`themeItems.${index}.value`}>
                        {({ field, form }: FieldProps<any>) => {
                          const { touched, errors } = form
                          const fieldDidError = fieldError({ touched, errors }, index, 'value')

                          return (
                            <div>
                              <TextField
                                {...field}
                                error={fieldDidError}
                                fullWidth={true}
                                variant="outlined"
                                data-cy={`themeVal${index + 1}`}
                                label={`Value #${index + 1}`}
                                data-testid={`themeItems.${index}.value`}
                              />

                              {fieldDidError === true && (
                                <FormHelperText id="component-error-text" className={classes.errorText}>
                                  {get(errors, `themeItems.${index}.value`, '4 characters or more')}
                                </FormHelperText>
                              )}
                            </div>
                          )
                        }}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid item={true} container={true} alignItems="center" spacing={4} xs={4} sm={4} md={3} lg={3} xl={3}>
                    <Grid item={true} xs={4}>
                      <Fab onClick={() => arrayHelpers.remove(index)} size="small" data-testid="theme-remove-field" aria-label="Remove" data-cy="removeTheme">
                        <RemoveIcon />
                      </Fab>
                    </Grid>

                    <Grid item={true} xs={4}>
                      <Fab
                        onClick={() => arrayHelpers.insert(index + 1, { key: '', value: '' })} // insert an empty object at a position
                        size="small"
                        data-testid="theme-add-field"
                        aria-label="Add"
                        data-cy="addTheme"
                      >
                        <AddIcon />
                      </Fab>
                    </Grid>
                    <Grid item={true} xs={4}>
                      {item.key === 'companyLogo' && (
                        <Tooltip
                          title={<CompanyLogoBox path={item.value} />}
                          placement="bottom"
                          classes={{
                            tooltip: classes.tooltipWidth,
                          }}
                        >
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      )}
                      {['waveColor', 'waveOpacity'].includes(item.key) && (
                        <Tooltip title={<ColorBox color={waveColor} opacity={waveOpacity} />} placement="bottom">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      )}
                      {item.key === 'primaryColor' && (
                        <Tooltip title={<ColorBox color={primaryColor} opacity={1} />} placement="bottom">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      )}
                      {['ClientCompanyPath', 'ProgramPath'].includes(item.key) && (
                        <Tooltip title={<ParticipationAgreementBox clientCompanyPath={clientCompanyPath} programPath={programPath} />} placement="bottom">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              <Grid container={true} spacing={3} justifyContent="flex-start" data-testid="recommendedKeys">
                <div className={classes.recommendedKeysWrapper}>
                  Recommended keys:
                  <span
                    onClick={() => addRecommendedThemeItem(arrayHelpers, 'primaryColor', '#FFFFFF')}
                    className={classes.recommendedKey}
                    data-cy="primaryColorTheme"
                  >
                    primaryColor
                  </span>
                  <span onClick={() => addRecommendedThemeItem(arrayHelpers, 'logoUrl', 'http://')} className={classes.recommendedKey} data-cy="logoUrlTheme">
                    logoUrl
                  </span>
                </div>
              </Grid>
            </>
          ) : (
            <Grid container={true} item={true} xs={6}>
              <Button
                color="secondary"
                data-cy="addThemeBtn"
                data-testid="addThemeBtn"
                variant="outlined"
                onClick={() => arrayHelpers.push({ key: '', value: '' })}
              >
                Add Theme Item
              </Button>
            </Grid>
          )}
        </div>
      )}
    </FieldArray>
  )
}

export default ThemeForm
