import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    title: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '48px',
      textAlign: 'center',
      marginBottom: '15px',
    },
    closeIcon: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      cursor: 'pointer',
    },
    text: {
      lineHeight: 1.7,
      fontSize: '0.9rem',
      textAlign: 'center',
      width: '85%',
      margin: '0 auto',
    },
    form: {
      width: '60%',
      margin: '20px auto 0',
    },
    submitBtn: {
      margin: '20px 0 35px 0',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
