import { useRef, useState } from 'react'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { FieldArray, FieldArrayRenderProps } from 'formik'

import useTagCreatorStyles from './tag-creator.styles'

export interface ITagValues {
  tags: string[]
}

type TagArrayProps = {
  theme?: any
}

const TagCreator = ({ tags }: TagArrayProps & ITagValues) => {
  const classes = useTagCreatorStyles()
  const input = useRef<HTMLInputElement>(null)
  const [duplicateError, setDuplicateError] = useState(false)

  const handleNewTag = (newTag: string, arrayHelpers: FieldArrayRenderProps) => {
    if (newTag !== '' && tags != null && tags.indexOf(newTag) < 0) {
      arrayHelpers.push(newTag)
    }
    if (input != null && input.current != null) {
      input.current.value = ''
    }
    if (tags.indexOf(newTag) >= 0) {
      setDuplicateError(true)
    }
  }

  return (
    <FieldArray name="tags">
      {arrayHelpers => {
        return (
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextField
                label="Add Tag"
                variant="standard"
                inputRef={input}
                error={duplicateError}
                helperText={duplicateError ? 'This tag already exists' : ''}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  // Handle submit on enter keypress
                  setDuplicateError(false)
                  if (e.charCode === 13) {
                    e.preventDefault()
                    const newTag = (e.target as HTMLInputElement).value
                    handleNewTag(newTag, arrayHelpers)
                  }
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setDuplicateError(false)
                  const newTag = (e.target as HTMLInputElement).value
                  handleNewTag(newTag, arrayHelpers)
                }}
                fullWidth={true}
                data-cy="tags"
                inputProps={{
                  'data-testid': `tag-input`,
                }}
              />
            </Grid>
            <Grid item={true} xs={12}>
              {tags != null &&
                tags.length > 0 &&
                tags.map((tag, index) => {
                  return (
                    <Chip
                      label={tag}
                      key={index}
                      data-testid="tag"
                      onDelete={() => arrayHelpers.remove(index)}
                      className={classes.tagChip}
                      variant="outlined"
                    />
                  )
                })}
            </Grid>
          </Grid>
        )
      }}
    </FieldArray>
  )
}
export default TagCreator
