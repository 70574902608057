import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import { AlertContext } from '../../providers'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle } from '../../utils/hooks'

import FilesList from './../../components/file-upload/file-list'
import FileUploader from './../../components/file-upload/file-uploader'
import useSharedFormStyles from './../../shared-styles/form.styles'

interface IDriverForm {
  name: string
}

export const CreateEditDriver = () => {
  const classes = useSharedFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId
  const isEditMode = driverId !== 'new'

  const [driver, setCurrentDriver] = useState<IDriverType | null>(null)
  const [files, setFiles] = useState<string[]>([])
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState<boolean>(false)

  useDocumentTitle('Drivers', isEditMode ? driver?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/drivers/${driverId}`).then(retrievedDriver => {
        redirectToListViewOnError(retrievedDriver, history, addAlert, '/drivers')

        if (isSubscribed) {
          setCurrentDriver(retrievedDriver)
        }
      })

      Api.get(`/api/drivers/${driverId}/files`).then(fileList => {
        if (isSubscribed) {
          setFiles(fileList)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, driverId, history, addAlert])

  const saveDriver = async (values: IDriverForm, actions: FormikHelpers<IDriverForm>) => {
    const createDriverPayload: IDriverForm = {
      name: values.name,
    }

    let submittedDriver

    try {
      if (isEditMode) {
        handleFormSubmit(await Api.put(`/api/drivers/${driverId}`, createDriverPayload), addAlert, history, null, values, actions)
      } else {
        handleFormSubmit(
          (submittedDriver = await Api.post('/api/drivers', createDriverPayload)),
          addAlert,
          history,
          `/drivers/${submittedDriver.id}`,
          values,
          actions
        )
      }
    } catch (e) {
      addAlert({ alertType: 'error', message: 'Driver could not be submitted at the moment' })
    }
  }

  const handleDeleteResponse = async () => {
    try {
      handleDelete(await Api.del('/api/drivers', driverId), addAlert, history, '/drivers')
    } catch (e) {
      addAlert({ alertType: 'error', message: 'Driver could not be deleted at the moment' })
    }
  }

  const schema = Yup.object({
    name: Yup.string().required('Name is required'),
  })

  const initialValues = isEditMode && driver != null ? { name: driver.name } : { name: '' }

  return (
    <>
      <Formik validationSchema={schema} enableReinitialize={true} onSubmit={saveDriver} initialValues={initialValues}>
        {({ isValid, isSubmitting, dirty }: FormikProps<IDriverForm>) => {
          return (
            <Form>
              <Card>
                <CardHeader title={isEditMode ? 'Edit Driver' : 'Create Driver'} data-cy="formTitle" />

                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} data-cy="driverName">
                      <FormikTextField initialized={isEditMode} label="Name" name="name" id="driverName" required={true} fullWidth={true} />
                    </Grid>

                    {isEditMode && (
                      <>
                        <FileUploader setFiles={setFiles} filesEndpoint={`/api/drivers/${driverId}/files/`} />

                        <Grid item={true} xs={12}>
                          <Typography className={classes.denseFormSubheader} variant="subtitle1">
                            Files
                          </Typography>
                        </Grid>

                        {files.length === 0 ? (
                          <Grid item={true} xs={12} sm={12} md={8} lg={5} xl={4}>
                            <Alert alertType="warning" message="No files are staged to upload. Upload one to begin" />
                          </Grid>
                        ) : (
                          <FilesList filesList={files} setAllFiles={(val: any) => setFiles(val)} fileEndpoint={`/api/drivers/${driverId}/files`} />
                        )}
                      </>
                    )}
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                    Save
                  </Button>

                  <Button type="button" onClick={() => history.push('/drivers')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button
                      className={classes.deleteBtn}
                      type="button"
                      color="secondary"
                      onClick={() => setShowConfirmDeletionModal(true)}
                      data-cy="deleteBtn"
                      data-testid="deleteBtn"
                    >
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Form>
          )
        }}
      </Formik>

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={handleDeleteResponse}
        closeModal={() => setShowConfirmDeletionModal(false)}
        dialogContent="This action is not reversible."
        dialogTitle="Are you sure you want to delete this driver?"
      />
    </>
  )
}

export default CreateEditDriver
