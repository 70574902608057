/** function to check if string can be parsed to a valid json or not */
export const isValidJson = (val: string): boolean => {
  try {
    JSON.parse(val)
  } catch (e) {
    return false
  }
  return true
}

export const convertTimestampToDate = (timestamp: string) => {
  const date = new Date(timestamp)

  const formattedDate = date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  return formattedDate.replace(/\//g, '-')
}

export const folderTreeToList = (folderTree: IFolderTreeType | null) => {
  if (!folderTree?.tree) return []

  return Object.entries(folderTree.tree).map(([folderId, folderDetails]) => ({
    id: Number(folderId),
    ...folderDetails,
  })) as IFolderTypeSimple[]
}

export const base64ToString = (base64: string) => {
  const binString = atob(base64)

  // if codePointAt(0) ever returned undefined for some reason
  // default it to 32 which is a space, this is really just to
  // make typescript happy
  const bytesConverter = (m: string) => m.codePointAt(0) ?? 32

  return new TextDecoder().decode(Uint8Array.from(binString, bytesConverter))
}

export const stringToBase64 = (s: string) => {
  const bytes = new TextEncoder().encode(s)

  // @ts-ignore es2015 is too low
  const binString = String.fromCodePoint(...bytes)
  return btoa(binString)
}
