import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'

interface ISearchField {
  searchText: string
  setSearchText: (search: string) => void
}

const SearchField = (props: ISearchField) => {
  const { searchText, setSearchText } = props

  return (
    <Input
      placeholder="Search"
      disableUnderline={true}
      fullWidth={true}
      type="text"
      autoComplete="off"
      required={false}
      data-cy="filterSelectSearch"
      onChange={(event: any) => setSearchText(event.target.value)}
      value={searchText}
      inputProps={{
        'aria-label': 'Search',
        'data-testid': 'single-select-search-input',
        autoComplete: 'off',
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon data-testid="searchIcon" />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="Clear search" onClick={() => setSearchText('')} size="large">
            {searchText && <ClearIcon data-testid="clearSearch" fontSize="small" />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default SearchField
