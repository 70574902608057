import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ArrowDropDown } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'

import api from '../../utils/api'

import useStyles from './create-edit.styles'

const getNetworkType = (node: INodeType) => {
  if (node.uniqueId.length < 15) {
    return ''
  }

  // list of devices that use lora-chirpstack, more might need to be added later to account for other nodes
  const chirpstackNodes = ['leak2', 'probe2', 'motion2', 'leak3', 'temp3', 'cxamnas1', 'gateway', 'bvs']

  if (node.nodeTypeName === 'nbiot_leak') {
    return 'nbiot-sercomm'
  } else if (node.nodeTypeName === 'leak4s') {
    return 'sidewalk'
  } else if (chirpstackNodes.includes(node.nodeTypeName ?? '')) {
    return 'lora-chirpstack'
  } else {
    return ''
  }
}

const NodeGoToButtons = ({ node }: { node: INodeType | null }) => {
  const [claimId, setClaimId] = useState<number | undefined>(undefined)
  const [menuAnchor, setMenuAnchor] = useState<any>(null)

  const history = useHistory()
  const classes = useStyles()

  const networkType = node ? getNetworkType(node) : ''

  useEffect(() => {
    const fetchClaims = async () => {
      const claims = await api.get(`/api/claims/${networkType}`)

      if (!claims.error) {
        const claim = claims.find((c: IClaimType) => c.uniqueId === node?.uniqueId)
        if (claim) {
          setClaimId(claim.id)
        }
      }
    }

    if (networkType) {
      fetchClaims()
    }
  }, [node?.uniqueId, networkType])

  return (
    <>
      {Boolean(node?.folderId || claimId) && (
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={e => {
            setMenuAnchor(e.currentTarget)
          }}
          data-testid="nodesGoToButton"
          endIcon={<ArrowDropDown />}
          className={classes.btnMargin}
        >
          Go to
        </Button>
      )}
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        {node?.folderId && (
          <MenuItem data-testid="goToFolderButton" onClick={() => history.push(`/folders/${node?.folderId}`)}>
            Folder
          </MenuItem>
        )}
        {claimId && (
          <MenuItem data-testid="goToClaimButton" onClick={() => history.push(`/claims/${networkType}/${claimId}`)}>
            Claim
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default NodeGoToButtons
