import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

import TableLink from './../../components/table-link'
import { AlertContext, UserPermissionsContext } from './../../providers'

const ApplicationsListView = () => {
  const [apps, setApplications] = useState<IApplicationType[] | null>(null)
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)
  const searchResultsHook = useSearchData()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const classes = useTableStyles()

  useDocumentTitle('Applications')

  const columns = [
    { name: 'Name', options: { filter: true, sort: true } },
    { name: 'Core', options: { filter: false, sort: false } },
    { name: 'Core ID', options: { filter: true, sort: true } },
    { name: 'Base Path', options: { filter: true, sort: true } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchApplicationData = async () => {
    const applications = await Api.get('/api/applications')
    const cores = await Api.get('/api/cores')

    if (applications.error) {
      addAlert({ alertType: 'error', message: applications?.message || 'Could not retreive applications' })
      return Promise.reject(applications)
    }

    if (cores.error) {
      addAlert({ alertType: 'error', message: cores?.message || 'Could not retrieve cores' })
    }

    const getCore = (coreId: number) => {
      return cores.filter((core: { id: number }) => core.id === coreId)[0]
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = applications.map((application: IApplicationType) => {
      let coreName
      if (!cores.error) {
        const core = getCore(application.coreId)

        coreName = (
          <TableLink
            to={`/cores/${application.coreId}`}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {core?.name}
          </TableLink>
        )
      } else {
        // if an error happened when retrieving the cores, we just won't show core name in the table
        coreName = ''
      }

      return [application.name, coreName, application.coreId, application.basePath, convertTimestampToDate(application.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setApplications(applications) })
  }

  const NewApplicationLink = forwardRef((componentProps: any, ref: any) => <Link to="/applications/new" ref={ref} {...componentProps} />)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchApplicationData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  return (
    <Grid className={classes.tableContainer} data-cy="applicationsList" container={true} justifyContent="flex-end">
      <Grid container={true} justifyContent="flex-end">
        <AddButton
          userPermissions={userPermissions}
          newComponentLink={NewApplicationLink}
          entityName="Applications"
          buttonName="Add Application"
          dataCy="addBtn"
          dataTestId="addBtn"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Applications"
          /* Entities that exist inside the table */
          idList={
            apps !== null
              ? apps.map(app => ({
                  id: app.id,
                }))
              : []
          }
          entityDeleteEndpoint="/api/applications"
          columns={columns}
          loading={state.loading}
          error={state.error}
          refresh={() => setRefreshCounter(c => c + 1)}
          preserveSearchResults={{ ...searchResultsHook }}
          data={state.data}
          options={{
            filterType: 'checkbox' as const,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, apps, 'applications', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="applications"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!apps?.find(application => application.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ApplicationsListView
