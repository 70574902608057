import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { get } from 'lodash'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import DateSchedule from '../../components/form-elements/date-schedule'
import MultiFilterSelect from '../../components/form-elements/filter-select/multi-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import { AlertContext } from '../../providers'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import useStyles from './create-edit.styles'

interface IPayload {
  defaultSchedule: string | null
  name: string
  reactions: number[]
}

interface IFormValues {
  defaultSchedule: string | null
  name: string
  reactionsToPick: number[]
}

export const getReactionsToPick = (allReactions: IReactionType[], allRules: IRuleType[]) => {
  const reactionsToSelect: any = []
  let generatedReactionName = ''

  allReactions.forEach(reaction => {
    for (const rule of allRules) {
      if (rule.id === reaction.ruleId) {
        generatedReactionName = generatedReactionName.concat(rule.name)
        break
      }
    }
    generatedReactionName = generatedReactionName.concat(` - ${reaction.name} - ${reaction.eventType} - ${reaction.type}`)
    const newReaction = { name: generatedReactionName, id: reaction.id }
    reactionsToSelect.push(newReaction)
    generatedReactionName = ''
  })

  return reactionsToSelect
}

const CreateNotificationSchema = () => {
  const classes = useStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ schemaId: string }>()
  const schemaId = params.schemaId
  const isEditMode = schemaId !== 'new'

  const [retrievedSchema, setRetrievedSchema] = useState<INotificationSchemaType | null>(null)
  const [reactions, setReactions] = useState<IReactionType[]>([])
  const [reactionsError, setReactionsError] = useState<string>('')
  const [rules, setRules] = useState<IRuleType[]>([])
  const [rulesError, setRulesError] = useState<string>('')
  const [reactionsToPick, setReactionsToPick] = useState<IReactionType[]>([])
  const [reactionsLoading, setReactionsLoading] = useState<boolean>(false)

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  useDocumentTitle('Notification Schemas', isEditMode ? retrievedSchema?.name : 'new')

  useEffect(() => {
    let isSubscribed = true

    Api.get('/api/rules').then(rulesData => {
      if (isSubscribed && !rulesData.error) {
        setRules(rulesData)
      } else {
        setRulesError(rulesData.message)
        addAlert({ alertType: 'error', message: rulesData.message })
      }
    })

    setReactionsLoading(true)
    Api.get('/api/reactions').then(reactionsData => {
      setReactionsLoading(false)
      if (isSubscribed && !reactionsData.error) {
        setReactions(reactionsData)
      } else {
        setReactionsError(reactionsData.message)
        addAlert({ alertType: 'error', message: reactionsData.message })
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/notificationschemas/${schemaId}`).then(schemaData => {
        redirectToListViewOnError(schemaData, history, addAlert, '/notification-schemas')

        if (isSubscribed) {
          setRetrievedSchema(schemaData)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, schemaId, history, addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      setReactionsToPick(getReactionsToPick(reactions, rules))
    }
    return () => {
      isSubscribed = false
    }
  }, [reactions, rules])

  const saveSchema = async (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
    const schemaPayload: IPayload = {
      defaultSchedule: values.defaultSchedule,
      name: values.name,
      reactions: values.reactionsToPick,
    }

    try {
      if (isEditMode) {
        handleFormSubmit(await Api.put(`/api/notificationschemas/${schemaId}`, schemaPayload), addAlert, history, null, values, actions)
      } else {
        let createdSchema
        handleFormSubmit(
          (createdSchema = await Api.post('/api/notificationschemas', schemaPayload)),
          addAlert,
          history,
          `/notification-schemas/${createdSchema.id}`,
          values,
          actions
        )
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit the notification schema' })
    }
  }

  const deleteSchema = async () => {
    try {
      handleDelete(await Api.del(`/api/notificationschemas`, schemaId), addAlert, history, '/notification-schemas')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete the notification schema' })
      history.push('/notification-schemas')
    }
  }

  const createNotiSchemaAlertMessage =
    'Notification Schemas combine a set of Reactions with a default schedule. Users subscribed to this schema will receive all notifications from the selected Reactions, but only during the hours specified in the default schedule.'

  const initialValues: IFormValues =
    isEditMode && retrievedSchema
      ? {
          name: get(retrievedSchema, 'name'),
          reactionsToPick: get(retrievedSchema, 'reactions'),
          defaultSchedule: get(retrievedSchema, 'defaultSchedule'),
        }
      : {
          name: '',
          reactionsToPick: [],
          defaultSchedule: '',
        }

  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    reactionsToPick: Yup.array().required('Please select at least one reaction'),
    defaultSchedule: Yup.string()
      .min(1, 'Add a default schedule')
      .required('Required'),
  })

  return (
    <>
      {!rulesError && !reactionsError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveSchema} validationSchema={schema}>
          {({ isValid, isSubmitting, dirty }) => {
            return (
              <Form>
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="notificationSchemaName">
                      <FormikTextField initialized={isEditMode} name="name" label="Name" required={true} />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Alert alertType="warning" message={createNotiSchemaAlertMessage} />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="reactionsInput">
                      <Typography className={classes.formSubheader} variant="subtitle1">
                        Reactions
                      </Typography>

                      <MultiFilterSelect<IReactionType, IFormValues>
                        canSelectAll={true}
                        name="reactionsToPick"
                        required={true}
                        items={reactionsToPick}
                        loading={reactionsLoading}
                        initialValues={retrievedSchema?.reactions}
                        entityName="Reactions"
                        displayField="name"
                      />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="defaultSchedule">
                      <Typography className={classes.defaultScheduleSubheader} variant="subtitle1">
                        Default Schedule
                      </Typography>

                      <DateSchedule name="defaultSchedule" required={true} showHelperText={true} hasBusinessClosed={false} hasOpenAllDay={false} />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" data-cy="saveBtn" data-testid="saveBtn" disabled={!isValid || isSubmitting || !dirty}>
                    Save
                  </Button>

                  <Button type="button" data-cy="cancelBtn" data-testid="cancelBtn" color="inherit" onClick={() => history.push('/notification-schemas')}>
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button data-testid="deleteBtn" type="button" data-cy="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Form>
            )
          }}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteSchema}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this notification schema?"
      />

      {(rulesError || reactionsError) && (
        <PermissionsFormError
          errorMessages={combineErrorMessages(reactionsError, rulesError)}
          formName="Notification Schema Form"
          listViewRoute="/notification-schema"
          hasGoBackButton={true}
        />
      )}
    </>
  )
}

export default CreateNotificationSchema
