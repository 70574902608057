import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Grid } from '@mui/material'

import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import { TableLink } from '../../components/table-link'
import { AlertContext, ImpersonateContext, OwnUserContext, UserPermissionsContext } from '../../providers'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useSearchData, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'
import { getFolders, hasImpersonatePermission } from '../users/list'

interface IProps {
  roleId: number
}

const RoleUsers = ({ roleId }: IProps) => {
  const [users, setUsers] = useState<IUserType[]>([])
  const [role, setRole] = useState<IRoleType | null>(null)
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const history = useHistory()
  const classes = useTableStyles()
  const searchResultsHook = useSearchData()
  const { addAlert } = useContext(AlertContext)

  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)
  const currentUser = useContext<IUserType | null>(OwnUserContext)
  const impersonatedUserContext = useContext<IImpersonateContext | null>(ImpersonateContext)

  const columns = [
    { name: 'Email', options: { filter: true, sort: true } },
    { name: 'Phone', options: { filter: true, sort: false } },
    { name: 'First Name', options: { filter: true, sort: true } },
    { name: 'Last Name', options: { filter: true, sort: true } },
    { name: 'Folders', options: { filter: false, sort: false } },
    { name: 'Impersonate', options: { filter: true, sort: false } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ].filter(item => {
    if (!hasImpersonatePermission(userPermissions)) {
      return item.name !== 'Impersonate'
    } else if (!hasImpersonatePermission(userPermissions) || (currentUser && !currentUser.impersonatedBy)) {
      return item
    } else {
      return item.name !== 'Impersonate'
    }
  })

  useEffect(() => {
    let isSubscribed = true
    Api.get(`/api/roles/${roleId}`).then(res => {
      if (isSubscribed && !res.error) {
        setRole(res)
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [roleId])

  const showImpersonateButton = (id: number) => {
    return Boolean(currentUser && !currentUser.impersonatedBy && hasImpersonatePermission(userPermissions) && currentUser.id !== id)
  }

  const fetchUserData = async () => {
    const queryBody: IQueryBody = {
      filters: [{ fieldName: 'roleId', operator: 'eq', value: roleId }],
      first: 10000,
    }
    const res = await Api.post('/api/users/query?detail=true', queryBody)

    if (res.error) {
      addAlert({ alertType: 'error', message: res.message })
      return Promise.reject(res)
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = res.results.map((user: IUserType) => {
      const userFolders = user.foldersDetail ? getFolders(user.folders, user.foldersDetail) : []
      const firstFolder = userFolders.length > 0 && userFolders[0]
      const folderNameList =
        userFolders.length > 0 ? (
          <Grid container={true}>
            <TableLink
              to={firstFolder ? `/folders/${firstFolder.id}` : ''}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {firstFolder && firstFolder.name}
            </TableLink>
            {userFolders.length > 1 ? <span onClick={e => e.stopPropagation()}>, ...</span> : null}
          </Grid>
        ) : (
          '...'
        )

      const impersonate = showImpersonateButton(user.id) ? (
        <span
          data-testid="roles-users-list-impersonate-btn"
          className={classes.applicationLink}
          onClick={e => {
            e.stopPropagation()
            if (impersonatedUserContext) {
              impersonatedUserContext.setImpersonatedUserId(user.id)
            }
          }}
        >
          Impersonate
        </span>
      ) : currentUser && !currentUser.impersonatedBy && hasImpersonatePermission(userPermissions) && currentUser.id === user.id ? (
        <span data-testid="disabled-users-list-impersonate-btn">N/A</span>
      ) : null

      return [
        user.email,
        user.phone,
        user.information?.first,
        user.information?.last,
        folderNameList,
        impersonate,
        convertTimestampToDate(user.updatedAt),
      ].filter(item => item != null)
    })
    return Promise.resolve({ data: allRowData, setEntities: () => setUsers(res.results) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchUserData, [currentUser, userPermissions, refreshCounter])
  const state = useTableState(memoizedFetch)

  return (
    <Grid className={classes.tableContainer} container={true} justifyContent="flex-end">
      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title={role ? `Users with ${role.name.charAt(0).toUpperCase() + role.name.slice(1)} role` : 'Users with this role'}
          entityDeleteEndpoint="/api/users"
          idList={users.length > 0 ? users.map(u => ({ id: u.id })) : []}
          refresh={() => setRefreshCounter(c => c + 1)}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          options={{
            filterType: 'checkbox' as const,
            elevation: 0,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, users, 'users', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="users"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!users?.find(user => user.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default RoleUsers
