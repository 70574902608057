import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import CodemirrorWithTags from '../../components/form-elements/codemirror-with-tag-set'
import MultiFilterSelect from '../../components/form-elements/filter-select/multi-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import useSharedFormStyles from './../../shared-styles/form.styles'

interface IGlobalNodetypeTemplPayload {
  allowedRoles: number[]
  name: string
}

export interface IGlobalNodetypeFormValues {
  name: string
  source: string
  allowedRoles: number[]
}

export const GlobalNodetypeTemplateForm = () => {
  const classes = useSharedFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ globalNodetypeTemplateId: string }>()
  const globalNodetypeTemplateId = params.globalNodetypeTemplateId
  const isEditMode = globalNodetypeTemplateId !== 'new'

  const [currentTemplate, setCurrentTemplate] = useState<IGlobalNodeTypeTemplateType | null>(null)
  const [currentSource, setCurrentSource] = useState<string>('')
  const [roles, setRoles] = useState<IRoleType[]>([])
  const [rolesLoading, setRolesLoading] = useState<boolean>(false)
  const [rolesError, setRolesError] = useState<string>('')

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  useDocumentTitle('Global Nodetype Templates', isEditMode ? currentTemplate?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    setRolesLoading(true)

    Api.get('/api/roles').then(rolesData => {
      setRolesLoading(false)
      if (isSubscribed && rolesData.error) {
        setRolesError(rolesData?.message || 'Could not load roles')
        addAlert({ alertType: 'error', message: rolesData?.message || 'Could not load roles' })
      } else {
        setRoles(rolesData)
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/globalnodetypetemplates/${globalNodetypeTemplateId}`).then(retrievedTemplate => {
        redirectToListViewOnError(retrievedTemplate, history, addAlert, '/global-nodetype-templates')

        if (isSubscribed) {
          setCurrentTemplate(retrievedTemplate)

          Api.get(`/api/globalnodetypetemplates/${globalNodetypeTemplateId}/source`).then(retrievedSource => {
            if (typeof retrievedSource === 'string') {
              setCurrentSource(retrievedSource)
            } else if (typeof retrievedSource === 'object') {
              const source = JSON.stringify(retrievedSource)
              setCurrentSource(source)
            }
          })
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, globalNodetypeTemplateId, history, addAlert])

  const saveTemplate = async (values: IGlobalNodetypeFormValues, actions: FormikHelpers<IGlobalNodetypeFormValues>) => {
    const payload: IGlobalNodetypeTemplPayload = {
      name: values.name,
      allowedRoles: values.allowedRoles,
    }

    try {
      if (!isEditMode) {
        let createdTemplate

        handleFormSubmit(
          (createdTemplate = await Api.post(`/api/globalnodetypetemplates`, payload)),
          addAlert,
          history,
          `/global-nodetype-templates/${createdTemplate.id}`,
          values,
          actions
        )

        await Api.putFile(`/api/globalnodetypetemplates/${createdTemplate.id}/source`, values.source)
      } else {
        handleFormSubmit(await Api.put(`/api/globalnodetypetemplates/${globalNodetypeTemplateId}`, payload), addAlert, history, null, values, actions)

        await Api.putFile(`/api/globalnodetypetemplates/${globalNodetypeTemplateId}/source`, values.source)
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit the template' })
    }
  }

  const deleteGlobalTemplate = async () => {
    try {
      handleDelete(await Api.del(`/api/globalnodetypetemplates/`, globalNodetypeTemplateId), addAlert, history, '/global-nodetype-templates')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete the template' })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    allowedRoles: Yup.array().required('Required'),
  })

  const initialValues =
    isEditMode && currentTemplate
      ? {
          name: currentTemplate.name,
          source: currentSource,
          allowedRoles: currentTemplate.allowedRoles,
        }
      : {
          name: '',
          source: '',
          allowedRoles: [],
        }

  const alertMessage = 'Users with these roles can edit this node type template in the Admin application and view this template in the Dashboard application.'

  return (
    <>
      {!rolesError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveTemplate} validationSchema={schema}>
          {({ values, isValid, setFieldValue, isSubmitting, dirty }) => (
            <Form>
              <Card>
                <CardHeader title={isEditMode ? 'Edit Global Nodetype Template' : 'Create Global Nodetype Template'} data-cy="globalNttTitle" />

                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="name">
                      <FormikTextField id="name" name="name" label="Name" required={true} />
                    </Grid>

                    <Grid item={true} xs={12} data-cy="codeMirror">
                      <div className={classes.codemirrorLabel} data-testid="codemirror-with-tags">
                        Source
                      </div>

                      <CodemirrorWithTags
                        formValues={values}
                        setFieldValue={setFieldValue}
                        helpText="The riot tag inside this source can be nested inside any nodetype templates on the dashboard's node display"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.denseFormSubheader} variant="subtitle1">
                        Allowed Roles
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={4} xl={4}>
                      <MultiFilterSelect<IRoleType, IGlobalNodetypeFormValues>
                        canSelectAll={true}
                        name="allowedRoles"
                        required={true}
                        items={roles != null ? roles : []}
                        loading={rolesLoading}
                        initialValues={currentTemplate && currentTemplate.allowedRoles ? currentTemplate.allowedRoles : []}
                        entityName="Roles"
                        displayField="name"
                      />
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Alert alertType="warning" message={alertMessage} />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" disabled={!isValid || isSubmitting || (!isEditMode && !dirty)} data-cy="saveBtn" data-testid="saveBtn">
                    Save
                  </Button>

                  <Button type="button" onClick={() => history.push('/global-nodetype-templates')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button type="button" data-cy="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal} data-testid="deleteBtn">
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteGlobalTemplate}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this global nodetype template?"
      />

      {rolesError && (
        <PermissionsFormError
          errorMessages={combineErrorMessages(rolesError)}
          formName="Global Nodetype Template Form"
          listViewRoute="/global-nodetype-templates"
          hasGoBackButton={true}
        />
      )}
    </>
  )
}

export default GlobalNodetypeTemplateForm
