import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    subscriptionSpinner: {
      margin: '30px 50%',
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
