import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import Alert from '../alert'

export const combineErrorMessages = (...args: string[]): string => {
  let combinedMessage = ''

  args.forEach(errorMsg => {
    if (errorMsg !== '') {
      const capitalizedMessage = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1) + '. '
      combinedMessage = combinedMessage.concat(capitalizedMessage)
    }
  })

  return combinedMessage
}

export const getErrorMessage = (formName: string, formSpecificErrors: string): string => {
  return `You are not allowed to access ${formName}. ${formSpecificErrors}`
}

type IProps = {
  formName: string
  errorMessages: string
  listViewRoute?: string
  hasGoBackButton: boolean
}

const PermissionsFormError = (props: IProps) => {
  const { formName, errorMessages, listViewRoute, hasGoBackButton } = props

  const history = useHistory()

  return (
    <Grid container={true} justifyContent="center" direction="column" spacing={1} alignItems="center">
      <Grid item={true} xs={6}>
        <Alert alertType="error" message={getErrorMessage(formName, errorMessages)} />
      </Grid>

      {hasGoBackButton && listViewRoute && (
        <Grid item={true} xs={12} container={true} justifyContent="center">
          <Button onClick={() => history.push(listViewRoute)} data-testid="goBackBtn">
            {`Back to List View`}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default PermissionsFormError
