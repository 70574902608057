import { Dispatch, SetStateAction, useEffect } from 'react'
import { isEmpty } from 'lodash'

import SingleFilterSelect from '../../../components/form-elements/filter-select/single-select'
import api from '../../../utils/api'

interface INodeTypeFieldProps {
  nodeTypeId?: number
  nodesList: INodeType[]
  nodetypesList: INodeTypeType[]
  setChannels: Dispatch<SetStateAction<string[]>>
  setNodesForNodetypeList: Dispatch<SetStateAction<INodeType[]>>
  setNodesForNodetypeLoading: Dispatch<SetStateAction<boolean>>
  touchedFormikObject: any
  loading: boolean
  retreivedLambda: ILambdaType | null
}

const NodeTypeField = (nodetypeFieldProps: INodeTypeFieldProps) => {
  const {
    nodeTypeId,
    nodesList,
    nodetypesList,
    setChannels,
    touchedFormikObject,
    setNodesForNodetypeList,
    setNodesForNodetypeLoading,
    loading,
    retreivedLambda,
  } = nodetypeFieldProps

  useEffect(() => {
    let isSubscribed = true
    if (nodeTypeId) {
      const queryBody = { filters: [{ fieldName: 'nodeTypeId', operator: 'eq', value: +nodeTypeId }] }
      setNodesForNodetypeLoading(true)

      api.post('/api/nodes/query', queryBody).then(data => {
        if (isSubscribed) {
          setNodesForNodetypeLoading(false)
          setNodesForNodetypeList(data.results as INodeType[])
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [nodeTypeId, setNodesForNodetypeList, setNodesForNodetypeLoading])

  useEffect(() => {
    let isSubscribed = true

    if (nodeTypeId) {
      const selectedNodetype = nodetypesList.find((item: INodeTypeType) => +item.id === +nodeTypeId)
      if (selectedNodetype && selectedNodetype.channels && !isEmpty(selectedNodetype.channels)) {
        if (isSubscribed) {
          setChannels(Object.keys(selectedNodetype.channels))
        }
      } else if (selectedNodetype && selectedNodetype.channels && isEmpty(selectedNodetype.channels)) {
        if (isSubscribed) {
          setChannels([])
        }
      }
    }

    return () => {
      isSubscribed = false
    }
  }, [nodeTypeId, nodesList, nodetypesList, setChannels, setNodesForNodetypeList, touchedFormikObject.nodeType])

  return (
    <SingleFilterSelect
      name="nodeTypeId"
      label="Select a Node Type"
      required={true}
      displayField="name"
      items={nodetypesList}
      loading={loading}
      initialValue={retreivedLambda?.nodeTypeId}
    />
  )
}

export default NodeTypeField
