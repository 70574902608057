import { useEffect, useMemo, useState } from 'react'
import { Alert, CircularProgress, Grid } from '@mui/material'

import { ClientSidePaginatedTable } from '../../components/table'
import TableLink from '../../components/table-link'
import Api from '../../utils/api'

import useStyles from './create-edit.styles'

interface IProps {
  schemaId: number
}

interface INotificationSchemaUserType {
  userId: number
  schedule: string
  firstName: string
  lastName: string
  email: string
}

const NotificationSchemaUsers = (props: IProps) => {
  const { schemaId } = props

  const classes = useStyles()

  const [notificationSchemaUsers, setNotificationSchemaUsers] = useState<INotificationSchemaUserType[]>([])
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    Api.get(`/api/v2/notificationschemas/${schemaId}/users`).then(res => {
      setIsLoading(false)

      if (res.error) {
        setError(res?.message ?? 'Something went wrong, we could not load users subscribed to this notification schema')
      } else {
        setError('')

        const retrievedUsers = res?.users
        setNotificationSchemaUsers(retrievedUsers ?? [])
      }
    })
  }, [schemaId])

  const columns = [
    { name: 'Email', options: { filter: false, sort: false } },
    { name: 'First Name', options: { filter: false, sort: false } },
    { name: 'Last Name', options: { filter: false, sort: false } },
    { name: 'Schedule', options: { filter: false, sort: false } },
  ]

  const tableData = useMemo(() => {
    const allRowData: Array<Array<number | string | React.ReactNode>> = notificationSchemaUsers.map(item => {
      const userId = (
        <TableLink
          to={`/users/${item.userId}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {item?.email}
        </TableLink>
      )

      return [userId, item?.firstName, item?.lastName, item.schedule]
    })

    return allRowData
  }, [notificationSchemaUsers])

  return (
    <Grid container={true} spacing={3}>
      {isLoading && !error && (
        <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.topMargin}>
          <CircularProgress />
        </Grid>
      )}

      {!isLoading && error && (
        <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.topMargin}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}

      {!isLoading && !error && notificationSchemaUsers.length === 0 && (
        <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.topMargin}>
          <Alert severity="info">This notification schema currently does not have any users that belong to it</Alert>
        </Grid>
      )}

      {!isLoading && !error && notificationSchemaUsers.length > 0 && (
        <Grid item={true} xs={12} className={classes.topMargin}>
          <ClientSidePaginatedTable
            title="Users"
            idList={[]}
            refresh={() => {}}
            columns={columns}
            loading={false}
            error={null}
            data={tableData}
            options={{
              filter: false,
              search: false,
              searchOpen: false,
              selectableRows: 'none',
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default NotificationSchemaUsers
