import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FilesList from '../../components/file-upload/file-list'
import FileUploader from '../../components/file-upload/file-uploader'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import useSharedFormStyles from '../../shared-styles/form.styles'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'

interface ICoresPayload extends ICoreFormValues {
  public: boolean
}

interface ICoreFormValues {
  name: string
}

export const CoresForm = () => {
  const classes = useSharedFormStyles()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ coreId: string }>()
  const coreId = params.coreId
  const isEditMode = coreId !== 'new'

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)
  const [currentCore, setCurrentCore] = useState<ICoreType | null>(null)
  const [coreFiles, setCoreFiles] = useState<string[]>([])

  useDocumentTitle('Cores', isEditMode ? currentCore?.name : 'new')

  useEffect(() => {
    if (isEditMode) {
      try {
        Api.get(`/api/cores/${coreId}/files`).then(files => {
          setCoreFiles(files)
        })
        Api.get(`/api/cores/${coreId}`).then(core => {
          redirectToListViewOnError(core, history, addAlert, '/cores')
          setCurrentCore(core)
        })
      } catch (e) {
        const message = (e as Error).message
        addAlert({ alertType: 'error', message: String(message) || 'List of core files could not be loaded' })
      }
    }
  }, [addAlert, coreId, isEditMode, history])

  const saveCore = async (formValues: ICoreFormValues, actions: FormikHelpers<ICoreFormValues>) => {
    const corePayload: ICoresPayload = {
      name: formValues.name,
      public: false,
    }
    let submittedCore

    try {
      if (isEditMode) {
        handleFormSubmit(await Api.put(`/api/cores/${coreId}`, corePayload), addAlert, history, null, formValues, actions)
      } else {
        handleFormSubmit((submittedCore = await Api.post('/api/cores', corePayload)), addAlert, history, `/cores/${submittedCore.id}`, formValues, actions)
      }
    } catch (e) {
      const message = (e as Error)?.message
      addAlert({ alertType: 'error', message: String(message) || 'Core could not be submitted' })
    }
  }

  const deleteCore = async () => {
    try {
      handleDelete(await Api.del('/api/cores/', coreId), addAlert, history, '/cores')
    } catch (error) {
      addAlert({ alertType: 'error', message: 'An error occured when deleting a core' })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  })

  const nameInputAlertMessage =
    'Cores, together with Applications, form the backbone of your Meshify application. They contain the HTML, CSS, and Javascript files used to create the interface users interact with when they use your application.'

  const initialValues = isEditMode && currentCore ? { name: currentCore.name } : { name: '' }

  const editCoreFormElements = (
    <>
      <FileUploader setFiles={setCoreFiles} filesEndpoint={`/api/cores/${coreId}/files/`} />

      <Grid item={true} xs={12}>
        <Typography className={classes.denseFormSubheader} variant="subtitle1">
          Files
        </Typography>
      </Grid>

      {coreFiles.length === 0 ? (
        <Grid item={true} xs={12} sm={12} md={3} lg={2} xl={2}>
          <Alert alertType="warning" message="No files. Upload one to begin" />
        </Grid>
      ) : (
        <FilesList filesList={coreFiles} setAllFiles={(val: any) => setCoreFiles(val)} fileEndpoint={`/api/cores/${coreId}/files/`} />
      )}
    </>
  )

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveCore} validationSchema={schema}>
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <Card>
              <CardHeader title={isEditMode ? 'Edit Core' : 'Create Core'} data-cy="formTitle" />

              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="coreName">
                    <FormikTextField initialized={isEditMode} name="name" label="Name" required={true} id="coreName" />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Alert alertType="warning" message={nameInputAlertMessage} />
                  </Grid>

                  {isEditMode ? editCoreFormElements : null}
                </Grid>
              </CardContent>

              <CardActions>
                <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                  Save
                </Button>

                <Button type="button" onClick={() => history.push('/cores')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                  Cancel
                </Button>

                {isEditMode ? (
                  <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                    Delete
                  </Button>
                ) : null}
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteCore}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this core?"
      />
    </>
  )
}

export default CoresForm
