import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Language from '@mui/icons-material/Language'
import MenuIcon from '@mui/icons-material/Menu'
import NightsStayIcon from '@mui/icons-material/NightsStay'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'
import classNames from 'classnames'

import desktopLogo from '../../assets/logo.png'
import { SMALL_SCREEN_TRIGGER_WIDTH } from '../../constants'
import { AlertContext, AuthenticationContext, ImpersonateContext, OwnUserContext, SidenavContext } from '../../providers'
import Api from '../../utils/api'

import { ThemeContext } from './../../providers/theme'
import { Search } from './search/search'
import { useStyles } from './header.styles'
import ProfileMenu from './profile-menu'

interface ILanguage {
  name: string
  code: string
}

type TransifexType = {
  live?: {
    onFetchLanguages: (cb: (languages: ILanguage[]) => void) => void
    translateTo: (code: ILanguage['code']) => void
    selected_lang: ILanguage['code']
  }
}

interface IWindow extends Window {
  Transifex: TransifexType
}

type LanguageMenuType = Pick<MenuProps, 'anchorEl' | 'onClose'> & {
  languages: ILanguage[]
  selectedLanguageCode: ILanguage['code']
  onMenuItemClick: (str: ILanguage) => void
}

const DesktopLogo = () => {
  const classes = useStyles()
  return (
    <div id="meshifyDesktopLogo" data-cy="meshifyDesktopLogo" className={classes.sectionDesktop} data-testid="logo">
      <RouterLink to="/">
        <img alt="Logo" className={classes.desktopLogo} src={desktopLogo} />
      </RouterLink>
    </div>
  )
}

const ImpersonatedEmail = () => {
  const classes = useStyles()
  const ownUserContext = useContext(OwnUserContext)
  const impersonatingUserEmail = ownUserContext?.impersonatedBy?.email
  const impersonatedFirstName = ownUserContext?.information?.first
  const impersonatedLastName = ownUserContext?.information?.last
  const fullName = `${impersonatedFirstName ?? ''} ${impersonatedLastName ?? ''}`
  const impersonatedUserDisplayValue = ownUserContext?.email ? ownUserContext?.email : fullName.trim() !== '' ? fullName : 'Guest User'

  return (
    <>
      <span className={classes.impersonatingUserEmail}>{impersonatingUserEmail} as</span>
      <span data-testid="impersonatedUserDisplayValue" className={classes.impersonatedUserDisplayValue}>
        {impersonatedUserDisplayValue}
      </span>
    </>
  )
}

const LanguageMenu = ({ anchorEl, onClose, onMenuItemClick, languages, selectedLanguageCode }: LanguageMenuType) => {
  const classes = useStyles()

  return (
    <Menu
      id="languageMenu"
      data-cy="languageMenu"
      data-testid="languageMenu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {/* a workaround for this github issue https://github.com/mui-org/material-ui/issues/5186to
          to make sure first item doesn't stay hovered */}
      <MenuItem className={classes.menuPlaceholder} />
      {languages.map(lang => (
        <MenuItem key={lang.code} className={selectedLanguageCode === lang.code ? classes.selectedLanguage : ''} onClick={() => onMenuItemClick(lang)}>
          <ListItemText inset={false} primary={lang.name} />
        </MenuItem>
      ))}
    </Menu>
  )
}

const ThemeMenuButton = () => {
  const classes = useStyles()
  const { setTheme, themeKey } = useContext(ThemeContext)
  const smallScreenMatches = useMediaQuery(`(max-width:${SMALL_SCREEN_TRIGGER_WIDTH}px)`)

  return (
    <Tooltip title={themeKey === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'} arrow={true}>
      <IconButton
        id="themeMenuButton"
        data-cy="themeMenuButton"
        aria-haspopup="true"
        className={classes.themeButton}
        onClick={() => {
          if (setTheme) {
            setTheme(themeKey === 'light' ? 'dark' : 'light')
          }
        }}
        size="small"
        data-testid="themeButton"
      >
        {themeKey === 'light' ? (
          <NightsStayIcon fontSize={smallScreenMatches ? 'small' : 'medium'} />
        ) : (
          <WbSunnyIcon fontSize={smallScreenMatches ? 'small' : 'medium'} />
        )}
      </IconButton>
    </Tooltip>
  )
}

const SidenavToggle = () => {
  const classes = useStyles()
  const sidenavContext = useContext(SidenavContext)
  const smallScreenMatches = useMediaQuery(`(max-width:${SMALL_SCREEN_TRIGGER_WIDTH}px)`)

  return (
    <MenuIcon
      className={classes.menuIcon}
      fontSize={smallScreenMatches ? 'small' : 'medium'}
      onClick={sidenavContext.toggleSidenav}
      data-testid="menu-icon"
      data-cy="toggleSidenav"
    />
  )
}

const ProfileMenuButton = ({ handleProfileMenuOpen }: { handleProfileMenuOpen: (evt: React.MouseEvent<HTMLSpanElement, MouseEvent> | null) => void }) => {
  const classes = useStyles()

  const ownUserContext = useContext(OwnUserContext)
  const smallScreenMatches = useMediaQuery(`(max-width:${SMALL_SCREEN_TRIGGER_WIDTH}px)`)

  return (
    ownUserContext && (
      <span
        id="profileMenuButton"
        data-cy="profileMenuButton"
        aria-haspopup="true"
        className={classNames(classes.profileMenuButton, {
          [classes.noImpersonateProfileMenuBtn]: ownUserContext && !ownUserContext.impersonatedBy,
          [classes.smallScreenMenuButton]: smallScreenMatches,
        })}
        onClick={handleProfileMenuOpen}
        data-testid="profileMenuButton"
      >
        {ownUserContext && !ownUserContext.impersonatedBy ? ownUserContext.email : <ImpersonatedEmail />}
        <KeyboardArrowDown
          fontSize="small"
          className={classNames(classes.arrowDown, {
            [classes.smallScreenArrowDown]: smallScreenMatches,
          })}
        />
      </span>
    )
  )
}

const NoContextLogoutBtn = ({ handleLogout }: { handleLogout: (impersonateContext: IImpersonateContext) => void }) => {
  const impersonateContext = useContext(ImpersonateContext)

  return (
    <Button
      onClick={() => {
        if (impersonateContext) {
          handleLogout(impersonateContext)
        }
      }}
      data-testid="noContextLogoutBtns"
    >
      Logout
    </Button>
  )
}

const LanguageMenuButton = ({
  handleLanguageMenuOpen,
}: {
  handleLanguageMenuOpen: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
}) => {
  const classes = useStyles()
  const smallScreenMatches = useMediaQuery(`(max-width:${SMALL_SCREEN_TRIGGER_WIDTH}px)`)

  return (
    <Tooltip title="Language" arrow={true}>
      <IconButton
        className={classes.iconButton}
        data-cy="languageMenuButton"
        id="languageMenuButton"
        data-testid="languageMenuButton"
        aria-haspopup="true"
        onClick={handleLanguageMenuOpen}
        color="inherit"
        size="large"
      >
        <Language fontSize={smallScreenMatches ? 'small' : 'medium'} />
      </IconButton>
    </Tooltip>
  )
}

const ToolbarButtons: React.FC<{}> = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.toolbarButtons}>{children}</div>
}

export const Header = () => {
  const { addAlert } = useContext(AlertContext)
  const { setIsAuthenticated } = useContext(AuthenticationContext)

  const [anchorElLanguage, setAnchorElLanguage] = useState<(EventTarget & HTMLButtonElement) | undefined>(undefined)
  const [anchorElProfile, setAnchorElProfile] = useState<(EventTarget & HTMLSpanElement) | undefined>(undefined)
  const [languages, setLanguages] = useState<ILanguage[]>([])
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<ILanguage['code']>('')

  const classes = useStyles()
  const ownUserContext = useContext(OwnUserContext)

  // Set both anchor elements to null to close both menus
  const handleMenuClose = () => {
    setAnchorElProfile(undefined)
    setAnchorElLanguage(undefined)
  }

  const handleLogout = async (impersonateContext: IImpersonateContext) => {
    try {
      await Api.get('/api/logout')
      await Api.get('/api/users/unimpersonate')

      impersonateContext.setImpersonatedUserId(null)
    } catch (e) {
      const message = (e as Error).message
      setIsAuthenticated(false)
      addAlert({ alertType: 'error', message: String(message) ?? 'We could not log you out. Please try again' })
    }
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLSpanElement, MouseEvent> | null) => {
    setAnchorElProfile(event?.currentTarget)
  }

  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
    setAnchorElLanguage(event?.currentTarget)
  }

  const handleUpdateLanguage = (lang: ILanguage) => {
    setSelectedLanguageCode(lang.code)
    const Transifex = ((window as unknown) as IWindow).Transifex

    // tell transifex live to translate the page
    // based on user selection
    Transifex?.live?.translateTo(lang.code)
    handleMenuClose()
  }

  useEffect(() => {
    const { Transifex } = (window as unknown) as IWindow
    if (Transifex?.live) {
      Transifex.live.onFetchLanguages(fetchedLanguages => {
        setLanguages(fetchedLanguages as ILanguage[])
      })
      setSelectedLanguageCode(Transifex.live?.selected_lang)
    }
  }, [])

  return (
    <div className={classes.headerWrapper} data-cy="header">
      <AppBar>
        <Toolbar className={classes.toolBar}>
          <SidenavToggle />
          <DesktopLogo />
          <Search />
          <ToolbarButtons>
            <ProfileMenuButton handleProfileMenuOpen={handleProfileMenuOpen} />
            {Boolean(!ownUserContext || ownUserContext.error) && <NoContextLogoutBtn handleLogout={handleLogout} />}
            <LanguageMenuButton handleLanguageMenuOpen={handleLanguageMenuOpen} />
            <ThemeMenuButton />
          </ToolbarButtons>
        </Toolbar>

        <ProfileMenu anchorEl={anchorElProfile} onClose={handleMenuClose} handleLogout={handleLogout} />

        <LanguageMenu
          languages={languages}
          onMenuItemClick={handleUpdateLanguage}
          selectedLanguageCode={selectedLanguageCode}
          anchorEl={anchorElLanguage}
          onClose={handleMenuClose}
        />
      </AppBar>
    </div>
  )
}

export default Header
