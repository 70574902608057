import { darken, lighten, Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) => {
  return createStyles({
    tableContainer: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.light,
      // this rule should make all sortable column headers bold
      '& th': {
        '& span': {
          '& div': {
            color: theme.palette.mode === 'light' ? lighten(theme.palette.common.black, 0.1) : darken(theme.palette.common.white, 0.1),
            fontWeight: 600,
            fontSize: '0.75rem',
            textAlign: 'left',
          },
        },
      },
    },
    applyButtonContainer: {
      marginTop: 15,
    },
    applicationLink: {
      textDecoration: 'none',
      color: `${theme.palette.primary.main}`,
      '&:visited': {
        color: theme.palette.primary.main,
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    greenColor: {
      color: `${theme.palette.success.main}`,
    },
    redColor: {
      color: theme.palette.error.main,
    },
    addBtn: {
      marginBottom: theme.spacing(2),
    },
  })
}

const useStyles = makeStyles(styles)
export default useStyles
