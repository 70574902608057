import { useEffect } from 'react'

import SingleFilterSelect from '../../../components/form-elements/filter-select/single-select'
import { getSelectedNodeType } from '../create-edit'

interface IProps {
  isEditMode: boolean
  createFromExistingTemplate: boolean
  nodeTypeId: number | null
  nodeTypes: INodeTypeType[]
  setFieldValue: (fieldName: string, value: any) => void
  loading: boolean
  currentTemplate: INodeTypeTemplateType | null
}

const NodeTypeIdFilterSelect = <GenericFormValues extends object>(props: IProps) => {
  const { nodeTypeId, nodeTypes, isEditMode, createFromExistingTemplate, setFieldValue, loading, currentTemplate } = props

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && nodeTypeId && nodeTypes) {
      const selectedNodeTypeName = getSelectedNodeType(nodeTypes, nodeTypeId)?.name

      if ((isEditMode || createFromExistingTemplate) && selectedNodeTypeName) {
        if (isSubscribed) {
          setFieldValue('disabledNodeTypeName', getSelectedNodeType(nodeTypes, nodeTypeId).name + '-')
        }
      }
    }
    return () => {
      isSubscribed = false
    }
  }, [nodeTypes, nodeTypeId, createFromExistingTemplate, isEditMode, setFieldValue])

  return (
    <SingleFilterSelect<INodeTypeType, GenericFormValues>
      name={('nodeTypeId' as unknown) as string & keyof GenericFormValues}
      label="Please select a node type"
      displayField="name"
      required={true}
      items={nodeTypes}
      loading={loading}
      initialValue={currentTemplate?.nodeTypeId}
    />
  )
}

export default NodeTypeIdFilterSelect
