import { useContext, useMemo, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { debounce } from 'lodash'

import { SMALL_SCREEN_TRIGGER_WIDTH } from '../../../constants'
import { AlertContext } from '../../../providers'

import fetchSuggestions, { ISuggestionType } from './fetchSuggestions'
import useSearchStyles from './search.styles'

interface ISuggestionItem {
  label: string
  type: string
  id: number
  metadataSearch: boolean
}

const renderSectionTitle = (section: { title: string }) => {
  return (
    <Typography variant="subtitle1" color="inherit">
      {section.title}
    </Typography>
  )
}

export const getSuggestionValue = (suggestion: { label: string }) => {
  return suggestion?.label ?? ''
}

export const getSectionSuggestions = (section: { entities?: ISuggestionItem[] }) => {
  return section?.entities ?? []
}

export const shouldRenderSuggestions = (value: string) => {
  // fetch data after entering three or more characters
  return value.trim().length > 2
}

export const Search = () => {
  const classes = useSearchStyles()
  const { addAlert } = useContext(AlertContext)
  const history = useHistory()

  const [queryResults, setQueryResults] = useState<{ total: number; suggestions: ISuggestionType[] }>({ total: 0, suggestions: [] })
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedSuggestion, setSelectedSuggestion] = useState<ISuggestionItem | null>(null)

  const smallScreenMatches = useMediaQuery(`(max-width:${SMALL_SCREEN_TRIGGER_WIDTH}px)`)

  const renderSuggestion = (suggestion: ISuggestionItem, { query, isHighlighted }: { query: any; isHighlighted: boolean }) => {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)
    const link = `/${suggestion.type}/${suggestion.id}`

    if (suggestion.label === '') {
      return <div />
    } else {
      return (
        <RouterLink to={link}>
          <MenuItem selected={isHighlighted} component="div">
            <div className={classes.suggestionsWrapper}>
              {parts.map((part, index) =>
                part.highlight ? (
                  <span key={String(index)}>{part.text}</span>
                ) : (
                  <span key={index}>
                    <strong>{part.text}</strong>
                    {suggestion.metadataSearch && <span className={classes.metadataChip}>Metadata</span>}
                  </span>
                )
              )}
            </div>
          </MenuItem>
        </RouterLink>
      )
    }
  }

  const handleSuggestionsClearRequested = () => {
    setQueryResults({
      total: 0,
      suggestions: [],
    })
  }

  const debouncedAutoComplete = useMemo(
    () =>
      debounce((value: string) => {
        setLoading(true)
        handleSuggestionsClearRequested()
        fetchSuggestions(value)
          .then(query => {
            setQueryResults({
              suggestions: query.results?.suggestions ?? [],
              total: query.results?.total ?? 0,
            })
            setLoading(false)
          })
          .catch(({ message }) => {
            addAlert({ alertType: 'error', message })
          })
      }, 500),
    [addAlert, setQueryResults, setLoading]
  )

  const handleSuggestionsFetchRequested = ({ value }: { value: string }) => {
    debouncedAutoComplete(value)
  }

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && selectedSuggestion && selectedSuggestion.type && selectedSuggestion.id) {
      history.push(`/${selectedSuggestion.type}/${selectedSuggestion.id}`)
    }
  }

  const renderInputComponent = (inputProps: any) => {
    const { classes: inputClasses, inputRef = () => {}, ref, ...other } = inputProps

    return (
      <TextField
        data-cy="globalSearchInput"
        id="searchInput"
        fullWidth={true}
        variant="outlined"
        onKeyPress={handleEnterKeyPress}
        InputProps={{
          inputRef: (node: React.ReactElement) => {
            ref(node)
            inputRef(node)
          },
          classes: {
            input: inputClasses.inputInput,
          },
        }}
        {...other}
      />
    )
  }

  // a bit of a strange syntax, looks like it comes from the package
  const handleOnHighlighted = (suggestion: { suggestion: ISuggestionItem }) => {
    if (suggestion && suggestion.suggestion) {
      setSelectedSuggestion(suggestion.suggestion)
    }
  }

  const autosuggestProps = {
    multiSection: true,
    // An unfortunately convenient any, if we wanted to fix this we'd have to refactor to tie data to and id we provide via this object.
    suggestions: queryResults.suggestions as any,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    getSectionSuggestions,
    renderInputComponent,
    renderSuggestion,
    renderSectionTitle,
    shouldRenderSuggestions,
    onSuggestionHighlighted: handleOnHighlighted,
  }

  return (
    <>
      <div className={classes.search}>
        <div className={classes.searchIconWrapper} data-cy="globalSearch">
          {!smallScreenMatches && <SearchIcon className={classes.searchIcon} />}
        </div>

        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            // @ts-ignore
            classes,
            placeholder: 'Search Folders, Nodes and Users',
            value: inputValue,
            onChange: (event: unknown, { newValue }: { newValue: string }) => {
              setInputValue(newValue)
            },
          }}
          theme={{
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
            sectionTitle: classes.sectionTitle,
            sectionContainer: classes.sectionContainer,
          }}
          renderSuggestionsContainer={options => {
            return (
              !loading && (
                <Paper data-testid="test" data-cy="globalSearchRes" {...options.containerProps} square={true}>
                  {options.children}
                </Paper>
              )
            )
          }}
        />

        {inputValue && (
          <div className={classes.clearSearchIcon} onClick={() => setInputValue('')}>
            <CloseIcon fontSize="small" data-cy="clearSearch" color="primary" />
          </div>
        )}
      </div>

      {loading && (
        <div className={classes.loadingPaperContainer} data-cy="resultsLoading">
          <Paper className={classes.loadingPaper} elevation={1} square={true}>
            <CircularProgress size={26} color="secondary" />
          </Paper>
        </div>
      )}
    </>
  )
}
