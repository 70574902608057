import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ButtonGroup, IconButton, Tab, Tabs } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { FastField, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { get } from 'lodash'
import moment from 'moment-timezone'
import { NumberParam, useQueryParam } from 'use-query-params'
import * as Yup from 'yup'

import { AddressForm } from '../../components/address'
import Alert from '../../components/alert'
import Checkbox from '../../components/form-elements/checkbox'
import MultiFilterSelect from '../../components/form-elements/filter-select/multi-select'
import FormikTextField from '../../components/form-elements/text-field'
import MetadataForm, { IMetadataItems, validateMetadataItems } from '../../components/metadata'
// eslint-disable-next-line import/no-duplicates
import ConfirmSendInviteEmailModal from '../../components/modal'
// eslint-disable-next-line import/no-duplicates
import ConfirmRevokeMembershipModal from '../../components/modal'
// eslint-disable-next-line import/no-duplicates
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import { AlertContext, ImpersonateContext, OwnUserContext } from '../../providers'
import api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { folderTreeToList } from '../../utils/helper-functions'
import { useDocumentTitle, useToggle } from '../../utils/hooks'
import { parseInitialValue } from '../folders/folder-form'

import ChangePassword from './change-password-form/change-password-form'
import useStyles from './create-edit.styles'
import UserHeaderAction from './header-actions'
import UserPreferencesForm, { PreferencesType } from './user-preferences-form'
import UsersTasks from './users-tasks'

type IUserFormValues = {
  firstName: string
  lastName: string
  email: string
  role: string
  isRoot: boolean
  folders: number[]
  phone: string
  address: string
  city: string
  state: string
  country: string
  postalCode: string
  timeZone: string
  externalApp: string
  currPassword: string
  preferences: PreferencesType[]
  preferencesNotifications: string[]
  language: string
} & IMetadataItems

interface IUserPayloadType {
  email?: string
  folders: number[]
  information?: {
    address?: {
      address?: string
      code?: string
      country?: string
      locality?: string
      region: string
    }
    first?: string
    last?: string
    timezone?: string
  }
  isRoot?: boolean
  language: string
  metadata?: {
    [key: string]: any
  }
  preferences?: {
    [key: string]: any
  }
  phone?: string
  roleId: number
  currPassword?: string
}

type ISetFieldError = (field: 'phone' & string, message: string) => void

export const genUserPayload = (values: IUserFormValues, deleteEmailFromPayload: boolean | null) => {
  const payload = {
    email: values.email,
    folders: values.folders,
    roleId: Number(values.role),
    information: {
      address: {
        address: values.address,
        code: values.postalCode,
        country: values.country,
        locality: values.city,
        region: values.state,
      },
      first: values.firstName,
      last: values.lastName,
      timezone: values.timeZone,
    },
    isRoot: values.isRoot,
    language: values.language,
    phone: values.phone,
    currPassword: values.currPassword,
    metadata:
      values.metadataItems.length > 0
        ? values.metadataItems.reduce((acc: any, curr: any) => {
            let value = curr.value
            try {
              if (curr.asJSON && typeof curr.value === 'string') {
                value = JSON.parse(curr.value)
              }
            } catch {
              // do nothing on catch
            } finally {
              acc[curr.key] = value
            }
            return acc
          }, {})
        : {},
    preferences:
      values.preferences.length > 0
        ? values.preferences.reduce((acc: any, curr: any) => {
            let value = curr.value
            try {
              if (curr.asJSON && typeof curr.value === 'string') {
                value = JSON.parse(curr.value)
              }
            } catch {
              // do nothing on catch
            } finally {
              acc[curr.key] = value
            }
            return acc
          }, {})
        : {},
  }

  if (deleteEmailFromPayload) {
    // @ts-ignore
    delete payload.email
  }

  // update notification preferences seperately to make sure it's always a list from the buttons
  payload.preferences.notification = values.preferencesNotifications

  return payload
}

export const isRootCheckboxDisabled = (retrievedUser: IUserType | null, loggedInUser: IUserType | null) => {
  if (retrievedUser && loggedInUser) {
    return loggedInUser.id === retrievedUser.id
  } else {
    return false
  }
}

export const validateEmail = (value: string): boolean => {
  /* eslint-disable */
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  /* eslint-enable */

  return emailRegex.test(String(value).toLowerCase())
}

export const getInitialUserPreferences = (preferences: any) => {
  return preferences
    ? Object.entries(preferences)
        .map(item => {
          const value = parseInitialValue(item[1])
          return {
            key: item[0],
            value,
            asJSON: typeof item[1] === 'object',
          }
        })
        // we are filtering out the `notification` entry here because we will handle it
        // seperately.
        .filter(entry => entry.key !== 'notification')
    : []
}

// get the notification preference out of preferences
// if the value is already an array, keep it that way
// if it can be JSON parsed and it's an array, keep it that way
// otherwise return an empty array because it's bad data.
export const getNotificationPreferences = (preferences: any) => {
  let value = preferences?.notification
  if (typeof value === 'object' && Array.isArray(value)) {
    return value
  } else {
    try {
      value = JSON.parse(value)
      return Array.isArray(value) ? value : []
    } catch {
      return []
    }
  }
}

const createMembership = async (userId: string, externalApp?: string) => {
  let params: string[][] = []

  // for Admin
  if (externalApp === '') {
    params = [
      ['referToApplication', 'admin'],
      ['userId', userId],
    ]
  }
  // for when Protect, Insights or Central button was selected
  else if (externalApp === 'insights' || externalApp === 'protect' || externalApp === 'central') {
    params = [
      ['referToApplication', 'admin'],
      ['userId', userId],
      ['externalApp', externalApp],
    ]
  }
  // According to Keith, because enrollment is a Carbon core in HSB related tenants, it should use the referToApplication query
  // so when this option is selected, the API endpoint params will look slighly different than for Admin, Insights or Protect
  else {
    params = [
      ['referToApplication', 'enrollment'],
      ['userId', userId],
    ]
  }

  const urlParams = new URLSearchParams(params)

  const data = await api.post(`/api/authentication/membership?${urlParams.toString()}`, {})
  return data
}

export const UserForm = () => {
  const history = useHistory()
  const params = useParams<{ userId: string }>()
  const classes = useStyles()
  const timeZones = moment.tz.names()
  const userId = params.userId
  const isEditMode = userId !== 'new'
  const { addAlert } = useContext(AlertContext)

  const defaultTabIndex = 0

  const [selectedTabIndex, setSelectedTabIndex] = useQueryParam('tab', NumberParam)
  const [folders, setFolders] = useState<IFolderTypeSimple[] | null>(null)
  const [roles, setRoles] = useState<IRoleType[]>([])
  const [retrievedUser, setRetrievedUser] = useState<IUserType | null>(null)
  const [isMembershipRevoked, setIsMembershipRevoked] = useState<boolean>(false)
  const [foldersError, setFoldersError] = useState<string>('')
  const [foldersLoading, setFoldersLoading] = useState<boolean>(false)
  const [rolesError, setRolesError] = useState<string>('')
  const [submitCounter, setSubmitCounter] = useState<number>(0)

  const [showConfirmDeletionModal, toggleConfirmDeletionModal] = useToggle(false)
  const [showRevokeMembershipModal, toggleRevokeMembershipModal] = useToggle(false)
  const [showSendInviteEmailModal, toggleSendInviteEmailModal] = useToggle(false)

  const [showPasswordField, togglePasswordField] = useToggle(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)

  const currentUser = useContext<IUserType | null>(OwnUserContext)
  const refetchUserContext = useContext<IImpersonateContext | null>(ImpersonateContext)

  useDocumentTitle('Users', isEditMode ? retrievedUser?.email : 'new')

  useEffect(() => {
    if (!selectedTabIndex) {
      setSelectedTabIndex(0)
    }
  }, [selectedTabIndex, setSelectedTabIndex])

  useEffect(() => {
    let isSubscribed = true
    setFoldersLoading(true)

    api.get('/api/folders/tree').then(folderData => {
      if (isSubscribed) {
        setFoldersLoading(false)

        if (!folderData.error) {
          setFolders(folderTreeToList(folderData))
        } else {
          const folderErrorMessage = folderData.message ? folderData.message : 'Could not access folders'
          setFoldersError(folderErrorMessage)
          addAlert({ alertType: 'error', message: folderErrorMessage })
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true

    api.get('/api/roles').then(roleData => {
      if (isSubscribed) {
        if (!roleData.error) {
          setRoles(roleData)
        } else {
          const roleErrorMessage = roleData.message ? roleData.message : 'Could not access roles'
          setRolesError(roleErrorMessage)
          addAlert({ alertType: 'error', message: roleErrorMessage })
        }
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      const setUserData = async () => {
        const retrieved = await api.get(`/api/users/${userId}`)
        redirectToListViewOnError(retrieved, history, addAlert, '/users')

        if (isSubscribed) {
          setRetrievedUser(retrieved)
        }
      }
      setUserData()
    }

    return () => {
      isSubscribed = false
    }
  }, [isEditMode, userId, history, addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      api.get(`/api/authentication/membership?userId=${userId}`).then(data => {
        if (data.error) {
          if (isSubscribed) {
            setIsMembershipRevoked(true)
          }
        }
      })
    }

    return () => {
      isSubscribed = false
    }
  }, [isEditMode, userId])

  const schemaValidationShape = {
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Must be a valid email'),
    role: Yup.string().required('Required'),
    folders: Yup.array(Yup.number()).required('Required'),
    phone: Yup.string().test('len', 'Length of number must be greater than or equal to 10', val => {
      // Phone number should be either empty, or not empty but length greater then or equal to 10
      return (val && val.length >= 10) || !val
    }),
    language: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    postalCode: Yup.string(),
    timeZone: Yup.string(),
  }

  const schema = Yup.object().shape(schemaValidationShape)

  const schemaWithPassword = Yup.object().shape({
    ...schemaValidationShape,
    currPassword: Yup.string().required('Required'),
  })

  const deleteUser = async () => {
    try {
      handleDelete(await api.del('/api/users', userId), addAlert, history, '/users')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete the user' })
    }
  }

  const revokeMembership = async () => {
    toggleRevokeMembershipModal()

    try {
      const data = await api.del('/api/authentication', `membership?userId=${userId}`)
      setIsMembershipRevoked(true)

      addAlert(
        data.error
          ? {
              message: `Could not revoke membership ${data.message ? String(data.message) : ''}`,
              alertType: 'error',
            }
          : { message: 'Membership Revoked Successfully', alertType: 'success' }
      )
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not revoke membership' })
    }
  }

  const sendInviteEmail = async (externalApp?: string) => {
    toggleSendInviteEmailModal()
    try {
      const data = await createMembership(userId, externalApp)
      setIsMembershipRevoked(false)
      addAlert(
        data.error
          ? {
              message: `Could not send an email ${data.message ? String(data.message) : ''}`,
              alertType: 'error',
            }
          : { message: 'Invitation email was sent', alertType: 'success' }
      )
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not send an invite email' })
    }
  }

  /*
    If on current user's profile and email value was not changed,
    delete email from PUT request payload for successful update
    without providing current password
  */
  const shouldDeleteEmailFromPayload = (values: IUserFormValues) => {
    const isCurrentUserProfile = currentUser && retrievedUser && currentUser.id === retrievedUser.id
    return isEditMode && isCurrentUserProfile && currentUser && currentUser.email === values.email
  }

  const validateMobileNumber = async (userPayload: IUserPayloadType, setFieldError: ISetFieldError, setSubmitting: (state: boolean) => void) => {
    const currentPhoneNumber = get(retrievedUser, 'phone', '')
    if (userPayload.phone && currentPhoneNumber !== userPayload.phone) {
      const data = await api.getPhoneInfo(userPayload.phone)
      setSubmitting(false)
      if (data.error || data.carrier.type !== 'mobile') {
        const message = data.error ? String(data.message) : 'Not a valid mobile number'
        addAlert({
          message: message ? `Phone Number Error: ${message}` : 'Not a valid mobile number',
          alertType: 'error',
        })
        setFieldError('phone', 'You must enter a valid mobile number')
        return false
      }
      return true
    }
    return true
  }

  const saveAndInvite = async (values: IUserFormValues, setSubmitting: FormikHelpers<IUserFormValues>['setSubmitting'], setFieldError: ISetFieldError) => {
    const userPayload = genUserPayload(values, shouldDeleteEmailFromPayload(values))
    setSubmitting(true)
    try {
      const isValidMobile = await validateMobileNumber(userPayload, setFieldError, setSubmitting)
      if (!isValidMobile) {
        return
      }

      const createdUser = await api.post('/api/users', userPayload)
      if (!createdUser.error) {
        const res = await createMembership(createdUser.id, values.externalApp)
        addAlert(
          res.error
            ? { message: `Submission Errored: ${String(res.message)}`, alertType: 'error' }
            : { message: 'Successfully Created and Invited User', alertType: 'success' }
        )
        history.push('/users')
      } else {
        let errorMessage = `Submission Errored: ${String(createdUser.message)}`
        if (createdUser.message === 'Integrity Constraint Violation') {
          errorMessage = 'Submission Errored: User with this email address already exists'
        }

        addAlert({
          message: errorMessage,
          alertType: 'error',
        })
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not save and invite user' })
    }
    setSubmitting(false)
  }

  const saveUser = async (values: IUserFormValues, helpers: FormikHelpers<IUserFormValues>) => {
    const { setFieldError, setSubmitting } = helpers
    const userPayload = genUserPayload(values, shouldDeleteEmailFromPayload(values))
    setSubmitting(true)
    try {
      const isValidMobile = await validateMobileNumber(userPayload, setFieldError, setSubmitting)
      if (!isValidMobile) {
        return
      }

      let submittedUser

      if (isEditMode) {
        handleFormSubmit((submittedUser = await api.put(`/api/users/${userId}`, userPayload)), addAlert, history, null, values, helpers)

        setSubmitCounter(submitCounter + 1)

        // to prevent form reset on submit in edit mode
        const updatedUser: IUserType = {
          ...userPayload,
          createdAt: submittedUser.createdAt,
          updatedAt: submittedUser.updatedAt,
          id: submittedUser.id,
          lastUsed: submittedUser.lastUsed,
        }
        setRetrievedUser(updatedUser)
      } else {
        handleFormSubmit((submittedUser = await api.post('/api/users', userPayload)), addAlert, history, `/users/${submittedUser.id}`, values, helpers)
      }
      if (refetchUserContext) {
        refetchUserContext.setForceUserRefresh(true)
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit user' })
    }
    setSubmitting(false)
  }

  const formInitialValues: IUserFormValues =
    isEditMode && retrievedUser != null
      ? {
          firstName: get(retrievedUser, 'information.first', ''),
          lastName: get(retrievedUser, 'information.last', ''),
          email: retrievedUser.email,
          currPassword: '',
          role: String(retrievedUser.roleId),
          isRoot: get(retrievedUser, 'isRoot', false),
          language: get(retrievedUser, 'language', 'en-US'),
          folders:
            retrievedUser.folders != null && folders != null
              ? folders.filter(folder => retrievedUser.folders.includes(folder.id)).map(folder => folder.id)
              : [],
          metadataItems: retrievedUser.metadata
            ? Object.entries(retrievedUser.metadata).map(item => {
                let value
                if (item[1] === '') {
                  value = ''
                } else {
                  if (typeof item[1] === 'object') {
                    value = JSON.stringify(item[1])
                    return {
                      key: item[0],
                      value,
                      asJSON: true,
                    }
                  } else {
                    value = item[1]
                  }
                }
                return {
                  key: item[0],
                  value,
                  asJSON: false,
                }
              })
            : [],
          preferences: getInitialUserPreferences(retrievedUser.preferences),
          // preferencesNotifications is a single property from `preferences` that we will be handling seperately.
          // this field will be for alert notification types (sms, phone, push, email)
          // there is a form in user-preferences-form that requires a list to operator and the formik existing
          // `preferences` is an object of strings.
          preferencesNotifications: getNotificationPreferences(retrievedUser.preferences),
          phone: get(retrievedUser, 'phone', ''),
          address: get(retrievedUser, 'information.address.address', ''),
          city: get(retrievedUser, 'information.address.locality', ''),
          state: get(retrievedUser, 'information.address.region', ''),
          country: get(retrievedUser, 'information.address.country', ''),
          postalCode: get(retrievedUser, 'information.address.code', ''),
          timeZone: get(retrievedUser, 'information.timezone', ''),
          externalApp: 'protect',
        }
      : {
          firstName: '',
          lastName: '',
          email: '',
          currPassword: '',
          role: '',
          isRoot: false,
          language: 'en-US',
          folders: [],
          metadataItems: [],
          preferences: [],
          preferencesNotifications: ['sms', 'email', 'push', 'phone'],
          phone: '',
          address: '',
          city: '',
          state: '',
          country: '',
          postalCode: '',
          timeZone: '',
          externalApp: 'protect',
        }

  const currentPasswordEndAdornment = () => (
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
      data-testid="show-current-password"
      size="small"
    >
      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  return (
    <>
      {/* if folders and roles endpoints returned data successfully, render users form
       * otherwise we will render an error message */}
      {!foldersError && !rolesError && (
        <>
          <Card elevation={1}>
            <Formik
              data-testid="user-create-form"
              initialValues={formInitialValues}
              enableReinitialize={isEditMode}
              validationSchema={showPasswordField ? schemaWithPassword : schema}
              onSubmit={saveUser}
              validate={validateMetadataItems}
              isInitialValid={isEditMode && submitCounter > 0}
            >
              {({ setSubmitting, values, isValid, isSubmitting, setFieldError, dirty }: FormikProps<IUserFormValues>) => {
                return (
                  <Form>
                    <CardHeader
                      title={isEditMode ? 'Edit User' : 'Create User'}
                      data-testid="userFormTitle"
                      data-cy="formTitle"
                      action={<UserHeaderAction isEditMode={isEditMode} currentUser={currentUser} userId={userId} userEmail={retrievedUser?.email ?? ''} />}
                      classes={{
                        action: classes.cardActions,
                      }}
                    />

                    <CardContent>
                      <Tabs
                        value={selectedTabIndex ? selectedTabIndex : defaultTabIndex}
                        onChange={(event: React.ChangeEvent<{}>, value: number) => setSelectedTabIndex(value, 'push')}
                        textColor="secondary"
                        indicatorColor="secondary"
                        className={classes.tabs}
                      >
                        <Tab label="Info" />
                        <Tab label="Tasks" />
                      </Tabs>
                    </CardContent>

                    {selectedTabIndex === 0 && (
                      <>
                        <CardContent>
                          <Typography className={classes.denseFormSubheader} variant="subtitle1">
                            Required Information
                          </Typography>

                          <Grid container={true} spacing={3}>
                            <Grid item={true} sm={12} xs={12} md={4} lg={4} xl={4} data-cy="firstName">
                              <FormikTextField initialized={isEditMode} name="firstName" label="First Name" required={true} />
                            </Grid>

                            <Grid item={true} sm={12} xs={12} md={4} lg={4} xl={4} data-cy="lastName">
                              <FormikTextField initialized={isEditMode} name="lastName" label="Last Name" required={true} />
                            </Grid>

                            <Grid item={true} sm={12} xs={12} md={4} lg={4} xl={4} data-cy="email">
                              {currentUser && retrievedUser && currentUser.id === retrievedUser.id ? (
                                <>
                                  <FormikTextField initialized={isEditMode} disabled={!showPasswordField} formDependent={true} name="email" label="Email" />
                                  {showPasswordField && (
                                    <FormikTextField
                                      initialized={isEditMode}
                                      formDependent={true}
                                      type={showCurrentPassword ? 'text' : 'password'}
                                      name="currPassword"
                                      label="Current Password"
                                      required={true}
                                      endAdornment={currentPasswordEndAdornment}
                                    />
                                  )}
                                  <Button
                                    data-testid="toggleEmailBtn"
                                    data-cy="toggleEmailBtn"
                                    color="primary"
                                    variant="outlined"
                                    onClick={togglePasswordField}
                                    className={classes.togglePasswordBtn}
                                  >
                                    Toggle Email Edit
                                  </Button>
                                </>
                              ) : (
                                <FormikTextField name="email" label="Email" />
                              )}
                            </Grid>
                          </Grid>

                          <Grid container={true} spacing={3}>
                            <Grid item={true} sm={12} xs={12} md={6} lg={6} xl={6} data-cy="roleSelection">
                              <FormikTextField
                                formDependent={true}
                                initialized={isEditMode}
                                name="role"
                                label="Role"
                                required={true}
                                select={true}
                                hasEmptyOption={true}
                              >
                                {roles != null ? (
                                  roles?.map((role: IRoleType, index: number) => (
                                    <option key={index} value={role.id} data-testid={`role-${index}`}>
                                      {role.name}
                                    </option>
                                  ))
                                ) : (
                                  <div>No roles found</div>
                                )}
                              </FormikTextField>

                              <Checkbox name="isRoot" label="Is Root" disabled={isRootCheckboxDisabled(retrievedUser, currentUser)} />
                            </Grid>

                            <Grid item={true} sm={12} xs={12} md={6} lg={6} xl={6}>
                              <Alert
                                alertType="warning"
                                message="Root users, regardless of their role type, have access to every part of the platform and can create and delete without restriction. Root users cannot unroot themselves."
                              />
                            </Grid>
                          </Grid>

                          {isEditMode && (
                            <Grid item={true} xs={12}>
                              <Link to={`/subscriptions/${userId}`} color="primary" component={RouterLink}>
                                User Subscriptions
                              </Link>
                            </Grid>
                          )}

                          <Typography className={classes.formSubheader} variant="subtitle1">
                            Folders
                          </Typography>

                          <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12}>
                              <p className={classes.foldersHelperText}>
                                Please select the top level folder for this user. All folders under the selected folder in the tree will be visible to the user
                                as well
                              </p>
                            </Grid>

                            <Grid item={true} sm={12} xs={12} md={6} lg={6} xl={4} data-cy="folderSelection">
                              <MultiFilterSelect<IFolderTypeSimple, IUserFormValues>
                                canSelectAll={false}
                                name="folders"
                                required={true}
                                items={folders != null ? folders : []}
                                loading={foldersLoading}
                                initialValues={retrievedUser?.folders}
                                entityName="Folders"
                                displayField="name"
                                shortcutCallback={(id: number) => history.push(`/folders/${id}`)}
                              />
                            </Grid>
                          </Grid>

                          {currentUser && retrievedUser && currentUser.id === retrievedUser.id && (
                            <Grid item={true} xs={12}>
                              <ChangePassword />
                            </Grid>
                          )}

                          <Grid container={true}>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={6} item={true}>
                              <Typography className={classes.formSubheader} variant="subtitle1">
                                Metadata
                              </Typography>

                              <MetadataForm metadataItems={values.metadataItems} />
                            </Grid>

                            <Grid xs={12} sm={12} md={12} lg={12} xl={6} item={true}>
                              <Typography className={classes.formSubheader} variant="subtitle1">
                                User Preferences
                              </Typography>

                              <UserPreferencesForm preferences={values.preferences} />
                            </Grid>
                          </Grid>

                          <Grid xs={12} item={true}>
                            <Typography className={classes.formSubheader} variant="subtitle1">
                              Address
                            </Typography>
                          </Grid>

                          <Grid container={true} spacing={3}>
                            <Grid item={true} xs={4} data-cy="cellNubmer">
                              <FormikTextField data-cy="userCell" initialized={isEditMode} name="phone" type="tel" label="Primary Mobile" />
                            </Grid>
                            <AddressForm isEditMode={isEditMode} />

                            <Grid container={true} item={true} xs={12}>
                              <Grid item={true} xs={6}>
                                <Typography className={classes.denseFormSubheader} variant="subtitle1">
                                  Timezone
                                </Typography>
                              </Grid>

                              <Grid item={true} xs={6}>
                                {isMembershipRevoked || userId === 'new' ? (
                                  <Typography className={classes.denseFormSubheader} variant="subtitle1">
                                    Invite Preferences
                                  </Typography>
                                ) : null}
                              </Grid>

                              <Grid container={true} item={true} xs={12} sm={12} md={6} xl={6} lg={6}>
                                <Grid item={true} xs={12} sm={12} md={6} xl={6} lg={6}>
                                  <FormikTextField select={true} name="timeZone" label="Timezone" data-cy="timeZone">
                                    {timeZones != null ? (
                                      timeZones.map((timeZone: string, index: number) => (
                                        <option key={index} value={timeZone}>
                                          {timeZone}
                                        </option>
                                      ))
                                    ) : (
                                      <div>No timezones found</div>
                                    )}
                                  </FormikTextField>
                                </Grid>
                              </Grid>

                              <Grid item={true} xs={6}>
                                {isMembershipRevoked || userId === 'new' ? (
                                  <FastField name="externalApp">
                                    {({ field, form }: FieldProps<any>) => {
                                      return (
                                        <Grid item={true} xs={4} data-cy="externalApp">
                                          <ButtonGroup variant="outlined" color="secondary" className={classes.inviteBtnGroup}>
                                            <Button
                                              data-testid="no-external-app-btn"
                                              data-testcy="no-external-app-btn"
                                              variant={field.value === '' ? 'contained' : 'outlined'}
                                              onClick={() => form.setFieldValue('externalApp', '')}
                                            >
                                              Admin
                                            </Button>
                                            <Button
                                              data-testid="enrollment-app-btn"
                                              data-testcy="enrollment-app-btn"
                                              variant={field.value === 'enrollment' ? 'contained' : 'outlined'}
                                              onClick={() => form.setFieldValue('externalApp', 'enrollment')}
                                            >
                                              Enrollment
                                            </Button>
                                            <Button
                                              data-testid="protect-external-app-btn"
                                              data-testcy="protect-external-app-btn"
                                              variant={field.value === 'protect' ? 'contained' : 'outlined'}
                                              onClick={() => form.setFieldValue('externalApp', 'protect')}
                                            >
                                              Protect
                                            </Button>
                                            <Button
                                              data-testid="insights-external-app-btn"
                                              data-testcy="insights-external-app-btn"
                                              variant={field.value === 'insights' ? 'contained' : 'outlined'}
                                              onClick={() => form.setFieldValue('externalApp', 'insights')}
                                            >
                                              Insights
                                            </Button>
                                            <Button
                                              data-testid="central-external-app-btn"
                                              data-testcy="central-external-app-btn"
                                              variant={field.value === 'central' ? 'contained' : 'outlined'}
                                              onClick={() => form.setFieldValue('externalApp', 'central')}
                                            >
                                              Central
                                            </Button>
                                          </ButtonGroup>
                                        </Grid>
                                      )
                                    }}
                                  </FastField>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>

                        <CardActions>
                          <Button data-testid="saveBtn" data-cy="saveBtn" disabled={!isValid || isSubmitting || !dirty} color="primary" type="submit">
                            Save
                          </Button>

                          {!isEditMode && (
                            <Button
                              disabled={!validateEmail(values.email) || isSubmitting || !dirty}
                              data-testid="saveAndInviteUserBtn"
                              data-cy="saveAndInviteUserBtn"
                              onClick={e => {
                                e.preventDefault()
                                saveAndInvite(values, setSubmitting, setFieldError)
                              }}
                            >
                              Save and Invite
                            </Button>
                          )}

                          <Button type="button" onClick={() => history.push('/users')} color="inherit" data-testid="cancelBtn" data-cy="cancelBtn">
                            Cancel
                          </Button>

                          {isEditMode && (
                            <>
                              <Button
                                type="button"
                                data-testid="deleteBtn"
                                data-cy="deleteBtn"
                                className={classes.deleteBtn}
                                onClick={toggleConfirmDeletionModal}
                              >
                                Delete
                              </Button>

                              {isMembershipRevoked ? (
                                <Button
                                  type="button"
                                  data-testid="sendInviteEmailBtn"
                                  data-cy="sendInviteEmailBtn"
                                  color="secondary"
                                  onClick={toggleSendInviteEmailModal}
                                  variant="outlined"
                                >
                                  Send Invitation Email
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  data-testid="revokeMembershipBtn"
                                  data-cy="revokeMembershipBtn"
                                  color="secondary"
                                  onClick={toggleRevokeMembershipModal}
                                  variant="outlined"
                                >
                                  Revoke Membership
                                </Button>
                              )}
                            </>
                          )}
                        </CardActions>
                      </>
                    )}

                    <ConfirmSendInviteEmailModal
                      show={isEditMode && showSendInviteEmailModal}
                      confirmModalAction={() => sendInviteEmail(values.externalApp)}
                      closeModal={toggleSendInviteEmailModal}
                      dialogTitle="Do you want to send an invitation email?"
                    />
                  </Form>
                )
              }}
            </Formik>

            {selectedTabIndex === 1 && <UsersTasks userId={Number(userId)} />}
          </Card>

          <ConfirmDeletionModal
            data-testid="confirm-deletion-modal"
            show={isEditMode && showConfirmDeletionModal}
            confirmModalAction={deleteUser}
            closeModal={toggleConfirmDeletionModal}
            dialogTitle="Are you sure you want to delete this user?"
          />

          <ConfirmRevokeMembershipModal
            show={isEditMode && showRevokeMembershipModal}
            confirmModalAction={revokeMembership}
            closeModal={toggleRevokeMembershipModal}
            dialogTitle="Are you sure you want to revoke membership?"
            dialogContent="After revoking a user's membership you can send a new invitation email at any time"
          />
        </>
      )}

      {(foldersError || rolesError) && (
        <PermissionsFormError
          errorMessages={combineErrorMessages(foldersError, rolesError)}
          formName="User Form"
          listViewRoute="/users"
          hasGoBackButton={true}
        />
      )}
    </>
  )
}

export default UserForm
