import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import Checkbox from '../../components/form-elements/checkbox'
import FormikMultiSelect from '../../components/form-elements/multiselect/formik-multiselect'
import FormikTextField from '../../components/form-elements/text-field'
import Theme from '../../components/form-elements/theme'
import ConfirmDeletionModal from '../../components/modal'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import useSharedFormStyles from './../../shared-styles/form.styles'

interface IDomainValues {
  domainName: string
  forceHTTPS: boolean
  defaultApplication: number | string /* The id of the default application */
  externalApp?: 'insights' | 'protect' | 'api' | 'central'
  enabledApplications: IApplicationType[]
  themeItems: Array<{ key: string; value: string }>
}

export const validateSelect = (formValues: IDomainValues) => {
  const errors: { enabledApplications?: string; defaultApplication?: string } = {}

  if (formValues.enabledApplications.length === 0) {
    errors.enabledApplications = 'Required'
  }

  if (formValues.defaultApplication === 0) {
    errors.defaultApplication = 'A default app must be selected'
  }

  return errors
}

const schema = Yup.object().shape({
  domainName: Yup.string().required('Domain name is required'),
  forceHTTPS: Yup.bool(),
  externalApp: Yup.string(),
  defaultApplication: Yup.string().required('A default app must be selected'),
  enabledApplications: Yup.array(Yup.string()),
  themeItems: Yup.array(
    Yup.object().shape({
      key: Yup.string().required('Key is required'),
      value: Yup.string(),
    })
  ),
})

const DomainForm = () => {
  const classes = useSharedFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ domainId: string }>()
  const domainId = params.domainId
  const isEditMode = domainId !== 'new'

  const [apps, setApps] = useState<IApplicationType[]>([])
  const [appsError, setAppsError] = useState<string>('')
  const [domain, setDomain] = useState<IDomainType | null>(null)
  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  useDocumentTitle('Domains', isEditMode ? domain?.fqdn : 'new')

  useEffect(() => {
    let isSubscribed = true
    Api.get('/api/applications').then(appData => {
      if (isSubscribed) {
        if (!appData.error) {
          setApps(appData)
        } else {
          setAppsError(appData?.message || 'Could not load applications')
        }
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/domains/${domainId}`).then(data => {
        redirectToListViewOnError(data, history, addAlert, '/domains')

        if (isSubscribed) {
          setDomain(data)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, domainId, history, addAlert])

  const submitDomain = async (values: IDomainValues, helpers: FormikHelpers<any>) => {
    // When hitting post send undefined, when hitting update send empty string (for backend uniqueness reasons)
    const defaultExternalApp = isEditMode ? '' : undefined
    const payload = {
      apps: values.enabledApplications.map(app => app.id),
      defaultApplication: Number(values.defaultApplication),
      externalApp: values.externalApp ?? defaultExternalApp,
      fqdn: values.domainName,
      forceHTTPs: values.forceHTTPS,
      theme:
        values.themeItems.length > 0
          ? values.themeItems.reduce((acc: any, curr: any) => {
              acc[curr.key] = curr.value
              return acc
            }, {})
          : {},
    }
    if (isEditMode) {
      handleFormSubmit(await Api.put(`/api/domains/${domainId}`, payload), addAlert, history, null, values, helpers)
    } else {
      let submittedDomain
      handleFormSubmit((submittedDomain = await Api.post('/api/domains', payload)), addAlert, history, `/domains/${submittedDomain.id}`, values, helpers)
    }
    helpers.setSubmitting(false)
  }

  const deleteDomain = async () => {
    handleDelete(await Api.del('/api/domains', domainId), addAlert, history, '/domains')
  }

  const initialValues =
    isEditMode && domain != null && domain.apps != null
      ? {
          domainName: domain.fqdn,
          forceHTTPS: domain.forceHttps,
          defaultApplication: apps.filter(app => app.id === domain.defaultApplication).reduce((ignore, cur) => cur.id, 0),
          enabledApplications: apps.filter(app => domain.apps.indexOf(app.id) > -1),
          externalApp: domain.externalApp,
          themeItems: domain.theme
            ? Object.entries(domain.theme).map(item => ({
                key: String(item[0]),
                value: String(item[1]),
              }))
            : [],
        }
      : {
          domainName: '',
          forceHTTPS: false,
          defaultApplication: '',
          enabledApplications: [],
          themeItems: [],
        }

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize={isEditMode} validationSchema={schema} validate={validateSelect} onSubmit={submitDomain}>
        {({ values, isValid, isSubmitting, dirty }: FormikProps<IDomainValues>) =>
          !appsError && (
            <Form>
              <Card>
                <CardHeader title={isEditMode ? 'Edit Domain' : 'Create Domain'} data-cy="formTitle" />

                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} container={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item={true} xs={12} data-cy="domainName">
                        <FormikTextField initialized={isEditMode} label="Domain Name" name="domainName" required={true} />
                      </Grid>

                      <Grid item={true} xs={12} data-cy="externalApp">
                        <FormikTextField select={true} initialized={isEditMode} label="External App" name="externalApp" hasEmptyOption={true}>
                          {['insights', 'protect', 'api', 'central'].map((item, idx) => {
                            return (
                              <option key={idx} value={item}>
                                {item}
                              </option>
                            )
                          })}
                        </FormikTextField>
                      </Grid>

                      <Grid item={true} xs={12} data-cy="forceHTTPSCheckBox">
                        <Checkbox name="forceHTTPS" label="Force HTTPS" />
                      </Grid>
                    </Grid>

                    <Grid container={true} item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item={true} xs={12}>
                        <Alert
                          alertType="warning"
                          message="Domain names with the pattern *.carbon.meshify.com work automatically with both HTTP and HTTPS. Selecting Force HTTPS forces all users to use HTTPS, which is more secure."
                        />
                      </Grid>

                      <Grid className={classes.warningMargin} item={true} xs={12}>
                        <Alert
                          alertType="warning"
                          message="Custom domain names require a CNAME to yourname.carbon.meshify.com. If you'd like to use HTTPS with a custom domain name, please contact support."
                        />
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.denseFormSubheader} variant="subtitle1">
                        Applications
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} container={true}>
                      <Grid item={true} xs={12} data-cy="enabledApps">
                        <FormikMultiSelect name="enabledApplications" label="Enabled Applications" required={true} listItems={apps} />
                      </Grid>

                      <Grid item={true} xs={12} data-cy="defaultApp">
                        <FormikTextField
                          data-cy="defaultApplicationSelect"
                          initialized={isEditMode}
                          select={true}
                          label="Default Application"
                          name="defaultApplication"
                          required={true}
                          hasEmptyOption={true}
                        >
                          {values.enabledApplications.length > 0 ? (
                            values.enabledApplications
                              .sort((a, b) => {
                                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
                              })
                              .map((app: IApplicationType) => (
                                <option key={app.id} value={app.id}>
                                  {app.name}
                                </option>
                              ))
                          ) : (
                            <option>Select at least one enabled application to select a default</option>
                          )}
                        </FormikTextField>
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Alert
                        alertType="warning"
                        message="Select the Applications that can be accessed at this domain. The default application will show when a user visits the root domain."
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Typography className={classes.denseFormSubheader} variant="subtitle1">
                        Theme
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={8} lg={6} xl={6}>
                      <Theme themeItems={values.themeItems} />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button type="submit" color="primary" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                    Save
                  </Button>

                  <Button type="button" onClick={() => history.push('/domains')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button className={classes.deleteBtn} onClick={() => toggleConfirmModal()} type="button" data-cy="deleteBtn" data-testid="deleteBtn">
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Form>
          )
        }
      </Formik>

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteDomain}
        closeModal={toggleConfirmModal}
        dialogContent="This process is not reversible."
        dialogTitle="Are you sure you want to delete this domain?"
      />

      {appsError && (
        <PermissionsFormError errorMessages={combineErrorMessages(appsError)} formName="Domain Form" listViewRoute="/domains" hasGoBackButton={true} />
      )}
    </>
  )
}

export default DomainForm
