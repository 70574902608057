import { useEffect, useState } from 'react'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'

import { DispatchContextType } from '../../../../providers/websocket-stream'

import { useStyles } from './live-stream-btn.styles'

interface IProps {
  isSubscribed: boolean
  loadingData: boolean
  nodeId: number
  dispatch: DispatchContextType['dispatch']
}

export const LiveStreamBtn = (props: IProps) => {
  const classes = useStyles()
  const { dispatch, isSubscribed, nodeId, loadingData } = props

  const [success, setSuccess] = useState(isSubscribed)
  const [copy, setCopy] = useState<string>('')

  useEffect(() => {
    let isCurrent = true
    if (isCurrent) {
      setSuccess(isSubscribed)
      setCopy(isSubscribed ? 'Streaming Live Data' : 'Click to start streaming live data')
    }
    return () => {
      isCurrent = false
    }
  }, [isSubscribed])

  const handleButtonClick = () => {
    if (!success) {
      setSuccess(false)
      dispatch({ type: 'subscribe:data', payload: { nodeId } })
    }
  }

  return (
    <Fade in={true} timeout={500}>
      <div className={classes.root}>
        {loadingData === false && (
          <>
            <div className={classes.wrapper}>
              <Fab size="small" aria-label="save" color="secondary" onClick={handleButtonClick}>
                <DataUsageIcon className={classes.icon} />
              </Fab>
              {success && <CircularProgress color="secondary" size={53} className={classes.fabProgress} />}
            </div>

            <div className={classes.wrapper}>
              {success ? (
                <Typography color="secondary">{copy}</Typography>
              ) : (
                <Button variant="outlined" color="secondary" onClick={handleButtonClick}>
                  {copy}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Fade>
  )
}
