import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import MaterialLink from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import * as Yup from 'yup'

import { AddressForm } from '../../components/address'
import SingleFilterSelect from '../../components/form-elements/filter-select/single-select'
import FormikTextField from '../../components/form-elements/text-field'
import ThemeForm, { ThemeType } from '../../components/form-elements/theme'
import MetadataForm, { IMetadataItems, validateMetadataItems } from '../../components/metadata'
import ConfirmDeletionModal from '../../components/modal'
import TagCreator from '../../components/tag-creator'
import { AlertContext, OwnUserContext } from '../../providers'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { folderTreeToList, isValidJson } from '../../utils/helper-functions'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import useFolderFormStyles from './folder-form.styles'

type FolderFormValues = {
  folderName: string
  parentFolder: number | string
  address: string
  city: string
  state: string
  country: string
  postalCode: string
  lat: string | number
  lng: string | number
  tags: string[]
  themeItems: Array<{ key: string; value: string }>
  folderType: IFolderType['type']
  businessRuleset: IFolderType['businessRuleset']
  timezone: string
} & IMetadataItems

/** we need to transform array of theme and metadata values
 * to the format that backend will accept
 */
export const getMetadataOrThemeValues = (values: any) => {
  return values.length > 0
    ? values.reduce((acc: any, curr: any) => {
        acc[curr.key] = curr.value
        return acc
      }, {})
    : {}
}

/**
 * if a string can be parsed to a valid json - parse it
 * otherwise, just keep it as a string
 */
export const getParsedValue = (inputValue: string) => {
  if (isValidJson(inputValue)) {
    return JSON.parse(inputValue)
  } else {
    return inputValue
  }
}

/**
 * Packing values for backend:
 * All html input data has the type of string, e.g. typing [1,2,3] in an input will save the string "[1,2,3]".
 * Metadata and Theme need to support types other than strings.
 * We support the distinction by taking all raw strings, parsing them as JSON and send the server the parsed value.
 * If the string cannot be parsed it is sent to the server as the raw string representation.
 */
export const pack = (values: IMetadataItems[] | ThemeType[]) => {
  const items = getMetadataOrThemeValues(values)

  if (!isEmpty(items)) {
    for (const objKey in items) {
      if (items.hasOwnProperty(objKey)) {
        const parsedValue = getParsedValue(items[objKey])
        items[objKey] = parsedValue
      }
    }
  }

  return items
}

/**
 * properly parse value we get from server to display in input fields for Theme or Metadata values
 */
export const parseInitialValue = (initialVal: any) => {
  // stringfiy arrays of objects to be properly displayed in input fields
  if (typeof initialVal !== 'string') {
    return JSON.stringify(initialVal)
  } else {
    // handle edge case - if user intentially created a valid JSON as a string,
    // wrap it in additional set of quotes
    // this will allow "[1, 2]" to be displayed as a string array in input
    // and not be converted to array
    if (isValidJson(initialVal)) {
      return JSON.stringify(initialVal)
    }
    // otherwise simply return a string we got from server
    else {
      return initialVal
    }
  }
}

/** parse value we got from server to fill in form with them */
export const getInitialThemeValues = (themeValues: any) => {
  return themeValues
    ? Object.entries(themeValues).map(item => ({
        key: item[0],
        value: parseInitialValue(item[1]),
      }))
    : []
}

interface IParentFolderSelect {
  currentFolder: IFolderType | null
  folderTree: IFolderTreeType | null
  loading: boolean
}

const ParentFoldersFilterSelect = (props: IParentFolderSelect) => {
  const { folderTree, loading, currentFolder } = props
  const history = useHistory()

  const allFolders = useMemo(() => folderTreeToList(folderTree), [folderTree])

  return (
    <SingleFilterSelect<IFolderTypeSimple, FolderFormValues>
      name="parentFolder"
      label="Please select a parent folder"
      required={true}
      items={allFolders}
      loading={loading}
      displayField="name"
      shortcutCallback={(id: number) => history.push(`/folders/${id}`)}
      initialValue={currentFolder?.parentFolderId}
    />
  )
}

interface IChildrenFolderMenu {
  folderTree: IFolderTreeType | null
  currentFolder: IFolderType | null
}

export const ChildrenFoldersMenu = (props: IChildrenFolderMenu) => {
  const { folderTree, currentFolder } = props
  const history = useHistory()
  const classes = useFolderFormStyles()
  const [childrenAnchorEl, setChildrenAnchorEl] = useState<HTMLElement | null>(null)

  const childrenFolders = useMemo(() => {
    if (!currentFolder || !folderTree?.tree) return []

    const folder = folderTree.tree[String(currentFolder.id)]

    if (!folder?.children) return []

    return folder.children
      .map(x => ({
        id: x,
        ...folderTree.tree[String(x)],
      }))
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a > b ? 1 : 0))
  }, [folderTree, currentFolder])

  if (childrenFolders && childrenFolders.length === 0) {
    return null
  }

  return (
    <>
      <span>/</span>
      <MaterialLink className={classNames(classes.breadcrumbLink, classes.childrenLink)} onClick={event => setChildrenAnchorEl(event.currentTarget)}>
        ... {childrenFolders.length} children
      </MaterialLink>
      <Menu
        open={Boolean(childrenAnchorEl)}
        keepMounted={true}
        onClose={() => setChildrenAnchorEl(null)}
        anchorEl={childrenAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {childrenFolders.map(folder => (
          <MenuItem key={folder.id} onClick={() => history.push(`/folders/${folder.id}`)}>
            {folder.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

interface IParentFoldersList {
  folderTree: IFolderTreeType | null
  currentFolder: IFolderType | null
}

const ParentFoldersList = (props: IParentFoldersList) => {
  const { folderTree, currentFolder } = props
  const classes = useFolderFormStyles()

  const parentFolders = useMemo(() => {
    if (!currentFolder || !folderTree?.tree) return []

    let folderId = currentFolder.parentFolderId

    const parents = []
    // starting with the first parent folder, walk up the tree to each
    // next parent folder until there is no parent left
    while (folderTree.tree[String(folderId)]) {
      const folder = folderTree.tree[String(folderId)]

      parents.unshift({
        id: folderId,
        ...folder,
      })

      if (!folder.parentId) break
      folderId = folder.parentId
    }

    return parents
  }, [folderTree, currentFolder])

  if (parentFolders && parentFolders.length === 0) {
    return null
  }

  return (
    <>
      {parentFolders.map(folder => (
        <Fragment key={folder.id}>
          <Link to={`/folders/${folder.id}`} className={classNames(classes.breadcrumbLink, classes.applicationLink)}>
            {folder.name}
          </Link>
          <span>/</span>
        </Fragment>
      ))}
    </>
  )
}

export const FolderForm = () => {
  const classes = useFolderFormStyles()
  const params = useParams<{ folderId: string }>()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  const folderId = params.folderId
  const isEditMode = folderId !== 'new'

  const folderTypeOptions = ['Participant', 'Program', 'Client Company']
  const businessRulesetOptions = ['HSB', 'MUNICHRE']
  const timeZones = moment.tz.names()

  const [folderTree, setFolderTree] = useState<IFolderTreeType | null>(null)
  const [currentFolder, setCurrentFolder] = useState<IFolderType | null>(null)
  const [submitCounter, setSubmitCounter] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  const currentUser = useContext<IUserType | null>(OwnUserContext)

  useDocumentTitle('Folders', isEditMode ? currentFolder?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Api.get(`/api/folders/${folderId}`).then(retrievedFolder => {
        redirectToListViewOnError(retrievedFolder, history, addAlert, '/folders')

        if (isSubscribed) {
          setCurrentFolder(retrievedFolder)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [folderId, isEditMode, history, addAlert])

  useEffect(() => {
    let isSubscribed = true
    setLoading(true)

    Api.get('/api/folders/tree')
      .then(folderData => {
        setLoading(false)
        if (isSubscribed && !folderData.error) {
          setFolderTree(folderData)
        }
      })
      .catch(() => {
        setLoading(false)
      })

    return () => {
      isSubscribed = false
    }
  }, [])

  const saveFolder = async (values: FolderFormValues, actions: FormikHelpers<FolderFormValues>) => {
    const folderPayload: Partial<IFolderType> = {
      information: {
        address: {
          address: values.address,
          locality: values.city,
          region: values.state,
          country: values.country,
          code: values.postalCode,
        },
      },
      location: values.lat === '' || values.lng === '' ? null : { lat: values.lat, lng: values.lng },
      metadata: pack(values.metadataItems),
      name: values.folderName,
      parentFolderId: Number(values.parentFolder),
      tags: values.tags,
      theme: pack(values.themeItems),
      type: values.folderType,
      businessRuleset: values.businessRuleset,
      timezone: values.timezone,
    }

    try {
      setSubmitCounter(submitCounter + 1)
      if (isEditMode) {
        let updatedFolder
        handleFormSubmit((updatedFolder = await Api.put(`/api/folders/${folderId}`, folderPayload)), addAlert, history, null, values, actions)
        if (!updatedFolder.error) {
          setCurrentFolder(updatedFolder)
        }
      } else {
        let createdFolder
        handleFormSubmit((createdFolder = await Api.post('/api/folders', folderPayload)), addAlert, history, `/folders/${createdFolder.id}`, values, actions)
        if (!createdFolder.error) {
          setCurrentFolder(createdFolder)
        }
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit folder' })
    }
  }

  const schema = Yup.object().shape({
    folderName: Yup.string().required('Folder name is required'),
    parentFolder: Yup.string().required('Parent folder is required'),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    postalCode: Yup.string(),
    lng: Yup.number()
      .max(180, 'Must be less than 180')
      .min(-180, 'Must be greater than -180'),
    lat: Yup.number()
      .max(90, 'Must be less than 90')
      .min(-90, 'Must be greater than -90'),
    metadataItems: Yup.array(Yup.object()),
    tags: Yup.array(Yup.string()),
  })

  const deleteFolder = async () => {
    try {
      handleDelete(await Api.del('/api/folders', folderId), addAlert, history, '/folders')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete the folder' })
    }
  }

  const initialValues = isEditMode
    ? {
        folderName: currentFolder?.name ?? '',
        parentFolder: currentFolder?.parentFolderId ?? '',
        folderType: currentFolder?.type ?? '',
        businessRuleset: currentFolder?.businessRuleset ?? '',
        address: currentFolder?.information?.address?.address ?? '',
        city: currentFolder?.information?.address?.locality ?? '',
        state: currentFolder?.information?.address?.region ?? '',
        country: currentFolder?.information?.address?.country ?? '',
        postalCode: currentFolder?.information?.address?.code ?? '',
        lat: currentFolder?.location?.lat ?? '',
        lng: currentFolder?.location?.lng ?? '',
        timezone: currentFolder?.timezone ?? '',
        metadataItems: currentFolder?.metadata
          ? Object.entries(currentFolder.metadata).map(item => ({
              key: item[0],
              value: parseInitialValue(item[1]),
            }))
          : [],
        // we should get rid of all of those parsing and stringification for Theme and MEtadata after admin1 is disabled
        // should be able to safely delete the following functions: getInitialThemeValues, parseInitialValue and pack
        // they werr added to prevent inconsistency between admin1 and admin2
        themeItems: getInitialThemeValues(currentFolder?.theme),
        tags: currentFolder?.tags ?? [],
      }
    : {
        folderName: '',
        parentFolder: '',
        folderType: '' as IFolderType['type'],
        businessRuleset: '' as IFolderType['businessRuleset'],
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        lat: '',
        lng: '',
        metadataItems: [],
        themeItems: [],
        tags: [],
        timezone: currentUser?.information?.timezone ?? 'US/Eastern',
      }

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveFolder} validationSchema={schema} validate={validateMetadataItems}>
        {({ isValid, values, isSubmitting, dirty }: FormikProps<FolderFormValues>) => (
          <Form>
            <CardContent>
              <Grid spacing={3} container={true}>
                {isEditMode && currentFolder && (
                  <Grid item={true} xs={12}>
                    <div className={classes.folderPathWrapper}>
                      {/* list of parent folders */}
                      <ParentFoldersList folderTree={folderTree} currentFolder={currentFolder} />
                      {/* current folder */}
                      <span className={classNames(classes.breadcrumbLink, classes.currentFolderLink)}>{currentFolder.name}</span>
                      {/* dropdown of children folders */}
                      <ChildrenFoldersMenu folderTree={folderTree} currentFolder={currentFolder} />
                    </div>
                  </Grid>
                )}

                <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormikTextField id="folderInput" data-cy="nameTextField" label="Folder" name="folderName" required={true} formDependent={false} />
                </Grid>

                <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormikTextField initialized={isEditMode} data-cy="folderTypeInput" name="folderType" label="Folder Type" select={true}>
                    {folderTypeOptions.map(option => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      )
                    })}
                  </FormikTextField>
                </Grid>

                <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormikTextField initialized={isEditMode} name="businessRuleset" label="Business Ruleset" select={true}>
                    {businessRulesetOptions.map(option => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      )
                    })}
                  </FormikTextField>
                </Grid>

                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={4} data-cy="parentFolderInput">
                  <ParentFoldersFilterSelect folderTree={folderTree} loading={loading} currentFolder={currentFolder} />
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography className={classes.denseFormSubheader} variant="subtitle1">
                    Location
                  </Typography>
                </Grid>

                <Grid item={true} container={true} spacing={3}>
                  <AddressForm isEditMode={isEditMode} />

                  <Grid item={true} xs={12} sm={6} md={2} lg={2} xl={2}>
                    <FormikTextField label="Latitude" type="number" data-cy="lat" name="lat" step="0.000001" helperText="Between -90 and 90" />
                  </Grid>

                  <Grid item={true} xs={12} sm={6} md={2} lg={2} xl={2}>
                    <FormikTextField label="Longtitude" type="number" data-cy="long" name="lng" step="0.000001" helperText="Between -180 and 180" />
                  </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography className={classes.denseFormSubheader} variant="subtitle1">
                    Timezone
                  </Typography>
                </Grid>

                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField select={true} name="timezone" label="Timezone" data-cy="timeZone">
                    {timeZones &&
                      timeZones.map((timeZone: string, index: number) => (
                        <option key={index} value={timeZone}>
                          {timeZone}
                        </option>
                      ))}
                  </FormikTextField>
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography className={classes.denseFormSubheader} variant="subtitle1">
                    Theme
                  </Typography>

                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={8}>
                    <ThemeForm themeItems={values.themeItems} />
                  </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography className={classes.denseFormSubheader} variant="subtitle1">
                    Metadata
                  </Typography>

                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={8}>
                    <MetadataForm metadataItems={values.metadataItems} />
                  </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography className={classes.denseFormSubheader} variant="subtitle1">
                    Tags
                  </Typography>
                  <TagCreator tags={values.tags} />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-cy="saveBtn" data-testid="saveBtn">
                Save
              </Button>

              <Button type="button" onClick={() => history.push('/folders')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                Cancel
              </Button>

              {isEditMode ? (
                <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                  Delete
                </Button>
              ) : null}
            </CardActions>
          </Form>
        )}
      </Formik>

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteFolder}
        closeModal={toggleConfirmModal}
        dialogContent="This action is not reversible."
        dialogTitle="Are you sure you want to delete this folder?"
      />
    </>
  )
}

export default FolderForm
