import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export type WidthType = { width: 'xl' | 'lg' | 'md' | 'sm' | 'xs' }

export const styles = () =>
  createStyles({
    tableLoader: {
      textAlign: 'center',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1rem',
    },
    errorMessage: {
      padding: '0.4rem',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    // looks like jss does not support @page right now, it throws error if I uncomment this code
    // will disable table print option for now, but once this is fixed, we can re-enable it and uncomment this
    // https://github.com/cssinjs/jss/issues/620
    // "@page": {
    //   size: 'A4 landscape',
    //   margin: '0.1cm'
    // }
    // styles taken from mui-datatables to match their search box
    searchBoxContainer: {
      display: 'flex',
      flex: '1 0 auto',
      alignItems: 'center',
    },
    searchBoxSearchIcon: {
      margin: '0 8px 0 0',
    },
    searchBoxTextField: {
      flex: '0.8 0 0%',
    },
  })

export const defaultPaginationStyles = () => {
  return createStyles({
    root: {
      '&:last-child': {
        padding: '0px 25px 0px 0px',
      },
    },
    pageNum: {
      marginRight: '2px',
      marginLeft: '2px',
    },
  })
}

export const paginationActionStyles = () => {
  return createStyles({
    root: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
    },
    pageNum: {
      marginRight: '2px',
      marginLeft: '2px',
    },
  })
}

export const useDefaultPaginationStyles = makeStyles(defaultPaginationStyles)
export const usePaginationActionStyles = makeStyles(paginationActionStyles)
const useStyles = makeStyles(styles)
export default useStyles
