import { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import { ClientSidePaginatedTable } from '../../components/table'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'

import { AlertContext, ImpersonateContext } from './../../providers'

const EmailTemplateList = () => {
  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplateType[]>([])

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const impersonateContext = useContext<IImpersonateContext | null>(ImpersonateContext)
  const classes = useTableStyles()
  const searchResultsHook = useSearchData()

  useDocumentTitle('Email Templates')

  const columns = [
    { name: 'Name', options: { filter: false, sort: true } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchEmailTemplatesData = async () => {
    const templates = await Api.get('/api/emailtemplates')

    if (templates.error) {
      addAlert({ alertType: 'error', message: templates?.message || 'Could not load email templates' })
      return Promise.reject(templates)
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = templates.map((emailTemplate: IEmailTemplateType) => {
      return [emailTemplate.key, convertTimestampToDate(emailTemplate.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setEmailTemplates(templates) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchEmailTemplatesData, [impersonateContext])
  const state = useTableState(memoizedFetch)

  const customCellClick = (colData: any, cellMeta: MuiTableCellMetaType): void => {
    if (emailTemplates) {
      history.push(`/email-templates/edit/${emailTemplates[cellMeta.dataIndex].key}`)
    }
  }

  return (
    <Grid justifyContent="center" container={true}>
      <Grid className={classes.tableContainer} data-cy="emailTemplatesList" item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Email Templates"
          idList={emailTemplates != null ? emailTemplates.map(template => ({ id: template.key })) : []}
          refresh={() => {}} // this table does allow deletion
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          preserveSearchResults={{ ...searchResultsHook }}
          options={{
            filterType: 'checkbox',
            pagination: false,
            onCellClick: customCellClick,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EmailTemplateList
