import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedFormStyles } from '../../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    info: {
      marginTop: theme.spacing(2),
    },
    errorWrapper: {
      marginTop: theme.spacing(4),
    },
    loadingSpinner: {
      marginTop: theme.spacing(5),
    },
    primaryContactDescription: {
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    primaryContactEmail: {
      wordBreak: 'break-all',
    },
    ...sharedFormStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
