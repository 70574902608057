import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableRow: {
    selected: {
      backgroundColor: 'f28732',
    },
  },
}))
