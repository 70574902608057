import { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import PersonIcon from '@mui/icons-material/Person'
import UnimpersonateIcon from '@mui/icons-material/TransitEnterexit'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import useMediaQuery from '@mui/material/useMediaQuery'

import { SMALL_SCREEN_TRIGGER_WIDTH } from '../../../constants'
import { AlertContext, ImpersonateContext, OwnUserContext } from '../../../providers'
import Api from '../../../utils/api'

import { useStyles } from './profile-menu.styles'

type IProps = Pick<MenuProps, 'anchorEl' | 'onClose'> & { handleLogout: (impersonateContext: IImpersonateContext) => Promise<void> }

const ProfileMenu = ({ anchorEl, onClose, handleLogout }: IProps) => {
  const classes = useStyles()

  const { addAlert } = useContext(AlertContext)
  const ownUserContext = useContext(OwnUserContext)
  const impersonateContext = useContext(ImpersonateContext)

  const smallScreenMatches = useMediaQuery(`(max-width:${SMALL_SCREEN_TRIGGER_WIDTH}px)`)

  const handleUnimpersonate = async () => {
    try {
      await Api.get('/api/users/unimpersonate')
      addAlert({ alertType: 'success', message: 'Successfully unimpersonated' })
      impersonateContext?.setImpersonatedUserId(null)
      if (onClose) {
        onClose({}, 'backdropClick')
      }
    } catch (err) {
      addAlert({ alertType: 'error', message: String(err) || 'We could not impersonate this user. Please try again' })
    }
  }

  return (
    <Menu
      id="profileMenu"
      data-cy="profileMenu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <RouterLink className={classes.menuItem} to={ownUserContext && ownUserContext.id ? `/users/${ownUserContext.id}` : '#'}>
        <MenuItem dense={smallScreenMatches} onClick={onClose as MenuItemProps['onClick']}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText inset={false} primary="Profile" />
        </MenuItem>
      </RouterLink>

      <a target="_blank" rel="noopener noreferrer" href={`${window.location.origin}/dashboard3#alarms`} className={classes.dashboardLink}>
        <MenuItem dense={smallScreenMatches} onClick={onClose as MenuItemProps['onClick']}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText inset={false} primary="Dashboard" />
        </MenuItem>
      </a>

      {ownUserContext?.impersonatedBy && (
        <MenuItem dense={smallScreenMatches} onClick={() => handleUnimpersonate()}>
          <ListItemIcon className={classes.unimpersonateMenuItem}>
            <UnimpersonateIcon />
          </ListItemIcon>
          <ListItemText inset={false} primary="Unimpersonate" classes={{ primary: classes.unimpersonateMenuItem }} data-testid="unimpersonate" />
        </MenuItem>
      )}

      <MenuItem
        className={classes.menuItem}
        dense={smallScreenMatches}
        onClick={() => {
          if (impersonateContext) {
            handleLogout(impersonateContext)
          }
        }}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText inset={false} primary="Logout" data-testid="logoutBtn" />
      </MenuItem>
    </Menu>
  )
}

export default ProfileMenu
