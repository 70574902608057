import { useCallback, useContext, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Formik, FormikProps } from 'formik'
import moment, { Moment } from 'moment'
import * as Yup from 'yup'

import TimeRangeSelectorForm, { ITimeRangeFormValues } from '../../../../components/time-range-selector-form'
import { AlertContext } from '../../../../providers'
import useSharedFormStyles from '../../../../shared-styles/form.styles'
import Api from '../../../../utils/api'
import { useTableState } from '../../../../utils/hooks'

import { ChannelHistoryTable } from './table'

type HistoryProps = {
  selectedChannel: string
  nodeId: number
}

export interface IChannelHistoryData {
  createdAt: string
  timestamp: string
  value: any
}

const ChannelHistory = ({ selectedChannel, nodeId }: HistoryProps) => {
  const defaultDaysOfHistory = 1
  const classes = useSharedFormStyles()
  const { addAlert } = useContext(AlertContext)
  const [endTime, setEndTime] = useState<Moment>(moment())
  const [startTime, setStartTime] = useState<Moment>(moment(endTime).subtract(defaultDaysOfHistory, 'days'))

  const memoizedFetch = useCallback(() => {
    if (selectedChannel == null || nodeId == null || startTime == null || endTime === null) {
      return Promise.resolve({ data: [] })
    }
    const historyRequestBody = {
      nodeId,
      channelName: selectedChannel,
      startTime,
      endTime,
    }

    return Api.post('/api/data/history', historyRequestBody).then(res => {
      if (!res.error) {
        return Promise.resolve({ data: res })
      }
      addAlert({
        alertType: 'error',
        message: typeof res.error === 'string' ? res.error : 'Something went wrong',
      })
      return Promise.resolve({ data: [] })
    })
  }, [nodeId, startTime, endTime, selectedChannel, addAlert])

  const channelHistoryState = useTableState(memoizedFetch)

  // by default we show history for past 7 days
  const initialValues = {
    endTime: endTime.format('YYYY-MM-DD'),
    startTime: startTime.format('YYYY-MM-DD'),
  }

  const schema = Yup.object().shape({
    startTime: Yup.date().required('Please select Start Date'),
    endTime: Yup.date()
      .required('Please select End Date')
      .min(Yup.ref('startTime'), 'Must be bigger than Start Time'),
  })

  const updateTimeRange = (formValues: ITimeRangeFormValues) => {
    setStartTime(moment(formValues.startTime))
    setEndTime(moment(formValues.endTime))
  }

  return (
    <>
      <Typography data-testid="channel-history-title" className={classes.formSubheader} variant="subtitle1">
        {`Channel history ${selectedChannel ? ' - ' + selectedChannel : ''}`}
      </Typography>

      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={updateTimeRange}>
        {({ isValid, values }: FormikProps<ITimeRangeFormValues>) => (
          <>
            <TimeRangeSelectorForm isFormValid={isValid} />

            <ChannelHistoryTable
              nodeId={nodeId}
              downloadFileName={
                selectedChannel && selectedChannel.length > 0
                  ? `${selectedChannel}-${nodeId}-${values.startTime} to ${values.endTime}.csv`
                  : 'tableDownload.csv'
              }
              loading={channelHistoryState.loading}
              error={channelHistoryState.error}
              data={(channelHistoryState.data as unknown) as IChannelHistoryData[]}
              channelDataIndex={1}
              selectedChannel={selectedChannel}
              startTime={startTime}
              endTime={endTime}
              isNumericData={false}
              data-testid="historyTable"
            />
          </>
        )}
      </Formik>
    </>
  )
}

export default ChannelHistory
