import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    documentationLink: {
      color: theme.palette.primary.main,
    },
    icon: {
      marginBottom: '-2px',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
