import { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Button, Grid, IconButton } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import FormikTextField from '../../components/form-elements/text-field'
import { AlertContext } from '../../providers'
import Api from '../../utils/api'
import { useDocumentTitle } from '../../utils/hooks'
import useLoginStyles from '../login/login.styles'
import LoginContent from '../login/login-content'

interface IResetPasswordValues {
  newPassword: string
  confirmPassword: string
}

const ResetPassword = () => {
  const loginClasses = useLoginStyles()

  const params = useParams<{ token: string }>()
  const token = params.token
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)

  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  useDocumentTitle('Reset Password')

  const handlePasswordReset = async (values: IResetPasswordValues) => {
    const res = await Api.put('/api/passwords/changepasswordwithtoken', {
      newPassword: values.newPassword,
      token,
    })

    if (res.error) {
      addAlert({ alertType: 'error', message: res?.message || 'Something went wrong and we could not change your password' })
    } else {
      addAlert({ alertType: 'success', message: 'Successfully Changed Password' })
      history.push('/login')
    }
  }

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters')
      .max(100, 'Password must be less than 100 characters.'),
    confirmPassword: Yup.string()
      .required('Confirm your password')
      .oneOf([Yup.ref('newPassword')], 'Password does not match'),
  })

  const endAdornment = () => (
    <IconButton aria-label="toggle npassword visibility" onClick={() => setShowNewPassword(!showNewPassword)} data-testid="show-new-password" size="large">
      {showNewPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  const confirmPasswordEndAdornment = () => (
    <IconButton
      aria-label="toggle confirm password visibility"
      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
      data-testid="show-confirm-password"
      size="large"
    >
      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} sm={12} md={5} lg={5} xl={4}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid item={true} xs={8}>
            <div className={loginClasses.meshifyTextWrapper}>
              <span className={loginClasses.meshifySpan}>Meshify</span>
              <span className={loginClasses.adminSpan}>Admin</span>
            </div>
          </Grid>

          <Grid item={true} xs={8}>
            <p className={loginClasses.welcomeText}>Reset your password</p>
          </Grid>

          <Grid item={true} xs={8}>
            <Formik initialValues={{ newPassword: '', confirmPassword: '' }} onSubmit={handlePasswordReset} validationSchema={schema}>
              {(formikBag: FormikProps<IResetPasswordValues>) => {
                const { isValid, dirty } = formikBag

                return (
                  <Form className={loginClasses.smallTopMargin}>
                    <Grid container={true}>
                      <Grid item={true} xs={12} className={loginClasses.smallTopMargin}>
                        <FormikTextField
                          data-cy="newPass"
                          name="newPassword"
                          label="New Password"
                          required={true}
                          type={showNewPassword ? 'text' : 'password'}
                          formDependent={true}
                          endAdornment={endAdornment}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <FormikTextField
                          data-cy="confirmNewPass"
                          name="confirmPassword"
                          label="Confirm Password"
                          required={true}
                          formDependent={true}
                          type={showConfirmPassword ? 'text' : 'password'}
                          endAdornment={confirmPasswordEndAdornment}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item={true} xs={12} className={loginClasses.smallTopMargin}>
                        <Button
                          variant="contained"
                          color="primary"
                          data-testid="resetPasswordBtn"
                          data-cy="resetPwdBtn"
                          type="submit"
                          disabled={!isValid || !dirty}
                          fullWidth={true}
                        >
                          Reset Password
                        </Button>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Grid container={true} justifyContent="flex-end" className={loginClasses.smallTopMargin}>
                          <Button onClick={() => history.push('/')} color="primary">
                            Go back to Login
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
        </Grid>
      </Grid>

      <LoginContent />
    </Grid>
  )
}

export default ResetPassword
