import { forwardRef, useCallback, useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { uniqBy } from 'lodash'
import { MUIDataTableColumn } from 'mui-datatables'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import { AlertContext, UserPermissionsContext } from '../../providers'
import useTableStyles from '../../shared-styles/table.styles'
import api from '../../utils/api'
import { useDocumentTitle, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

const NewNodeLink = forwardRef((componentProps: any, ref: any) => <Link to="/alias-channels/new" ref={ref} {...componentProps} />)

const List = () => {
  // TODO: implement useSearchData() hook and abort
  const { addAlert } = useContext(AlertContext)
  const classes = useTableStyles()
  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)

  const history = useHistory()
  const [aliasChannels, setAliasChannels] = useState<IAliasChannelType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const nodeMap = useRef(new Map<number, string>())

  useDocumentTitle('Alias Channels')

  const columns: MUIDataTableColumn[] = [
    { name: 'Alias Node Name', options: { filter: false, sort: false } },
    { name: 'Alias Channel', options: { filter: false, sort: true } },
    { name: 'Source Node Name', options: { filter: false, sort: false } },
    { name: 'Source Node Channel', options: { filter: false, sort: true } },
    { name: 'Start Time', options: { filter: false, sort: true } },
    { name: 'End Time', options: { filter: false, sort: true } },
    { name: 'Created At', options: { filter: false, sort: true } },
    { name: 'Updated At', options: { filter: false, sort: true } },
  ]

  const getChannels = async () => {
    let channels: IAliasChannelType[]
    try {
      const resp = await api.get('/api/aliaschannels')
      if (resp.error) {
        addAlert({ alertType: 'error', message: resp.message })
        return Promise.reject(resp)
      }
      channels = resp as IAliasChannelType[]
      setAliasChannels(resp as IAliasChannelType[])
    } catch (e) {
      addAlert({ alertType: 'error', message: 'We could not load list of alias channels. Please try again later' })
      throw new Error(String(e))
    }

    const aliasNodeIDs = uniqBy(channels as IAliasChannelType[], 'aliasNodeId').map(n => n.aliasNodeId)
    const sourceNodeIDs = uniqBy(channels as IAliasChannelType[], 'sourceNodeId').map(n => n.sourceNodeId)

    const nodesBody = {
      filters: [
        { fieldName: 'id', operator: 'in', value: aliasNodeIDs },
        { fieldName: 'id', operator: 'in', value: sourceNodeIDs },
      ],
      type: 'or',
      first: 10000,
    }
    try {
      const resp = await api.post('/api/nodes/query', nodesBody)
      if (resp.error) {
        addAlert({ alertType: 'error', message: resp?.message || 'Could not retreive nodes' })
        return Promise.reject(resp)
      }
      const nodeData = resp.results as INodeType[]
      nodeData.forEach(a => {
        nodeMap.current.set(a.id, a.vanity)
      })
    } catch (e) {
      addAlert({ alertType: 'error', message: String(e) ?? 'Something went wrong' })
      throw new Error(String(e))
    }

    const data = (channels as IAliasChannelType[]).map((ac, idx) => {
      return [
        <Link key={idx} className={classes.applicationLink} to={`/nodes/${ac.aliasNodeId}`}>
          {nodeMap.current.get(ac.aliasNodeId)}
        </Link>,
        ac.aliasChannelName,
        <Link key={idx} className={classes.applicationLink} to={`/nodes/${ac.sourceNodeId}`}>
          {nodeMap.current.get(ac.sourceNodeId)}
        </Link>,
        ac.sourceChannelName,
        ac?.startTime ? new Date(ac.startTime).toLocaleString() : 'No date on file',
        ac?.endTime ? new Date(ac.endTime).toLocaleString() : 'No date on file',
        new Date(ac.createdAt).toLocaleString(),
        new Date(ac.updatedAt).toLocaleString(),
      ]
    })
    return Promise.resolve({
      data,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGetChannels = useCallback(getChannels, [refreshCounter])
  const tableState = useTableState(memoizedGetChannels)

  return (
    <Grid className={classes.tableContainer} data-cy="aliasChannelsList" container={true} justifyContent="flex-end">
      <Grid container={true} justifyContent="flex-end">
        <AddButton
          userPermissions={userPermissions}
          newComponentLink={NewNodeLink}
          entityName="Alias Channels"
          buttonName="Add Alias Channel"
          dataCy="addBtn"
          dataTestId="addBtn"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Alias Channels"
          entityDeleteEndpoint="/api/aliaschannels"
          idList={aliasChannels != null ? aliasChannels.map(({ id }) => ({ id })) : []}
          refresh={() => {
            setRefreshCounter(c => ++c)
          }}
          options={{
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) =>
              handleCellClick(history, (aliasChannels as unknown) as IBackendEntityType[], 'alias-channels', cellMeta),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="alias-channels"
                  isValidId={(id: number) => !!aliasChannels?.find(aliasChannel => aliasChannel.id === id)}
                />
              )
            },
          }}
          columns={columns}
          loading={tableState.loading}
          error={tableState.error}
          data={tableState.data}
        />
      </Grid>
    </Grid>
  )
}

export default List
