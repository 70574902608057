import { FormikHelpers } from 'formik'
import { History } from 'history'

export const handleFormSubmit = (data: any, addAlert: any, history: any, route: string | null, formValues: any, formikHelpers: FormikHelpers<any>) => {
  if (!data.error) {
    if (route) {
      history.push(route)
    }
    addAlert({ message: 'Successfully Submitted', alertType: 'success' })
  } else {
    addAlert({ message: `Submission Errored: ${String(data.message)}`, alertType: 'error' })
    // on error we want to preserve form values instead of clearing the form
    formikHelpers.resetForm({ values: formValues })
  }

  formikHelpers.setSubmitting(false)
}

export const handleDelete = (data: any, addAlert: IAlertContext['addAlert'], history: History, route: string) => {
  if (data.error) {
    addAlert({ alertType: 'error', message: data.message ? data.message : 'Could not delete' })
  } else {
    addAlert({ alertType: 'success', message: 'Successfully Deleted' })
  }

  history.push(route)
}

export const redirectToListViewOnError = (retreivedEntity: any, history: any, addAlert: any, route: string) => {
  if (retreivedEntity.error) {
    history.push(route)
    addAlert({ alertType: 'error', message: 'Not found' })
  }
}

export const getSourceInitValue = (updatedTemplateValue: string, retrievedSource: string): string => {
  if (updatedTemplateValue !== '' && updatedTemplateValue !== retrievedSource) {
    return updatedTemplateValue
  } else {
    return retrievedSource
  }
}

export const setCodeMirrorSource = (codemirrorInstance: any, setFieldValue: (field: string, value: string) => void, formikFieldName?: string) => {
  const fieldName = formikFieldName ? formikFieldName : 'source'

  // collectSourceLines iterates over all codemirror source chunks and returns an array of chunks containing source lines
  // Use of any here is hard to avoid due to codemirror typing
  const collectSourceLines = (obj: any, currentSourceLines: Array<{ text: string }>) => {
    if (obj.lines != null) {
      return currentSourceLines.concat(obj.lines)
    }
    // Children contain either a branch chunk or a leaf chunk for each element
    // Infinity flattens to an arbitrary depth
    return obj?.children?.map((child: any) => collectSourceLines(child, currentSourceLines)).flat(Infinity)
  }
  const sourceLines = collectSourceLines(codemirrorInstance?.doc, [])

  try {
    const updatedSource = sourceLines.reduce((acc: string, cur: { text: string }, index: number) => {
      return index !== sourceLines.length - 1 ? acc + (cur.text + '\n') : acc + cur.text
    }, '')
    setFieldValue(fieldName, updatedSource)
  } catch (e) {
    console.error(e)
  }
}
