import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { get } from 'lodash'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import MultiFilterSelect from '../../components/form-elements/filter-select/multi-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import useSharedFormStyles from '../../shared-styles/form.styles'
import api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext, OwnUserContext } from './../../providers'

interface IRoleFormValues {
  name: string
  allowedRoles: number[]
  permissions: number[]
}

export const getRolePayload = (values: IRoleFormValues, roles: IRoleType[]) => {
  const isFull = values.allowedRoles.length === roles.length
  // Empty array reflects select all allowed and future roles
  const allowedRoles: number[] = isFull ? [] : values.allowedRoles

  const rolePayload = {
    name: values.name,
    allowedRoles,
    permissions: values.permissions,
  }

  return rolePayload
}

export const RoleForm = () => {
  const classes = useSharedFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ roleId: string }>()
  const roleId = params.roleId
  const isEditMode = roleId !== 'new'

  const [roles, setRoles] = useState<IRoleType[] | null>(null)
  const [permissions, setPermissions] = useState<IPermissionType[] | null>(null)
  const [retrievedRole, setRetrievedRole] = useState<IRoleType | null>(null)
  const [submitCounter, setSubmitCounter] = useState<number>(0)

  const currentUser = useContext<IUserType | null>(OwnUserContext)

  const [showConfirmDeletionModal, toggleConfirmDeletionModal] = useToggle(false)

  useDocumentTitle('Roles', isEditMode ? retrievedRole?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    const setData = async () => {
      const [roleData, permissionData] = await Promise.all([api.get('/api/roles'), api.get('/api/permissions')])
      if (isSubscribed) {
        setRoles(roleData)
        setPermissions(permissionData)
      }
    }
    setData()
    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      api.get(`/api/roles/${roleId}`).then((role: IRoleType) => {
        redirectToListViewOnError(role, history, addAlert, '/roles')

        if (isSubscribed) {
          setRetrievedRole(role)
        }
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, roleId, history, addAlert])

  const saveRole = async (values: IRoleFormValues, actions: FormikHelpers<IRoleFormValues>) => {
    if (roles != null) {
      const rolePayload = getRolePayload(values, roles)
      let submittedRole

      try {
        if (isEditMode) {
          handleFormSubmit((submittedRole = await api.put(`/api/roles/${roleId}`, rolePayload)), addAlert, history, null, values, actions)

          setSubmitCounter(submitCounter + 1)

          // this helps us prevent form reset on submit in edit mode
          const updatedRetrievedRole: IRoleType = {
            ...rolePayload,
            createdAt: submittedRole.createdAt,
            id: submittedRole.id,
            updatedAt: submittedRole.updatedAt,
          }
          setRetrievedRole(updatedRetrievedRole)
        } else {
          handleFormSubmit((submittedRole = await api.post('/api/roles', rolePayload)), addAlert, history, `/roles/${submittedRole.id}`, values, actions)
        }
      } catch (e) {
        const message = (e as Error).message
        addAlert({ alertType: 'error', message: String(message) || 'Could not submit role' })
      }
    }
  }

  const deleteRole = async () => {
    try {
      handleDelete(await api.del(`/api/roles`, roleId), addAlert, history, '/roles')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete role' })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    allowedRoles: Yup.array().required('Please select roles'),
    permissions: Yup.array().required('Please select permissions'),
  })

  const initialAllowedRoles =
    isEditMode && retrievedRole && retrievedRole.allowedRoles && retrievedRole.allowedRoles.length === 0
      ? roles && roles.map(role => role.id)
      : retrievedRole && retrievedRole.allowedRoles

  const formInitialValues =
    isEditMode && retrievedRole
      ? {
          name: get(retrievedRole, 'name'),
          allowedRoles:
            initialAllowedRoles != null && roles != null ? (initialAllowedRoles.length === 0 ? roles.map(role => role.id) : initialAllowedRoles) : [],
          permissions: isEditMode && retrievedRole && retrievedRole.permissions ? retrievedRole.permissions : [],
        }
      : {
          name: '',
          allowedRoles: [],
          permissions: [],
        }

  return (
    <>
      <Formik data-testid="role-form" initialValues={formInitialValues} enableReinitialize={true} onSubmit={saveRole} validationSchema={schema}>
        {(formikProps: FormikProps<IRoleFormValues>) => {
          const { isValid, isSubmitting, dirty } = formikProps

          return (
            <Form>
              <CardContent data-cy="rolesForm">
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="roleName">
                    <FormikTextField initialized={isEditMode} name="name" label="Name" required={true} />
                  </Grid>
                </Grid>

                <Typography className={classes.formSubheader} variant="subtitle1">
                  Allowed Roles
                </Typography>

                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="allowedRoles">
                    <MultiFilterSelect<IRoleType, IRoleFormValues>
                      canSelectAll={true}
                      items={roles != null ? roles : []}
                      name="allowedRoles"
                      entityName="Roles"
                      required={true}
                      displayField="name"
                      initialValues={retrievedRole?.allowedRoles}
                      loading={roles === null}
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Alert
                      alertType="warning"
                      message="This describes who can create and edit users of this role. Users with one of the selected roles in this box are allowed to create and edit users of this role."
                    />
                  </Grid>
                </Grid>

                <Typography className={classes.formSubheader} variant="subtitle1">
                  Permissions
                </Typography>

                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="permissions">
                    <MultiFilterSelect<IPermissionType, IRoleFormValues>
                      canSelectAll={true}
                      items={permissions != null ? permissions : []}
                      name="permissions"
                      entityName="Permissions"
                      required={true}
                      displayField="name"
                      initialValues={retrievedRole?.permissions}
                      loading={permissions === null}
                    />
                  </Grid>

                  <Grid container={true} item={true} xs={12} sm={12} md={6} lg={6} xl={6} spacing={3}>
                    <Grid item={true} xs={12}>
                      <Alert
                        alertType="warning"
                        message="All users with this role inherit the selected permissions. Permissions set a users read and write access across the platform."
                      />
                    </Grid>

                    {currentUser?.isRoot && currentUser?.roleId === Number(roleId) && (
                      <Grid item={true} xs={12}>
                        <Alert
                          alertType="warning"
                          message="You are currently logged in as a Root User. Root User status overrides all permissions of the user's assigned role. Any updates made to your role's permissions will not affect your account, but it will affect other non-root users with this role."
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button data-cy="saveBtn" data-testid="saveBtn" disabled={!isValid || isSubmitting || !dirty} color="primary" type="submit">
                  Save
                </Button>

                <Button type="button" onClick={() => history.push('/roles')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                  Cancel
                </Button>

                {isEditMode && (
                  <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmDeletionModal}>
                    Delete
                  </Button>
                )}
              </CardActions>
            </Form>
          )
        }}
      </Formik>

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteRole}
        closeModal={toggleConfirmDeletionModal}
        dialogTitle="Are you sure you want to delete this role?"
        dialogContent="The process is not reversible."
      />
    </>
  )
}

export default RoleForm
