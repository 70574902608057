import { useCallback, useContext, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Formik, FormikProps } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

import { ClientSidePaginatedTable } from '../../../components/table'
import TableLink from '../../../components/table-link'
import TimeRangeSelectorForm, { ITimeRangeFormValues } from '../../../components/time-range-selector-form'
import { AlertContext } from '../../../providers'
import useSharedFormStyles from '../../../shared-styles/form.styles'
import Api from '../../../utils/api'
import { isValidJson } from '../../../utils/helper-functions'
import { useTableState } from '../../../utils/hooks'

type IProps = {
  nodeId: number
}

// backend gives us inconsistent response format for errors for different statuses, so need to account for that
export const getErrorMessage = (response: any): string => {
  let errorMessage = 'Something went wrong. Please try selecting smaller date range, or try again later.'

  if (typeof response === 'string' && !isValidJson(response)) {
    errorMessage = response
  } else if (response.error && typeof response.error === 'string') {
    errorMessage = response.error
  } else if (response.detail && typeof response.detail === 'string') {
    errorMessage = response.detail
  } else if (response.message && typeof response.message === 'string') {
    errorMessage = response.message
  } else if (isValidJson(response)) {
    const parsedRes = JSON.parse(response)
    return (parsedRes && parsedRes.detail) || 'Something went wrong.'
  }

  return errorMessage
}

const Reactions = (props: IProps) => {
  const { nodeId } = props
  const classes = useSharedFormStyles()
  const { addAlert } = useContext(AlertContext)

  const [reactions, setReactions] = useState<IReactionHistoryType[]>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [timeRangeUpdatedCounter, setTimeRangeUpdatedCounter] = useState<number>(0)

  const defaultDaysOfReactionsHistory = 1
  const defaultEndTime = moment().format('YYYY-MM-DD')
  const defaultStartTime = moment(defaultEndTime)
    .subtract(defaultDaysOfReactionsHistory, 'days')
    .format('YYYY-MM-DD')

  const [startTime, setStartTime] = useState<string>(defaultStartTime)
  const [endTime, setEndTime] = useState<string>(defaultEndTime)

  const reactionsColumns = [
    { name: 'Processed', options: { filter: false, sort: false } },
    { name: 'Reaction ID', options: { filter: false, sort: true } },
    { name: 'Reaction Type', options: { filter: false, sort: false } },
    { name: 'Reaction Body', options: { filter: false, sort: false } },
    { name: 'Reference ID', options: { filter: false, sort: true } },
    { name: 'Response Code', options: { filter: false, sort: true } },
    { name: 'Status Message', options: { filter: false, sort: false } },
    { name: 'User ID', options: { filter: false, sort: false } },
    { name: 'Alert Time', options: { filter: false, sort: false } },
  ]

  const fetchReactionsTableData = async () => {
    setShowSpinner(true)

    const response = await Api.get(`/api/reactions/history?nodeId=${nodeId}&startTime=${startTime}&endTime=${endTime}`)

    if (!response.error) {
      setReactions(response)
      setShowSpinner(false)

      let allRowData: Array<Array<number | string | React.ReactNode>> = []

      allRowData = response.map((reaction: IReactionHistoryType) => {
        const reactionId = (
          <TableLink
            to={`/reactions/${reaction.value.ReactionID}`}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {reaction.value.ReactionID}
          </TableLink>
        )

        const userId = (
          <TableLink
            to={`/users/${reaction.value.UserID}`}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {reaction.value.UserID}
          </TableLink>
        )

        const reactionMessageBody = <div dangerouslySetInnerHTML={{ __html: reaction.value?.ReactionMessage?.Body ?? '' }} />

        return [
          // LLL will format time in user local language
          // i.e. March 3, 2020 11:31 AM for vs 3. März 2020 11:32
          moment(reaction.value.Processed).format('LLL'),
          reactionId,
          reaction.value?.ReactionMessage?.Type,
          reactionMessageBody,
          reaction.value.ReferenceID,
          reaction.value.ResponseCode,
          reaction.value.StatusMessage,
          userId,
          moment(reaction.value?.AlertTS).format('LLL'),
        ]
      })

      return Promise.resolve({
        data: allRowData,
      })
    } else {
      const errorMessage = getErrorMessage(response)

      addAlert({
        alertType: 'error',
        message: errorMessage,
      })
      setReactions([])
      setShowSpinner(false)

      return Promise.resolve({ data: [], errorMessage })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedReactionsFetch = useCallback(fetchReactionsTableData, [timeRangeUpdatedCounter, nodeId])

  const reactionsState = useTableState(memoizedReactionsFetch)

  const initialValues = {
    endTime: defaultEndTime,
    startTime: defaultStartTime,
  }

  const schema = Yup.object().shape({
    startTime: Yup.date().required('Please select Start Date'),
    endTime: Yup.date()
      .required('Please select End Date')
      .min(Yup.ref('startTime'), 'Must be bigger than Start Time'),
  })

  const updateTimeRange = (formValues: ITimeRangeFormValues) => {
    const customStartTime = moment(formValues.startTime).format('YYYY-MM-DD')
    setStartTime(customStartTime)

    const customEndTime = moment(formValues.endTime).format('YYYY-MM-DD')
    setEndTime(customEndTime)

    setTimeRangeUpdatedCounter(timeRangeUpdatedCounter + 1)
  }

  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <Typography className={classes.formSubheader} variant="subtitle1">
          Reactions
        </Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <Formik initialValues={initialValues} validateOnMount={false} validationSchema={schema} onSubmit={updateTimeRange}>
          {({ isValid }: FormikProps<ITimeRangeFormValues>) => {
            return (
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TimeRangeSelectorForm isFormValid={isValid} />
                </Grid>
              </Grid>
            )
          }}
        </Formik>
      </Grid>

      {reactions !== null && !showSpinner && (
        <Grid item={true} xs={12} data-cy="reactionsTable">
          <ClientSidePaginatedTable
            title="Reactions"
            columns={reactionsColumns}
            loading={reactionsState.loading}
            error={reactionsState.error}
            data={reactionsState.data}
            refresh={() => {}}
            data-testid="reactionsTable"
            idList={reactions !== null ? reactions.map((reaction, index) => ({ id: index })) : []}
            options={{
              selectableRows: 'none' as const,
              pagination: true,
              textLabels: {
                body: {
                  noMatch: 'This node does not have any reactions for selected dates',
                  toolTip: '',
                },
              },
            }}
          />
        </Grid>
      )}

      {showSpinner && (
        <Grid item={true} xs={12} container={true} justifyContent="center">
          <CircularProgress className={classes.centerItem} color="secondary" />
        </Grid>
      )}
    </Grid>
  )
}

export default Reactions
