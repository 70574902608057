import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const styles = () => {
  return createStyles({
    tagChip: {
      marginRight: '5px',
    },
  })
}
const useStyles = makeStyles(styles)
export default useStyles
