import { useCallback, useContext, useMemo, useState } from 'react'
import moment from 'moment'

import { StreamDataContext } from '../../../../providers/websocket-stream'
import Api from '../../../../utils/api'
import { useTableState } from '../../../../utils/hooks'
import { parseChannelValue } from '..'

import { Table as CurrentValuesTable } from './../current-values-table'

type IProps = {
  nodeId: string
}

/**
 * we send requests to /api/data/current?nodeId=NODE_ID&raw=true
 * and to /api/data/current?nodeId=NODE_ID
 * the channels that are returned by the first request, but not returned by the second one
 * are the off schema channels
 */
const OffSchemaChannelsTable = ({ nodeId }: IProps) => {
  const [sortInfo, setSortInfo] = useState<{ activeColumn: number; sortDir: 'none' | 'asc' | 'desc' }>({ activeColumn: 0, sortDir: 'asc' as const })
  const { state } = useContext(StreamDataContext)

  const momentFormat = 'MMMM Do YYYY, h:mm:ss a'

  const offChannelFetch = useCallback(async () => {
    const currentNodeValuesData = await Api.get(`/api/data/current?nodeId=${nodeId}&raw=true`)
    const nodeChannels = await Api.get(`/api/data/current?nodeId=${nodeId}`)
    const nodeChannelNames = nodeChannels[nodeId] ? Object.keys(nodeChannels[nodeId]).map(item => item) : []
    const rawChannelNames = currentNodeValuesData[nodeId] ? Object.keys(currentNodeValuesData[nodeId]).map(item => item) : []

    const offSchemaChannelsObj: ICurrentValueType = {
      [nodeId]: {},
    }

    rawChannelNames.forEach(channelName => {
      if (!nodeChannelNames.includes(channelName)) {
        offSchemaChannelsObj[nodeId][channelName] = currentNodeValuesData[nodeId][channelName]
      }
    })

    const allRowData: React.ReactNode[][] = Object.keys(offSchemaChannelsObj[nodeId]).map(channel => {
      return [channel, parseChannelValue(offSchemaChannelsObj[nodeId][channel].value), offSchemaChannelsObj[nodeId][channel].timestamp]
    })

    return Promise.resolve({
      data: allRowData,
    })
  }, [nodeId])

  const offChannelState = useTableState(offChannelFetch)
  const { data, loading, error } = offChannelState

  const internalData = useMemo(() => {
    if (!data) {
      return null
    }
    const newData = []
    for (const [name, value, timestamp] of data as string[][]) {
      // We need to sort somewhere to ensure that find is getting the greatest of the timestamps
      const wsVal = state.nodeData
        .sort((itemA, itemB) => itemB.timestamp - itemA.timestamp)
        .find(item => {
          const apiTs = moment(timestamp).unix()
          const wsTs = item.timestamp

          if (item.channelName === name && wsTs > apiTs) {
            return item
          }
          return null
        })
      if (wsVal) {
        newData.push([wsVal.channelName, JSON.stringify(wsVal.value), moment.unix(wsVal.timestamp).toISOString()])
      } else {
        newData.push([name, value, timestamp])
      }
    }
    return newData
  }, [state.nodeData, data])

  const columns = [
    { name: 'Channel Name', options: { filter: true, sort: true } },
    { name: 'Value', options: { filter: true, sort: true } },
    {
      name: 'Timestamp',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          if (moment(value).valueOf() > 0) {
            return moment(value).format(momentFormat)
          } else {
            return ''
          }
        },
      },
    },
  ]

  return (
    <CurrentValuesTable
      title="Off Schema Channels"
      channelDataIndex={1}
      columns={columns}
      loading={loading}
      data={internalData ?? []}
      error={error}
      selectedChannel={null}
      setSortInfo={setSortInfo}
      options={{
        sortOrder: sortInfo.sortDir !== 'none' ? { name: columns[sortInfo.activeColumn].name, direction: sortInfo.sortDir } : undefined,
        textLabels: {
          body: {
            noMatch: 'No off schema channels',
          },
        },
      }}
    />
  )
}

export default OffSchemaChannelsTable
