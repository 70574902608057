import { grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    subscriptionListItem: {
      margin: theme.spacing(),
    },
    dateScheduleContainer: {
      padding: theme.spacing(),
    },
    scheduleButton: {
      margin: theme.spacing(2),
      float: 'left',
    },
    deleteScheduleButton: {
      margin: theme.spacing(),
    },
    timeSelectionContainer: {
      border: '1px solid' + grey[400],
    },
    timePicker: {
      width: '30%',
      margin: theme.spacing(),
    },
    divider: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
