import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    saveConfigButton: {
      marginLeft: '-10px',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
