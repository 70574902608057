import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) => ({
  notificationIcon: {
    paddingRight: '1rem',
    opacity: 0.9,
  },
  alertDisplayMessage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
  warning: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.grey[800],
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
})

const useStyles = makeStyles(styles)
export default useStyles
