import { MutableRefObject } from 'react'
import { useHistory } from 'react-router'
import { Clear, Search } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'

import useStyles from './table.styles'

const CustomSearchBox = ({
  searchText,
  handleSearch,
  entityPath,
  abortToken,
  isValidId,
}: {
  searchText: string
  handleSearch: (text: string) => void
  entityPath: string
  abortToken?: MutableRefObject<boolean>
  isValidId?: (id: number) => boolean
}) => {
  const styles = useStyles()
  const history = useHistory()

  const couldBeId = searchText?.length > 0 && Number.isFinite(Number(searchText))
  const validId = searchText?.length && isValidId && isValidId(Number(searchText))
  // show the button if it's a valid id or we don't have a function to check if it's an id but it could be an id.
  const showGoToIdButton = validId || (!isValidId && couldBeId)

  return (
    <div className={styles.searchBoxContainer} data-cy="tableSearch">
      <Search className={styles.searchBoxSearchIcon} />
      <TextField
        value={searchText || ''}
        fullWidth={true}
        onChange={e => handleSearch(e.target.value)}
        variant="standard"
        className={styles.searchBoxTextField}
        autoFocus={true}
        InputProps={{
          endAdornment: showGoToIdButton ? (
            <InputAdornment position="end">
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  if (abortToken) {
                    abortToken.current = true
                  }
                  history.push(`/${entityPath}/${searchText}`)
                }}
              >
                go to id
              </Button>
            </InputAdornment>
          ) : (
            undefined
          ),
        }}
      />
      <IconButton onClick={() => handleSearch('')}>
        <Clear />
      </IconButton>
    </div>
  )
}

export default CustomSearchBox
