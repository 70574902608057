import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { PushPin, PushPinOutlined } from '@mui/icons-material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { MUIDataTableColumn } from 'mui-datatables'

import { StatefulTableProps } from '../../../../components/table'
import { PINNED_CHANNELS_LS } from '../../../../constants'
import { AlertContext } from '../../../../providers'
import { StreamDataContext } from '../../../../providers/websocket-stream'
import useTableStyles from '../../../../shared-styles/table.styles'
import Api from '../../../../utils/api'

import { Table as CurrentValuesTable } from './../current-values-table'

type CVProps = Omit<StatefulTableProps, 'columns' | 'data'> & {
  setSelectedChannel: Dispatch<SetStateAction<string | null>>
  selectedChannel: string | null
  data: React.ReactNode[][] | null
}

interface IAliasChannel {
  aliasChannelName: string
  aliasNodeId: number
  createdAt: string
  endTime: string
  id: number
  sourceChannelName: string
  sourceNodeId: number
  startTime: string
}

// get an alias channel for a given channel, if it exists
export const getAliasChannel = (channelName: string, aliasChannels: IAliasChannel[], nodeId: number) => {
  return aliasChannels ? aliasChannels.find(channel => channel.aliasChannelName === channelName && channel.aliasNodeId === nodeId) : undefined
}

// get a source channel for a given channel, if it exists
export const getSourceChannel = (channelName: string, aliasChannels: IAliasChannel[], nodeId: number) => {
  return aliasChannels ? aliasChannels.find(channel => channel.sourceChannelName === channelName && channel.sourceNodeId === nodeId) : undefined
}

// determine if Channels table should have Alias Channel column, based on if any of the channels in table have alias channels
// if at least one channel in table has an alias, return true
export const hasAliasChannels = (data: any, aliasChannels: IAliasChannel[], nodeId: number): boolean => {
  let aliasChannelExists = false

  if (data && aliasChannels && aliasChannels.length) {
    for (const channelData of data) {
      for (const aliasChannel of aliasChannels) {
        if (aliasChannel.sourceChannelName === channelData[0] && nodeId === aliasChannel.sourceNodeId) {
          aliasChannelExists = true
          break
        }
      }
    }
  }

  return aliasChannelExists
}

// determine if Channels table should have Source Channel column, based on if any of the channels in table have source channels
// if at least one channel in table has a source, return true
export const hasSourceChannels = (data: any, aliasChannels: IAliasChannel[], nodeId: number): boolean => {
  let exists = false

  if (data && aliasChannels && aliasChannels.length) {
    for (const channelData of data) {
      for (const aliasChannel of aliasChannels) {
        if (aliasChannel.aliasChannelName === channelData[0] && nodeId === aliasChannel.aliasNodeId) {
          exists = true
          break
        }
      }
    }
  }

  return exists
}

export const ValuesTable = (props: CVProps) => {
  const { loading, error, data, title, selectedChannel, setSelectedChannel } = props
  const [pinnedChannels, setPinnedChannels] = useState<string[] | undefined>()
  const params = useParams<{ nodeId: string }>()
  const classes = useTableStyles()
  const nodeId = params?.nodeId
  const momentFormat = 'MMMM Do YYYY, h:mm:ss a'

  useEffect(() => {
    if (!pinnedChannels) {
      try {
        setPinnedChannels(JSON.parse(localStorage.getItem(PINNED_CHANNELS_LS) ?? '[]'))
      } catch {
        setPinnedChannels([])
      }
    } else {
      localStorage.setItem(PINNED_CHANNELS_LS, JSON.stringify(pinnedChannels))
    }
  }, [pinnedChannels])

  const [sortInfo, setSortInfo] = useState<{ activeColumn: number; sortDir: 'none' | 'asc' | 'desc' }>({ activeColumn: 0, sortDir: 'asc' as const })
  const [aliasChannels, setAliasChannels] = useState<IAliasChannel[]>([])

  const { state } = useContext(StreamDataContext)
  const { addAlert } = useContext(AlertContext)

  const columns: MUIDataTableColumn[] = [
    { name: 'Channel Name', options: { filter: true, sort: true } },
    { name: hasSourceChannels(data, aliasChannels, Number(nodeId)) ? 'Source Channel' : ' ', options: { filter: false, sort: false } }, // only show this column in tables that have source channels
    { name: ' ', options: { filter: false, sort: false } }, // column to display an arrow icon
    { name: 'Value', options: { filter: true, sort: false } },
    { name: ' ', options: { filter: false, sort: false } }, // column to display an arrow icon
    { name: hasAliasChannels(data, aliasChannels, Number(nodeId)) ? 'Alias Channel' : ' ', options: { filter: false, sort: false } }, // only show this column in tables that have alias channels
    {
      name: 'Timestamp',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          if (moment(value).valueOf() > 0) {
            return moment(value).format(momentFormat)
          } else {
            return ''
          }
        },
      },
    },
    {
      name: 'Pinned',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, meta) => {
          const channelName = meta.rowData[0]

          return (
            <IconButton
              onClick={e => {
                e.stopPropagation()
                setPinnedChannels(prev => {
                  if (!prev) return []

                  if (prev.includes(channelName)) {
                    return prev.filter(channel => channel !== channelName)
                  } else {
                    return [...prev, channelName]
                  }
                })
              }}
            >
              {pinnedChannels?.includes(channelName) ? <PushPin /> : <PushPinOutlined />}
            </IconButton>
          )
        },
      },
    },
  ]

  useEffect(() => {
    const body = {
      filters: [
        {
          fieldName: 'sourceNodeId',
          operator: 'eq',
          value: nodeId,
        },
        {
          fieldName: 'aliasNodeId',
          operator: 'eq',
          value: nodeId,
        },
      ],
      type: 'or',
    }
    Api.post('/api/aliaschannels/query', body).then(aliasChannelData => {
      if (!aliasChannelData.error) {
        setAliasChannels(aliasChannelData.results)
      } else {
        addAlert({ alertType: 'error', message: 'Could not retreive alias channels' })
      }
    })
  }, [nodeId, addAlert])

  const internalData = useMemo(() => {
    if (!data) {
      return [[]]
    }

    const newData = []
    for (const [name, value, timestamp] of data as string[][]) {
      // We need to sort somewhere to ensure that find is getting the greatest of the timestamps B - A is large to small, do not delete this!!!
      const wsVal = state.nodeData
        .sort((itemA, itemB) => itemB.timestamp - itemA.timestamp)
        .find(item => {
          const apiTs = moment(timestamp).unix()
          const wsTs = item.timestamp
          if (item.channelName === name && wsTs > apiTs) {
            return item
          }
          return null
        })

      if (wsVal) {
        newData.push([wsVal.channelName, null, null, JSON.stringify(wsVal.value), null, null, moment.unix(wsVal.timestamp).toISOString()])
      } else {
        const aliasChannel = getAliasChannel(name, aliasChannels, Number(nodeId))
        const sourceChannel = getSourceChannel(name, aliasChannels, Number(nodeId))

        // if alias channel exists for a given channel, we will show a channel value with an arrow pointing towards source channel name
        const sourceChannelName = aliasChannel ? (
          <Link to={`/nodes/${aliasChannel.sourceNodeId}?tab=1`} className={classes.applicationLink} onClick={e => e.stopPropagation()}>
            {aliasChannel.sourceNodeId + '.' + aliasChannel.sourceChannelName}
          </Link>
        ) : null
        const sourceChannelArrow = aliasChannel ? <ArrowRightAltIcon /> : null

        // if source channel exists, we will show it in front of the value, with arrow pointing towards the value
        const aliasChannelName = sourceChannel ? (
          <Link to={`/nodes/${sourceChannel.aliasNodeId}?tab=1`} className={classes.applicationLink} onClick={e => e.stopPropagation()}>
            {sourceChannel.aliasNodeId + '.' + sourceChannel.aliasChannelName}
          </Link>
        ) : null
        const aliasChannelArrow = sourceChannel ? <ArrowRightAltIcon /> : null

        newData.push([name, sourceChannelName, sourceChannelArrow, value, aliasChannelArrow, aliasChannelName, timestamp, false])
      }
    }

    return newData
  }, [state.nodeData, data, aliasChannels, classes.applicationLink, nodeId])

  return (
    <CurrentValuesTable
      channelDataIndex={3}
      selectedChannel={selectedChannel}
      data-testid="cvChannelsTable"
      title={title}
      loading={loading}
      error={error}
      columns={columns}
      data={internalData as string[][]}
      setSortInfo={setSortInfo}
      setSelectedChannel={setSelectedChannel}
      options={{
        sortOrder: sortInfo.sortDir !== 'none' ? { name: columns[sortInfo.activeColumn].name, direction: sortInfo.sortDir } : undefined,
        customSort(sortData, colIndex, order) {
          sortData.sort((a: any, b: any) => {
            const channelNameA = a.data[0]
            const channelNameB = b.data[0]

            // if only one of the channels is pinned, sort it to the top
            if (!(pinnedChannels?.includes(channelNameA) && pinnedChannels?.includes(channelNameB))) {
              if (pinnedChannels?.includes(channelNameA)) return -1
              if (pinnedChannels?.includes(channelNameB)) return 1
            }

            // otherwise, sort the data the correct way based on the column clicked
            const dataA = a.data[colIndex] === null || typeof a.data[colIndex] === 'undefined' ? '' : a.data[colIndex]
            const dataB = b.data[colIndex] === null || typeof b.data[colIndex] === 'undefined' ? '' : b.data[colIndex]

            return (typeof dataA.localeCompare === 'function' ? dataA.localeCompare(dataB) : dataA - dataB) * (order === 'asc' ? 1 : -1)
          })

          return sortData
        },
      }}
    />
  )
}

export default ValuesTable
