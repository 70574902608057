import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Api from '../../../utils/api'
import { useDocumentTitle } from '../../../utils/hooks'
import { getFolderLocation } from '../primary-contact'

import InsideAttributes from './attributes/whats-inside-attributes'
import InsideNodes from './nodes/whats-inside-nodes'
import WhatsInsideUsers from './users/whats-inside-users'
import useWhatsInsideStyles from './whats-inside.styles'

export const getFolderUsers = (usersList: IUserType[], currentFolderId: any): IUserType[] => {
  const folderUsers: IUserType[] = []

  if (usersList.length) {
    usersList.forEach(user => {
      user.folders.forEach(folderId => {
        if (folderId === Number(currentFolderId)) {
          folderUsers.push(user)
        }
      })
    })
  }

  return folderUsers
}

const WhatsInside = () => {
  const params = useParams<{ folderId: string }>()
  const folderId = params.folderId !== 'new' ? params.folderId : null
  const classes = useWhatsInsideStyles()

  const [currentFolder, setCurrentFolder] = useState<IFolderType | null>(null)
  const [folderUsers, setFolderUsers] = useState<IUserType[]>([])
  const [folderUsersError, setFolderUsersError] = useState<string>('')
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false)

  const [folderNodes, setFolderNodes] = useState<INodeType[]>([])
  const [nodesError, setNodesError] = useState<string>('')
  const [nodesLoading, setNodesLoading] = useState<boolean>(false)

  const [nodesMovedCounter, setNodesMovedCounter] = useState<number>(0)

  useDocumentTitle('Folders', currentFolder?.name)

  useEffect(() => {
    let isSubscribed = true

    const getFolder = async () => {
      const retrieved = await Api.get(`/api/folders/${folderId}`)

      if (isSubscribed && !retrieved.error) {
        setCurrentFolder(retrieved)
      }
    }

    getFolder()

    return () => {
      isSubscribed = false
    }
  }, [folderId])

  useEffect(() => {
    let isSubscribed = true
    setIsLoadingUsers(true)

    Api.post('/api/users/query', {
      filters: [{ fieldName: 'folders', operator: 'contains', value: Number(folderId) }],
      first: 1000,
    }).then(queryUsers => {
      setIsLoadingUsers(false)

      if (queryUsers.error) {
        setFolderUsersError(queryUsers?.message || 'Could not access users')
      } else {
        if (isSubscribed) {
          setFolderUsers(queryUsers.results)
        }
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [folderId])

  useEffect(() => {
    if (folderId) {
      setNodesLoading(true)
      Api.post('/api/nodes/query', {
        filters: [{ fieldName: 'folderId', operator: 'eq', value: +folderId }],
        type: 'or',
        first: 10000,
      }).then(data => {
        setNodesLoading(false)
        if (!data.error) {
          setFolderNodes(data.results)
        } else {
          setNodesError(data?.message || 'Could not load nodes')
        }
      })
    }
  }, [folderId, nodesMovedCounter])

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12}>
        <p className={classes.business}>Business</p>
        <p className={classes.address}>{currentFolder?.name}</p>
        <p className={classes.address}>{currentFolder?.information?.address?.address}</p>
        <p className={classes.address}>{getFolderLocation(currentFolder)}</p>
        <p className={classes.address}>{currentFolder?.information?.address?.code}</p>
      </Grid>

      <Grid item={true} xs={12}>
        <div className={classes.accordionWrapper}>
          <Accordion data-cy="usersAccordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className={classes.accordionTitle} variant="subtitle1">
                Users
              </Typography>

              <span className={classes.totalCount}>Total Users in folder: {folderUsers.length}</span>
            </AccordionSummary>

            <AccordionDetails>
              <WhatsInsideUsers folderUsers={folderUsers} isLoadingUsers={isLoadingUsers} folderUsersError={folderUsersError} />
            </AccordionDetails>
          </Accordion>

          <Accordion data-cy="nodesAccordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography className={classes.accordionTitle} variant="subtitle1">
                Nodes
              </Typography>

              <span className={classes.totalCount}>Total Nodes in folder: {folderNodes?.length}</span>
            </AccordionSummary>

            <AccordionDetails>
              <InsideNodes folderNodes={folderNodes} nodesError={nodesError} nodesLoading={nodesLoading} setNodesMovedCounter={setNodesMovedCounter} />
            </AccordionDetails>
          </Accordion>

          {currentFolder?.type === 'Participant' && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography className={classes.accordionTitle} variant="subtitle1">
                  Attributes
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <InsideAttributes folderId={folderId} />
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default WhatsInside
