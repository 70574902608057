import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const drawerWidth = 240

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flex: `0 0 ${drawerWidth}px`,
    },
    mobileRoot: {
      display: 'block',
    },
    menuDrawer: {
      zIndex: 120,
      flex: `0 0 ${drawerWidth}px`,
      '& div div:last-child': {
        '& li:last-child': {
          paddingBottom: '40px',
        },
      },
    },
    drawerPaper: {
      flex: `0 0 ${drawerWidth}px`,
      marginTop: '65px',
      width: 'inherit',
      height: 'calc(100% - 65px)',
    },
    drawerPaperSmallScreen: {
      flex: `0 0 ${drawerWidth}px`,
      width: 'inherit',
    },
    navbarSubheader: {
      textAlign: 'left',
      fontWeight: 'bold',
      position: 'static',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
