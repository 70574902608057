import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'

import Alert from '../../components/alert'
import { NO_FILE_API_COPY } from '../../constants'
import Api from '../../utils/api'
import { useFileAPIExists } from '../../utils/hooks'

import useStyles from './file-upload.styles'
import SelectedFilePreview from './selected-file-preview'

type IProps = {
  fileEndpoint: string // an endpoint to get, put or delete  a file - base url without ids or other params
  filesList: string[] // list of file names
  setAllFiles: (val: string) => void // function to update list of all files in a parent component,
}

export const getFileType = (fileName: string) => {
  const imageFileExtensions: string[] = ['ico', 'jpeg', 'jpg', 'png', 'pdf']
  const textFileExtensions = ['html', 'js', 'css', 'txt', 'json', 'csv']
  let fileExtension: string | undefined = fileName.split('.').pop()
  fileExtension = fileExtension == null ? '' : fileExtension

  const imageType = 'image'
  const textType = 'text'
  const otherType = 'other'

  if (imageFileExtensions.includes(fileExtension)) {
    return imageType
  } else if (textFileExtensions.includes(fileExtension)) {
    return textType
  } else {
    return otherType
  }
}

export const getFileAccessType = (fileEndpoint: string): 'public' | 'private' => {
  return fileEndpoint.endsWith('/private') ? 'private' : 'public'
}

export const getFileName = (file: string): string => {
  return file.charAt(0) === '/' ? file.slice(1) : file
}

const FileList = ({ fileEndpoint, filesList, setAllFiles }: IProps) => {
  const classes = useStyles()
  const fileAPISupported = useFileAPIExists()
  const [currentlySelectedFileName, setCurrentlySelectedFileName] = useState<string>('')
  const [updatedFileName, setUpdatedFileName] = useState<string>('')
  const [encodedImage, setEncodedImage] = useState<string>('')
  const [decodedImage, setDecodedImage] = useState<string>('')
  const [currentlySelectedFileUrl, setCurrentlySelectedFileUrl] = useState<string>('')
  const [currentlySelectedFileTextVal, setCurrentlySelectedFileTextVal] = useState<string>('')

  const handleFileNameClick = async (fileName: string) => {
    // currentlySelectedFileName and updatedFileName have to be 2 different variables
    // when user updates file name in the UI,
    // we delete file with old name and create file with same content, but with a new name
    // therefore we need to keep track of current file name, and updatedFileName - which is whatever new name user chooses
    // also, we use cleanedName for delete endpoint to make sure 2 slashes are not added
    const cleanedName = fileName.charAt(0) === '/' ? fileName.slice(1) : fileName
    setCurrentlySelectedFileName(cleanedName)
    setUpdatedFileName(fileName)
    const fileType = getFileType(fileName)

    try {
      const currentlySelectedFile = await Api.getFile(fileEndpoint + encodeURI(fileName), fileType)

      let textRes = ''

      if (fileType === 'image') {
        setEncodedImage(currentlySelectedFile)

        const reader = new FileReader()
        reader.onload = (event: any) => setDecodedImage(event.target.result)
        reader.readAsDataURL(currentlySelectedFile)
      } else {
        textRes = await currentlySelectedFile.text()
      }

      setCurrentlySelectedFileUrl(currentlySelectedFile)
      setCurrentlySelectedFileTextVal(textRes)
    } catch (error) {
      console.error('Error when getting file: ', error)
    }
  }

  const setCodemirrorValue = (val: string) => {
    setCurrentlySelectedFileTextVal(val)
  }

  const resetStateAfterFileDeletion = (val: {
    files: string
    currentlySelectedFileName: string
    currentlySelectedFileUrl: string
    updatedFileName: string
  }) => {
    setAllFiles(val.files)
    setCurrentlySelectedFileName(val.currentlySelectedFileName)
    setCurrentlySelectedFileUrl(val.currentlySelectedFileUrl)
    setUpdatedFileName(val.updatedFileName)
  }

  const resetStateAfterFileUpdate = (val: string) => {
    setAllFiles(val)
  }

  const FileListItem = ({ file }: { file: string }) => {
    if (fileAPISupported) {
      return (
        <li
          className={currentlySelectedFileName === getFileName(file) ? classNames([classes.fileName, classes.selectedFileName]) : classes.fileName}
          onClick={() => handleFileNameClick(file)}
        >
          {getFileName(file)}
        </li>
      )
    }

    return (
      <Tooltip title={NO_FILE_API_COPY} arrow={true}>
        <li className={classes.disabledListItem}> {file.charAt(0) === '/' ? file.slice(1) : file}</li>
      </Tooltip>
    )
  }

  return (
    <>
      {filesList && filesList.length ? (
        <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={3}>
          <ul className={classes.filesNameList} data-cy="listOfFiles">
            {filesList?.map((file: string, idx) => (
              <FileListItem file={file} key={idx} />
            ))}
          </ul>
        </Grid>
      ) : null}

      <Grid item={true} xs={12} sm={12} md={9} lg={9} xl={9}>
        {currentlySelectedFileUrl.length === 0 ? (
          <Alert alertType="warning" message="Select a file with the extension css, html, js, txt, py, xml, tag or csv to begin editing." />
        ) : (
          <SelectedFilePreview
            currentlySelectedFileUrl={currentlySelectedFileUrl}
            currentlySelectedFileName={currentlySelectedFileName}
            fileType={getFileType(currentlySelectedFileName)}
            decodedImage={decodedImage}
            encodedImage={encodedImage}
            currentlySelectedFileTextVal={currentlySelectedFileTextVal}
            setCodemirrorValue={setCodemirrorValue}
            updatedFileName={updatedFileName}
            setNewFileName={(val: string) => setUpdatedFileName(val)}
            fileEndpoint={fileEndpoint}
            resetStateAfterFileDeletion={resetStateAfterFileDeletion}
            resetStateAfterFileUpdate={resetStateAfterFileUpdate}
            fileAccessType={getFileAccessType(fileEndpoint)}
          />
        )}
      </Grid>
    </>
  )
}

export default FileList
