import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { get } from 'lodash'
import { MUIDataTableColumn } from 'mui-datatables'

import AddButton from '../../components/add-entity-button'
import { ServerSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import TableLink from '../../components/table-link'
import { READ_PERMISSIONS_LIST } from '../../constants/permissions'
import { ImpersonateContext, OwnUserContext, UserPermissionsContext } from '../../providers'
import useTableStyles from '../../shared-styles/table.styles'
import api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, usePagination, useTableState } from '../../utils/hooks'
import { handleCellClick } from '../../utils/table/table-helpers'

export const getFolders = (folderIds: number[], folderIdMap: { [key: number]: string }) => {
  return folderIds
    .map(folderId => {
      if (folderIdMap[folderId] == null) {
        return null
      }
      return { name: folderIdMap[folderId], id: folderId }
    })
    .filter(item => item != null)
}

// permission with id 202 is a permission for impersonation
// this ID is hardcoded on backend and is same across all tenants and environments
export const hasImpersonatePermission = (permissions: IPermissionType[]): boolean => {
  return permissions && permissions.length ? permissions?.find(item => item.id === READ_PERMISSIONS_LIST.impersonate.id) !== undefined : false
}

const UsersListView = () => {
  const classes = useTableStyles()
  const history = useHistory()

  const pageHook = usePagination()

  const [users, setUsers] = useState<IUserType[] | null>(null)
  const currentUser = useContext<IUserType | null>(OwnUserContext)
  const impersonatedUserContext = useContext<IImpersonateContext | null>(ImpersonateContext)
  const userPermissions = useContext<IPermissionType[]>(UserPermissionsContext)

  useDocumentTitle('Users')

  const columns: MUIDataTableColumn[] = [
    { name: 'Email', options: { filter: true, sort: true } },
    { name: 'Phone', options: { filter: true, sort: false } },
    { name: 'First Name', options: { filter: true, sort: true } },
    { name: 'Last Name', options: { filter: true, sort: true } },
    { name: 'Role', options: { filter: true, sort: false } },
    { name: 'Folders', options: { filter: true, sort: false } },
    { name: 'Impersonate', options: { filter: true, sort: false } },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ].filter(item => {
    if (!hasImpersonatePermission(userPermissions)) {
      return item.name !== 'Impersonate'
    } else if (!hasImpersonatePermission(userPermissions) || (currentUser && !currentUser.impersonatedBy)) {
      return item
    } else {
      return item.name !== 'Impersonate'
    }
  })

  const RoleName = ({ user }: { user: IUserType }) => {
    const roleName = user && user.roleName ? user.roleName : ''
    return (
      <TableLink
        className={classes.applicationLink}
        to={`/roles/${user.roleId}`}
        data-cy="userListUserName"
        data-testid="userRole"
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {roleName}
      </TableLink>
    )
  }

  const showImpersonateButton = (id: number) => {
    const bool = Boolean(currentUser && !currentUser.impersonatedBy && hasImpersonatePermission(userPermissions) && currentUser.id !== id)
    return bool
  }

  const formatData = (fetchedUsers: IUserType[] | null): React.ReactNode[][] => {
    return fetchedUsers && fetchedUsers.length
      ? fetchedUsers.map((user: IUserType, index: number) => {
          const userFolders = user.foldersDetail ? getFolders(user.folders, user.foldersDetail) : []
          const firstFolder = userFolders.length > 0 && userFolders[0]
          const folderNameList =
            userFolders.length > 0 ? (
              <Grid container={true}>
                <TableLink
                  to={firstFolder ? `/folders/${firstFolder.id}` : ''}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  {firstFolder && firstFolder.name}
                </TableLink>
                {userFolders.length > 1 ? (
                  <span
                    data-testid="moreFolders"
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    , ...
                  </span>
                ) : null}
              </Grid>
            ) : (
              '...'
            )

          return [
            user.email,
            get(user, 'phone', 'NA'),
            get(user, 'information.first', 'NA'),
            get(user, 'information.last', 'NA'),
            <RoleName key={index} user={user} />,
            folderNameList,

            showImpersonateButton(user.id) ? (
              <span
                data-testid="users-list-impersonate-btn"
                className={classes.applicationLink}
                onClick={e => {
                  e.stopPropagation()
                  if (impersonatedUserContext) {
                    impersonatedUserContext.setImpersonatedUserId(user.id)
                  }
                }}
              >
                Impersonate
              </span>
            ) : currentUser && !currentUser.impersonatedBy && hasImpersonatePermission(userPermissions) && currentUser.id === user.id ? (
              <span data-testid="disabled-users-list-impersonate-btn">N/A</span>
            ) : null,

            convertTimestampToDate(user.updatedAt),
          ].filter(item => item != null)
        })
      : []
  }

  const sanitizeColName = (colName: string) => {
    switch (colName) {
      case 'Email':
        return 'email'
      case 'First Name':
        return 'firstName'
      case 'Last Name':
        return 'lastName'
      case 'Modified':
        return 'updatedAt'
      default:
        return ''
    }
  }

  const fetchUserData = async () => {
    if (currentUser) {
      let fetchedUsers: IUserType[] = []
      const { offset, limit, searchText, currentSort, sortDir } = pageHook

      const queryBody: IQueryBody = {
        type: 'or',
        first: limit,
        orderBy: currentSort ? sanitizeColName(currentSort) : undefined,
        orderDirection: sortDir !== 'none' ? sortDir : undefined,

        filters: [
          { fieldName: 'email', operator: 'multilike', value: searchText },
          { fieldName: 'firstName', operator: 'multilike', value: searchText },
          { fieldName: 'lastName', operator: 'multilike', value: searchText },
        ],
        after: offset ? btoa(String(offset)) : undefined,
      }
      const res = await api.post('/api/users/query?detail=true', queryBody)
      const { totalCount, results } = res
      fetchedUsers = results
      const totalEntityCount = Number(totalCount)

      if (res.error) {
        return Promise.reject(res)
      }

      // If couldn't fetch users, don't try to fetch roles and folders, return empty array
      if (!fetchedUsers.length) {
        return Promise.resolve({
          data: [],
          setEntities: () => setUsers(fetchedUsers),
          setTotalRows: () => pageHook.setTotalRows(Number(totalEntityCount)),
        })
      }

      const paginatedData: React.ReactNode[][] = formatData(fetchedUsers)
      return Promise.resolve({
        data: paginatedData,
        setEntities: () => setUsers(fetchedUsers),
        setTotalRows: () => pageHook.setTotalRows(Number(totalEntityCount)),
      })
    } else {
      return Promise.resolve({
        data: [[]],
      })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchUserData, [
    pageHook.searchText,
    pageHook.pageNumber,
    pageHook.rowsPerPage,
    pageHook.currentSort,
    pageHook.sortDir,
    pageHook.rowsDeletedCounter,
    currentUser,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoFormatData = useCallback(formatData, [currentUser, userPermissions])

  const { loading, data, error } = useTableState(memoizedFetch, memoFormatData, users)

  const NewUserLink = forwardRef((componentProps: any, ref: any) => <Link to="/users/new" ref={ref} {...componentProps} />)

  return (
    <Grid justifyContent="center" className={classes.tableContainer} container={true}>
      <Grid item={true} xs={12} container={true} justifyContent="flex-end">
        <AddButton
          userPermissions={userPermissions}
          newComponentLink={NewUserLink}
          entityName="Users"
          buttonName="Add User"
          dataCy="addBtn"
          dataTestId="addBtn"
        />
      </Grid>

      <Grid item={true} xs={12} data-cy="usersList">
        <ServerSidePaginatedTable
          title="Users"
          pagination={{ ...pageHook }}
          columns={columns}
          loading={loading || !currentUser}
          error={error}
          data={data}
          idList={users != null && users.length ? users.map(u => ({ id: u.id })) : []}
          entityDeleteEndpoint="/api/users"
          refresh={pageHook.refreshTable}
          options={{
            filterType: 'checkbox' as const,
            sortOrder: pageHook.sortDir !== 'none' ? { name: pageHook.currentSort, direction: pageHook.sortDir } : undefined,
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, users, 'users', cellMeta, pageHook.abort),
            textLabels: {
              body: {
                noMatch: 'Sorry, no records founds',
                toolTip: '',
              },
            },
            customSearchRender: (searchText, handleSearch) => {
              return <CustomSearchBox searchText={searchText} handleSearch={handleSearch} entityPath="users" abortToken={pageHook.abort} />
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default UsersListView
