import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LocationOn } from '@mui/icons-material'
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, Grid, TextField as MaterialTextField, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { debounce } from 'lodash'

import TextField from '../form-elements/text-field'

interface IGoogleAddress {
  city: string
  state: string
  country: string
  postalCode: string
  // google specific terms
  route: string
  street_number: string
  subpremise: string
}
interface IAddressFormFields {
  address: string
  city: string
  state: string
  country: string
  postalCode: string
}

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY
const autocompleteService: { current: google.maps.places.AutocompleteService | null } = { current: null }
const geocoderService: { current: google.maps.Geocoder | null } = { current: null }

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const fetchAddressInfo = async (placeId: string) => {
  if (!geocoderService.current) {
    return null
  }

  const details = await geocoderService.current?.geocode({ placeId })

  if (!details || details?.results.length === 0) {
    return null
  }

  const result = details.results[0]
  const googleAddress = {} as IGoogleAddress

  for (const component of result.address_components) {
    if (component.types.includes('locality')) {
      googleAddress.city = component.long_name
    } else if (component.types.includes('route')) {
      googleAddress.route = component.long_name
    } else if (component.types.includes('street_number')) {
      googleAddress.street_number = component.long_name
    } else if (component.types.includes('subpremise')) {
      googleAddress.subpremise = component.long_name
    } else if (component.types.includes('administrative_area_level_1')) {
      googleAddress.state = component.short_name
    } else if (component.types.includes('postal_code')) {
      googleAddress.postalCode = component.short_name
    } else if (component.types.includes('country')) {
      googleAddress.country = component.short_name
    }
  }

  const addressForm: IAddressFormFields = {
    address: [googleAddress.street_number, googleAddress.route, googleAddress.subpremise].filter(x => x).join(' '),
    city: googleAddress.city,
    state: googleAddress.state,
    country: googleAddress.country,
    postalCode: googleAddress.postalCode,
  }
  return addressForm
}

export const AddressForm = ({ isEditMode }: { isEditMode: boolean }) => {
  const formik = useFormikContext<IAddressFormFields>()

  const [value, setValue] = useState<google.maps.places.AutocompletePrediction | null>(null)
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([])
  const [inputValue, setInputValue] = useState('')
  const [addressConfirmDetails, setAddressConfirmDetails] = useState<IAddressFormFields | null>(null)
  const [showAddressSearchModal, setShowAddressSearchModal] = useState<boolean>(false)

  const googleServicesLoaded = useRef<boolean>(false)

  if (typeof window !== 'undefined' && !googleServicesLoaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, document.querySelector('head'), 'google-maps')
    }

    googleServicesLoaded.current = true
  }

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results: google.maps.places.AutocompletePrediction[] | null) => void) => {
        autocompleteService.current?.getPlacePredictions(request, callback)
      }, 400),
    []
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!geocoderService.current && window.google) {
      geocoderService.current = new window.google.maps.Geocoder()
    }

    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results: google.maps.places.AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions: google.maps.places.AutocompletePrediction[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const populateOtherFields = async (address: google.maps.places.AutocompletePrediction | null) => {
    if (!address) {
      return
    }

    const geoDetails = await fetchAddressInfo(address.place_id)
    setAddressConfirmDetails(geoDetails)
  }

  const closeModal = () => {
    setShowAddressSearchModal(false)
    setAddressConfirmDetails(null)
    setInputValue('')
    setValue(null)
  }

  const onModalConfirm = useCallback(() => {
    const { setFieldValue } = formik

    setFieldValue('address', addressConfirmDetails?.address)
    setFieldValue('state', addressConfirmDetails?.state)
    setFieldValue('country', addressConfirmDetails?.country)
    setFieldValue('postalCode', addressConfirmDetails?.postalCode)
    setFieldValue('city', addressConfirmDetails?.city)

    closeModal()
  }, [formik, addressConfirmDetails])

  return (
    <>
      <Grid item={true} xs={4}>
        <TextField initialized={isEditMode} data-cy="address" name="address" label="Address" />
      </Grid>
      <Grid item={true} xs={4}>
        <TextField initialized={isEditMode} data-cy="state" name="state" label="State" />
      </Grid>
      <Grid item={true} xs={4}>
        <TextField initialized={isEditMode} data-cy="country" name="country" label="Country" />
      </Grid>
      <Grid item={true} xs={4}>
        <TextField initialized={isEditMode} data-cy="postalCode" name="postalCode" label="Postal Code" />
      </Grid>
      <Grid item={true} xs={4}>
        <TextField initialized={isEditMode} data-cy="city" name="city" label="City" />
      </Grid>
      <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
        <Button onClick={() => setShowAddressSearchModal(true)} variant="contained">
          Search address
        </Button>
      </Grid>

      <Dialog open={showAddressSearchModal} onClose={closeModal}>
        <DialogContent>
          <Autocomplete
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            filterOptions={x => x}
            sx={{ minWidth: 407 }}
            options={options}
            autoComplete={true}
            includeInputInList={true}
            filterSelectedOptions={true}
            fullWidth={true}
            value={value}
            noOptionsText="No locations"
            onChange={(event: any, newValue: google.maps.places.AutocompletePrediction | null) => {
              setOptions(newValue ? [newValue, ...options] : options)
              setValue(newValue)
              populateOtherFields(newValue)
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            renderInput={params => <MaterialTextField {...params} label="Search address..." fullWidth={true} />}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container={true} alignItems="center">
                    <Grid item={true} sx={{ display: 'flex', width: 44 }}>
                      <LocationOn sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid item={true} sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      {option.description}
                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
          />
        </DialogContent>
        <DialogContent hidden={addressConfirmDetails == null}>
          Do you want to override the address details with the one selected?
          <br />
          <br />
          {formik.values.address} → {addressConfirmDetails?.address}
          <br />
          {formik.values.city} → {addressConfirmDetails?.city}
          <br />
          {formik.values.state} → {addressConfirmDetails?.state}
          <br />
          {formik.values.country} → {addressConfirmDetails?.country}
          <br />
          {formik.values.postalCode} → {addressConfirmDetails?.postalCode}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={onModalConfirm} disabled={addressConfirmDetails == null}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
