import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import { MUIDataTableColumn } from 'mui-datatables'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { filterActiveColumn, filterLinkColumn, filterPlainTextColumn, handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

export const getNodetype = (nodetypes: INodeTypeType[], nodetypeId: number) => {
  return nodetypes.filter(nodetype => nodetype.id === nodetypeId)[0]
}

/**
 * get array of unique node type names for rules,
 *  we will display them in Node Types dropdown in fitler panel
 */
export const getUniqueNodetypeNames = (allRules: IRuleType[], allNodetypes: INodeTypeType[]): string[] => {
  const uniqueNodetypeNames: string[] = []

  allRules.forEach(rule => {
    allNodetypes.forEach(nodetype => {
      // add nodetype name for rule, if doesn't exist already
      if (rule.nodeTypeId === nodetype.id && uniqueNodetypeNames.indexOf(nodetype.name) === -1) {
        uniqueNodetypeNames.push(nodetype.name)
      }
    })
  })

  return uniqueNodetypeNames
}

export const RulesList = () => {
  const classes = useTableStyles()
  const { addAlert } = useContext(AlertContext)
  const history = useHistory()
  const searchResultsHook = useSearchData()

  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const [rules, setRules] = useState<IRuleType[]>([])
  const [nodetypes, setNodetypes] = useState<INodeTypeType[]>([])

  useDocumentTitle('Rules')

  const columns: MUIDataTableColumn[] = [
    { name: 'Name', options: { searchable: true, filter: false, sort: true } },
    {
      name: 'Node Type',
      options: {
        searchable: false,
        filter: true,
        sort: false,
        filterOptions: {
          names: getUniqueNodetypeNames(rules, nodetypes),
          logic: (location, filters) => filterLinkColumn(location, filters),
        },
      },
    },
    {
      name: 'Channel Name',
      options: {
        searchable: false,
        filter: true,
        sort: true,
        filterOptions: {
          logic: (location, filters) => filterPlainTextColumn(location, filters),
        },
      },
    },
    {
      name: 'Active',
      options: {
        searchable: false,
        filter: true,
        sort: true,
        filterOptions: {
          names: ['Active', 'Not Active'],
          logic: (location, filters) => filterActiveColumn(location, filters),
        },
      },
    },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchRulesData = async () => {
    const retrievedRules = await Api.get('/api/rules')
    const retrievedNodetypes = await Api.get('/api/nodetypes')

    if (retrievedRules.error) {
      addAlert({ alertType: 'error', message: retrievedRules.message })
      return Promise.reject(retrievedRules)
    }

    if (retrievedNodetypes.error) {
      addAlert({ alertType: 'error', message: retrievedNodetypes.message })
    } else {
      setNodetypes(retrievedNodetypes)
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = retrievedRules.map((rule: IRuleType) => {
      const nodetype = !retrievedNodetypes.error ? getNodetype(retrievedNodetypes, rule.nodeTypeId) : null

      const nodetypeName = nodetype ? (
        <Link
          to={`/node-types/${nodetype.id}`}
          className={classes.applicationLink}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {nodetype.name ?? ''}
        </Link>
      ) : (
        ''
      )

      const isActive = rule.active ? <Icon className={classes.greenColor}>check</Icon> : <Icon className={classes.redColor}>close</Icon>

      return [rule.name, nodetypeName, rule.channelName, isActive, convertTimestampToDate(rule.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setRules(retrievedRules) })
  }

  const NewRuleLink = forwardRef((buttonProps: any, ref: any) => <Link to="/rules/new" ref={ref} {...buttonProps} />)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchRulesData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  return (
    <Grid justifyContent="center" container={true} data-cy="rulesList">
      <Grid className={classes.tableContainer} container={true}>
        <Grid container={true} justifyContent="flex-end">
          <UserPermissionsContext.Consumer>
            {permissions => (
              <AddButton
                userPermissions={permissions}
                newComponentLink={NewRuleLink}
                entityName="Rules"
                buttonName="Add Rule"
                dataCy="addBtn"
                dataTestId="addRuleBtn"
              />
            )}
          </UserPermissionsContext.Consumer>
        </Grid>

        <Grid item={true} xs={12}>
          <ClientSidePaginatedTable
            title="Rules"
            entityDeleteEndpoint="/api/rules"
            idList={rules != null ? rules.map(r => ({ id: r.id })) : []}
            columns={columns}
            loading={state.loading}
            error={state.error}
            data={state.data}
            preserveSearchResults={{ ...searchResultsHook }}
            refresh={() => setRefreshCounter(c => c + 1)}
            options={{
              filter: true,
              filterType: 'dropdown',
              onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, rules, 'rules', cellMeta, searchResultsHook.abort),
              customSearchRender: (searchText, handleSearch) => {
                return (
                  <CustomSearchBox
                    searchText={searchText}
                    handleSearch={handleSearch}
                    entityPath="rules"
                    abortToken={searchResultsHook.abort}
                    isValidId={(id: number) => !!rules?.find(rule => rule.id === id)}
                  />
                )
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RulesList
