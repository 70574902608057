import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ArrowDropDown, Close } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'

import Checkbox from '../../components/form-elements/checkbox'
import MultiFilterSelect from '../../components/form-elements/filter-select/multi-select'
import SingleSelect from '../../components/form-elements/filter-select/single-select'
import { WRITE_PERMISSIONS_LIST } from '../../constants/permissions'
import { AlertContext, OwnUserContext, UserPermissionsContext } from '../../providers'
import Api from '../../utils/api'
import { handleFormSubmit } from '../../utils/form/form-helpers'
import { folderTreeToList } from '../../utils/helper-functions'

interface IProps {
  isEditMode: boolean
  templateId: number
}

interface IFolderFormValues {
  folderId: number
  includeSubFolders: boolean
  sendNotifications: boolean
}

interface IUserFormValues {
  userIds: number[]
}

const HeaderAction = (props: IProps) => {
  const { isEditMode, templateId } = props
  const history = useHistory()
  const ownUser = useContext(OwnUserContext)
  const userPermissions = useContext(UserPermissionsContext)
  const { addAlert } = useContext(AlertContext)

  const [menuAnchor, setMenuAnchor] = useState<any>(null)
  const [showFolderDialog, setShowFolderDialog] = useState<boolean>(false)
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false)
  const [folders, setFolders] = useState<IFolderTypeSimple[] | undefined>()
  const [users, setUsers] = useState<IUserType[] | undefined>()

  const hasPermissionToCreateUserTask = userPermissions.includes(WRITE_PERMISSIONS_LIST.tasks) || ownUser?.isRoot
  const hasPermissionToCreateFolderTask =
    (userPermissions.includes(WRITE_PERMISSIONS_LIST.tasks) && userPermissions.includes(WRITE_PERMISSIONS_LIST.notifications)) || ownUser?.isRoot
  const atLeastOneCreate = hasPermissionToCreateUserTask || hasPermissionToCreateFolderTask

  useEffect(() => {
    let isSubscribed = true
    if (showUserDialog) {
      if (!users) {
        Api.get('/api/users').then(res => {
          if (isSubscribed) {
            if (!res.error) {
              setUsers((res as IUserType[]).filter(u => !!u.email))
            } else {
              addAlert({ alertType: 'error', message: res.message })
            }
          }
        })
      }
    }

    return () => {
      isSubscribed = false
    }
  }, [addAlert, showUserDialog, users])

  useEffect(() => {
    let isSubscribed = true
    if (showFolderDialog && !folders) {
      Api.get('/api/folders/tree').then(res => {
        if (isSubscribed) {
          if (!res.error) {
            setFolders(folderTreeToList(res))
          } else {
            addAlert({ alertType: 'error', message: res.message })
          }
        }
      })
    }

    return () => {
      isSubscribed = false
    }
  }, [addAlert, folders, showFolderDialog])

  const createFolderTasks = async (values: IFolderFormValues, actions: FormikHelpers<IFolderFormValues>) => {
    const payload = {
      includeSubFolders: values.includeSubFolders,
      sendNotifications: values.sendNotifications,
      taskTemplateId: templateId,
    }

    try {
      handleFormSubmit(await Api.post(`/api/v2/folders/${values.folderId}/tasks`, payload), addAlert, history, null, values, actions)
      setShowFolderDialog(false)
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not create tasks' })
    }
  }

  const createUserTasks = async (values: IUserFormValues, actions: any) => {
    const userIds = values.userIds

    Promise.all(
      userIds.map(async userId => {
        let res
        try {
          handleFormSubmit((res = await Api.post(`/api/v2/tasks`, { taskTemplateId: templateId, userId })), () => {}, history, null, values, actions)
          if (!res.error) {
            addAlert({ message: `Successfully created task for ${userId}`, alertType: 'success' })
          } else {
            const message = res.message
            addAlert({
              alertType: 'error',
              message: `Could not create task for ${userId}: ${String(message)}`,
            })
          }
        } catch (e) {
          const message = (e as Error).message
          addAlert({ alertType: 'error', message: message ? `Could not create task for ${userId}: ${String(message)}` : `Could not create task for ${userId}` })
        }
      })
    ).then(() => {
      setShowUserDialog(false)
    })
  }

  return isEditMode ? (
    <>
      {atLeastOneCreate && (
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={e => setMenuAnchor(e.currentTarget)}
          data-testid="createTasksDropDown"
          endIcon={<ArrowDropDown />}
        >
          Create Tasks
        </Button>
      )}
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        {hasPermissionToCreateFolderTask && (
          <MenuItem
            onClick={() => {
              setShowFolderDialog(true)
              setMenuAnchor(null)
            }}
          >
            For Folder
          </MenuItem>
        )}
        {hasPermissionToCreateUserTask && (
          <MenuItem
            onClick={() => {
              setShowUserDialog(true)
              setMenuAnchor(null)
            }}
          >
            For User
          </MenuItem>
        )}
      </Menu>

      <Dialog open={showFolderDialog} onClose={() => setShowFolderDialog(false)}>
        <Formik initialValues={{ folderId: 0, includeSubFolders: false, sendNotifications: false }} enableReinitialize={true} onSubmit={createFolderTasks}>
          {({ isValid, isSubmitting, dirty }) => {
            return (
              <Form>
                <DialogTitle>
                  <Grid container={true} alignItems="center" justifyContent="space-between">
                    Select Folder
                    <IconButton onClick={() => setShowFolderDialog(false)}>
                      <Close />
                    </IconButton>
                  </Grid>
                </DialogTitle>

                <DialogContent dividers={true}>
                  <SingleSelect<IFolderTypeSimple, IFolderFormValues>
                    name="folderId"
                    label="Please select a folder. Tasks will be created for all contacts in this folder."
                    required={true}
                    items={folders ?? []}
                    loading={!folders}
                    displayField="name"
                    initialValue={undefined}
                  />
                  <div>
                    <Checkbox name="includeSubFolders" label="Include Sub Folders?" />
                  </div>
                  <div>
                    <Checkbox name="sendNotifications" label="Send Notifications?" />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button type="button" data-cy="cancelBtn" data-testid="cancelBtn" color="inherit" onClick={() => setShowFolderDialog(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" data-cy="saveBtn" data-testid="saveBtn" disabled={!isValid || isSubmitting || !dirty}>
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )
          }}
        </Formik>
      </Dialog>

      <Dialog open={showUserDialog} onClose={() => setShowUserDialog(false)}>
        <Formik initialValues={{ userIds: [] }} enableReinitialize={true} onSubmit={createUserTasks}>
          {({ isValid, isSubmitting, dirty }) => {
            return (
              <Form>
                <DialogTitle>
                  <Grid container={true} alignItems="center" justifyContent="space-between">
                    Select Users
                    <IconButton onClick={() => setShowUserDialog(false)}>
                      <Close />
                    </IconButton>
                  </Grid>
                </DialogTitle>

                <DialogContent dividers={true}>
                  <MultiFilterSelect<IUserType, IUserFormValues>
                    canSelectAll={false}
                    name="userIds"
                    required={false}
                    items={users ?? []}
                    loading={!users}
                    initialValues={[]}
                    entityName="users"
                    displayField="email"
                  />
                </DialogContent>
                <DialogActions>
                  <Button type="button" data-cy="cancelBtn" data-testid="cancelBtn" color="inherit" onClick={() => setShowUserDialog(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" data-cy="saveBtn" data-testid="saveBtn" disabled={!isValid || isSubmitting || !dirty}>
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>
  ) : null
}

export default HeaderAction
