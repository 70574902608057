import { useCallback, useContext, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

import { ClientSidePaginatedTable } from '../../../components/table'
import TableLink from '../../../components/table-link'
import TimeRangeSelectorForm, { ITimeRangeFormValues } from '../../../components/time-range-selector-form'
import { AlertContext } from '../../../providers'
import useSharedFormStyles from '../../../shared-styles/form.styles'
import Api from '../../../utils/api'
import { useDocumentTitle, useTableState } from '../../../utils/hooks'

type Props = { reactionId: string }

// backend gives us inconsistent response format for errors for different status, so need to account for that
export const getErrorMessage = (res: any): string => {
  let message = ''

  if (res.message) {
    message = res.message
  } else if (res.error === 'string') {
    message = res.error
  } else {
    message = 'Something went wrong'
  }

  return message
}

const SingleReactionHistory = (props: Props) => {
  const defaultDaysOfReactionsHistory = 1
  const defaultEndTime = moment().format('YYYY-MM-DD')
  const defaultStartTime = moment(defaultEndTime)
    .subtract(defaultDaysOfReactionsHistory, 'days')
    .format('YYYY-MM-DD')

  const [reactions, setReactions] = useState<IReactionHistoryType[]>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [timeRangeUpdatedCounter, setTimeRangeUpdatedCounter] = useState<number>(0)
  const [customStartTime, setCustomStartTime] = useState<string>(defaultStartTime)
  const [customEndTime, setCustomEndTime] = useState<string>(defaultEndTime)

  const classes = useSharedFormStyles()
  const { addAlert } = useContext(AlertContext)
  const { reactionId } = props

  useDocumentTitle('Reactions')

  const reactionsColumns = [
    { name: 'Processed', options: { filter: false, sort: false } },
    { name: 'Reaction ID', options: { filter: false, sort: false } },
    { name: 'Node ID', options: { filter: false, sort: false } },
    { name: 'Reference ID', options: { filter: false, sort: false } },
    { name: 'Response Code', options: { filter: false, sort: false } },
    { name: 'Status Message', options: { filter: false, sort: false } },
    { name: 'User ID', options: { filter: false, sort: false } },
  ]

  const fetchReactionsTableData = async () => {
    setShowSpinner(true)

    const res = await Api.get(`/api/reactions/${reactionId}/history?startTime=${customStartTime}&endTime=${customEndTime}`)

    // trying to handle a specific backend failure when 500 request returns us string response
    if (!res.error && typeof res !== 'string') {
      setReactions(res)
      setShowSpinner(false)
    } else if (res.error) {
      const errorMessage = getErrorMessage(res)
      setShowSpinner(false)
      addAlert({ alertType: 'error', message: errorMessage })
      return Promise.reject(res)
    }

    let allRowData: Array<Array<number | string | React.ReactNode>> = []

    allRowData = res.map((reaction: IReactionHistoryType) => {
      const nodeId = (
        <TableLink
          to={`/nodes/${reaction.value.NodeID}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {reaction.value.NodeID}
        </TableLink>
      )

      const userId = (
        <TableLink
          to={`/users/${reaction.value.UserID}`}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {reaction.value.UserID}
        </TableLink>
      )

      return [
        /// LLL will format time in user local language
        // i.e. March 3, 2020 11:31 AM for vs 3. März 2020 11:32
        moment(reaction.value.Processed).format('LLL'),
        reaction.value.ReactionID,
        nodeId,
        reaction.value.ReferenceID,
        reaction.value.ResponseCode,
        reaction.value.StatusMessage,
        userId,
      ]
    })

    return Promise.resolve({
      data: allRowData,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedReactionsFetch = useCallback(fetchReactionsTableData, [timeRangeUpdatedCounter])

  const reactionsState = useTableState(memoizedReactionsFetch)

  const initialValues = {
    endTime: defaultEndTime,
    startTime: defaultStartTime,
  }

  const schema = Yup.object().shape({
    startTime: Yup.date().required('Please select Start Date'),
    endTime: Yup.date()
      .required()
      .min(Yup.ref('startTime'), 'Must be bigger than Start Time'),
  })

  const updateTimeRange = (formValues: ITimeRangeFormValues) => {
    const startTime = moment(formValues.startTime).format('YYYY-MM-DD')
    setCustomStartTime(startTime)

    const endTime = moment(formValues.endTime).format('YYYY-MM-DD')
    setCustomEndTime(endTime)

    setTimeRangeUpdatedCounter(timeRangeUpdatedCounter + 1)
  }

  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <Typography className={classes.formSubheader} variant="subtitle1">
          Reaction Log
        </Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={updateTimeRange}>
          {({ isValid }) => {
            return (
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TimeRangeSelectorForm isFormValid={isValid} />
                </Grid>
              </Grid>
            )
          }}
        </Formik>
      </Grid>

      {reactions !== null && !showSpinner && (
        <Grid item={true} xs={12} data-cy="currentReactionHistoryTable">
          <ClientSidePaginatedTable
            title="Current Reaction History"
            searchOpen={false}
            columns={reactionsColumns}
            loading={reactionsState.loading}
            data={reactionsState.data}
            error={reactionsState.error}
            refresh={() => {}}
            data-testid="currentReactionHistoryTable"
            idList={reactions !== null ? reactions.map((reaction, index) => ({ id: index })) : []}
            options={{
              selectableRows: 'none' as const,
              pagination: true,
              textLabels: {
                body: {
                  noMatch: 'No reaction history for selected time range',
                  toolTip: '',
                },
              },
            }}
          />
        </Grid>
      )}

      {showSpinner && (
        <Grid item={true} xs={12} container={true} justifyContent="center">
          <CircularProgress className={classes.centerItem} color="secondary" />
        </Grid>
      )}
    </Grid>
  )
}

export default SingleReactionHistory
