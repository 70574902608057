import { useEffect } from 'react'

const RouteError = () => {
  useEffect(() => {
    throw new Error(`The path ${window.location.hash ? window.location.hash : window.location.hash} does not exist`)
  }, [])

  return <div />
}

export default RouteError
