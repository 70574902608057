import { useContext, useEffect, useState } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import classNames from 'classnames'
import { Form, Formik, FormikProps } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FormikCheckbox from '../../components/form-elements/checkbox'
import SingleFilterSelect from '../../components/form-elements/filter-select/single-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import OfficialDocsLink from '../../components/official-doc-link'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import { ThemeContext } from '../../providers/theme'
import api from '../../utils/api'
import { getSourceInitValue, handleDelete, handleFormSubmit, redirectToListViewOnError, setCodeMirrorSource } from '../../utils/form/form-helpers'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import ChannelsDropdown from './form-elements/channels-dropdown'
import NodeTypeField from './form-elements/nodetype-field'
import useLambdaFormStyles from './create-edit.styles'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/gruvbox-dark.css'

interface ILambdaFormValues {
  name: string
  channelName: string
  active: boolean
  payloadTimestamp: string
  source: string
  payloadValue: string
  nodeTypeId?: number
  nodeId?: number
}

// month and days should always be 2 digits long, i.e. 11 for Nov, 04 for April, 05 for 5 of the current month etc
export const getCorrectDateFormat = (date: number) => {
  const correctFormat = date.toString().length === 1 ? '' + 0 + date : date
  return correctFormat
}

// looks like for json we only need to encode stuff between curly braces
// and then wrap it again inside curly braces after encoding
export const encodeValue = (value: string) => {
  let encodedValue

  if (value.charAt(0) === '{' && value.slice(-1) === '}') {
    const valueWithoutJsonBraces = value.substr(1).slice(0, -1)
    encodedValue = encodeURI(valueWithoutJsonBraces)
    encodedValue = '{' + encodedValue + '}'
  } else {
    encodedValue = encodeURI(value)
  }

  return encodedValue
}

const LambdaForm = () => {
  const classes = useLambdaFormStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ lambdaId: string }>()
  const lambdaId = params.lambdaId
  const isEditMode = lambdaId !== 'new'

  const { themeKey } = useContext(ThemeContext)
  const [showConfirmDeletionModal, toggleConfirmDeletionModal] = useToggle(false)

  const [currentLambda, setCurrentLambda] = useState<ILambdaType | null>(null)
  const [nodetypesList, setNodetypesList] = useState<INodeTypeType[]>([])
  const [nodetypesError, setNodetypesError] = useState<string>('')
  const [nodetypesLoading, setNodetypesLoading] = useState<boolean>(false)
  const [nodesForNodetypeList, setNodesForNodetypeList] = useState<INodeType[]>([])
  const [nodesForNodetypeLoading, setNodesForNodetypeLoading] = useState<boolean>(false)
  const [source, setSource] = useState<string>('')
  const [updatedSourceValue, setUpdatedSourceValue] = useState<string>('')
  const [isLambdaTested, setIsLambdaTested] = useState<boolean>(false)
  const [defaultPayloadTimestamp, setDefaultPayloadTimestamp] = useState<string>('')
  const [lambdaTestResult, setLambdaTestResult] = useState<string>('')
  const [channels, setChannels] = useState<string[]>([])

  useDocumentTitle('Lambdas', isEditMode ? currentLambda?.name : 'new')

  useEffect(() => {
    let isSubscribed = true

    const date = new Date()
    const currentDate = `${date.getFullYear()}-${getCorrectDateFormat(date.getMonth() + 1)}-${getCorrectDateFormat(date.getDate())}`
    const currentTime = getCorrectDateFormat(date.getHours()) + ':' + getCorrectDateFormat(date.getMinutes()) + ':' + getCorrectDateFormat(date.getSeconds())
    // by default we show current time in payload timestamp input field
    setDefaultPayloadTimestamp(`${currentDate}T${currentTime}`)

    setNodetypesLoading(true)
    api.get('/api/nodetypes').then(data => {
      setNodetypesLoading(false)
      isSubscribed = true // need to re-assign the value explicitly for no error message render on save
      if (isSubscribed && !data.error) {
        setNodetypesList(data)
      } else {
        addAlert({ alertType: 'error', message: data?.message || 'Could not load nodetypes' })
        setNodetypesError(data?.message || 'Could not load nodetypes')
      }

      if (isEditMode) {
        api.get(`/api/lambdas/${lambdaId}`).then((retreivedlambda: ILambdaType) => {
          redirectToListViewOnError(retreivedlambda, history, addAlert, '/lambdas')

          if (isSubscribed) {
            setCurrentLambda(retreivedlambda)
            api.get(`/api/lambdas/${lambdaId}/source`).then((retreivedSource: string) => {
              // most of the time source is a string, however there are some weird cases when it is stored as an object on backend
              // might be just an old badly formatted data from the past that we can account for here
              if (typeof retreivedSource === 'string') {
                setSource(retreivedSource)
              } else if (typeof retreivedSource === 'object') {
                const stringifiedSource = JSON.stringify(retreivedSource)
                setSource(stringifiedSource)
              }
            })
          }
        })
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [lambdaId, isEditMode, history, addAlert, updatedSourceValue])

  const deleteLambda = async () => {
    try {
      handleDelete(await api.del('/api/lambdas', lambdaId), addAlert, history, '/lambdas')
    } catch (e) {
      addAlert({ alertType: 'error', message: 'Could not delete lambda' })
    }
  }

  const saveLambda = async (values: ILambdaFormValues, helpers: any) => {
    const { name, active, channelName, nodeTypeId } = values

    setUpdatedSourceValue(values.source)

    const lambdaPayload = {
      name,
      active,
      channelName,
      nodeTypeId,
    }

    try {
      if (isEditMode) {
        handleFormSubmit(await api.put(`/api/lambdas/${lambdaId}`, lambdaPayload), addAlert, history, null, values, helpers)
        await api.putFile(`/api/lambdas/${lambdaId}/source`, values.source)
      } else {
        let createdLambda

        handleFormSubmit((createdLambda = await api.post('/api/lambdas', lambdaPayload)), addAlert, history, `/lambdas/${createdLambda.id}`, values, helpers)

        await api.putFile(`/api/lambdas/${createdLambda.id}/source`, values.source)
      }
    } catch (e) {
      addAlert({ alertType: 'error', message: 'Could not submit lambda' })
    }

    helpers.setSubmitting(false)
  }

  const testLambda = async (formValues: ILambdaFormValues) => {
    setIsLambdaTested(false)
    setLambdaTestResult('')

    const nodeId = formValues.nodeId
    const channelName = formValues.channelName.length ? formValues.channelName : '-1'
    const timestamp = moment(formValues.payloadTimestamp).format('YYYY-MM-DD') + 'T' + moment(formValues.payloadTimestamp).format('HH:mm:ss') + 'Z'
    const encodedValue = encodeValue(formValues.payloadValue)

    const options = {
      headers: {
        Accept: 'text/html',
      },
    }

    try {
      let testResult = await api.putFile(
        `/api/lambdas/test?channelName=${channelName}&nodeId=${nodeId}&value=${encodedValue}&timestamp=${timestamp}`,
        formValues.source,
        options
      )

      if (testResult === '') {
        testResult = 'Something went wrong'
        addAlert({ alertType: 'error', message: 'Something went wrong' })
      } else if (testResult.error && testResult.message) {
        testResult = testResult.message
        addAlert({ alertType: 'error', message: testResult })
      }

      setIsLambdaTested(true)
      setLambdaTestResult(testResult)
    } catch (err) {
      console.error(err)
    }
  }

  const payloadAlertMessage =
    'Choose a payload value to test against the current lambda. Integers, booleans, and strings are supported. Strings must be enclosed in quotation marks.'

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    nodeTypeId: Yup.number()
      .required()
      .positive('Nodetype is required'), // -1 is used to indicate that no value is not selected in signle-select, so we need postiive() validation here
    channelName: Yup.string().required('Channel is required'),
  })

  const initialValues =
    isEditMode && currentLambda
      ? {
          name: currentLambda.name,
          channelName: currentLambda.channelName,
          active: currentLambda.active,
          nodeTypeId: currentLambda.nodeTypeId,
          payloadTimestamp: defaultPayloadTimestamp,
          source: getSourceInitValue(updatedSourceValue, source),
          nodeId: undefined,
          payloadValue: '',
        }
      : {
          name: '',
          channelName: '',
          active: false,
          nodeTypeId: undefined,
          payloadTimestamp: defaultPayloadTimestamp,
          nodeId: undefined,
          source: '',
          payloadValue: '',
        }

  return (
    <>
      {!nodetypesError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveLambda} validationSchema={schema}>
          {({ values, isValid, setFieldValue, touched, isSubmitting }: FormikProps<ILambdaFormValues>) => (
            <Form>
              <Card>
                <CardHeader title={isEditMode ? 'Edit Lambda' : 'Create Lambda'} data-cy="lambdaTitle" />

                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <Typography className={classes.noMarginSubheader} variant="subtitle1">
                        Config
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Grid container={true} spacing={5}>
                        <Grid item={true} xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                              <FormikTextField name="name" label="Name" required={true} id="lambdaName" data-cy="lambdaName" />
                            </Grid>

                            <Grid item={true} xs={12} data-cy="nodeType">
                              <NodeTypeField
                                nodeTypeId={values.nodeTypeId}
                                nodesList={nodesForNodetypeList}
                                nodetypesList={nodetypesList}
                                setNodesForNodetypeList={setNodesForNodetypeList}
                                setNodesForNodetypeLoading={setNodesForNodetypeLoading}
                                setChannels={setChannels}
                                touchedFormikObject={touched}
                                loading={nodetypesLoading}
                                retreivedLambda={currentLambda}
                              />
                            </Grid>

                            <Grid item={true} xs={12}>
                              <ChannelsDropdown
                                selectedNodeTypeId={values.nodeTypeId}
                                channels={channels}
                                setFieldValue={setFieldValue}
                                initialChannelName={initialValues.channelName}
                                initialNodeTypeId={initialValues.nodeTypeId}
                              />
                            </Grid>

                            <Grid item={true} xs={true} data-cy="activeCheckbox">
                              <FormikCheckbox name="active" label="Active" />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={7} lg={7} xl={7}>
                          <Grid container={true}>
                            <Grid item={true} xs={6}>
                              <div className={classes.sourceLabel}>Source</div>
                            </Grid>

                            <Grid item={true} xs={6}>
                              <div className={classes.docsLink}>
                                <OfficialDocsLink url="https://github.com/meshifyiot/public-carbon-docs/blob/master/concepts/lambdas.md" />
                              </div>
                            </Grid>
                          </Grid>

                          <Grid item={true} xs={12} data-cy="codeMirror">
                            <CodeMirror
                              value={values.source}
                              className={classNames([classes.border, classes.lambdaCodemirror])}
                              options={{
                                mode: 'xml',
                                lineWrapping: true,
                                lineNumbers: true,
                                theme: themeKey === 'dark' ? 'gruvbox-dark' : 'default',
                              }}
                              onBlur={codemirrorInstance => {
                                // Types are wrong, ignore, take lines from instance and concatenate them to the parent formik form.
                                setCodeMirrorSource(codemirrorInstance, setFieldValue)
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Grid container={true} spacing={10}>
                        <Grid item={true} xs={12} sm={12} md={5} lg={5} xl={5} data-cy="testConfigNode">
                          <Typography className={classes.lambdaFormSubheader} variant="subtitle1">
                            Test Config
                          </Typography>

                          <SingleFilterSelect
                            // this filter select shows list of all nodes for a selected node type
                            name="nodeId"
                            label="Select a node:"
                            required={false}
                            displayField="vanity"
                            items={nodesForNodetypeList}
                            loading={nodesForNodetypeLoading}
                            initialValue={undefined} // this filter select used for testing only, no data is ever saved in it
                          />

                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                              label="Payload Timestamp"
                              value={values.payloadTimestamp}
                              onChange={date => setFieldValue('payloadTimestamp', date)}
                              renderInput={renderParams => (
                                <TextField
                                  {...renderParams}
                                  variant="standard"
                                  fullWidth={true}
                                  data-cy="testConfigTimeStamp"
                                  helperText="Date to send with the test data point, defaults to today and now"
                                />
                              )}
                            />
                          </LocalizationProvider>

                          <FormikTextField
                            initialized={isEditMode}
                            name="payloadValue"
                            label="Payload Value"
                            required={false}
                            data-cy="testConfigPayloadValue"
                          />

                          <Button
                            color="secondary"
                            variant="outlined"
                            type="button"
                            data-cy="testConfigBtn"
                            data-testid="testLambdaBtn"
                            onClick={() => testLambda(values)}
                            disabled={values.nodeId == null || !values.payloadValue}
                          >
                            Test Lambda
                          </Button>
                        </Grid>

                        <Grid item={true} xs={12} sm={12} md={7} lg={7} xl={7} data-cy="testConfigOutput">
                          <Typography className={classes.lambdaFormSubheader} variant="subtitle1">
                            Test Output
                          </Typography>

                          <div className={classes.testOutputWrapper}>
                            {!isLambdaTested && (
                              <p className={classes.testOutputMessage}>To test a lambda, fill in the config fields and press the Test Lambda button</p>
                            )}
                            {isLambdaTested && <pre className={classes.testResult}>{lambdaTestResult}</pre>}
                          </div>

                          <Alert alertType="warning" message={payloadAlertMessage} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  <Button color="primary" type="submit" disabled={!isValid || isSubmitting} data-cy="saveBtn" data-testid="saveBtn">
                    Save
                  </Button>

                  <Button type="button" onClick={() => history.push('/lambdas')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                    Cancel
                  </Button>

                  {isEditMode && (
                    <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmDeletionModal}>
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteLambda}
        closeModal={toggleConfirmDeletionModal}
        dialogTitle="Are you sure you want to delete this lambda?"
      />

      {nodetypesError && (
        <PermissionsFormError errorMessages={combineErrorMessages(nodetypesError)} formName="Lambda Form" listViewRoute="/lambdas" hasGoBackButton={true} />
      )}
    </>
  )
}

export default LambdaForm
