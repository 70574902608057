import { useContext, useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import FormikTextField from '../../../components/form-elements/text-field'
import { AlertContext } from '../../../providers'
import Api from '../../../utils/api'

import useStyles from './styles'

interface IProps {
  nodeId: number
}
interface IFormValues {
  config: string
}

// this tab was a request from Hunter. He wants to be able to use the claims api to publish configuration to the node,
// which would queue a settings downlink message through the network server. The payloads for each sensor type are hardcoded and provided by Hunter
const SensorConfiguration = ({ nodeId }: IProps) => {
  const [claimId, setClaimId] = useState<number | null>(null)

  const { addAlert } = useContext(AlertContext)
  const classes = useStyles()

  useEffect(() => {
    Api.get(`/api/data/current?nodeId=${nodeId}`).then(res => {
      if (res.error) {
        addAlert({ message: res.message ?? 'Could not retreive claim id', alertType: 'error' })
      } else {
        if (res[nodeId]?.claimid?.value) {
          setClaimId(res[nodeId].claimid.value)
        }
      }
    })
  }, [nodeId, addAlert])

  const configOptions: string[] = ['Leak2, Default', 'Motion2, Default', 'Motion2, Vibration - Safes', 'Motion2, Vibration - Motors', 'Probe2, Default']

  const initialValues: IFormValues = {
    config: '',
  }

  const schema = Yup.object().shape({
    config: Yup.string().required('Please select a value'),
  })

  const handleSubmit = async (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
    let payload
    switch (values.config) {
      case 'Leak2, Default':
        payload = {
          deviceFeatures: 128,
          features: 180,
          interval: 7200,
          seqUnconf: 4,
          vibDebInterval: 10,
          vibFeatures: 16,
          vibRepInterval: 7200,
          vibReadInterval: 15,
          vibThresh: 20,
        }
        break
      case 'Motion2, Default':
        payload = {
          deviceFeatures: 224,
          features: 244,
          interval: 900,
          seqUnconf: 30,
          vibDebInterval: 10,
          vibFeatures: 16,
          vibRepInterval: 900,
          vibReadInterval: 15,
          vibThresh: 20,
        }
        break
      case 'Motion2, Vibration - Safes':
        payload = {
          deviceFeatures: 224,
          features: 252,
          interval: 900,
          seqUnconf: 30,
          vibDebInterval: 10,
          vibFeatures: 16,
          vibRepInterval: 900,
          vibReadInterval: 15,
          vibThresh: 20,
        }
        break
      case 'Motion2, Vibration - Motors':
        payload = {
          deviceFeatures: 224,
          features: 252,
          interval: 900,
          seqUnconf: 30,
          vibDebInterval: 10,
          vibFeatures: 16,
          vibRepInterval: 900,
          vibReadInterval: 15,
          vibThresh: 30,
        }
        break
      case 'Probe2, Default':
        payload = {
          deviceFeatures: 192,
          features: 244,
          interval: 900,
          seqUnconf: 30,
          vibDebInterval: 10,
          vibFeatures: 16,
          vibRepInterval: 900,
          vibReadInterval: 15,
          vibThresh: 20,
        }
        break
      default:
        payload = {}
    }

    if (claimId) {
      actions.setSubmitting(true)
      Api.post(`/api/claims/lora-chirpstack/${claimId}/settings/features`, payload).then(res => {
        actions.setSubmitting(false)
        if (res.error) {
          addAlert({ message: res.message ?? 'Failed to submit', alertType: 'error' })
        } else {
          addAlert({ message: 'Successfully Submitted', alertType: 'success' })
        }
      })
    } else {
      addAlert({ message: 'Failed to submit. No claim id was found for this node', alertType: 'error' })
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
      {({ isValid, isSubmitting, dirty }) => (
        <Form>
          <Grid spacing={3} container={true}>
            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormikTextField name="config" label="Sensor Configuration" select={true}>
                {configOptions
                  .sort((a, b) => {
                    return a.toLowerCase() < b.toLowerCase() ? -1 : a > b ? 1 : 0
                  })
                  .map(option => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    )
                  })}
              </FormikTextField>
            </Grid>

            <Grid item={true} xs={12}>
              <Button color="primary" type="submit" disabled={!isValid || isSubmitting || !dirty} data-testid="saveBtn" className={classes.saveConfigButton}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default SensorConfiguration
