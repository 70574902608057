import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import useTableStyles from '../../shared-styles/table.styles'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { filterActiveColumn, filterLinkColumn, filterPlainTextColumn, handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'

export const getRule = (rules: IRuleType[], ruleId: number) => {
  return rules.filter(rule => rule.id === ruleId)[0]
}

/** get array of unique rule names, we will display them in Rule dropdown in fitler panel */
export const getUniqueRuleNames = (allReactions: IReactionType[], allRules: IRuleType[]): string[] => {
  const uniqueRuleNames: string[] = []

  allReactions.forEach(reaction => {
    allRules.forEach(rule => {
      // add rule, if doesn't exist already
      if (reaction.ruleId === rule.id && uniqueRuleNames.indexOf(rule.name) === -1) {
        uniqueRuleNames.push(rule.name)
      }
    })
  })

  return uniqueRuleNames
}

const ReactionsList = () => {
  const history = useHistory()
  const classes = useTableStyles()
  const { addAlert } = useContext(AlertContext)
  const searchResultsHook = useSearchData()

  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const [reactions, setReactions] = useState<IReactionType[]>([])
  const [rules, setRules] = useState<IRuleType[]>([])

  useDocumentTitle('Reactions')

  const columns = [
    { name: 'Name', options: { filter: false, sort: true } },
    {
      name: 'Rule Type',
      options: {
        searchable: false,
        filter: true,
        sort: true,
        filterOptions: {
          // hardcoding those names so that even if no reactions in table have 'Exit' rule type,
          // user still can select it from dropdown and see that there are no reactions for this rule type
          names: ['enter', 'exit', 'always'],
          logic: (location: string, filters: string[]) => filterPlainTextColumn(location, filters),
        },
      },
    },
    {
      name: 'Rule',
      options: {
        searchable: false,
        filter: true,
        sort: false,
        filterOptions: {
          names: getUniqueRuleNames(reactions, rules),
          logic: (location: any, filters: string[]) => filterLinkColumn(location, filters),
        },
      },
    },
    {
      name: 'Type',
      options: {
        searchable: false,
        filter: true,
        sort: true,
        filterOptions: {
          // hardcoding those names so that even if no reactions in table have type 'sms',
          // user still can select it from dropdown and see that there are no reactions for this type
          names: ['sms', 'email', 'webhook', 'phone', 'push'],
          logic: (location: string, filters: string[]) => filterPlainTextColumn(location, filters),
        },
      },
    },
    {
      name: 'Active',
      options: {
        searchable: false,
        filter: true,
        sort: true,
        filterOptions: {
          names: ['Active', 'Not Active'],
          logic: (location: any, filters: string[]) => filterActiveColumn(location, filters),
        },
      },
    },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchReactionsData = async () => {
    const retrievedReactions = await Api.get('/api/v2/reactions')
    const retrievedRules = await Api.get('/api/rules')

    if (retrievedReactions.error) {
      addAlert({ alertType: 'error', message: retrievedReactions.message })
      return Promise.reject(retrievedReactions)
    }

    if (retrievedRules.error) {
      addAlert({ alertType: 'error', message: retrievedRules.message })
    } else {
      setRules(retrievedRules)
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = retrievedReactions.reactions.map((reaction: IReactionType) => {
      const rule = !retrievedRules.error ? getRule(retrievedRules, reaction.ruleId) : null

      const ruleName = rule ? (
        <Link
          to={`/rules/${rule.id}`}
          className={classes.applicationLink}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {rule.name ?? ''}
        </Link>
      ) : (
        ''
      )

      const isActive = reaction.active ? <Icon className={classes.greenColor}>check</Icon> : <Icon className={classes.redColor}>close</Icon>

      return [reaction.name, reaction.eventType, ruleName, reaction.type, isActive, convertTimestampToDate(reaction.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setReactions(retrievedReactions.reactions) })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchReactionsData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  const NewReactionLink = forwardRef((linkProps: any, ref: any) => <Link to="/reactions/new" ref={ref} {...linkProps} />)

  return (
    <Grid className={classes.tableContainer} container={true} justifyContent="flex-end" data-cy="reactionsList">
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewReactionLink}
              entityName="Reactions"
              buttonName="Add Reaction"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Reactions"
          entityDeleteEndpoint="/api/reactions"
          idList={reactions != null ? reactions.map(r => ({ id: r.id })) : []}
          refresh={() => setRefreshCounter(c => c + 1)}
          preserveSearchResults={{ ...searchResultsHook }}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          options={{
            filter: true,
            filterType: 'dropdown',
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) =>
              handleCellClick(history, reactions, 'reactions', cellMeta, searchResultsHook.abort, '?tab=0'),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="reactions"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!reactions?.find(reaction => reaction.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ReactionsList
